import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import CancelableAPIRequest from 'src/models/CancelableAPIRequest'
import { useAuthState } from 'src/providers/AuthProviders'
import getUnreadNews from 'src/services/get/getUnreadNews'
import { setHasNewsNotifications } from 'src/redux/notfication/getNotifications'

const useNewsHasNotifications = () => {
  const { user } = useAuthState()
  const dispatch = useDispatch()

  useEffect(() => {
    const req = new CancelableAPIRequest(getUnreadNews, user?.id)
    req.invoke((resp) => {
      dispatch(setHasNewsNotifications(resp))
    })

    return () => {
      req.cancel()
    }
  }, [])
}

export default useNewsHasNotifications
