import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import DarkPink from 'src/assets/img/shop/dark_pink_arrow.png'

import { useMutation } from 'react-query'
import { authActionTypes, useAuthDispatch, useAuthState } from 'src/providers/AuthProviders'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import { getExchangeHeartByRedDiamond } from 'src/services/get/getHeartExchangeConfigs'
import ShopExchangeCompleteModal from 'src/pages/main/shop/components/ShopHeartTab/ShopExchangeCompleteModal'
import ShopExchangeInsufficientModal from 'src/pages/main/shop/components/ShopHeartTab/ShopExchangeInsufficientModal'
import ExchangeResult from 'src/pages/main/shop/components/ShopDiamondTab/ExchangeResult'

import DiamondRed from 'src/assets/img/common/diamond-red.png'
import Life from 'src/assets/img/common/life.png'

const ItemExchangeBox = styled.div.attrs(({ boxStyle }) => ({
  className: `${boxStyle} rounded-xl flex font-MPlus1 font-bold text-silver items-center gap-4 justify-around p-1`
}))``

const ShopExchangeConfirmModal = () => {
  const authDispatch = useAuthDispatch()
  const { user } = useAuthState()
  const { setModalContent, closeModal, selectedHeartExchange, setButtons, setTitle } =
    useShopContext()

  const { mutate: handleExchange } = useMutation(getExchangeHeartByRedDiamond, {
    onSuccess: ({ data }) => {
      if (!data) return

      authDispatch({ type: authActionTypes.EXCHANGE_HEART_BY_RED_DIAMOND, payload: data })
      setModalContent(<ShopExchangeCompleteModal />)
    }
  })

  const {
    number_of_red_diamonds: numberOfRedDiamonds,
    number_of_hearts_exchanged: numberOfHeartsExchanged
  } = selectedHeartExchange

  const currentRedDiamonds = user?.red_amount
  const currentPossessionHeart = user.possession_heart

  const onConfirm = useCallback(() => {
    if (currentRedDiamonds >= numberOfRedDiamonds) {
      handleExchange(selectedHeartExchange.id)
    } else {
      setModalContent(<ShopExchangeInsufficientModal />)
    }
  }, [])

  useEffect(() => {
    setButtons([
      {
        label: 'キャンセル',
        onClick: closeModal,
        type: 'close'
      },
      {
        label: 'OK',
        onClick: onConfirm
      }
    ])
    setTitle('確認')
  }, [])

  return (
    <>
      <div className="text-candy-pink font-bold text-lg bg-white w-full font-Inter flex flex-col gap-4 py-4 items-center">
        <p className="text-center whitespace-pre-line ">
          {`赤ダイヤ${numberOfRedDiamonds}個をハート${numberOfHeartsExchanged}個に\n交換しますか？`}
        </p>
        <ItemExchangeBox boxStyle="bg-[#F8F8F8] px-4">
          <div className="flex items-center gap-2">
            <img src={DiamondRed} className="w-10" />
            <span>{numberOfRedDiamonds}</span>
          </div>
          <img src={DarkPink} className="w-4 h-4" />
          <div className="flex items-center gap-2">
            <img src={Life} className="w-10" />
            <span>{numberOfHeartsExchanged}</span>
          </div>
        </ItemExchangeBox>
        <p>所持赤ダイヤ</p>
        <ExchangeResult
          theme='pink'
          imgSrc={DiamondRed}
          from={currentRedDiamonds}
          to={currentRedDiamonds - numberOfRedDiamonds}
        />
        <p>所持ピンクハート</p>
        <ExchangeResult
          theme='pink'
          imgSrc={Life}
          from={currentPossessionHeart}
          to={currentPossessionHeart + numberOfHeartsExchanged}
        />
      </div>
    </>
  )
}

export default ShopExchangeConfirmModal
