import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Tab } from '@headlessui/react'
import HeaderTabButton from 'src/components/common/HeaderTabButton'
import BackButton from 'src/components/common/BackButton'

import bellIcon from 'src/assets/img/menu/gradient_header/bell_icon.png'
import giftIcon from 'src/assets/img/menu/gradient_header/gift_box_icon.png'
import diamondIcon from 'src/assets/img/menu/gradient_header/diamond_icon.png'
import speakerOnIcon from 'src/assets/img/menu/gradient_header/speaker_on_icon.png'
import speakerOffIcon from 'src/assets/img/menu/gradient_header/speaker_off_icon.png'
import crownIcon from 'src/assets/img/menu/gradient_header/crown_icon.png'
import calendarIcon from 'src/assets/img/menu/gradient_header/calendar_icon.png'

const GradientHeader = ({ onHandleTabChange, title, tabList, backPath }) => {
  const [icon, setIcon] = useState({})

  const generateIcon = (title) => {
    switch (title) {
      case 'TALENT':
        return {
          link: diamondIcon,
          icon: 'Diamond Icon',
          className: 'absolute right-2 top-[-0.25rem]'
        }
      case 'プレゼントBOX':
        return {
          link: giftIcon,
          icon: 'Gift Icon',
          className: 'absolute h-[5rem] top-0 right-[-1rem]'
        }
      case 'お知らせ':
        return {
          link: bellIcon,
          icon: 'Bell Icon',
          className: 'absolute right-[-1.5rem] h-[6rem] top-[-0.25rem] overflow-hidden'
        }
      case '開催中イベント':
        return {
          link: speakerOnIcon,
          icon: 'Speaker On Icon',
          className: 'absolute right-[-0.75rem] h-[4.5rem] top-3'
        }
      case '過去イベント':
        return {
          link: speakerOffIcon,
          icon: 'Speaker Off Icon',
          className: 'absolute right-[-0.75rem] h-[4.5rem] top-3'
        }
      case 'ランキング':
        return {
          link: crownIcon,
          icon: 'Crown Icon',
          className: 'absolute h-[7rem] top-[-0.55rem] right-[-1.6rem]'
        }
      case '配信スケジュール':
        return {
          link: calendarIcon,
          icon: 'Calendar Icon',
          className: 'absolute h-[4.5rem] top-2 right-[-1.2rem]'
        }
      default:
        break
    }
  }

  useEffect(() => {
    setIcon(generateIcon(title))
  }, [])

  return (
    <Tab.List className="bg-gradient-sky-to-pink z-10 drop-shadow-md shadow-md flex px-6 justify-between overflow-hidden h-[80px] relative">
      <BackButton backPath={backPath} />
      <div className="font-bold w-2/3 flex flex-col items-center justify-center h-full gap-1">
        <div className="font-Inter text-[1.375rem]">{title}</div>
        {tabList?.length > 0 && (
          <ul className="flex gap-2 mb-2 items-center">
            {tabList.map((tab) => (
              <li key={tab.index}>
                <HeaderTabButton
                  title={tab.title}
                  onClick={() => onHandleTabChange(tab.index)}
                  hasNotification={tab.hasNotification}
                  textStyle={tab.textStyle}
                  activeTextStyle={tab.activeTextStyle}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
      <div />
      <img src={icon.link} alt={icon.name} className={icon.className} />
    </Tab.List>
  )
}

GradientHeader.propTypes = {
  onHandleTabChange: PropTypes.func,
  title: PropTypes.string.isRequired,
  tabList: PropTypes.array,
  backPath: PropTypes.string
}

export default GradientHeader
