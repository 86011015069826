import { useCallback } from 'react'
import { useFetchRankingTabData } from '../hooks/useFetchRankingTabData'

import RankingLayout from 'src/pages/myPage/Ranking/RankingLayout'
import RankingTabItem from 'src/components/ranking/RankingTabItem'
import { TimeTabs } from 'src/components/ranking/TimeTabs'

import { Tabs } from 'src/constants/common'

const TalentRankingContainer = () => {
  const pageType = 'talent'
  const { activeKey, setActiveKey, rankings, rankingCount } = useFetchRankingTabData(pageType)
  const handleTabChange = useCallback((index) => {
    setActiveKey(Tabs[index].key)
  }, [])

  return (
    <RankingLayout title="ランキング" activetab="talent" classNameHeader="!bg-gradient-sky-to-pink">
      <TimeTabs onChangeTab={handleTabChange} tabs={Tabs} gap="gap-5" activeKey={activeKey} />
      <div style={{ height: 'calc(100dvh - 264px)' }}>
        <ul
          className="mt-2 overflow-y-auto hidden-scroll"
          style={{ maxHeight: 'calc(100dvh - 264px)' }}
        >
          {Array.from({ length: rankingCount }, (_, index) => {
            const rankingItem = rankings[`rank_${index + 1}`]
            return rankingItem && <RankingTabItem key={index} item={rankingItem} />
          })}
        </ul>
      </div>
    </RankingLayout>
  )
}

export default TalentRankingContainer
