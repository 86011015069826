import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LiveExtraTime } from '../../components/LiveExtraTime'
import StartExtraTime from '../../components/StartExtraTime'
import _ from 'lodash'
import { HourglassModal } from './_HourglassModal'
import { LiveComments } from './_LiveComments'
import { LiveItemModal } from './_LiveItemModal'
import { useDuringContext } from './_Provider'
import { withDuringLayout } from './_withDuringLayout'
import { useSubcriber } from '../../hooks/useSubcriber'
import { postCountinueExtraTime } from 'src/services/post/postContinueExtraTime'
import { useAuthState } from '../../../../providers/AuthProviders'
import MultiLoveEffectModal from 'src/components/Effect/MultiLoveEffectModal'
import { NormalTime } from '../../components/NormalTime'
import lovePointIcon from 'src/pages/voiceLive/assets/love_point.png'
import whiteBackwardVector from 'src/assets/img/common/white_backward_rounded_vector.png'
import { TimeHelper } from './_TimeHelper'

const DuringDelivery = withDuringLayout(() => {
  const { liveInfoQuery, liveItemsSocket } = useDuringContext()
  const { data: liveInfos } = liveInfoQuery
  const navigate = useNavigate()
  const startedExtraTime = useRef({ showStartedExtra: () => {} })
  const isPremiumTime = _.get(liveInfos, 'is_premium_time')
  const isExtraTime = _.get(liveInfos, 'is_extra_time')
  const { user } = useAuthState()
  const isHasPassport = Boolean(_.get(user, 'platinum_passport'))
  const isHasDreamCompass = Boolean(_.get(user, 'dream_compass'))
  const isRequireDreamCompass = _.get(liveInfos, 'is_dream_compass')
  const isRequirePassport = _.get(liveInfos, 'is_platinum_passport')
  const isFreePassport = _.get(liveInfos, 'is_free_platium_passport')
  const canWorkWithFreePassport = _.get(liveInfos, 'can_work_with_free_passport')
  const [lovePoint, setLovePoint] = useState(_.get(liveInfos, 'live_stream.love_point'))
  const [isMultiLoveModalOpen, setIsMultiLoveModalOpen] = useState(false)
  const [multiLevel, setMultiLevel] = useState({})

  const normalPassportModal = useMemo(() => {
    if (isRequirePassport) {
      if (isFreePassport) {
        return isHasPassport
      } else {
        return true
      }
    } else {
      return true
    }
  }, [isHasPassport, isRequirePassport, isFreePassport])

  const { channelName, appId, token } = useMemo(() => {
    const memoizedValue = {
      channelName: _.get(liveInfos, 'agora_infos.channel'),
      appId: _.get(liveInfos, 'agora_infos.app_id'),
      token: _.get(liveInfos, 'agora_infos.token')
    }
    return memoizedValue
  }, [liveInfos])

  const closeMultiLoveModal = useCallback(() => {
    setIsMultiLoveModalOpen(false)
    localStorage.removeItem('item')
    localStorage.removeItem('currentLevel')
    localStorage.removeItem('nextLevel')
  }, [setIsMultiLoveModalOpen])

  const handleComplete = async () => {
    if (liveItemsSocket.enableExtraTime) {
      await liveInfoQuery.refetch()
      const iconHelp = document.getElementById('icon-help')
      if (iconHelp) {
        iconHelp.click()
      }
      liveItemsSocket.setLimittedHourglass(false)
      startedExtraTime.current?.showStartedExtra()
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if (!liveInfos) return

    if (!_.get(liveInfos, 'can_join_live_stream')) {
      navigate(-1)
      return
    }

    if (
      _.get(liveInfos, 'white_list').length > 0 &&
      !_.get(liveInfos, 'white_list').includes(_.get(user, 'id'))
    ) {
      navigate(-1)
      return
    }

    if (
      _.get(liveInfos, 'black_list').length > 0 &&
      _.get(liveInfos, 'black_list').includes(_.get(user, 'id'))
    ) {
      navigate(-1)
      return
    }

    if (isRequireDreamCompass && !isHasDreamCompass) {
      navigate(-1)
      return
    }

    if (isRequirePassport) {
      if (isFreePassport && !isHasPassport && (_.get(liveInfos, 're_joined') || isExtraTime)) {
        navigate(-1)
        return
      }

      if (!isFreePassport && !isHasPassport) {
        navigate(-1)
        return
      }
    }

    if (_.get(liveInfos, 'live_stream.status') !== 'incomming') {
      navigate(-1)
    } else if (
      isExtraTime &&
      !_.get(liveInfos, 'can_join_extra_time') &&
      _.get(liveInfos, 'can_continue_extra')
    ) {
      startedExtraTime.current?.showStartedExtra()
      navigate(-1)
    } else {
      liveItemsSocket.setEnableExtraTime(_.get(liveInfos, 'enable_extra_time'))
      liveItemsSocket.setExtraWatchableTime(_.get(liveInfos, 'extra_watchable_time'))
      liveItemsSocket.setLimittedHourglass(_.get(liveInfos, 'can_watch_till_normal_time'))
      if (isExtraTime) {
        liveItemsSocket.setLimittedHourglass(_.get(liveInfos, 'can_watch_till_end_live'))
        startedExtraTime.current?.showStartedExtra()
      }
      if (!_.get(liveInfos, 're_joined')) {
        document.getElementById('icon-help').click()
      }
    }
  }, [liveInfos])

  useEffect(() => {
    if (!liveItemsSocket.disableUser) return

    navigate(-1)
  }, [liveItemsSocket.disableUser])

  useEffect(() => {
    if (!liveItemsSocket.loveItem) return

    setLovePoint((prevLovePoint) => prevLovePoint + liveItemsSocket.loveItem)
    liveItemsSocket.setLoveItem(0)
  }, [liveItemsSocket.loveItem])

  useSubcriber({
    channelName,
    appId,
    token,
    settingId: _.get(liveInfos, 'live_stream.livestream_setting_id')
  })

  const handleCompleteContinue = () => {
    postCountinueExtraTime({ livestream_id: _.get(liveInfos, 'live_stream.id') }).then(() => {
      navigate(-1)
    })
  }
  return (
    <>
      {_.get(liveInfos, 'live_stream.watchable_time') && (
        <div className="relative h-full flex flex-col pt-1 pl-4 pb-4">
          <div className="flex items-center justify-between">
            <button
              className="w-12 h-12 flex items-center justify-center rounded-full "
              onClick={() => navigate(-1)}
              style={{
                background: 'linear-gradient(to right, #C0E4FF 0, #D3B8FF 100%)'
              }}
            >
              <img className="w-fit h-fit mr-1" src={whiteBackwardVector} alt="back" />
            </button>
            <div className="flex flex-col items-center">
              <NormalTime
                time={_.get(liveInfos, 'live_stream.watchable_time')}
                onComplete={handleComplete}
              />

              {!isPremiumTime && (<LiveExtraTime
                time={_.get(liveInfos, 'count_down_extra_time')}
                className="relative"
              />)}

              <StartExtraTime
                time={
                  !_.get(liveInfos, 'can_continue_extra') &&
                  new Date(_.get(liveInfos, 'extra_continue_time')) > new Date()
                    ? _.get(liveInfos, 'extra_continue_time')
                    : _.get(liveInfos, 'extra_watchable_time')
                }
                ref={startedExtraTime}
                onComplete={handleCompleteContinue}
                className="relative"
              />
              <div className="flex -mr-8 mt-1">
                <div className="bg-[#9724C2] rounded-3xl px-2 py-0.5 w-fit text-white text-[10px] font-semibold h-fit">
                  <img src={lovePointIcon} className="inline-block mr-0.5" />
                  {lovePoint}
                </div>
                <TimeHelper
                  normalPassportModal={normalPassportModal}
                  freeMinutes={_.get(liveInfos, 'passport_free_time')}
                />
              </div>
            </div>
          </div>

          <MultiLoveEffectModal
            character={_.get(liveInfos, 'character')}
            isMultiLoveModalOpen={isMultiLoveModalOpen}
            multiLevel={multiLevel}
            onClose={closeMultiLoveModal}
          />

          <div className="flex-1 flex items-end gap-3 pr-2">
            <div className="relative flex flex-col justify-end flex-1">
              <LiveComments
                normalPassportModal={normalPassportModal}
                canWorkWithFreePassport={canWorkWithFreePassport}
                livestreamId={_.get(liveInfos, 'live_stream.id')}
              />
            </div>
            <div className="pb-2">
              <HourglassModal
                isExtraTime={_.get(liveInfos, 'is_extra_time')}
                setIsMultiLoveModalOpen={setIsMultiLoveModalOpen}
                setMultiLevel={setMultiLevel}
                normalPassportModal={normalPassportModal}
              />
              <LiveItemModal
                setIsMultiLoveModalOpen={setIsMultiLoveModalOpen}
                setMultiLevel={setMultiLevel}
                normalPassportModal={normalPassportModal}
                canWorkWithFreePassport={canWorkWithFreePassport}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
})
export { DuringDelivery }
