import ModalCommon from 'src/components/common/ModalCommon'
import PropTypes from 'prop-types'
import { closeModalButton } from 'src/constants/button_close'

export const ModalNoticeJoin = ({ title, isOpen, onClose, children }) => {
  return (
    <ModalCommon
      title={title}
      open={isOpen}
      onClose={onClose}
      height='max-h-fit'
      buttons={[closeModalButton('閉じる', onClose)]}
    >
      {children}
    </ModalCommon>
  )
}

ModalNoticeJoin.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any
}
