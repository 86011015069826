import { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import { useGetRecommendsData } from 'src/services/hooks/useFetchRecommends'
import { useFetchRankingTabData } from 'src/pages/myPage/Ranking/hooks/useFetchRankingTabData'
import useLoading from 'src/components/layouts/hooks/useLoading'

import { Wrapper } from 'src/components/common/Wrapper'
import RankingLayout from 'src/pages/myPage/Ranking/RankingLayout'
import RankingTabItem from 'src/components/ranking/RankingTabItem'
import RecommendedMan from 'src/components/myPage/RecommendedMan'
import Loading from 'src/components/layouts/Loading'
import { TimeTabs } from 'src/components/ranking/TimeTabs'

import { CIRCLE_IMAGE_OF_CHARACTER } from 'src/constants/characters'
import { Tabs } from 'src/constants/common'

const MyLoveLevelContainer = () => {
  const pageName = 'love'
  const {
    activeKey,
    setActiveKey,
    rankings,
    rankingCount,
    isLoading: isRankingDataLoading
  } = useFetchRankingTabData(pageName)

  const recommends = useGetRecommendsData(activeKey)

  const findRecommendMan = useMemo(
    () => recommends.data?.find((man) => man.recommend),
    [recommends.data]
  )
  const recommendManImage = useMemo(() => {
    if (!findRecommendMan) return
    return CIRCLE_IMAGE_OF_CHARACTER[findRecommendMan.id]
  }, [findRecommendMan])

  const handleTabChange = useCallback((index) => {
    setActiveKey(Tabs[index].key)
  }, [])
  const isLoadingRecommend = useMemo(() => recommends.isLoading, [recommends])
  const { progress } = useLoading([isLoadingRecommend, isRankingDataLoading])

  if (isLoadingRecommend || isRankingDataLoading) {
    return (
      <Wrapper>
        <Loading progress={progress} />
      </Wrapper>
    )
  } else {
    return (
      <RankingLayout
        title="ランキング"
        activetab="mylovelevel"
        classNameHeader="!bg-gradient-sky-to-pink"
      >
        <TimeTabs onChangeTab={handleTabChange} tabs={Tabs} gap="gap-5" activeKey={activeKey} />
        <Wrapper>
          {findRecommendMan && (
            <RecommendedMan
              infor={{ ...findRecommendMan, imageSrc: recommendManImage }}
              isHideTitle
              activeKey={activeKey}
              isShowRankingTag
            />
          )}
          <ul
            className="mt-2 overflow-y-auto hidden-scroll"
            style={{ maxHeight: 'calc(100dvh - 362px)' }}
          >
            {Array.from({ length: rankingCount }, (_, index) => {
              const rankingItem = rankings[`rank_${index + 1}`]
              return rankingItem && <RankingTabItem key={index} item={rankingItem} />
            })}
          </ul>
        </Wrapper>
      </RankingLayout>
    )
  }
}

MyLoveLevelContainer.propTypes = {
  activeKey: PropTypes.string
}

export default MyLoveLevelContainer
