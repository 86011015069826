import { useContext, useEffect, useState, Children, cloneElement, isValidElement } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { MYPAGE_PATHS } from 'src/routes/myPage'
import { HistoryRouteContext } from 'src/routes'

import { Tab } from '@headlessui/react'
import StandardPageWrapper from 'src/components/common/StandardPageWrapper'
import { MainLayout } from 'src/components/layouts/MainLayout'

import GradientHeader from 'src/components/common/GradientHeader'

const NoticeWrapper = styled.div.attrs({
  className: ''
})`
  height: calc(100dvh - 140px);
`

const Content = styled.div.attrs({
  className: 'hidden-scroll overflow-y-auto font-medium'
})`
  height: calc(100dvh - 200px);
`

const RankingLayout = ({
  children,
  title,
  activetab,
  isHideHeader,
  classNameHeader = 'shadow-custom backdrop-blur-sm'
}) => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const tabList = [
    {
      title: 'TALENTRANKING',
      hasNotification: false,
      index: 0,
      textStyle: 'text-[0.6rem] xs:text-[0.75rem] leading-[1rem] px-2',
      activeTextStyle: 'background: linear-gradient(90deg, #40E7FF 0%, #FF48E2 100%)'
    },
    {
      title: 'MYLOVELEVEL',
      hasNotification: false,
      index: 1,
      textStyle: 'text-[0.6rem] xs:text-[0.75rem] leading-[1rem] px-2',
      activeTextStyle: 'background: linear-gradient(90deg, #40E7FF 0%, #FF48E2 100%)'
    }
  ]

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { title, activetab })
    }

    return child
  })

  const handleChangeTab = (tabIndex) => {
    setSelectedIndex(tabIndex)
    if (tabIndex === 0) redirectTo(MYPAGE_PATHS.MyTalentPage)
    else if (tabIndex === 1) redirectTo(MYPAGE_PATHS.MyLoveLevelPage)
  }

  useEffect(() => {
    setSelectedIndex(activetab === 'mylovelevel' ? 1 : 0)
  }, [activetab])

  return (
    <MainLayout classNameHeader={classNameHeader} active="mypage">
      <StandardPageWrapper className="mt-10 pb-[40px]" overflow={true}>
        <NoticeWrapper>
          <Tab.Group selectedIndex={selectedIndex}>
            {!isHideHeader && (
              <GradientHeader tabList={tabList} onHandleTabChange={handleChangeTab} title={title} />
            )}
            <Tab.Panels className="sm:w-desktop-width relative">
              <Content>{childrenWithProps}</Content>
            </Tab.Panels>
          </Tab.Group>
        </NoticeWrapper>
      </StandardPageWrapper>
    </MainLayout>
  )
}

RankingLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  activetab: PropTypes.string,
  classNameHeader: PropTypes.string,
  isHideHeader: PropTypes.bool
}

export default RankingLayout
