import { useState, useEffect, useMemo, useRef, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import useNavigateBack from 'src/hooks/useNavigateBack'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import useLoading from 'src/components/layouts/hooks/useLoading'

import { getHistoryManga } from 'src/services/get/getHistoryManga'
import MangaInfoModal from 'src/components/Manga/MangaInfoModal'
import MangaContents from 'src/components/Manga/MangaContents'
import SettingsModal from 'src/components/Manga/SettingModal'
import { MainLayout } from 'src/components/layouts/MainLayout'
import Loading from 'src/components/layouts/Loading'
import ModalCommon from 'src/components/common/ModalCommon'

import { Wrapper } from 'src/components/common/Wrapper'
import { MAIN_PATHS } from 'src/routes/main'
import { HistoryRouteContext } from 'src/routes'
import { birthCartoonBGM } from 'src/constants/character_bgm'
import { characterNames } from 'src/constants/characters'

import FloatBackButton from 'src/assets/img/common/float_back_btn.png'
import GearIcon from 'src/assets/img/common/gear_icon.png'

const GearButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="absolute bottom-[0.5dvh] right-5 cursor-pointer opacity-80 transition-opacity duration-300 ease-in-out z-[100]"
    >
      <img src={GearIcon} alt="gear icon" />
    </button>
  )
}

GearButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export const RecommendMangaPage = () => {
  const ref = useRef(null)
  const handleBack = useNavigateBack()
  const { redirectTo } = useContext(HistoryRouteContext)
  const { id, name } = useParams()
  const [data, setData] = useState([])
  const [setting, setSetting] = useState({
    bgm: true
  })
  const [settingForm, setSettingForm] = useState({ ...setting })
  const [loading, setLoading] = useState(true)
  const [isMangaInfoOpen, setIsMangaInfoOpen] = useState(false)
  const [isBGMModalOpen, setIsBGMModalOpen] = useState(false)
  const [currPage, setCurrPage] = useState(1)
  const [mangaInfoButtons] = useState([
    {
      label: 'キャンセル',
      type: 'close',
      onClick: () => {
        setIsMangaInfoOpen(false)
      }
    },
    {
      label: 'ショップへ',
      onClick: () => {
        redirectTo(MAIN_PATHS.ShopPage)
        setIsMangaInfoOpen(false)
      }
    }
  ])

  const bgmModalButtons = useMemo(
    () => [
      {
        label: 'OK',
        onClick: () => {
          setSetting(settingForm)
          setIsBGMModalOpen(false)
        }
      }
    ],
    [settingForm, isBGMModalOpen]
  )

  const fetchData = useCallback(async () => {
    const result = await getHistoryManga(id)
    if (result) setData(result)
    setLoading(false)
  }, [id])

  useEffect(() => {
    fetchData()
  }, [])

  const limitPageNumber = useMemo(() => data.limit_page_number, [data])

  const nameTitle = useMemo(() => (characterNames.includes(name) ? name : 'noname'), [name])

  const bgm = useMemo(() => birthCartoonBGM(id, currPage), [id, currPage])

  useEffect(() => {
    if (!ref.current) return
    if (setting.bgm) {
      ref.current.play()
    } else {
      ref.current.pause()
    }
  }, [ref.current, setting, bgm])

  const toggleModal = useCallback(() => setIsMangaInfoOpen(!isMangaInfoOpen), [isMangaInfoOpen])

  const { showLoading, progress } = useLoading([loading])

  if (showLoading) {
    return (
      <Wrapper>
        <Loading progress={progress} />
      </Wrapper>
    )
  }

  return (
    <MainLayout
      active="menu"
      classNameHeader=""
      className="h-full mana-wrapper"
      showHeader={true}
      showFooter={true}
    >
      {bgm && <audio ref={ref} src={bgm} autoPlay loop preload="auto" />}
      <Header>
        <button type="button" onClick={handleBack}>
          <img src={FloatBackButton} className="w-[64px]" alt="return button" />
        </button>
        <h2 className="text-lg">生い立ち漫画</h2>
        <h2 className="text-[1.375rem]">{nameTitle}</h2>
      </Header>

      <Content>
        <MangaContents
          data={data.items}
          allPageOfManga={data.all_page_of_manga}
          currPage={currPage}
          setCurrPage={setCurrPage}
          limitPageNumber={limitPageNumber}
          isOpen={isMangaInfoOpen}
          toggleModal={toggleModal}
        />
        <GearButton onClick={() => setIsBGMModalOpen(true)} />
      </Content>

      <ModalCommon
        open={isMangaInfoOpen}
        onClose={() => setIsMangaInfoOpen(false)}
        title={'お知らせ'}
        buttons={mangaInfoButtons}
      >
        <MangaInfoModal />
      </ModalCommon>
      <ModalCommon
        open={isBGMModalOpen}
        onClose={() => setIsBGMModalOpen(false)}
        title={'設定'}
        buttons={bgmModalButtons}
        theme="pink"
      >
        <SettingsModal theme="pink" setting={settingForm} setSetting={setSettingForm} />
      </ModalCommon>
    </MainLayout>
  )
}

const Header = styled.div.attrs({
  className:
    'relative w-full h-[80px] z-50 flex gap-x-2 items-center my-4 top-4 font-Inter font-bold'
})``

const Content = styled.div.attrs({
  className: 'md:w-desktop-width mx-auto relative overflow-y-auto flex items-center'
})`
  height: calc(100dvh - 190px);
`
