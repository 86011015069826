import PropTypes from 'prop-types'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'

import MagnifyingGlassButton from 'src/assets/img/shop/magnifying_glass.png'
import ShopExplanatoryModal from 'src/pages/main/shop/components/ShopDiamondTab/ShopExplanatoryModal'

const ShopPaymentItem = ({ shopDetails }) => {
  const { setModalContent } = useShopContext()
  const handleShowDetail = () => {
    setModalContent(<ShopExplanatoryModal shopDetails={shopDetails} />)
  }
  const firstName = shopDetails?.first_name
  const secondName = shopDetails?.second_name

  return (
    <div className="flex items-center gap-1 relative">
      <div className="flex items-start gap-2">
        <img
          src={shopDetails.icon.url}
          className="rounded-md w-[4.4375rem] h-[4.125rem] border border-light-pinkish"
        />
        {shopDetails.explanatory_text && (
          <button className="absolute top-[3.3rem] left-[3.8rem] z-10" onClick={handleShowDetail}>
            <img src={MagnifyingGlassButton} className="w-5" />
          </button>
        )}

        <div className="text-xs flex flex-col justify-between text-left gap-2">
          <p
            className="text-bubblegum-pink text-sm xs:text-lg font-black font-MPlus1 w-full"
            style={{
              textShadow: '-1px 0 white, 1px 0 white, 0 1px white, 0 -1px white',
              filter: 'drop-shadow(#FFAEDA 0px 2px 0px)'
            }}
          >
            {firstName}
          </p>
          {secondName && (
            <div className="flex">
              <p className="font-Inter font-semibold text-[0.5625rem] bg-pale-pink rounded-md text-white py-0.5 px-1 leading-[10px] max-w-[112px] xs:max-w-[144px]">
                {secondName}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ShopPaymentItem.propTypes = {
  shopDetails: PropTypes.object
}

export default ShopPaymentItem
