import React from 'react'
import PropTypes from 'prop-types'
import ModalCommon from 'src/components/common/ModalCommon'
import TitleMiniGame from 'src/assets/img/mini_game/title_minigame.png'
import MascotChara from 'src/assets/img/mini_game/mascot_chara.png'

const ModalMiniGame = ({ isOpen, onClose, onPlay }) => {
  const modalButtons = [
    {
      label: '戻る',
      onClick: () => {
        onClose()
      },
      type: 'close'
    },
    {
      label: 'PLAY',
      onClick: () => {
        onPlay()
      }
    }
  ]

  return (
    <ModalCommon open={isOpen} onClose={onClose} title="ミニゲーム" buttons={modalButtons}>
      <div className="bg-[#FFFFFF] px-2 pt-6 pb-12 text-center mb-[16px] relative h-80 overflow-auto hidden-scroll">
        <img src={MascotChara} alt="MascotChara" className='absolute w-1/4 bottom-8 right-[3%] sm:w-22 sm:bottom-8 sm:right-4 rotate-12'/>
        <img src={TitleMiniGame} alt="Title" className="w-[267px] mb-4 mx-auto" />
        <p className="text-cornflower-blue font-semibold text-[0.8125rem] font-Inter">
          彼からのお返事が届く間のみ、
          <br />
          ミニゲームを遊んでハートを
          <br />
          GETする事ができるョ!
          <br />
          お返事が届いた時点で強制終了だコケッ！
        </p>
        <div className="flex justify-center mt-4">
          <div className="bg-[#EFF4FF] rounded-md ">
            <p className="text-cornflower-blue font-Inter text-[0.5625rem] font-semibold px-6 py-2">
              ※ゲームは何回でも出来るけど
              <br />
              ハートが貰えるのは1日
              <br />
              (am5時～翌日am4時59分) の
              <br />
              24時間の間で3回までだョ！
            </p>
          </div>
        </div>
      </div>
    </ModalCommon>
  )
}

ModalMiniGame.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onPlay: PropTypes.func
}

export { ModalMiniGame }
