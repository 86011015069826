import PropTypes from 'prop-types'
import Present from 'src/models/Present'
import ModalCommon from 'src/components/common/ModalCommon'
import PresentItem from 'src/components/menu/PresentItem'

const PresentAcceptModal = ({ show, onClose, presents, isNormalItem }) => {
  return (
    <ModalCommon
      open={show}
      onClose={onClose}
      buttons={[
        {
          label: 'OK',
          onClick: () => onClose()
        }
      ]}
      title={
        <div className="text-center font-bold">
          <p>プレゼント受け取り</p>
          <p className="text-[0.8125rem] text-cornflower-blue mt-1">
            以下のプレゼントを受け取りました
          </p>
        </div>
      }
    >
      <div className="bg-white font-semibold w-full text-center whitespace-pre-line h-56 overflow-y-auto hidden-scroll">
        <ul>
          {presents.map((present) => (
            <PresentItem
              key={present.id}
              present={present}
              isNormalItem={isNormalItem}
              isModalItem={true}
              isOnlyItem={presents.length === 1}
            />
          ))}
        </ul>
      </div>
    </ModalCommon>
  )
}

PresentAcceptModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  presents: PropTypes.arrayOf(PropTypes.instanceOf(Present)),
  isNormalItem: PropTypes.func.isRequired
}

export default PresentAcceptModal
