import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Countdown from 'react-countdown'
import { useDuringContext } from '../pages/DuringDelivery/_Provider'
import { useCharacterLiveContext } from 'src/providers/LivestreamProvider'
import _ from 'lodash'
import { formattedCountDownTime } from 'src/lib/date'
import BlueClock from 'src/assets/img/common/clock-blue.png'

const StartExtraTime = ({ isShow, time, livestreamId, className, onComplete }, ref) => {
  const [isVisible, setIsVisible] = useState(isShow || false)
  const [displayTime, setDisplayTime] = useState(time)
  const { liveItemsSocket } = useDuringContext()
  const { characterLiveSocket } = useCharacterLiveContext()

  useEffect(() => {
    if (
      liveItemsSocket.extraWatchableTime === undefined ||
      liveItemsSocket.extraWatchableTime === '' ||
      new Date(displayTime) > new Date(liveItemsSocket.extraWatchableTime)
    ) {
      return
    }

    setDisplayTime(liveItemsSocket.extraWatchableTime)
  }, [displayTime, liveItemsSocket.extraWatchableTime])

  useEffect(() => {
    if (
      characterLiveSocket.extraWatchableTime === undefined ||
      characterLiveSocket.extraWatchableTime === '' ||
      characterLiveSocket.livestreamId !== livestreamId ||
      new Date(displayTime) > new Date(characterLiveSocket.extraWatchableTime)
    ) {
      return
    }

    setDisplayTime(characterLiveSocket.extraWatchableTime)
  }, [characterLiveSocket.livestreamId, characterLiveSocket.extraWatchableTime])

  useEffect(() => {
    _.set(ref, 'current.showStartedExtra', () => setIsVisible(true))
  }, [])

  return (
    <>
      {isVisible && (
        <div
          className={clsx(
            'flex items-center gap-3 bg-white rounded-l-[10px] px-2 pb-0.5',
            className
          )}
        >
          <div className="flex items-center gap-1 text-[#6F9BFF] text-[9px] font-semibold leading-[9px]">
            <img src={BlueClock} className="w-2.5" />
            残り時間
          </div>
          <span className="text-[#406ED8] text[15px] font-semibold">
            <Countdown
              date={new Date(displayTime)}
              onComplete={onComplete}
              renderer={({ hours, minutes, seconds }) => {
                return <>{formattedCountDownTime(hours, minutes, seconds)}</>
              }}
            />
          </span>
        </div>
      )}
    </>
  )
}

StartExtraTime.propTypes = {
  isShow: PropTypes.bool,
  time: PropTypes.string,
  livestreamId: PropTypes.number,
  className: PropTypes.string,
  onComplete: PropTypes.func
}

// @ts-ignore
export default React.forwardRef(StartExtraTime)
