import styled, { keyframes } from 'styled-components'

export const blurIn = keyframes`
  from {
    opacity: 0;
    filter: blur(5px);
  }
  to {
    opacity: 1;
    filter: blur(0);
  }
`

export const Container = styled.div`
  animation: ${blurIn} 0.5s ease-in-out;
  /* Possibly prevent scrolling if you want an overlay effect */
  overflow: hidden;
  position: relative;
`
