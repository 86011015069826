import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import HTMLReactParser from 'html-react-parser'

import { spendHeart } from 'src/services/get/getChat'
import { formatDate } from 'src/lib/date'
import { canSpendHeart, userPossessionHeart } from 'src/lib/message'
import { authActionTypes, useAuthDispatch, useAuthState } from 'src/providers/AuthProviders'

import useAudio from 'src/components/common/UseAudio'

import { GiftDetail } from 'src/components/message/ChatBox/GiftDetail'
import { SpendHeartModal } from 'src/components/message/ChatBox/SpendHeartModal'

import VoicePause from 'src/assets/img/message/detail/voice_pause.png'
import VoiceStart from 'src/assets/img/message/detail/voice_start.png'

export const ItemChat = ({
  msg,
  loveLvSettings,
  character,
  changeBgImage,
  isScrollOpenTalk,
  handleUpdateMessage,
  setMultiLevel,
  setIsMultiLoveModalOpen,
  handleOpenGift
}) => {
  const { advices } = msg
  const { user } = useAuthState()
  const authDispatch = useAuthDispatch()
  const [isOpenSpendHeartModal, setIsOpenSpendHeartModal] = useState(false)
  const [canViewDetail, setCanViewDetail] = useState(false)
  const [playing, playAudio, pauseAudio] = useAudio(msg.character_voice)
  const [userHeart, setUserHeart] = useState(0)
  const [canSpendHeartState, setCanSpendHearState] = useState(false)

  useEffect(() => {
    setUserHeart(userPossessionHeart(user))
    setCanSpendHearState(canSpendHeart(userHeart, loveLvSettings?.open_chat_heart))
  }, [user, userHeart])

  const closeSpendHeartModal = useCallback(() => {
    setIsOpenSpendHeartModal(false)
  }, [])

  useEffect(() => {
    setCanViewDetail(msg.is_heart_consumption || character.navigator)
  }, [msg.is_heart_consumption, character])

  const onSpendHeartConfirm = useCallback(async () => {
    try {
      const { data, currentLevel, nextLevel } = await spendHeart(msg.character_chat_id, user?.id)
      if (data) {
        handleUpdateMessage({
          ...msg,
          is_heart_consumption: true,
          description: data.description,
          title: data.title
        })
        setIsOpenSpendHeartModal(false)
        setCanViewDetail(true)
        authDispatch({
          type: authActionTypes.SPEND_HEART,
          payload: loveLvSettings?.open_chat_heart
        })
        if (!currentLevel || !nextLevel) return
        setMultiLevel({
          currentLevel: currentLevel,
          nextLevel: nextLevel
        })
        setIsMultiLoveModalOpen(true)
      }
    } catch {}
  }, [
    msg.character_chat_id,
    user?.id,
    loveLvSettings,
    handleUpdateMessage,
    setMultiLevel,
    setIsMultiLoveModalOpen
  ])

  return (
    <>
      <div
        className="flex w-full font-Inter pl-[12px] items-start font-medium leading-5 text-silver relative"
        onClick={() => changeBgImage(msg)}
      >
        <div
          className={
            'rounded-[9px] relative left-[30%] transform -translate-x-[30%] bg-white max-w-5/6 w-5/6 h-auto p-2 pl-3 pt-[15px] border-[0.5px] border-white ml-3.5 mt-2.5 z-5 break-words shadow-lg'
          }
        >
          <div className="chat-item" />
          <div
            className={`text-justify text-[#484848] text-[14px] font-light whitespace-pre-line ${
              !canViewDetail && 'pb-3'
            }`}
          >
            <div>
              <span className="inline">
                {HTMLReactParser(msg?.title)}{' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html: canViewDetail ? msg?.description : msg?.description + ' ... '
                  }}
                />
              </span>
              {!canViewDetail && (
                <span
                  onClick={() => setIsOpenSpendHeartModal(true)}
                  className="bg-sky-blue text-white px-1 rounded-[0.25rem] font-Inter font-bold text-[0.625rem] cursor-pointer inline-block"
                >
                  続きを見る
                </span>
              )}
            </div>
          </div>
          {canViewDetail && advices?.length ? (
            <div className="mt-2.5">
              <div className="bg-[linear-gradient(90deg,#FBED96_0%,#ABECD6_100%)] -mr-[8.5px] -ml-[12.5px] text-left pl-3.5 py-1 text-[#325282] text-[10px] font-medium">
                {advices[0].title}
              </div>
              <div className="text-[#FAED97] text-[10px] text-justify pt-[7px] leadding-[15px]">
                {advices[0].content}
              </div>
            </div>
          ) : null}
          <div className="flex justify-between items-center mt-4">
            {canViewDetail ? (
              <div className="flex-row">
                {(msg?.character_voice_id || msg?.character_voice) && (
                  <div>
                    {playing ? (
                      <img src={VoicePause} onClick={pauseAudio} />
                    ) : (
                      <img src={VoiceStart} onClick={playAudio} />
                    )}
                  </div>
                )}
                {msg?.setting_item_id && (
                  <GiftDetail
                    msg={msg}
                    isReceived={msg?.is_received_present}
                    giftType="setting_item"
                    handleOpenGift={handleOpenGift}
                  />
                )}
                {(msg?.character_cloth_id.length > 0 || msg?.character_cloth) && (
                  <GiftDetail
                    msg={msg}
                    isReceived={msg?.is_received_cloth}
                    giftType="character_cloth"
                    handleOpenGift={handleOpenGift}
                  />
                )}
              </div>
            ) : <div/>}
            <p className="text-xs text-[#9AC8FF] font-medium">
              {formatDate(new Date(msg?.created_at))}
            </p>
          </div>
        </div>
        <SpendHeartModal
          isOpen={isOpenSpendHeartModal}
          closeModal={closeSpendHeartModal}
          onConfirm={onSpendHeartConfirm}
          userHeart={userHeart}
          spendHeartAmount={loveLvSettings?.open_chat_heart}
          canSpendHeart={canSpendHeartState}
          isOpenTalk
          isScrollOpenTalk={isScrollOpenTalk}
        />
      </div>
    </>
  )
}

ItemChat.propTypes = {
  msg: PropTypes.object.isRequired,
  loveLvSettings: PropTypes.object,
  character: PropTypes.object,
  changeBgImage: PropTypes.func,
  isOpenTalk: PropTypes.func,
  isScrollOpenTalk: PropTypes.func,
  handleUpdateMessage: PropTypes.func,
  isOpenGift: PropTypes.bool,
  setIsOpenGift: PropTypes.func,
  setMultiLevel: PropTypes.func,
  setIsMultiLoveModalOpen: PropTypes.func,
  handleOpenGift: PropTypes.func
}
