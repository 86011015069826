export const getRankingDisplay = (rank, lovePoints, isOshiMenDisplay) => {
  if (!isOshiMenDisplay) {
    return '対象外'
  }
  if (lovePoints === 0) {
    return '圏外'
  }
  if (rank > 99) {
    return '圏外'
  } else {
    return '現在'
  }
}
