import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Modal } from 'src/components/common/Modal'
import CloseIcon from 'src/assets/img/common/close_icon.png'
import RoundedButton from 'src/components/common/RoundedButton'

export const ConfirmModal = ({ isOpen, setIsOpen, handleUpdate, character }) => {
  const handleConfirm = () => {
    handleUpdate(character.id)
    setIsOpen(false)
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="sm:w-[329px] w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino"
    >
      <div
        onClick={() => setIsOpen(false)}
        className="w-[18px] h-[18px] absolute top-2 right-2 z-50 cursor-pointer"
      >
        <img className="w-full h-full" src={CloseIcon} alt="close" />
      </div>
      <ModalBody>
        <div className="text-center text-xl font-bold text-cornflower-blue">確認</div>
        <div className="bg-white py-8 px-14 text-center font-bold mt-4">
          推しメンを{character.name}に 変更します。 よろしいですか？
        </div>
        <div className="grid grid-cols-2 gap-4 mt-2.5 text-xl xs:text-2xl font-bold">
          <RoundedButton className="bg-metallic-gray" onClick={() => setIsOpen(false)} text={'キャンセル'}/>
          <RoundedButton className="bg-cornflower-blue" onClick={handleConfirm} text={'変更する'}/>
        </div>
      </ModalBody>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  handleUpdate: PropTypes.func,
  character: PropTypes.object
}

const ModalBody = styled.div.attrs({
  className: 'w-full h-fit pt-4 pb-3 px-3 relative bg-[#F3F3F3] rounded-xl'
})`
  background-size: 100% 100%;
`
