// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import BGModalTitle from '../../assets/img/bg-modal-title.png'
import BtnClose from '../../assets/img/btn-close.png'
import { Modal } from './Modal'

const ModalWithBg = ({
  open,
  onClose,
  title,
  children
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="z-[9999] md:w-[332px] w-10/12 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-left shadow-xl transform transition-all sm:max-w-lg sm:w-full font-hiragino"
    >
      <div
        onClick={onClose}
        className="w-[50px] h-[44px] absolute -top-4 -right-5 z-50 cursor-pointer"
      >
        <img className="w-[22px] h-[22px]" src={BtnClose} alt="close" />
      </div>
      <ModalBody>
        {title && <ModalTitle>{title}</ModalTitle>}
        {children}
      </ModalBody>
    </Modal>
  )
}

ModalWithBg.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

const ModalBody = styled.div.attrs({
  className: 'w-full h-fit px-8 py-[40px] relative'
})`
  background-size: 100% 100%;
`

const ModalTitle = styled.div.attrs({
  className:
    'h-fit px-8 py-[7px] relative text-center text-white text-xl font-vldLineGR leading-[25px] mx-4 mb-11'
})`
  background-image: url(${BGModalTitle});
  background-size: 100% 100%;

  @media (max-width: 375px) {
    font-size: 18px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }
`

export { ModalWithBg, ModalTitle }
