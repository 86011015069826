import React, { useCallback, useEffect, useState } from 'react'
import './StyleWebMoney/index.css'
import { useMutation } from 'react-query'
import { postPaymentOrderWebMoney } from 'src/services/post/postPaymentOrder'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import { useAuthState } from 'src/providers/AuthProviders'
import { useNavigate } from 'react-router-dom'
import ShopPurchaseResultScreen from 'src/pages/main/shop/components/ShopDiamondTab/ShopPurchaseResultScreen'
import IconWebmoney from 'src/assets/img/common/icon-webmoney.png'

const ShopWebMoney = () => {
  const { setModalContent } = useShopContext()
  const { user } = useAuthState()
  const receivedData = JSON.parse(localStorage.getItem('dataToSend'))
  const [disableButton, setDisableButtonr] = useState(false)
  const amountPay = new Intl.NumberFormat().format(receivedData?.selectedItem.listed_price)
  const orderId = Math.floor(Date.now() / 1000).toString()
  const [information, setInformation] = useState(false)
  const {
    data: orderConfigs,
    mutate: fetchOrderConfigs
  } = useMutation(postPaymentOrderWebMoney, {
    onError: () => {
      // @ts-ignore
      setModalContent(<ShopPurchaseResultScreen isError />)
    }
  })

  const navigate = useNavigate()

  const onPurchase = useCallback(
    (method) => {
      fetchOrderConfigs({
        order: {
          user_id: user?.id || 1,
          product_id: receivedData?.selectedItem.id,
          payment_amount: receivedData?.selectedItem.listed_price,
          payment_method: method,
          order_id: orderId
        }
      })
    },
    [user?.id, receivedData]
  )

  const handlePay = () => {
    setDisableButtonr(true)
    onPurchase('web_money')
  }

  const handleCancelPay = () => {
    navigate('/shop')
  }

  const shopName = () => {
    const firstName = receivedData.selectedItem.first_name
    const secondName = receivedData.selectedItem.second_name

    if (firstName && secondName) {
      return firstName + ', ' + secondName
    }

    return firstName
  }

  useEffect(() => {
    document?.f?.submit()
  }, [orderConfigs])

  return (
    <>
      <div className="content-payment-webmoney" style={{ height: '100%', overflowY: 'scroll' }}>
        <div className="body-order">
          <div>
            <div className="information-order">
              <div className="ttl_area">
                <div className="ttl_inn">
                  <h2>
                    <span>ご利用内容</span>
                  </h2>
                </div>
              </div>
              <div className="ga_inner">
                <div className="btoo">
                  <input type="button" id="popop" value="取引詳細" className="text-information-order" onClick={() => setInformation(!information)} />
                </div>
                <div className="ga_hide d-block overflow-hidden" id="hd_op" style={{ height: information ? '100px' : '0px' }}>
                  <table className="ga_table">
                    <tbody>
                      <tr>
                        <td>オーダーID</td>
                        <td>1704782185</td>
                      </tr>
                      <tr>
                        <td>取引概要</td>
                        <td>{shopName()}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <div className="ga_open01">
                    <ul>
                      <li>
                        <div>合計</div>
                        <div>¥{amountPay}</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pgn_area">
            <div className="ttl_area">
              <div className="ttl_inn">
                <h2>
                  <span>お支払い手続き</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="swiper_area">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <a href="#slide-webmoney" className="icon-webmoney">
                  <img src={IconWebmoney} alt="WebMoney" className="img-webmoney" />
                  <span>WebMoney</span>
                </a>
              </div>
            </div>
          </div>

          <div id="slide-webmoney" className="content">
            <section id="select19" className="select_form">
              <div className="ttl_area02">
                <div className="ttl_inn02 mx-auto">
                  <h3>
                    <span className="fs-16">WebMoney</span>
                  </h3>
                </div>
              </div>

              <div className="select_form_inn">
                <div className="ip_10">
                  <div className="mesbox">決済手続きのため、WebMoneyサイトに遷移します。</div>
                </div>
                <div className="sbt_area">
                  <input type="button" value="戻 る" className="un_sbt_deco" onClick={handleCancelPay} />
                  <input type="button" name="sbt" disabled={disableButton} value="次 へ" className="sbt_deco" onClick={handlePay} />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      {
        orderConfigs && <div style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: orderConfigs?.data }} />
      }
    </>
  )
}
export default ShopWebMoney
