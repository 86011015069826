import { useContext, useEffect, useMemo, useState, useRef } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { CIRCLE_IMAGE_OF_CHARACTER } from 'src/constants/characters'
import { useAuthState } from 'src/providers/AuthProviders'
import getUnreadNews from 'src/services/get/getUnreadNews'
import useLoading from 'src/components/layouts/hooks/useLoading'
import getCurrentEvents from 'src/services/get/getCurrentEvents'
import { useGetRecommendsData } from 'src/services/hooks/useFetchRecommends'
import { getRankingD6 } from 'src/services/ranking'
import getUserPresents from 'src/services/get/getUserPresents'
import CancelableAPIRequest from 'src/models/CancelableAPIRequest'
import { postUserLivestream } from 'src/services/post/postUserLivestream'
import { getListLivestreams } from 'src/services/get/getListLivestreams'
import { setFirstLive, setSecondLive } from 'src/redux/live/setLiveList'

import StartLoading from 'src/components/layouts/StartLoading'
import TipsModal from 'src/components/common/TipsModal'
import Loading from 'src/components/layouts/Loading'
import { MainLayout } from 'src/components/layouts/MainLayout'
import EventSlideBanner from 'src/components/myPage/EventSlideBanner'
import RecommendedMan from 'src/components/myPage/RecommendedMan'
import TopRanking from 'src/components/myPage/TopRanking'
import { NotificationArea } from 'src/components/myPage/NotificationArea'

import { HistoryRouteContext } from 'src/routes'
import { VOICE_LIVE_PATH } from 'src/routes/live'

import Booth from './livestream/Booth'
import { ModalExtraTime } from '../common/ModalExtraTime'
import LiveBooth from './livestream/LiveBooth'
import Schedule from './livestream/Schedule'

const MyPageContainer = () => {
  const { user } = useAuthState()
  const scheduleRef = useRef(null)
  const [haveScheduleToday, sethaveScheduleToday] = useState(false)
  const { redirectTo } = useContext(HistoryRouteContext)
  const recommends = useGetRecommendsData()
  const [presents, setPresents] = useState([])
  const hasUnreadNews = useQuery('has-unread-news', () => getUnreadNews(user.id))
  const events = useQuery('events', () => getCurrentEvents())
  const topRankingCharacters = useQuery('top-ranking-week', () => getRankingD6('current_week'))
  const dispatch = useDispatch()
  const { data: liveSetting, refetch } = useQuery({
    queryFn: async () => {
      let data
      await getListLivestreams().then((res) => {
        if (res) {
          data = res
          const livestreams = res.livestreams
          if (livestreams) {
            dispatch(setFirstLive(livestreams.find((live) => live.live_type === 'Aブース')))
            dispatch(setSecondLive(livestreams.find((live) => live.live_type === 'Bブース')))
          }
        }
      })
      return data
    },
    queryKey: 'get-live-setting-infos'
  })
  const liveList = useSelector((state) => state.liveList)
  const [openExtralModal, setOpenExtraModal] = useState(false)

  localStorage.removeItem('idTokenRegister')
  localStorage.removeItem('accessTokenRegister')

  useEffect(() => {
    const req = new CancelableAPIRequest(getUserPresents, user?.id)
    req.invoke((resp) => {
      setPresents(resp)
    })

    return () => req.cancel()
  }, [])

  const findRecommendMan = useMemo(
    () => recommends.data?.find((man) => man.recommend),
    [recommends.data]
  )

  const recommendManImage = useMemo(() => {
    if (!findRecommendMan) return
    return CIRCLE_IMAGE_OF_CHARACTER[findRecommendMan.id]
  }, [findRecommendMan])

  const createUserLivestream = async (livestreamSettingId) => {
    await postUserLivestream(livestreamSettingId)
  }

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (liveSetting) {
      createUserLivestream(liveSetting.id)
    }
  }, [liveSetting])

  const handleScrollToSchedule = () => {
    if (haveScheduleToday) {
      scheduleRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      redirectTo(VOICE_LIVE_PATH.LiveSchedulePage, { state: { tab: 'current_month' } })
    }
  }
  const { showLoading, progress } = useLoading([
    hasUnreadNews.isLoading,
    events.isLoading,
    recommends.isLoading,
    topRankingCharacters.isLoading
  ])

  if (showLoading) {
    if (localStorage.getItem('isFromOpening')) {
      return (
        <LoadingWrapper>
          <StartLoading progress={progress} />
        </LoadingWrapper>
      )
    } else {
      return (
        <LoadingWrapper>
          <Loading progress={progress} />
        </LoadingWrapper>
      )
    }
  } else {
    if (hasUnreadNews.data !== null && events.data && recommends.data && recommendManImage) {
      return (
        <MainLayout
          className="relative"
          classNameHeader=""
          showFooter={!showLoading}
          showHeader={!showLoading}
        >
          <Wrapper>
            <EventSlideBanner events={events.data} />
            <div className="grid grid-cols-2 mt-12 font-bold justify-items-center gap-1 px-2">
              {liveSetting && liveSetting.id && (liveList?.firstLive || liveList?.secondLive) ? (
                <>
                  <LiveBooth
                    setOpenExtraModal={setOpenExtraModal}
                    handleScrollToSchedule={handleScrollToSchedule}
                    livestream={liveList.firstLive}
                    refetch={refetch}
                    user={user}
                    liveSetting={liveSetting}
                    title="A"
                    isFirst={true}
                  />
                  <LiveBooth
                    setOpenExtraModal={setOpenExtraModal}
                    handleScrollToSchedule={handleScrollToSchedule}
                    livestream={liveList.secondLive}
                    refetch={refetch}
                    user={user}
                    liveSetting={liveSetting}
                    title="B"
                    isFirst={false}
                  />
                </>
              ) : (
                <>
                  <Booth title="A" handleScrollToSchedule={handleScrollToSchedule} />
                  <Booth title="B" handleScrollToSchedule={handleScrollToSchedule} />
                </>
              )}
            </div>
            <ModalExtraTime isOpen={openExtralModal} onClose={() => setOpenExtraModal(false)} />
            <TopRanking
              className="mt-5"
              characters={Object.fromEntries(
                Object.entries(topRankingCharacters.data?.rank).slice(0, 3)
              )}
            />
            <RecommendedMan infor={{ ...findRecommendMan, imageSrc: recommendManImage }} />
            <div ref={scheduleRef}>
              <Schedule sethaveScheduleToday={sethaveScheduleToday} />
            </div>
          </Wrapper>
          <NotificationArea presents={presents} />
          <TipsModal type="mypage" />
        </MainLayout>
      )
    }
  }
}

export default MyPageContainer

const Wrapper = styled.div.attrs({
  className: 'relative pt-12 pb-4 overflow-y-scroll hidden-scroll bg-white'
})`
  height: calc(100dvh - 74px);
`
const LoadingWrapper = styled.div.attrs({
  className: 'relative pt-12 pb-4 overflow-y-scroll hidden-scroll bg-white'
})`
  height: calc(100dvh);
`
