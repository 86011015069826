import axiosInstance from '../common/axiosInstance'
import { API_ROUTES } from '../common/routes'

export const getListLivestreams = async () => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Live.ListLivestreams)

    return res?.data || null
  } catch {
    return null
  }
}
