import PropTypes from 'prop-types'
import clsx from 'clsx'

import IconR from 'src/assets/img/gacha/R.png'
import IconSR from 'src/assets/img/gacha/SR.png'
import IconSSR from 'src/assets/img/gacha/SSR.png'

import { useEffect, useState } from 'react'

const getItemGacha = (gacha) => {
  let data = []

  switch (gacha.gacha_type) {
    case 'item':
      data = gacha.gacha_items
      break
    case 'avatar':
      data = gacha.gacha_character_cloths
      break
  }

  return data
}

const CapsuleDetailsModal = ({ handleClose, gachaDetail }) => {
  const [rarityR, setRarityR] = useState([])
  const [raritySR, setRaritySR] = useState([])
  const [raritySSR, setRaritySSR] = useState([])
  const data = getItemGacha(gachaDetail)

  useEffect(() => {
    if (!data) return
    const sortLove = (a, b) => {
      if (a.increase_love === 0 && b.increase_love !== 0) {
        return -1
      } else if (a.increase_love !== 0 && b.increase_love === 0) {
        return 1
      } else {
        return b.increase_love - a.increase_love
      }
    }
    const rarityR = data.filter((item) => item.rarity === 'r')
    const raritySR = data.filter((item) => item.rarity === 'sr')
    const raritySSR = data.filter((item) => item.rarity === 'ssr')
    setRarityR(rarityR.sort((a, b) => sortLove(a, b)))
    setRaritySR(raritySR.sort((a, b) => sortLove(a, b)))
    setRaritySSR(raritySSR.sort((a, b) => sortLove(a, b)))
  }, [])

  function processRarityInfo() {
    const rarityInfo = {}
    if (!data) return
    data.forEach((item) => {
      const { rarity, rate } = item

      if (!rarityInfo[rarity]) {
        rarityInfo[rarity] = {
          count: 0,
          totalRate: 0
        }
      }

      rarityInfo[rarity].count++
      rarityInfo[rarity].totalRate += Number(rate)
    })

    const resultArray = Object.keys(rarityInfo).map((rarity) => {
      const { count, totalRate } = rarityInfo[rarity]
      return { rarity, count, totalRate }
    })

    return resultArray
  }
  const resultGacha = processRarityInfo(gachaDetail)

  const ICONRARITY = {
    ssr: IconSSR,
    sr: IconSR,
    r: IconR
  }

  const ICONCLASSNAME = {
    ssr: 'h-6',
    sr: 'h-6',
    r: 'h-6'
  }

  const handleIconType = (item) => {
    return ICONRARITY[item] || null
  }

  const handleClassIcon = (item) => {
    return ICONCLASSNAME[item] || null
  }

  const allItems = raritySSR?.concat(raritySR, rarityR) || []

  const pickUpItemList = allItems.filter((item) => item.is_picup)

  const itemDetailsNotIsPicUp = allItems.filter((item) => !item.is_picup)

  const getRate = (item) => {
    return Number.isFinite(item.rate) ? parseFloat(Number(item.rate).toFixed(4)) : item.rate
  }

  return (
    <div className="py-5 px-3 flex flex-col items-center overflow-y-scroll bg-white h-80 hidden-scroll">
      <table className="border-separate border-spacing-0 mx-1 w-[90%]">
        <thead>
          <tr className="bg-cornflower-blue text-center text-white text-xs">
            <th className="border-l border-t border-cornflower-blue p-1 text-center rounded-tl-md">
              レア度
            </th>
            <th className="border-x border-white p-1">提供数</th>
            <th className="border-b border-gray-400 p-1 rounded-tr-md">提供割合</th>
          </tr>
        </thead>
        <tbody>
          {resultGacha.reverse().map((item, index) => (
            <tr key={index} className={'bg-white text-center p-1  text-base'}>
              <td
                className={`p-1 flex justify-center border-cornflower-blue border-l border-b  ${
                  index === resultGacha.length - 1 ? 'rounded-bl-lg' : ''
                }`}
              >
                <img src={handleIconType(item.rarity)} className={handleClassIcon(item.rarity)} />
              </td>
              <td className="p-1 border-x border-b border-cornflower-blue font-normal">
                {item.count}
              </td>
              <td
                className={`p-1 border-b border-cornflower-blue font-light border-r ${
                  index === resultGacha.length - 1 ? 'rounded-br-lg' : ''
                }`}
              >
                {parseFloat(Number(item.totalRate).toFixed(4))}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {pickUpItemList && pickUpItemList.length > 0 && (
        <>
          <h3 className="text-center mt-4 mb-2 text-cornflower-blue font-semibold text-lg">
            ピックアップ
          </h3>
          <div className="grid grid-cols-3 gap-2 w-full bg-[#F3F9FF] rounded-md p-4">
            {pickUpItemList.map((item, index) => (
              <ItemDetails
                key={index}
                image={item.item_image_url}
                name={item.name}
                rate={
                  getRate(item)
                }
                loveAmount={item.increase_love}
                type={gachaDetail.gacha_type}
              />
            ))}
          </div>
        </>
      )}
      {itemDetailsNotIsPicUp && itemDetailsNotIsPicUp.length > 0 && (
        <>
          <h3 className="text-center mt-4 mb-2 text-cornflower-blue font-semibold text-lg">
            その他アイテム
          </h3>
          <div className="grid grid-cols-3 gap-2 w-full bg-[#F3F9FF] rounded-md p-4">
            {itemDetailsNotIsPicUp.map((item, index) => {
              return (
                <ItemDetails
                  key={index}
                  image={item.item_image_url}
                  name={item.name}
                  rate={
                    getRate(item)
                  }
                  loveAmount={item.increase_love}
                  type={gachaDetail.gacha_type}
                />
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

CapsuleDetailsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  gachaDetail: PropTypes.object.isRequired
}

const ItemDetails = ({ image, name, rate, loveAmount, type }) => {
  return (
    <div className="fontInter flex flex-col items-center text-cornflower-blue">
      <ItemThumbnail
        image={image}
        className=""
        imgClassName="w-16 h-16"
        loveAmount={loveAmount}
        rarity
        type={type}
      />
      <div
        className="pt-1 text-[0.625rem] text-center line-clamp-2 text-ellipsis font-black"
        style={{
          textShadow: '-2px 0 white, 2px 0 white, 0 2px white, 0 -2px white',
          filter: 'drop-shadow(0px 0px 2px #ffffff)'
        }}
      >
        {name}
      </div>
      <span
        className="truncate text-[0.5rem] font-medium"
        style={{
          textShadow: '-2px 0 white, 2px 0 white, 0 2px white, 0 -2px white',
          filter: 'drop-shadow(0px 0px 2px #ffffff)'
        }}
      >{`出現率 ${rate}%`}</span>
    </div>
  )
}

const ItemThumbnail = ({
  image,
  rarity,
  loveAmount,
  className = 'w-[72px] h-[80px] border-cornflower-blue rounded-sm',
  imgClassName = 'w-[60px]',
  type
}) => {
  return (
    <div
      className={clsx(
        'flex justify-center items-center relative',
        className,
        !rarity && 'border-2 drop-shadow-md bg-white'
      )}
    >
      <img className={clsx(imgClassName)} src={image} />
    </div>
  )
}

ItemDetails.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
  loveAmount: PropTypes.number.isRequired,
  rarity: PropTypes.number,
  type: PropTypes.string.isRequired
}

ItemThumbnail.propTypes = {
  image: PropTypes.string.isRequired,
  rarity: PropTypes.bool,
  loveAmount: PropTypes.number,
  className: PropTypes.string,
  imgClassName: PropTypes.string,
  type: PropTypes.string.isRequired
}

export default CapsuleDetailsModal
