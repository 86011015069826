import clsx from 'clsx'
import PropTypes from 'prop-types'

const StandardPageWrapper = ({
  children,
  className = 'p-4 flex flex-col gap-2',
  overflow = false,
  containerRef
}) => {
  return (
    <div
      className={clsx('sm:w-[400px] w-full', className, overflow && 'overflow-y-hidden hidden-scroll')}
      ref={containerRef}
    >
      {children}
    </div>
  )
}

export default StandardPageWrapper

StandardPageWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  overflow: PropTypes.bool,
  containerRef: PropTypes.any
}
