import React from 'react'
import PropTypes from 'prop-types'
import { useAuthState } from 'src/providers/AuthProviders'
import Countdown from 'react-countdown'
import ModalCommon from 'src/components/common/ModalCommon'
import { formattedTimeToSecond } from 'src/lib/date'
import TitleHourglassRed from 'src/pages/voiceLive/assets/title_hourglass_red.png'
import ChickenLoader from 'src/assets/img/character/mascot/good_mood.png'
import DiamondRed from 'src/assets/img/common/diamond-red.png'
import ArrowRight from 'src/assets/img/common/arrow_right.png'

const ModalExtraContinue = ({ open, onClose, sendExtraContinueTime, time }) => {
  const { user } = useAuthState()

  return (
    <ModalCommon
      title="コンテニュー?"
      open={open}
      onClose={onClose}
      buttons={[
        {
          label: 'キャンセル',
          onClick: () => {
            onClose()
          },
          type: 'close'
        },
        {
          label: '再入場',
          onClick: () => {
            sendExtraContinueTime()
          }
        }
      ]}
    >
      <div className="bg-[#FFFFFF] text-center px-1 pt-4 pb-6 mb-4 flex flex-col gap-2 leading-tight">
        <div className="text-[15px] font-bold">
          <div>
            <Countdown
              date={time}
              onComplete={() => {
                onClose()
              }}
              renderer={({ hours, minutes, seconds }) => {
                return (
                  <>
                    あと
                    <span className="inline text-red-500">
                      {formattedTimeToSecond(hours, minutes, seconds)}秒
                    </span>
                    以内なら入場可能だコケッ!
                  </>
                )
              }}
            />
          </div>
          <p>コンテニューしますか？</p>
        </div>
        <div className="text-[17px] text-[#6F9BFF] font-extrabold">必要赤ダイヤ</div>

        <div className="flex justify-center rounded-xl items-center bg-[#F3F3F3] mx-auto gap-5 py-0.5 px-4">
          <img src={DiamondRed} className="w-[38px] h-[38px]" />
          <span className="font-semibold text-[18px] text-[#878787]">×</span>
          <span className="font-semibold text-[18px] text-[#878787]">5</span>
        </div>

        <div className='flex flex-row ml-16'>
          <img src={TitleHourglassRed} className='w-[157px] h-[96px]' />
          <img src={ChickenLoader} className='flex self-end w-[76px] h-[64px] rotate-[15deg] -ml-4' />
        </div>

        <div className="flex flex-col justify-center rounded-xl items-center bg-[#F3F9FF] gap-3 py-2 mx-2 px-4">
          <div className="text-[17px] text-[#6F9BFF] font-extrabold">所持赤ダイヤ</div>
          <div className='flex flex-row w-10/12 justify-between items-center'>
            <img src={DiamondRed} className='w-[38px] h-[38px]' />
            <span className='font-semibold text-[15px] text-[#878787]'>{user?.red_amount.toLocaleString()}</span>
            <img src={ArrowRight} className='w-[10px] h-[10px]' />
            <span className='font-semibold text-[15px] text-[#FF4747]'>{(user?.red_amount - 5).toLocaleString()}</span>
          </div>
        </div>
      </div>
    </ModalCommon>
  )
}

ModalExtraContinue.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  sendExtraContinueTime: PropTypes.func,
  time: PropTypes.string
}

export default ModalExtraContinue
