import PropTypes from 'prop-types'

const RoundedButton = ({ className, text, onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      className={`${className} rounded-[3.625rem] text-white text-center py-2 font-MPlus1 font-bold ${disabled ? 'opacity-50' : ''}`}
      disabled={disabled}
    >
      {text}
    </button>
  )
}

RoundedButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default RoundedButton
