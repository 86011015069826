import { useContext, useEffect, useState, Children, cloneElement, isValidElement } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { HistoryRouteContext } from 'src/routes'
import { VOICE_LIVE_PATH } from 'src/routes/live'

import { Tab } from '@headlessui/react'
import GradientHeader from 'src/components/common/GradientHeader'
import StandardPageWrapper from 'src/components/common/StandardPageWrapper'
import { MainLayout } from 'src/components/layouts/MainLayout'

const ScheduleLayout = ({
  children,
  title,
  activetab,
  classNameHeader = 'shadow-custom backdrop-blur-sm'
}) => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const tabList = [
    {
      title: '今月',
      hasNotification: false,
      index: 0,
      textStyle: 'text-base px-2 py-0.5',
      activeTextStyle: 'background: linear-gradient(to right, #40E7FF 0%, #FF48E2 100%)'
    },
    {
      title: '来月',
      hasNotification: false,
      index: 1,
      textStyle: 'text-base px-2 py-0.5',
      activeTextStyle: 'background: linear-gradient(to right, #40E7FF 0%, #FF48E2 100%)'
    }
  ]

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { title, activetab })
    }

    return child
  })

  const handleChangeTab = (tabIndex) => {
    if (tabIndex === selectedIndex) return

    setSelectedIndex(tabIndex)
    if (tabIndex === 0) {
      redirectTo(VOICE_LIVE_PATH.LiveSchedulePage, { state: { tab: 'current_month' } })
    } else if (tabIndex === 1) {
      redirectTo(VOICE_LIVE_PATH.LiveScheduleNextPage, { state: { tab: 'next_month' } })
    }
  }

  useEffect(() => {
    setSelectedIndex(activetab === 'next_month' ? 1 : 0)
  }, [activetab])

  return (
    <MainLayout classNameHeader={classNameHeader} className="overflow-y-hidden" active="mypage">
      <StandardPageWrapper className="mt-10" overflow={true}>
        <Tab.Group selectedIndex={selectedIndex}>
          <GradientHeader tabList={tabList} onHandleTabChange={handleChangeTab} title={title} backPath="/mypage" />
          <Tab.Panels className="sm:w-desktop-width relative">
            <Content>{childrenWithProps}</Content>
          </Tab.Panels>
        </Tab.Group>
      </StandardPageWrapper>
    </MainLayout>
  )
}

ScheduleLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  activetab: PropTypes.string,
  classNameHeader: PropTypes.string
}

export default ScheduleLayout

const Content = styled.div.attrs({
  className: 'hidden-scroll overflow-y-auto'
})`
  height: calc(100vh - 200px);
`
