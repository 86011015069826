import { useEffect, useMemo, useState, useRef } from 'react'
import { useAuthState } from 'src/providers/AuthProviders'
import ReactPlayer from 'react-player'

import PropTypes from 'prop-types'

import Inquiry from 'src/models/Inquiry'
import { genDateString } from 'src/lib/date'
import CancelableAPIRequest from 'src/models/CancelableAPIRequest'
import getUserInquiryDetail from 'src/services/get/getUserInquiryDetail'
import { postThreadFeedback } from 'src/services/post/postThreadFeedback'

import NotificationIcon from 'src/components/common/NotificationIcon'
import SectionHeader from 'src/components/menu/SectionHeader'
import Loading from 'src/components/layouts/Loading'
import useLoading from 'src/components/layouts/hooks/useLoading'
import RoundedButton from 'src/components/common/RoundedButton'
import MessageInput from 'src/components/menu/MessageInput'

import { CATEGORIES } from 'src/constants/common'

const MessageBox = ({ item }) => {
  const regexImageExtensions = /(png|gif|jpg|heic|jpeg)$/
  const extensionsFile1 = item.attached_file.url?.split('.')
  const extensionsFile2 = item.attached_file_two.url?.split('.')
  const extensionsFile3 = item.attached_file_three.url?.split('.')
  const typeFile1 = extensionsFile1?.pop().toLowerCase()
  const typeFile2 = extensionsFile2?.pop().toLowerCase()
  const typeFile3 = extensionsFile3?.pop().toLowerCase()

  return (
    <div
      className={`w-full border-y mb-10 ${
        item.contact_type === 'user_send'
          ? 'border-[#FFD0EA] text-candy-pink'
          : 'border-metallic-gray py-1 text-silver'
      }`}
    >
      <p className="text-left break-words whitespace-pre-wrap">{item.content}</p>

      <div className="gap-6 flex flex-col">
        {item.attached_file.url &&
          (regexImageExtensions.test(typeFile1) ? (
            <img src={item.attached_file.url} className="w-full h-[300px] object-contain my-3" />
          ) : (
            <ReactPlayer
              height="100%"
              width="100%"
              controls
              url={item.attached_file.url}
              className="my-3"
            />
          ))}

        {item.attached_file_two.url &&
          (regexImageExtensions.test(typeFile2) ? (
            <img
              src={item.attached_file_two.url}
              className="w-full h-[300px] object-contain my-3"
            />
          ) : (
            <ReactPlayer
              height="100%"
              width="100%"
              style={{ marginTop: item.attached_file_two.url ? '10px' : '0px' }}
              controls
              url={item.attached_file_two.url}
              className="my-3"
            />
          ))}
        {item.attached_file_three.url &&
          (regexImageExtensions.test(typeFile3) ? (
            <img
              src={item.attached_file_three.url}
              className="w-full h-[300px] object-contain my-3"
            />
          ) : (
            <ReactPlayer
              height="100%"
              width="100%"
              style={{ marginTop: item.attached_file_three.url ? '10px' : '0px' }}
              controls
              url={item.attached_file_three.url}
              className="my-3"
            />
          ))}
      </div>
      <p className="text-right font-medium text-[0.5625rem] mt-3">
        {genDateString(item.created_at, true, '/')}
      </p>
    </div>
  )
}

const MessageSection = ({ messageList }) => {
  return (
    <>
      {messageList === 'user_send' ? (
        <SectionHeader title="お問合せ内容" className="text-metallic-gray text-xs mt-8" />
      ) : (
        <SectionHeader title="運営からの返信" className="text-metallic-gray text-xs mt-8" />
      )}

      {messageList.messages.map((messsage) => (
        <MessageBox key={messsage.id} item={messsage} />
      ))}
    </>
  )
}

const InquiryHistory = ({ isLoadingList, inquiries, handleUpdateReadInquiry }) => {
  const [isLoadingDetail, setIsLoadingDetail] = useState(false)
  const [inquiryID, setInquiryID] = useState(null)
  const [inquiry, setInquiry] = useState(null)
  const { user } = useAuthState()
  const [isShowMessageBox, setIsShowMessageBox] = useState(false)
  const [message, setMessage] = useState('')
  const textareaRef = useRef(null)

  const reorderMessages = useMemo(() => {
    const result = []
    const messageList = inquiry?.messageFeedback
    let currentGroup = { contact_type: null, messages: [] }
    if (messageList) {
      for (let i = 0; i < messageList.length; i++) {
        if (
          currentGroup.messages.length === 0 ||
          currentGroup.contact_type === messageList[i].contact_type
        ) {
          currentGroup.contact_type = messageList[i].contact_type
          currentGroup.messages.push(messageList[i])
        } else {
          result.push(currentGroup)
          currentGroup = { contact_type: messageList[i].contact_type, messages: [messageList[i]] }
        }
      }

      if (currentGroup.messages.length > 0) {
        result.push(currentGroup)
      }
    } else {
      return []
    }

    return result
  }, [inquiry])

  const payloadOption = useMemo(
    () => CATEGORIES.filter((item) => item.name === inquiry?.contactKinds),
    [inquiry]
  )

  const loadInquiryDetail = () => {
    setIsLoadingDetail(true)
    const req = new CancelableAPIRequest(getUserInquiryDetail, user?.id, inquiryID)
    req.invoke((resp) => {
      setInquiry(resp ?? null)
      setIsLoadingDetail(false)
    })

    return () => req.cancel()
  }

  const handleSendFeedback = async () => {
    await postThreadFeedback(user.id, inquiryID, inquiry.contentId, message, payloadOption)
    loadInquiryDetail()
    setIsShowMessageBox(false)
    setMessage('')
  }

  const handleShowMessageBoxButton = () => {
    setIsShowMessageBox(true)
    if (textareaRef.current) {
      textareaRef.current?.focus()
    }
  }

  useEffect(() => {
    if (isShowMessageBox) {
      textareaRef.current?.focus()
    }
  }, [isShowMessageBox])

  useEffect(() => {
    const cancelRequest = loadInquiryDetail()
    return cancelRequest
  }, [inquiryID])

  const { showLoading, progress } = useLoading([isLoadingList, isLoadingDetail])

  if (showLoading) return <Loading progress={progress} />

  if (!inquiries || inquiries.length === 0) {
    return (
      <div className="text-[0.8125rem] text-silver font-Inter text-left bg-white pt-4 pb-20 pl-2">
        お問合せ履歴はありません。
      </div>
    )
  }

  const handleEnterText = (text) => {
    if (text.length <= 1500) setMessage(text)
  }

  if (inquiry) {
    return (
      <div className="p-4 w-full bg-white text-left font-Inter text-white text-[0.875rem] font-semibold">
        <section className="border border-candy-pink rounded-md p-3">
          <p className="text-candy-pink">{inquiry.contactKinds}</p>
          {reorderMessages.map((item, index) => (
            <MessageSection messageList={item} key={index} />
          ))}
          {reorderMessages.length === 1 && (
            <>
              <SectionHeader title="運営からの返信" className="text-metallic-gray text-xs mt-8" />
              <p className="text-silver border-y border-metallic-gray py-1">
                運営からの返信はまだありません
              </p>
            </>
          )}
        </section>
        <div className={'flex items-center gap-0.5 justify-center w-full'}>
          <RoundedButton
            className="bg-metallic-gray w-1/2 text-2xl mt-4"
            text="戻る"
            onClick={() => {
              setInquiry(null)
              setInquiryID(null)
            }}
          />
          <RoundedButton
            className="bg-candy-pink text-2xl w-1/2 mt-4"
            text="返信する"
            onClick={handleShowMessageBoxButton}
          />
        </div>
        {isShowMessageBox && (
          <>
            <section className="border border-candy-pink rounded-md p-3 mt-8">
              <MessageInput
                title="お問合せ内容"
                message={message}
                showPreview={false}
                ref={textareaRef}
                handleEnterText={handleEnterText}
              />
            </section>
            <div className={'flex items-center gap-0.5 justify-center w-full mb-20'}>
              <RoundedButton
                disabled={message.length === 0}
                className="bg-candy-pink text-2xl w-1/2 mt-4"
                text="送信"
                onClick={handleSendFeedback}
              />
            </div>
          </>
        )}
      </div>
    )
  }

  return (
    <div className="p-4 w-full bg-white text-left font-Inter text-white text-[0.875rem] font-semibold">
      <ul className="flex flex-col gap-2">
        {inquiries
          .slice(0)
          .reverse()
          .map((el) => (
            <li
              key={el.id}
              className="relative px-4 pt-2 pb-4 rounded-md w-full text-left cursor-pointer"
              style={{ background: 'linear-gradient(90deg, #FF86C8 0%, #FFC7E5 100%)' }}
              onClick={() => {
                setInquiryID(el.id)
                handleUpdateReadInquiry(el.id)
              }}
            >
              {el.adminResponse && (
                <>{!el.isReaded && <NotificationIcon className="top-2 right-2" />}</>
              )}
              <p className="leading-7 h-12">{el.contactKinds}</p>
              <div className="text-[0.5625rem] text-right absolute bottom-1 right-2">
                {genDateString(el.createdAt, true, '/')}
              </div>
            </li>
          ))}
      </ul>
    </div>
  )
}

MessageSection.propTypes = {
  messageList: PropTypes.shape({
    contact_type: PropTypes.string.isRequired,
    messages: PropTypes.array.isRequired
  })
}

MessageBox.propTypes = {
  item: PropTypes.shape({
    attached_file: PropTypes.object,
    attached_file_three: PropTypes.object,
    attached_file_two: PropTypes.object,
    contact_type: PropTypes.oneOf(['admin_send', 'user_send']).isRequired,
    content: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  }).isRequired
}

InquiryHistory.propTypes = {
  isLoadingList: PropTypes.bool.isRequired,
  inquiries: PropTypes.arrayOf(PropTypes.instanceOf(Inquiry)),
  handleUpdateReadInquiry: PropTypes.func.isRequired
}

export default InquiryHistory
