import PropTypes from 'prop-types'

import ExchangeResult from 'src/pages/main/shop/components/ShopDiamondTab/ExchangeResult'

import { CURRENCY_TYPE } from 'src/models/GachaInfo'
import diamondRed from 'src/assets/img/common/diamond-red.png'
import diamondBlue from 'src/assets/img/common/diamond-blue.png'
import GachaTicket from 'src/assets/img/gacha/gacha-ticket.png'
import SSRTicket from 'src/assets/img/gacha/ssr-ticket.png'

const CURRENCY_TEXT = {
  FIVE_DIAMOND: 'ダイヤ5個',
  FIFTY_DIAMOND: 'ダイヤ50個',
  ONE_TICKET: 'ガチャチケット1枚',
  TEN_TICKET: 'ガチャチケット10枚',
  ONE_SSR: 'SSR確定ガチャチケット1枚',
  TEN_SSR: 'SSR確定ガチャチケット10枚'
}

const CurrencyDisplay = ({ number, type }) => {
  let content

  switch (type) {
    case 'diamond':
      content = (
        <>
          <img src={diamondBlue} alt="Diamond Blue" className="w-9" />
          <span>or</span>
          <img src={diamondRed} alt="Diamond Red" className="w-9" />
        </>
      )
      break
    case 'ssr':
      content = <img src={SSRTicket} alt="SSR icon" className="w-9" />
      break
    case 'gacha':
      content = <img src={GachaTicket} alt="gacha icon" className="w-9" />
      break
    default:
      content = <img src={GachaTicket} alt="Gacha icon" className="w-9" />
  }
  return (
    <div className="flex gap-4 items-center justify-center bg-[#F8F8F8] rounded-xl py-1 w-4/5 font-MPlus1 text-lg font-bold text-silver">
      <div className="flex items-center justify-between gap-1">{content}</div>
      <p>x</p>
      <p>{number}</p>
    </div>
  )
}

const CURRENCY_DISPLAY = {
  ダイヤ5個: <CurrencyDisplay type="diamond" number="5" />,
  ダイヤ50個: <CurrencyDisplay type="diamond" number="50" />,
  ガチャチケット1枚: <CurrencyDisplay type="gacha" number="1" />,
  ガチャチケット10枚: <CurrencyDisplay type="gacha" number="10" />,
  SSR確定ガチャチケット1枚: <CurrencyDisplay type="ssr" number="10" />,
  SSR確定ガチャチケット10枚: <CurrencyDisplay type="ssr" number="10" />
}

const CapsuleCurrencyChangeDisplay = ({
  currencyType,
  currencyText,
  redCount,
  blueCount,
  ticketCount,
  ssrCount
}) => {
  const genCurrencyChange = () => {
    let change
    let redChange

    const CurrencySsrTicket = () => {
      if (currencyText === CURRENCY_TEXT.TEN_SSR && ssrCount >= 10) {
        change = Math.max(ssrCount - 10, 0)
      } else if (currencyText === CURRENCY_TEXT.ONE_SSR && ssrCount >= 1) {
        change = Math.max(ssrCount - 1, 0)
      } else {
        change = 0
      }
      return <ExchangeResult imgSrc={SSRTicket} from={ssrCount} to={change} />
    }

    const CurrencyTicket = () => {
      if (currencyText === CURRENCY_TEXT.TEN_TICKET && ticketCount >= 10) {
        change = Math.max(ticketCount - 10, 0)
      } else if (currencyText === CURRENCY_TEXT.ONE_TICKET && ticketCount >= 1) {
        change = Math.max(ticketCount - 1, 0)
      } else {
        change = 0
      }
      return <ExchangeResult imgSrc={GachaTicket} from={ticketCount} to={change} />
    }

    const CurrencyDiamond = () => {
      if (blueCount >= 50 && currencyText === CURRENCY_TEXT.FIFTY_DIAMOND) {
        change = Math.max(blueCount - 50, 0)
      } else {
        change = 0
      }
      if (blueCount < 50 && currencyText === CURRENCY_TEXT.FIFTY_DIAMOND && redCount > 0) {
        redChange = Math.max(redCount + blueCount - 50, 0)
      } else {
        redChange =
          (redCount && currencyText === CURRENCY_TEXT.FIFTY_DIAMOND) ||
          currencyText === CURRENCY_TEXT.FIVE_DIAMOND
            ? redCount
            : 0
      }
      if (blueCount >= 5 && currencyText === CURRENCY_TEXT.FIVE_DIAMOND) {
        change = Math.max(blueCount - 5, 0)
      }
      if (blueCount < 5 && currencyText === CURRENCY_TEXT.FIVE_DIAMOND && redCount > 0) {
        redChange = Math.max(redCount + blueCount - 5, 0)
      }

      return (
        <>
          <ExchangeResult imgSrc={diamondBlue} from={blueCount} to={change} />
          <ExchangeResult imgSrc={diamondRed} from={redCount} to={redChange} />
        </>
      )
    }

    switch (currencyType) {
      case CURRENCY_TYPE.SSRTicket:
        return CurrencySsrTicket()
      case CURRENCY_TYPE.Ticket:
        return CurrencyTicket()
      default:
        return CurrencyDiamond()
    }
  }

  return (
    <div className="my-2 w-full flex flex-col items-center ">
      <p className="text-cornflower-blue text-center font-bold">必要ダイヤ</p>
      {CURRENCY_DISPLAY[currencyText]}
      {(currencyText === CURRENCY_TEXT.FIVE_DIAMOND ||
        currencyText === CURRENCY_TEXT.FIFTY_DIAMOND) && (
        <div className="text-[0.5625rem] text-cornflower-blue font-semibold mt-1">
          ※青ダイヤから優先的に消費されます
        </div>
      )}
      <div className="mt-3 bg-[#F3F9FF] w-[90%] pt-3 pb-5 rounded-md">
        <p className="text-cornflower-blue text-center font-bold mb-3">所有ダイヤ</p>
        {genCurrencyChange()}
      </div>
    </div>
  )
}

CurrencyDisplay.propTypes = {
  number: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['diamond', 'ssr', 'gacha']).isRequired
}

CapsuleCurrencyChangeDisplay.propTypes = {
  currencyType: PropTypes.number.isRequired,
  currencyText: PropTypes.string.isRequired,
  redCount: PropTypes.number.isRequired,
  blueCount: PropTypes.number.isRequired,
  ticketCount: PropTypes.number.isRequired,
  ssrCount: PropTypes.number.isRequired
}

export default CapsuleCurrencyChangeDisplay
