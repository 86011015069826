import React from 'react'
import PropTypes from 'prop-types'

import ModalCommon from 'src/components/common/ModalCommon'

const CapsuleModalSoldOut = ({ handleClose }) => {
  return (
    <ModalCommon
      buttons={[
        {
          label: '選び直す',
          onClick: () => handleClose
        }
      ]}
      onClose={handleClose}
      title={'アイテム不足'}
      isContainedByModal={false}
    >
      <div className="bg-white flex flex-col items-center justify-center py-4 gap-4 font-Inter text-cornflower-blue font-semibold">
        ダイヤ(またはガチャチケット)が足りません
      </div>
    </ModalCommon>
  )
}

CapsuleModalSoldOut.propTypes = {
  handleClose: PropTypes.func.isRequired
}

export default CapsuleModalSoldOut
