/* eslint-disable indent */
import { useContext } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'

import { Listbox, Transition } from '@headlessui/react'
import { MENU_PATHS } from 'src/routes/menu'
import SectionHeader from 'src/components/menu/SectionHeader'
import { HistoryRouteContext } from 'src/routes'
import RoundedButton from 'src/components/common/RoundedButton'
import MessageInput from 'src/components/menu/MessageInput'

const PinkButton = ({ onClick, label }) => {
  return (
    <button
      onClick={onClick}
      className="text-white bg-candy-pink font-semibold rounded-md mx-1 px-1"
    >
      {label}
    </button>
  )
}

const InquiryForm = ({
  onBack,
  isSubmitted,
  onSubmit,
  onEdit,
  userId,
  onConfirm,
  categories,
  selectedCategory,
  message,
  setSelectedCategory,
  setMessage,
  showHistory,
  setFiles,
  setFile1,
  setFile2,
  setFile3,
  file1,
  file2,
  file3,
  showPreview
}) => {
  const { redirectTo } = useContext(HistoryRouteContext)

  const fileSelectedHandler = (e, index) => {
    switch (index) {
      case 0:
        return handleUploadFile(e, setFile1, index)
      case 1:
        return handleUploadFile(e, setFile2, index)
      case 2:
        return handleUploadFile(e, setFile3, index)
    }
  }

  const handleUploadFile = (e, setFile, index) => {
    const selectedFiles = e.target.files[0]
    if (selectedFiles?.size > 3 * 1024 * 1024) {
      alert('ファイルサイズが大きすぎます。3MB 未満のファイルを選択してください。')
      e.target.value = ''
    } else {
      const allowedExtensions = ['jpeg', 'png', 'mp4', 'wav', 'heic', 'gif', 'mov', 'h.264', 'jpg']
      const fileName = selectedFiles?.name.toLowerCase()
      const fileExtension = fileName?.split('.').pop()

      if (allowedExtensions.includes(fileExtension)) {
        if (index === 0) {
          const readerFile1 = new FileReader()
          readerFile1.onload = (e) => {
            const imageURL = e.target.result
            setFile(imageURL)
          }
          readerFile1.readAsDataURL(selectedFiles)
          setFiles((prev) => ({
            ...prev,
            file1: selectedFiles
          }))
        } else if (index === 1) {
          const readerFile2 = new FileReader()
          readerFile2.onload = (e) => {
            const imageURL = e.target.result
            setFile(imageURL)
          }
          readerFile2.readAsDataURL(selectedFiles)
          setFiles((prev) => ({
            ...prev,
            file2: selectedFiles
          }))
        } else {
          const readerFile3 = new FileReader()
          readerFile3.onload = (e) => {
            const imageURL = e.target.result
            setFile(imageURL)
          }
          readerFile3.readAsDataURL(selectedFiles)
          setFiles((prev) => ({
            ...prev,
            file3: selectedFiles
          }))
        }
      }
    }
  }

  const getFileStatus = (file) => {
    if (file) {
      return <p>ファイルが選択されました。</p>
    } else {
      return <p>ファイルが選択されていません。</p>
    }
  }

  const handleEnterText = (text) => {
    if (text.length <= 1500) setMessage(text)
  }

  if (isSubmitted) {
    return (
      <div className="w-full p-4 bg-white text-xs text-left flex flex-col items-center font-Inter text-[0.875rem]">
        <p className="w-full leading-5 font-bold text-silver mt-2">
          お問合せありがとうございます。
        </p>
        <p className="mt-8">
          お問合せ内容の返信は
          <PinkButton onClick={showHistory} label={'お問合せ履歴と返信'} />
          よりご覧いただけます。
        </p>
        <div className={'flex items-center gap-0.5 mt-2 justify-center w-full'}>
          <RoundedButton
            className="bg-candy-pink text-2xl w-1/2 mt-4"
            text="戻る"
            onClick={onBack}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="w-full p-4 bg-white text-xs text-left flex flex-col items-center font-Inter text-[0.875rem]">
      {showPreview ? (
        <div className="w-full">
          <div className="leading-5 font-medium text-silver mt-2">
            以下の内容で問合せをします。 内容をご確認の上、送信してください。
          </div>
        </div>
      ) : (
        <div className="w-full">
          <SectionHeader
            title="お問い合わせの前に"
            className="font-semibold border-b w-full border-[#FFE8F5] text-candy-pink"
          />
          <div className="leading-5 font-medium text-silver mt-1">
            <p>
              必ず
              <PinkButton onClick={() => redirectTo(MENU_PATHS.HelpPage)} label={'よくある質問'} />
              をご参照ください。
              原則として「よくある質問」に記載されております。また、不具合に関する報告は先に、｢現在確認されている不具合に関するご案内｣をご確認下さい。問題が解決しない場合には、下記フォームよりお問合せください。
            </p>
            <p className="mt-0.5">
              運営からの返答は
              <PinkButton onClick={showHistory} label={'運営からの返答は'} />
              より閲覧していただけます。（返答に時間がかかることもございますのであらかじめご了承ください。）
            </p>
          </div>
        </div>
      )}
      <SectionHeader
        title="ユーザーID"
        className="mt-6 font-semibold border-b w-full text-xs border-metallic-gray text-metallic-gray py-0"
      />

      <span
        className={`${
          showPreview ? '' : 'text-xl'
        } mt-2 mb-6 w-full text-left pl-0.5 font-semibold text-candy-pink `}
      >
        {userId || '------'}
      </span>
      <SectionHeader
        title="カテゴリー"
        className="font-semibold border-b w-full text-xs border-metallic-gray text-metallic-gray py-0"
      />
      <div className={`${showPreview ? 'mt-2' : 'mt-4'} relative w-full z-10`}>
        {showPreview ? (
          <span className=" text-candy-pink font-semibold">{selectedCategory.name}</span>
        ) : (
          <Listbox value={selectedCategory} onChange={setSelectedCategory}>
            {({ open }) => (
              <>
                <Listbox.Button
                  className={`
                  ${open ? 'bg-candy-pink' : 'bg-white'}
                   w-full p-2 my-2 rounded-md border border-candy-pink flex justify-center items-center text-[0.9375rem]  ${
                     !selectedCategory ? 'text-gray-500' : ''
                   }`}
                >
                  <>
                    <span className={`${open ? 'text-white' : 'text-candy-pink'} truncate`}>
                      {selectedCategory?.name || 'カテゴリを選択してください'}
                    </span>
                    <span
                      className={`transform ${open ? 'text-white' : 'text-candy-pink rotate-180'}`}
                    >
                      ▲
                    </span>
                  </>
                </Listbox.Button>
                <Transition
                  show={open}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={open ? 'h-60' : ''}
                >
                  <Listbox.Options className="bg-white my-0.5 rounded border border-candy-pink text-[0.6875rem] text-left absolute z-10 text-candy-pink w-5/6">
                    {categories.map((cat, i) => (
                      <Listbox.Option
                        key={cat.id}
                        value={cat}
                        className={`p-2 ${
                          i !== categories.length - 1 ? 'border-b border-candy-pink' : ''
                        }`}
                      >
                        {cat.name}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </>
            )}
          </Listbox>
        )}
      </div>
      <div className="mt-6 w-full">
        <MessageInput
          title="お問合せ内容"
          message={message}
          showPreview={showPreview}
          handleEnterText={handleEnterText}
        />
      </div>

      {!showPreview && (
        <>
          <SectionHeader
            title="写真ファイルの添付"
            subText="（合計3枚まで/写真1点につき3MBまで）"
            subTextStyle="text-[0.5rem]"
            className="mt-6 font-semibold border-b w-full text-xs border-metallic-gray text-metallic-gray py-0"
            addStyle="0"
          />
          <div className="w-full text-left">
            {[file1, file2, file3].map((item, index) => (
              <div className="mt-4 flex items-center gap-2" key={index}>
                <label
                  className="px-1 rounded bg-candy-pink font-semibold text-white"
                  htmlFor={`file${index}`}
                >
                  参照...
                </label>
                <input
                  type="file"
                  id={`file${index}`}
                  accept="*/*"
                  onChange={(e) => fileSelectedHandler(e, index)}
                  style={{ display: 'none' }}
                />
                <span className="text-[0.5625rem] text-rosy-pink font-semibold">
                  {getFileStatus(item)}
                </span>
              </div>
            ))}
          </div>
        </>
      )}

      {
        showPreview &&
        <div className='w-full flex flex-col mt-6 gap-4'>
          {file1.includes('image') ? (
            <img src={file1} className="w-2/5 h-2/5 object-cover my-2 m-auto" />
          ) : (
            <ReactPlayer height="100%" width="100%" controls url={file1} />
          )}
          {file2.includes('image') ? (
            <img src={file2} className="w-2/5 h-2/5 object-cover my-2 m-auto" />
          ) : (
            <ReactPlayer
              height="100%"
              width="100%"
              style={{ marginTop: file2 ? '10px' : '0px' }}
              controls
              url={file2}
            />
          )}
          {file3.includes('image') ? (
            <img src={file3} className="w-2/5 h-2/5 object-cover my-2 m-auto" />
          ) : (
            <ReactPlayer
              height="100%"
              width="100%"
              style={{ marginTop: file3 ? '10px' : '0px' }}
              controls
              url={file3}
            />
          )}
        </div>
      }
      <div className={'flex items-center gap-0.5 mt-2 justify-center w-full'}>
        {showPreview ? (
          <>
            <RoundedButton
              className="bg-metallic-gray w-1/2 text-2xl mt-4"
              text="修正する"
              onClick={onEdit}
            />
            <RoundedButton
              className="bg-candy-pink text-2xl w-1/2 mt-4"
              text="送信"
              onClick={onSubmit}
            />
          </>
        ) : (
          <RoundedButton
            className={`${
              !selectedCategory || !message ? 'opacity-50' : ''
            } bg-candy-pink w-1/2 text-2xl mt-10`}
            disabled={!selectedCategory || !message}
            onClick={() => {
              onConfirm(selectedCategory.id, message)
            }}
            text="確認する"
          />
        )}
      </div>
    </div>
  )
}

PinkButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
}

InquiryForm.propTypes = {
  onBack: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  showHistory: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  selectedCategory: PropTypes.object,
  message: PropTypes.string,
  userId: PropTypes.number,
  setFile1: PropTypes.func,
  setFile2: PropTypes.func,
  setFile3: PropTypes.func,
  file1: PropTypes.string,
  file2: PropTypes.string,
  file3: PropTypes.string,
  setFiles: PropTypes.func,
  showPreview: PropTypes.bool
}

export default InquiryForm
