import React from 'react'
import PropTypes from 'prop-types'
import BtnClose from 'src/assets/img/btn-close.png'
import BtnClosePink from 'src/assets/img/common/btn_close_pink.svg'
import { Modal } from 'src/components/common/Modal'
import clsx from 'clsx'

const ModalCommon = ({
  open,
  onClose,
  title,
  children,
  buttons,
  theme = 'blue',
  height = 'max-h-[65%]',
  isContainedByModal = true
}) => {
  const Container = isContainedByModal ? Modal : 'div'
  const containerProps = isContainedByModal
    ? {
        open,
        onClose,
        className: 'w-full flex items-center justify-center',
        maxHeight: 'h-full'
      }
    : {}

  return (
    <Container {...containerProps}>
      <div
        className={clsx(height, 'w-full sm:w-[350px] max-w-[85%] px-5 pb-[10px] bg-light-gray flex flex-col shadow-xl transform transition-all font-Inter rounded-xl')}
        style={{ boxShadow: '0px 4px 4px 0px #00000040' }}
      >
        <div
          onClick={onClose}
          className="w-[18px] h-[18px] absolute top-[10px] right-[10px] z-50 cursor-pointer"
        >
          <img
            className="w-full h-full"
            src={theme === 'blue' ? BtnClose : BtnClosePink}
            alt="close"
          />
        </div>
        {title && (
          <div
            className={`text-center text-[22px] font-black py-2 ${
              theme === 'blue' ? 'text-cornflower-blue' : 'text-candy-pink'
            }`}
          >
            {title}
          </div>
        )}
        <div className="overflow-auto hidden-scroll">{children}</div>
        {buttons && buttons.length > 0 && (
          <div
            className={`flex items-center gap-0.5 mt-2 ${
              buttons.length === 1 ? 'justify-center' : 'justify-between'
            }`}
          >
            {buttons.map((btn, index) => (
              <button
                className={`w-[50%] text-white text-[24px] font-bold text-center rounded-[32px] py-2 font-MPlus1 ${
                  btn.disabled ? 'opacity-50' : ''
                } ${
                  btn.type === 'close'
                    ? 'bg-metallic-gray'
                    : theme === 'blue'
                      ? 'bg-cornflower-blue'
                      : 'bg-candy-pink'
                }`}
                key={index}
                onClick={btn.onClick}
                disabled={btn.disabled}
              >
                {btn.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </Container>
  )
}

ModalCommon.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      className: PropTypes.string,
      disabled: PropTypes.bool
    })
  ),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  theme: PropTypes.oneOf(['blue', 'pink']),
  isContainedByModal: PropTypes.bool,
  height: PropTypes.string
}

export default ModalCommon
