import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { formatDate } from 'src/lib/date'
import BgLove from 'src/assets/img/gacha/bg_love.png'
import ReactPlayer from 'react-player'
import BtnbackFile from 'src/assets/img/ranking/icon-back.png'
import clsx from 'clsx'

export const ItemChatReceived = ({ data, className }) => {
  const [openModal, setOpenModal] = useState(false)

  const attachmentUrl = data?.attachment_url

  const [checkFileVideo, checkFile] = useMemo(() => {
    if (!attachmentUrl) {
      return [false, false]
    }

    let newCheckFileVideo = true
    let newCheckFile = true

    if (attachmentUrl.includes('data:')) {
      if (attachmentUrl.includes('data:video')) {
        newCheckFile = false
      } else {
        newCheckFileVideo = false
      }
    } else {
      const regexImageExtensions = /(png|gif|jpg|heic|jpeg)$/
      const regexVideoExtensions = /(mp4|mov|wav)$/
      const extensionsData = attachmentUrl.split('.')
      const typeFile = extensionsData.pop().toLowerCase()
      if (regexImageExtensions.test(typeFile)) {
        newCheckFileVideo = false
      }
      if (regexVideoExtensions.test(typeFile)) {
        newCheckFile = false
      }
    }

    return [newCheckFileVideo, newCheckFile]
  }, [attachmentUrl])

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  return (
    <div className="flex items-end w-full flex-col-reverse pr-[24px]">
      <div
        className={`order-last rounded-lg flex-col relative ${
          className || 'bg-[#6F9BFF] border-[#6F9BFF]'
        } max-w-[270px] w-[270px] p-2 border-[0.5px] z-10 break-words min-h-[54px] flex items-center shadow-xl`}
      >
        <div className={`${className || ''} chat-received-item`} />
        <div
          className="text-justify text-[#FFFFFF] text-[0.875rem] font-normal whitespace-pre-line w-full font-Inter"
          style={{ overflowWrap: 'anywhere' }}
        >
          {data?.description || data?.content}
        </div>
        <div
          className={`font-Inter text-[12px] ${
            className ? 'text-[#D9BBFF]' : 'text-[#9AC8FF]'
          } text-right w-full font-medium`}
        >
          <span className="font-medium">{formatDate(new Date(data?.created_at))}</span>
        </div>
        {checkFile && (
          <img
            className="w-full h-full object-contain"
            src={data?.attachment_url}
            onClick={handleOpenModal}
          />
        )}
        {checkFileVideo && (
          <ReactPlayer
            controls
            width="100%"
            height="100%"
            url={data?.attachment_url}
            className="object-contain"
          />
        )}
        {data?.item && (
          <div className="font-inter flex flex-col items-center justify-center w-full font-bold">
            <ItemThumbnail
              image={data?.item.image || ''}
              imgClassName="w-[70px] object-contain"
              className=""
              rarity
              loveAmount={data?.item.increase_love}
            />
            <div className="text-[0.625rem] text-[#FFF]">{data?.item.name}</div>
            <div className="text-[0.625rem] text-[#FFF]">
              x{data?.item.count || data?.item.amount}個
            </div>
          </div>
        )}
      </div>
      {openModal && (
        <div className="">
          <div className="fixed top-[10%] left-[5%] z-[1001]" onClick={handleClose}>
            <img className="w-[30px]" src={BtnbackFile} />
          </div>
          <div className="sm:w-[360px] fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg font-hiragino flex items-center justify-center bg-[black] h-screen w-screen z-[1000]">
            <div className="block">
              {checkFile && (
                <img
                  className="w-full h-full"
                  src={data?.attachment_url}
                  onClick={handleOpenModal}
                />
              )}
              {checkFileVideo && (
                <div onClick={handleOpenModal}>
                  <ReactPlayer
                    playsinline
                    controls
                    width="100%"
                    height="100%"
                    url={data?.attachment_url}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

ItemChatReceived.propTypes = {
  data: PropTypes.object.isRequired,
  isMe: PropTypes.bool,
  className: PropTypes.string
}
const ItemThumbnail = ({
  image,
  rarity,
  loveAmount,
  className = 'w-[72px] h-[80px] border-menu-dark-blue rounded-sm',
  imgClassName = 'w-[60px]'
}) => {
  return (
    <div
      className={clsx(
        'flex justify-center items-center relative',
        className,
        !rarity && 'border-2 drop-shadow-md bg-white'
      )}
    >
      <img className={clsx(imgClassName)} src={image} />
      {loveAmount !== 0 && (
        <div
          className="text-white truncate absolute right-0 bottom-1 w-[56px] h-[18px] flex justify-center items-center"
          style={{ backgroundImage: `url('${BgLove}')`, backgroundSize: '100% 100%' }}
        >
          <div className="leading-3">
            <span className="text-[6px] pl-1">LOVE度</span>
            <span className="text-[9px]">{loveAmount}</span>
          </div>
        </div>
      )}
    </div>
  )
}

ItemThumbnail.propTypes = {
  image: PropTypes.string.isRequired,
  rarity: PropTypes.bool,
  loveAmount: PropTypes.number,
  className: PropTypes.string,
  imgClassName: PropTypes.string
}
