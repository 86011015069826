import { useContext, useEffect, useState } from 'react'
import { ActionCableContext } from '../../providers/ActionCableProvider'
import { useAuthState } from '../../providers/AuthProviders'

const useLiveChannel = ({ conversationId, enableExtraTimeQuery }) => {
  const { user } = useAuthState()
  const cable = useContext(ActionCableContext)
  const [watchableTime, setWatchableTime] = useState('')
  const [extraWatchableTime, setExtraWatchableTime] = useState('')
  const [enableExtraTime, setEnableExtraTime] = useState(enableExtraTimeQuery)
  const [limittedHourglass, setLimittedHourglass] = useState(false)
  const [item, setItem] = useState()
  const [expensiveItem, setExpensiveItem] = useState()
  const [disableUser, setDisableUser] = useState(false)
  const [activeItemData, setActiveItemData] = useState({ changed: false, items: [] })
  const [loveItem, setLoveItem] = useState(0)

  const getLiveItem = (data) => {
    if (data.expensive_item) {
      setItemsToAppend(data, 'expensive-item', true)
    } else {
      let type = 'item'
      if (data.is_extra_time) {
        if (data.started_extra_time) {
          if (user.id === data.user_id) {
            setLimittedHourglass(data.can_watch_till_end_live)
            if (
              new Date(data.extra_watchable_time) < new Date() &&
              new Date(data.extra_continue_time) > new Date()
            ) {
              setExtraWatchableTime(data.extra_continue_time)
            } else {
              setExtraWatchableTime(data.extra_watchable_time)
            }
          }
        } else {
          if (data.limitted_hourglass_item) {
            setLimittedHourglass(true)
            setEnableExtraTime(true)
            setExtraWatchableTime(data.extra_watchable_time)
          }
          setWatchableTime(data.watchable_time)
        }
        type = 'extra-time'
      }
      setItemsToAppend(data, type, false)
    }
    setLoveItem(data.extra_love)
  }

  const setItemsToAppend = (data, typeItem, isExpensive) => {
    const itemData = {
      item_name: data.item_name,
      user_name: data.user_name,
      image_url: data.image_url,
      type: typeItem,
      is_firework: data.is_firework,
      animation_url: data.animation_img,
      user_id: data.user_id,
      extra_love: data.extra_love,
      amount: data.amount || 1,
      item_type: data.item_type
    }

    if (isExpensive) {
      setExpensiveItem({ ...itemData })
      setItem({ ...itemData })
    } else {
      setItem({ ...itemData })
    }
  }

  const setCommentToAppend = (data) => {
    const commentData = {
      user_id: data.user_id,
      user_name: data.user_name,
      content: data.content,
      type: 'comment'
    }

    setItem({ ...commentData })
  }

  useEffect(() => {
    if (!cable || !conversationId) return

    const channel = cable.subscriptions.create(
      { channel: 'LiveChannel', conversation_id: conversationId },
      {
        connected: () => console.log('CONNECTED'),
        received: (response) => {
          if (response.action === 'broadcast_live_item') {
            getLiveItem(response.data)
          } else if (response.action === 'broadcast_live_comment') {
            setCommentToAppend(response.data)
          } else if (response.action === 'broadcast_live_view') {
            const typeJoined = response.data.re_joined ? 'rejoined-user' : 'joined-user'
            setItem({
              user_name: response.data.user_name,
              type: typeJoined,
              user_id: response.data.user_id
            })
          } else if (response.action === 'broadcast_black_list') {
            setDisableUser(response.data.black_list.includes(user.id))
          } else if (response.action === 'broadcast_white_list') {
            setDisableUser(
              response.data.white_list.length > 0 && !response.data.white_list.includes(user.id)
            )
          } else if (response.action === 'broadcast_active_item') {
            setActiveItemData(response.data)
          } else if (
            response.action === 'broadcast_remove_user' &&
            response.data.user_id === user.id
          ) {
            setDisableUser(true)
          } else if (response.action === 'broadcast_complete_live') {
            setDisableUser(true)
          } else if (response.action === 'broadcast_continue_love_item') {
            setLoveItem(response.data.extra_love)
          } else if (
            response.action === 'broadcast_remove_user_passport' &&
            response.data.user_id === user.id
          ) {
            setDisableUser(true)
          }
        }
      }
    )

    return () => {
      channel.unsubscribe()
    }
  }, [cable, conversationId])

  return {
    watchableTime,
    extraWatchableTime,
    setExtraWatchableTime,
    enableExtraTime,
    setEnableExtraTime,
    limittedHourglass,
    setLimittedHourglass,
    item,
    expensiveItem,
    setExpensiveItem,
    disableUser,
    setDisableUser,
    activeItemData,
    loveItem,
    setLoveItem
  }
}

export default useLiveChannel
