import { useContext } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import switchPin from 'src/services/patch/switchPin'
import { HistoryRouteContext } from 'src/routes'
import { renderAvatar } from 'src/constants/avatar'
import { formatTime } from 'src/constants/formatDate'
import { MAIN_PATHS } from 'src/routes/main'
import { removeNewlineCode } from 'src/lib/string'
import HTMLReactParser from 'html-react-parser'
import UnionIcon from 'src/assets/img/message/union_icon.svg'

export const MessageItem = ({ data, onPin }) => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const { message, character } = data
  const { name, avatar } = character

  const handleSwitchPin = async () => {
    await switchPin(character.id)
    onPin()
  }

  return (
    <div className="cursor-pointer grid grid-cols-[70px_auto_70px] border-b border-[#9AC8FF] py-3 items-center font-Inter text-[#333333]">
      <div className="flex items-end h-full relative">
        <div className="w-[60px] h-[60px] rounded-[50%] overflow-hidden">
          <img className="w-full h-full" src={renderAvatar(avatar, name)} alt="avatar" />
        </div>
        <img
          src={UnionIcon}
          alt="union"
          className={`absolute ${
            character.is_pin ? '' : 'filter grayscale'
          } cursor-pointer w-[18px] h-[18px] left-[42px]`}
          onClick={handleSwitchPin}
        />
      </div>
      <div
        onClick={() =>
          redirectTo(
            `${MAIN_PATHS.MessageDetailPage.replace(':characterId', data?.character?.id)}`,
            {
              state: { preUrl: location.pathname }
            }
          )
        }
      >
        <div className="cursor-pointer font-sans text-left text-base font-black leading-[22px]">
          {name}
        </div>
        <div
          className="text-justify text-[9px] font-light leading-3 text-ellipsis overflow-hidden min-h-[24px] mt-1"
          style={{
            WebkitLineClamp: '2',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical'
          }}
        >
          {HTMLReactParser(removeNewlineCode(message))}
        </div>
      </div>
      <div className="h-100 flex flex-col items-end">
        <MessageDate>{data.last_message_time && formatTime(data.last_message_time)}</MessageDate>
        {data.unreadTotal > 0 && <MessageUnread>{data.unreadTotal}</MessageUnread>}
      </div>
    </div>
  )
}

MessageItem.propTypes = {
  data: PropTypes.shape({
    channel_id: PropTypes.string,
    message: PropTypes.string,
    time: PropTypes.string,
    last_message_time: PropTypes.string,
    created_at: PropTypes.string,
    unreadTotal: PropTypes.number,
    character: PropTypes.shape({
      id: PropTypes.number,
      avatar: PropTypes.string,
      name: PropTypes.string,
      love_level: PropTypes.number,
      grow_level: PropTypes.number,
      is_pin: PropTypes.bool
    }),
    level: PropTypes.shape({
      love: PropTypes.shape({
        level: PropTypes.number,
        total_items: PropTypes.number,
        current_items: PropTypes.number
      }).isRequired,
      grow: PropTypes.shape({
        level: PropTypes.number,
        total_items: PropTypes.number,
        current_items: PropTypes.number
      }).isRequired
    })
  }),
  idx: PropTypes.number,
  onPin: PropTypes.func
}

const MessageUnread = styled.div.attrs({
  className: 'rounded-full bg-[#FF9900] w-[20px] h-[20px] text-[#FFF] text-[12px]'
})``

const MessageDate = styled.div.attrs({
  className: 'mb-[10px] text-[10px] text-[#8E8E8E]'
})``
