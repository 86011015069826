import { useEffect, useState } from 'react'
import { getConditions } from 'src/services/get/getConditions'

import LegaleseWrapper from 'src/components/layouts/LegaleseWrapper'
import SubmenuHeader from 'src/components/menu/SubmenuHeader'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import { MainLayout } from 'src/components/layouts/MainLayout'
import TipsModal from 'src/components/common/TipsModal'

export const MenuTermOfServicePage = () => {
  const [term, setTerm] = useState('')

  const fetchCondition = async () => {
    const data = await getConditions('condition')
    const content = data.find((item) => item?.condition?.status === 'is_public')?.condition?.content
    setTerm(content)
  }

  useEffect(() => {
    fetchCondition()
  }, [])

  return (
    <>
      <MainLayout className="bg-menu-bg" active="menu" classNameHeader="">
        <MenuWrapper className="flex items-center flex-col" overflow={true}>
          <SubmenuHeader title="利用規約"/>
          <LegaleseWrapper content={term} />
          <TipsModal type="menu" />
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

export default MenuTermOfServicePage
