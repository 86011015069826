import React from 'react'
import { MainLayout } from 'src/components/layouts/MainLayout'
import Loading from 'src/components/layouts/Loading'
import styled from 'styled-components'
import MenuBar from 'src/components/menu/MenuBar'
// import StandardizedButton from 'src/components/common/StandardizedButton'
import { useNavigate } from 'react-router-dom'
import { MYPAGE_PATHS } from 'src/routes/myPage'
import useLoading from 'src/components/layouts/hooks/useLoading'

import EventImg from './assets/event_banner.png'
import EventDefaultImg from './assets/event_banner_default.png'
import { useRankingRewardEvents } from 'src/services/hooks/useFetchRankingRewardEvents'
// import BtnViewpastevents from './assets/btn_viewpastevents.png'

export const RankingEventsPage = () => {
  const { data: rewardEvents = [], isFetched, isFetching } = useRankingRewardEvents()
  const isLoading = !isFetched && isFetching
  const navigate = useNavigate()
  const { showLoading, progress } = useLoading([isLoading])
  return (
    <Wrapper>
      <Header>
        <MenuBar title="recommend" />
      </Header>
      <MainLayout
        className="relative h-fit reward-page"
        active="mypage"
        showHeader={false}
        showFooter={true}
      >
        <div className="px-[16px]">
          {rewardEvents.map((rewardEvent, index) => {
            const startDate = new Date(rewardEvent.start_time)

            return (
              <div
                className="aspect-[343/85] mb-[16px] relative"
                key={index}
                onClick={() =>
                  navigate(MYPAGE_PATHS.RankingRewardPage.replace(':id', rewardEvent.id))
                }
              >
                <img
                  src={rewardEvent.event.image_url || EventDefaultImg}
                  className="aspect-[343/85] object-cover"
                  onError={(e) => {
                    e.target.src = EventDefaultImg
                  }}
                />
                <img src={EventImg} className="absolute top-0 left-0" />
                <span className="absolute right-[60px] top-[16px] rotate-[-28deg] text-[#cebef5] font-[VDL-LineGR]">
                  {startDate.getFullYear()}
                </span>
                <span className="absolute right-[48px] top-[32px] rotate-[-28deg] text-[#cebef5] font-[VDL-LineGR] text-[26px]">
                  {startDate.getMonth() + 1}
                </span>
              </div>
            )
          })}
        </div>

        {/* <StandardizedButton img={BtnViewpastevents} alt="決済ページに戻る" className="w-40" /> */}

        <div className="h-full pb-[112px] px-[14px]">{showLoading && <Loading progress={progress} />}</div>
      </MainLayout>
    </Wrapper>
  )
}

const Header = styled.div.attrs({
  className: 'relative w-full h-[80px] z-50'
})``

const Wrapper = styled.div.attrs({
  className:
    'sm:w-desktop-width w-screen h-screen mx-auto relative overflow-y-auto bg-center bg-no-repeat bg-cover bg-white'
})`
`
