/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import heart_0 from 'src/assets/img/header-heart/heart_0.png'
import heart4_1 from 'src/assets/img/header-heart/max_4_hearts/heart_4_1.png'
import heart4_2 from 'src/assets/img/header-heart/max_4_hearts/heart_4_2.png'
import heart4_3 from 'src/assets/img/header-heart/max_4_hearts/heart_4_3.png'
import heart4_4 from 'src/assets/img/header-heart/max_4_hearts/heart_4_4.png'

import heart8_1 from 'src/assets/img/header-heart/max_8_hearts/heart_8_1.png'
import heart8_2 from 'src/assets/img/header-heart/max_8_hearts/heart_8_2.png'
import heart8_3 from 'src/assets/img/header-heart/max_8_hearts/heart_8_3.png'
import heart8_4 from 'src/assets/img/header-heart/max_8_hearts/heart_8_4.png'
import heart8_5 from 'src/assets/img/header-heart/max_8_hearts/heart_8_5.png'
import heart8_6 from 'src/assets/img/header-heart/max_8_hearts/heart_8_6.png'
import heart8_7 from 'src/assets/img/header-heart/max_8_hearts/heart_8_7.png'
import heart8_8 from 'src/assets/img/header-heart/max_8_hearts/heart_8_8.png'

import heart12_1 from 'src/assets/img/header-heart/max_12_hearts/heart_12_1.png'
import heart12_2 from 'src/assets/img/header-heart/max_12_hearts/heart_12_2.png'
import heart12_3 from 'src/assets/img/header-heart/max_12_hearts/heart_12_3.png'
import heart12_4 from 'src/assets/img/header-heart/max_12_hearts/heart_12_4.png'
import heart12_5 from 'src/assets/img/header-heart/max_12_hearts/heart_12_5.png'
import heart12_6 from 'src/assets/img/header-heart/max_12_hearts/heart_12_6.png'
import heart12_7 from 'src/assets/img/header-heart/max_12_hearts/heart_12_7.png'
import heart12_8 from 'src/assets/img/header-heart/max_12_hearts/heart_12_8.png'
import heart12_9 from 'src/assets/img/header-heart/max_12_hearts/heart_12_9.png'
import heart12_10 from 'src/assets/img/header-heart/max_12_hearts/heart_12_10.png'
import heart12_11 from 'src/assets/img/header-heart/max_12_hearts/heart_12_11.png'
import heart12_12 from 'src/assets/img/header-heart/max_12_hearts/heart_12_12.png'

const heartImages = {
  12: {
    1: heart12_1,
    2: heart12_2,
    3: heart12_3,
    4: heart12_4,
    5: heart12_5,
    6: heart12_6,
    7: heart12_7,
    8: heart12_8,
    9: heart12_9,
    10: heart12_10,
    11: heart12_11,
    12: heart12_12
  },
  8: {
    1: heart8_1,
    2: heart8_2,
    3: heart8_3,
    4: heart8_4,
    5: heart8_5,
    6: heart8_6,
    7: heart8_7,
    8: heart8_8
  },
  4: {
    1: heart4_1,
    2: heart4_2,
    3: heart4_3,
    4: heart4_4
  }
}

export const RecoverHeart = ({ max, heartRecoveryMaximum, height = 'h-8' }) => {
  const heartImageSrc =
    heartRecoveryMaximum === 0 ? heart_0 : heartImages[max]?.[heartRecoveryMaximum] || heart_0

  return (
    <img
      src={heartImageSrc}
      alt="possession_heart_recovery_or_maximum"
      className={clsx(height, 'inline mr-0.5')}
    />
  )
}

RecoverHeart.propTypes = {
  max: PropTypes.number,
  heartRecoveryMaximum: PropTypes.number,
  height: PropTypes.string
}
