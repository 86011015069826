import { useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import BtnReturn from 'src/assets/img/common/btn_return.png'
import { useContext } from 'react'
import { HistoryRouteContext } from 'src/routes'

const ReturnButton = ({ back, onBack }) => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const navigate = useNavigate()
  const location = useLocation()

  const onClick = (e) => {
    e.stopPropagation()

    if (onBack) return onBack()

    if (back) {
      return navigate(back)
    } else if (location.pathname.includes('talk_story/detail/')) {
      return navigate('/talk_story')
    } else if (location.pathname === '/talk_story' || location.pathname === '/talk_real') {
      return navigate('/mypage')
    }

    redirectTo(-1)
  }

  return (
    <button onClick={onClick} className="absolute left-0 top-2" >
      <img src={BtnReturn} className="w-[64px]" alt="return button" />
    </button>
  )
}

export default ReturnButton

ReturnButton.propTypes = {
  back: PropTypes.string,
  onBack: PropTypes.func
}
