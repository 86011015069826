import { useQuery } from 'react-query'
import getOpeningStoryDetail from 'src/services/get/getOpeningStoryDetail'

const useGetOpeningStoryDetail = (id) => {
  return useQuery({
    queryKey: ['useGetOpeningStoryDetail', id],
    queryFn: () => getOpeningStoryDetail(id)
  })
}

export { useGetOpeningStoryDetail }
