import styled from 'styled-components'
import PropTypes from 'prop-types'

import DarkPink from 'src/assets/img/shop/dark_pink_arrow.png'
import Blue from 'src/assets/img/common/arrow_right.png'
const ItemExchangeBox = styled.div.attrs(({ boxStyle }) => ({
  className: `${boxStyle} rounded-xl flex font-MPlus1 font-bold text-silver items-center gap-4 justify-around p-1`
}))``

const StyledNumber = styled.span`
  width: 3rem;
  text-align: left;
  text-shadow: -2px 0 white, 2px 0 white, 0 2px white, 0 -2px white;
  filter: drop-shadow(0px 0px 2px #ffffff);
  white-space: nowrap;
`

const ExchangeResult = ({ imgSrc, from, to, theme = 'blue' }) => {
  return (
    <ItemExchangeBox boxStyle="w-full px-5">
      <img src={imgSrc} className="w-10" alt="Item" />
      <StyledNumber>{from.toLocaleString()}</StyledNumber>
      <img src={theme === 'blue' ? Blue : DarkPink} className="w-4 h-4" alt="Arrow" />
      <StyledNumber className="text-[#FF4747]">{to.toLocaleString()}</StyledNumber>
    </ItemExchangeBox>
  )
}

ExchangeResult.propTypes = {
  theme: PropTypes.string,
  imgSrc: PropTypes.string.isRequired,
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired
}

export default ExchangeResult
