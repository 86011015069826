import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { useDispatch, useSelector } from 'react-redux'

import FadeTransition from 'src/components/common/transitions/FadeTransition'
import CapsuleSlide from 'src/pages/main/capsule/CapsuleSlide'
import DialogBox from 'src/components/common/DialogBox'
// import GachaInfo from 'src/models/GachaInfo'

import getCounTimesroll from 'src/services/get/getCountTimeRoll'
import getGachaId from 'src/services/get/getGachaId'
import { fetchDetailGacha } from 'src/redux/gacha/getDetailGacha'

import blueHeaderBackground from 'src/assets/img/menu/background/blue_header_background.png'

const CapsuleTab = ({
  slideIndex,
  setSlideIndex,
  gachas,
  showDrawModal,
  isShown,
  setTicket,
  setIdItem,
  gachaDetail,
  setGachaDetail,
  countTimeRollCurrent,
  setcountTimeRoll
}) => {
  useEffect(() => {
    if (isShown) setSlideIndex(0)
  }, [isShown])

  const dispatch = useDispatch()
  const { gacha } = useSelector((state) => state.getDetailGacha)
  useEffect(() => {
    setGachaDetail(gacha)
  }, [gacha])

  useEffect(() => {
    if (Object.keys(gacha).length === 0) {
      dispatch(fetchDetailGacha(gachas[0].id))
    }
  }, [dispatch, gacha])

  useEffect(() => {
    const getIdFirst = async () => {
      const countTimeRollRes = await getCounTimesroll(gachas[0].id)

      setcountTimeRoll(countTimeRollRes.data)
    }
    getIdFirst()
  }, [])

  const handleIdItem = async (index) => {
    const currentSlide = gachas[index]
    setIdItem(currentSlide.id)
    const [countTimeRollRes, gachaIdRes] = await Promise.all([
      getCounTimesroll(currentSlide.id || gachas[0].id),
      getGachaId(currentSlide.id)
    ])

    setcountTimeRoll(countTimeRollRes.data)
    setGachaDetail(gachaIdRes.items)
  }

  return (
    <div
      className="relative px-2 py-4 overflow-y-auto hidden-scroll"
      style={{ height: 'calc(100dvh - 250px)' }}
    >
      <FadeTransition show={isShown} className="absolute top-0 left-0 right-0">
        <Carousel
          swipeable={true}
          showIndicators={false}
          showStatus={false}
          emulateTouch={true}
          showArrows={false}
          showThumbs={false}
          selectedItem={slideIndex}
          onChange={(index) => {
            setSlideIndex(index)
            handleIdItem(index)
          }}
        >
          {gachas.map((el, i) => {
            return (
              <CapsuleSlide
                countTimeRollCurrent={countTimeRollCurrent}
                id={el.id}
                key={i}
                image={el.image}
                startAt={el.start_at}
                endAt={el.end_at}
                gachasMode={el.gacha_modes}
                useSSGachaTicket={el.use_ss_gacha_ticket}
                showDrawModal={showDrawModal}
                setTicket={setTicket}
                gachaDetail={gachaDetail}
              />
            )
          })}
        </Carousel>
        {gachas[slideIndex]?.banner_text && (
          <div className="relative">
            <img src={blueHeaderBackground} className="-z-10 w-full rotate-180 aspect-[4/1]" />

            <div className="border border-cornflower-blue w-[95%] mx-auto rounded-3xl text-sm font-bold font-Inter py-3 px-3 text-cornflower-blue relative left-[47.5%] transform -translate-x-1/2 -top-16">
              <DialogBox
                text={'ニュース'}
                background="#9AB9FF"
                textStyle="text-base text-white font-Inter"
                rounded="rounded-[27px]"
                position="left-1/2 -top-4"
              />
              <div>
                {gachas[slideIndex]?.banner_text.match(/.{1,45}/g).map((chunk, index) => (
                  <div key={index} className="relative inline-block">
                    <div className="h-2 bg-[#E7F2FF] absolute top-3 z-0 inset-x-0" />
                    <p className="z-20 relative">{chunk}</p>
                  </div>
                ))}
              </div>
              <div className="relative">
                <div className="w-12 h-2 bg-[#E7F2FF] absolute top-3 z-0 left-1/2 transform -translate-x-1/2" />
                <p className="z-20 relative">GET!</p>
              </div>
              <p className="mt-1">詳細は上のボタンをタップ</p>
            </div>
          </div>
        )}
      </FadeTransition>
    </div>
  )
}

CapsuleTab.propTypes = {
  slideIndex: PropTypes.number.isRequired,
  setSlideIndex: PropTypes.func.isRequired,
  isShown: PropTypes.bool.isRequired,
  showDrawModal: PropTypes.func.isRequired,
  gachas: PropTypes.array,
  setTicket: PropTypes.func,
  setIdItem: PropTypes.func,
  gachaDetail: PropTypes.object,
  setGachaDetail: PropTypes.func,
  countTimeRollCurrent: PropTypes.object,
  setcountTimeRoll: PropTypes.func
}

export default CapsuleTab
