import React from 'react'

import { RecommendMangaPage } from 'src/pages/myPage/Recommend/Manga'

const MANGA_PATHS = {
  MangaList: '/manga/:id/:name'
}

const MangaRouter = [{ path: MANGA_PATHS.MangaList, element: <RecommendMangaPage /> }]

export { MangaRouter, MANGA_PATHS }
