import PropTypes from 'prop-types'
import clsx from 'clsx'
import styled from 'styled-components'

const TabDiv = styled.div.attrs(({ disabled, buttonStyle }) => ({
  className: clsx(
    buttonStyle,
    'relative font-light leading-[16px] rounded-[38px] mt-1 font-Inter w-full',
    'text-white bg-clip-padding px-2',
    disabled ? 'border-[3px] border-metallic-gray bg-white' : 'border-[2px] border-transparent'
  )
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ disabled }) =>
    disabled
      ? ''
      : `position: relative;
  border-radius: 38px;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -2;
    margin: -3px;
    border-radius: inherit;
    background: linear-gradient(90deg, #40e7ff 0%, #ff48e2 100%);
    transition: background 0.3s ease;
  }
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    border-radius: inherit;
    background: linear-gradient(180deg, #FFFFFF 72%, #DBDBDB 100%);
  }`}
`

const StyledText = styled.span.attrs(({ disabled, textStyle = 'text-2xl' }) => ({
  className: clsx(
    'truncate font-bold font-Inter',
    disabled ? 'text-metallic-gray' : 'text-transparent',
    textStyle
  )
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #40e7ff 0%, #ff48e2 100%);
  -webkit-background-clip: text;
  background-clip: text;
`

const GradientButton = ({
  className,
  onClick,
  title = '決済へ進む',
  disabled = false,
  textStyle,
  buttonStyle
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={clsx('w-full relative z-10', className)}
      style={{
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
      }}
    >
      <TabDiv disabled={disabled} buttonStyle={buttonStyle}>
        <StyledText disabled={disabled} textStyle={textStyle}>
          {title}
        </StyledText>
      </TabDiv>
    </button>
  )
}

GradientButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  textStyle: PropTypes.string,
  buttonStyle: PropTypes.string
}

export default GradientButton
