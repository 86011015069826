import React from 'react'
import { useNavigate } from 'react-router-dom'
import ReturnButton from 'src/components/common/ReturnButton'
import { MainLayout } from 'src/components/layouts/MainLayout'
import { MAIN_PATHS } from 'src/routes/main'

import BackroundInProgress from 'src/assets/img/common/bg-common.png'
import BackroundPopupFrame from 'src/assets/img/common/bg_popup_frame.png'
import MascotSorry from 'src/assets/img/character/mascot/sorry.png'
import BtnBackToTop from 'src/assets/img/common/btn_backtotop.png'

const NoContent = () => {
  const navigate = useNavigate()

  return (
    <MainLayout showHeader={false} active="" classNameHeader="pt-menu-header-offset px-4">
      <div className="w-screen h-menu-header-offset sm:w-desktop-width fixed sm:left-1/2 sm:-translate-x-1/2 z-[51]">
        <ReturnButton to="/mypage" className="absolute left-0 top-2" />
      </div>
      <div className="absolute top-0 bottom-0 w-screen pb-[100px] sm:w-desktop-width ">
        <img src={BackroundInProgress} alt="" className="w-full h-full" />
      </div>

      <div className="absolute top-0 bottom-0 flex justify-center w-screen sm:w-desktop-width bg-progress-gray ">
        <div className="h-[calc(100vh-132px)] w-[calc(100%-32px)] m-[16px] relative">
          <img src={BackroundPopupFrame} alt="" className="w-full h-full" />
          <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
            <div className="w-[220px] text-center">
              <img src={MascotSorry} alt="" className="mx-auto mb-[36px] w-[115px]" />
              <p className="font-bold text-base text-[#333333] max-w-[160px] mx-auto">
                お探しのページは見つかりませんでした
              </p>

              <div className="mt-[24px] mb-[48px] max-w-[220px]">
                <p>アクセスしようとしたページは見つかりませんでした。</p>
                <p>お手数ですが、トップページより再度お探しください。</p>
              </div>

              <div
                className="w-full mx-auto cursor-pointer"
                onClick={() => navigate(MAIN_PATHS.MyPage)}
              >
                <img src={BtnBackToTop} alt="" className="w-full mx-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default NoContent
