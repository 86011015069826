import { useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { HistoryRouteContext } from 'src/routes'
import { Tab } from '@headlessui/react'
import styled from 'styled-components'

const TabDiv = styled.div.attrs(({ selected, textStyle }) => ({
  className: clsx(
    'relative bg-white font-light leading-[16px] rounded-xl font-Inter',
    selected ? 'border-0 text-white bg-clip-padding border-[2px] border-transparent' : 'opacity-50',
    textStyle
  )
}))`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    ${({ selected, activeTextStyle }) => selected && activeTextStyle};
    transition: background 0.3s ease;
  }
`
const StyledText = styled.span.attrs(({ selected, disabledText = 'text-[#BCA8FF]' }) => ({
  className: clsx('truncate', selected ? 'text-transparent' : disabledText)
}))`
  ${({ selected, activeTextStyle }) =>
    selected &&
    `
    ${activeTextStyle};
    -webkit-background-clip: text;
    background-clip: text;
  `}
`

const HeaderTabButton = ({
  title,
  hasNotification = false,
  onClick,
  textStyle,
  activeTextStyle,
  disabledText
}) => {
  const { redirectTo } = useContext(HistoryRouteContext)

  const inProgress = () => {
    if (title === '応援購入') {
      redirectTo('/in-progress')
    }
  }

  return (
    <Tab onClick={onClick} className="w-full min-w-[80px]">
      {({ selected }) => (
        <TabDiv
          selected={selected}
          onClick={inProgress}
          className={textStyle}
          activeTextStyle={activeTextStyle}
        >
          <StyledText
            selected={selected}
            activeTextStyle={activeTextStyle}
            disabledText={disabledText}
          >
            {title}
          </StyledText>
          {
            <div
              className={clsx(
                title === '運営から' && 'absolute w-[0.4rem] h-[0.4rem] rounded-full',
                hasNotification && 'bg-warn-red'
              )}
              style={{ top: '3px', right: '3px' }}
            />
          }
        </TabDiv>
      )}
    </Tab>
  )
}

HeaderTabButton.propTypes = {
  title: PropTypes.string.isRequired,
  hasNotification: PropTypes.bool,
  onClick: PropTypes.func,
  textStyle: PropTypes.string,
  activeTextStyle: PropTypes.string,
  disabledText: PropTypes.string
}

export default HeaderTabButton
