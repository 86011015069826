import { useQuery } from 'react-query'
import getFirstStory from 'src/services/get/getFirstStory'

const useGetFirstStory = () => {
  return useQuery({
    queryKey: ['useGetFirstStory'],
    queryFn: () => getFirstStory()
  })
}

export { useGetFirstStory }
