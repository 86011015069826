import React from 'react'
import { useAuthState } from 'src/providers/AuthProviders'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import life from 'src/assets/img/common/life.png'
import ibg from 'src/assets/img/common/bar-bg.svg'
import add from 'src/assets/img/common/plus.png'
import clsx from 'clsx'

export const PossessionHeart = ({
  onClick,
  width,
  classNameLife = 'absolute w-6 top-2 right-[100px]',
  classNamePossessionHeart = 'absolute top-3 right-16 grid content-center text-sm text-center text-black'
}) => {
  const { user } = useAuthState()
  const navigate = useNavigate()

  return (
    <div className={clsx('relative z-0', width)}>
      <img src={ibg} className="h-8 absolute right-0 top-2" />
      <img src={life} className={classNameLife} />
      <img
        src={add}
        onClick={() => (onClick ? onClick() : navigate('/shop'))}
        className="absolute right-4 top-3.5 w-4 z-10 cursor-pointer"
      />
      <div className={classNamePossessionHeart}>{user?.possession_heart}</div>
    </div>
  )
}

PossessionHeart.propTypes = {
  onClick: PropTypes.func,
  width: PropTypes.string,
  classNameLife: PropTypes.string,
  classNamePossessionHeart: PropTypes.string
}
