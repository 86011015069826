import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Countdown from 'react-countdown'
import { useDuringContext } from '../pages/DuringDelivery/_Provider'
import { useCharacterLiveContext } from 'src/providers/LivestreamProvider'
import { formattedCountDownTime } from 'src/lib/date'
import BlueClock from 'src/assets/img/common/clock-blue.png'

const NormalTime = ({ time, livestreamId, onComplete }) => {
  const [isVisible, setIsVisible] = useState(new Date(time) > new Date())
  const [displayTime, setDisplayTime] = useState(time)
  const { liveItemsSocket } = useDuringContext()
  const { characterLiveSocket } = useCharacterLiveContext()
  const handleComplete = () => {
    setIsVisible(false)
    onComplete()
  }

  useEffect(() => {
    if (liveItemsSocket.watchableTime === undefined || liveItemsSocket.watchableTime === '') return

    setDisplayTime(liveItemsSocket.watchableTime)
  }, [displayTime, liveItemsSocket.watchableTime])

  useEffect(() => {
    if (
      characterLiveSocket.watchableTime === undefined ||
      characterLiveSocket.watchableTime === '' ||
      characterLiveSocket.livestreamId !== livestreamId
    ) {
      return
    }

    setDisplayTime(characterLiveSocket.watchableTime)
  }, [characterLiveSocket.livestreamId, characterLiveSocket.watchableTime])

  return (
    <>
      {isVisible && (
        <div className="relative flex items-center gap-3 bg-white rounded-l-[10px] px-2 pb-0.5">
          <div className="flex items-center gap-1 text-[#6F9BFF] text-[9px] font-semibold leading-[9px]">
            <img src={BlueClock} className="w-2.5" />
            残り時間
          </div>
          <span className="text-[#406ED8] text[15px] font-semibold">
            <Countdown
              date={new Date(displayTime)}
              onComplete={() => handleComplete()}
              renderer={({ hours, minutes, seconds }) => {
                return <>{formattedCountDownTime(hours, minutes, seconds)}</>
              }}
            />
          </span>
        </div>
      )}
    </>
  )
}

NormalTime.propTypes = {
  time: PropTypes.string,
  livestreamId: PropTypes.any,
  onComplete: PropTypes.func
}

export { NormalTime }
