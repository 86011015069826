export default class CancelableAPIRequest {
  // TODO: Need to be removed when using redux
  constructor(request, ...args) {
    this.request = request
    this.args = args
    this.cancelled = false
  }

  invoke(callback) {
    if (typeof callback !== 'function') {
      throw new Error(
        `A cancellable API request was invoked, but lacked a proper callback. (Callback was ${callback})`
      )
    }

    this.request(...this.args).then((res) => {
      if (this.cancelled) return

      callback(res)
    })
  }

  cancel() {
    this.cancelled = true
  }
}
