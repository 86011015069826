import styled from 'styled-components'

import { useGetRecommendsData, useUpdateRecommendsMan } from 'src/services/hooks/useFetchRecommends'
import useLoading from 'src/components/layouts/hooks/useLoading'

import StandardPageWrapper from 'src/components/common/StandardPageWrapper'
import { MainLayout } from 'src/components/layouts/MainLayout'
import TalentGroup from 'src/components/myPage/recommend/TalentGroup'
import Loading from 'src/components/layouts/Loading'
import { Tab } from '@headlessui/react'
import { Wrapper } from 'src/components/common/Wrapper'

import { GROUP } from 'src/constants/group'
import { RECOMMEND_TALENT } from 'src/constants/characters'
import GradientHeader from 'src/components/common/GradientHeader'

const GroupType = styled.h3.attrs({
  className:
    'pt-2.5 pb-2 bg-talent-group-header w-full font-outfit text-[#6F9BFF] text-2xl leading-[22px] uppercase font-bold drop-shadow-talent-group-header'
})``

export const RecommendPage = () => {
  const { data: recommends, isLoading } = useGetRecommendsData()
  const mutation = useUpdateRecommendsMan()

  const groupedByGroup = recommends
    ?.map((apiItem) => {
      const matchedTalent = RECOMMEND_TALENT.find((talent) => talent.name === apiItem.name)
      const matchedGroup = GROUP.find((group) => group.name === matchedTalent?.group)
      return {
        ...apiItem,
        ...matchedGroup,
        ...matchedTalent
      }
    })
    .reduce((acc, item) => {
      if (item.group) {
        let groupData = acc.find((group) => group.group === item.group)

        if (!groupData) {
          groupData = {
            group: item.group,
            japanesename: item.japaneseName,
            groupLogo: item.groupLogo,
            members: []
          }
          acc.push(groupData)
        }
        groupData.members.push({
          ...item
        })
      }

      return acc
    }, [])

  const result =
    groupedByGroup &&
    groupedByGroup.reduce(
      (acc, groupData) => {
        if (groupData.members.length > 1) {
          acc.group.push(groupData)
        } else {
          acc.solo.push(groupData)
        }
        return acc
      },
      { group: [], solo: [] }
    )

  const handleUpdateRecommendMan = (id) => {
    mutation.mutate(id)
  }

  const { showLoading, progress } = useLoading([isLoading, mutation.isLoading])
  if (showLoading || isLoading || mutation.isLoading) {
    return (
      <Wrapper>
        <Loading progress={progress} />
      </Wrapper>
    )
  }

  return (
    <MainLayout
      className="relative h-fit"
      active="mypage"
      classNameHeader="!bg-gradient-sky-to-pink"
    >
      <StandardPageWrapper className="bg-white h-full" overflow={true}>
        <div className="mt-10">
          <Tab.Group>
            <GradientHeader title="TALENT" />
          </Tab.Group>
        </div>
        <div className="space-y-10 overflow-y-auto h-[calc(100dvh-200px)] hidden-scroll">
          {result.group.length > 0 && (
            <div className="space-y-3">
              <GroupType>GROUP</GroupType>
              {result.group.map((group) => (
                <TalentGroup
                  characterList={group.members}
                  logo={group.groupLogo}
                  key={group.group}
                  handleUpdateRecommendMan={handleUpdateRecommendMan}
                />
              ))}
            </div>
          )}
          {result.solo.length > 0 && (
            <div className="space-y-3">
              <GroupType>SOLO</GroupType>
              {result.solo.map((group) => (
                <TalentGroup
                  characterList={group.members}
                  logo={group.groupLogo}
                  key={group.group}
                  handleUpdateRecommendMan={handleUpdateRecommendMan}
                />
              ))}
            </div>
          )}
        </div>
      </StandardPageWrapper>
    </MainLayout>
  )
}
