import { useContext, useMemo, useRef } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import clsx from 'clsx'

import { MYPAGE_PATHS } from 'src/routes/myPage'
import { HistoryRouteContext } from 'src/routes'

import { Modal } from 'src/components/common/Modal'
import { LoveLevel } from 'src/components/common/LevelProgress'

import MicIcon from 'src/assets/img/common/mic_icon.png'
import CloseIcon from 'src/assets/img/common/close_icon.png'
import heartIcon from 'src/assets/img/mypage/recommend/heart_icon.png'
import heartFillIcon from 'src/assets/img/mypage/recommend/heart_fill_icon.png'

const DISABLED = 'disable'

const ProfileItem = ({ title, content, showBorder = false }) => {
  return (
    <div className={clsx('flex py-0.5', showBorder && 'border-b')}>
      <div className="w-[35%]">{title}</div>
      <div className="flex-1">{content}</div>
    </div>
  )
}

export const CharacterModal = ({ isOpen, setIsOpen, character, setOpenConfirmModal }) => {
  const { id, name, recommend, level, voice } = character
  const voiceRef = useRef()
  const { redirectTo } = useContext(HistoryRouteContext)
  const bookmarkIcon = useMemo(() => (recommend ? heartFillIcon : heartIcon), [character.recommend])
  const handleVoice = () => {
    if (voice && voiceRef.current) {
      if (voiceRef.current.paused) {
        voiceRef.current.play()
      } else {
        voiceRef.current.pause()
        voiceRef.current.currentTime = 0
      }
    } else {
      redirectTo('/in-progress')
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="sm:w-[329px] w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino"
    >
      <button
        onClick={() => setIsOpen(false)}
        className="w-[18px] h-[18px] absolute top-3 right-3 z-50"
      >
        <img className="w-full h-full" src={CloseIcon} alt="close" />
      </button>
      <ModalBody>
        <img src={character.fullImage} alt="talent-image" className="basis-[43%] w-[91px] h-auto" />

        <button
          className="absolute bottom-1.5 left-1.5 px-2 py-1 bg-voice-gradient text-[11px] font-bold text-white rounded-lg drop-shadow-voice-btn flex justify-center items-center"
          onClick={handleVoice}
        >
          <img
            className="w-full scale-[0.55] ml-[-0.5rem] mr-[-0.125rem]"
            src={MicIcon}
            alt="close"
          />
          VOICE
        </button>
        <audio ref={voiceRef} src={voice} preload="auto" />
        <div className="flex-1 flex-col flex">
          <div className="pl-2 flex-grow">
            {character?.loveable_status !== DISABLED && (
              <img
                onClick={setOpenConfirmModal}
                src={bookmarkIcon}
                alt="o"
                className={clsx('mt-2 w-6', !recommend && 'cursor-pointer')}
              />
            )}
            <p className="text-[34px] font-bold leading-9 mt-2">{name}</p>
            <p className="font-bold text-[10px] text-[#9AC8FF] ml-[3px]">{character.englishName}</p>

            <div className="text-xs text-[#868686] font-extrabold mt-1 leading-[15px]">
              {character.description}
            </div>
            <div className="text-[11px] font-extrabold w-[82%] mt-1.5">
              <ProfileItem showBorder title="年齢" content={`${character.age}歳`} />
              <ProfileItem showBorder title="誕生日" content={character.birthdate} />
              <ProfileItem showBorder title="血液型" content={`${character.bloodType}型`} />
              <ProfileItem showBorder title="身長" content={`${character.height}cm`} />
              {character.zodiac && <ProfileItem title="星座" content={`${character.zodiac}座`} />}
              {character.job && <ProfileItem title="職業" content={`${character.job}座`} />}
            </div>
          </div>

          <div className="pl-2 font-bold mt-2 mb-1">
            {id < 6 && (
              <button
                className="bg-[#6F9BFF] text-white text-[13px] rounded-[7px] px-2 h-[30px]"
                onClick={() =>
                  redirectTo(
                    MYPAGE_PATHS.RecommendMangaPage.replace(':id', id).replace(
                      ':name',
                      name || 'noname'
                    )
                  )
                }
              >
                生い立ち漫画を読む
              </button>
            )}

            {character.cv && (
              <div className="text-[10px] pt-0.5 text-[#6F9BFF]">CV : {character.cv}</div>
            )}
          </div>

          <div className="bg-[#6F9BFF] text-white font-bold p-2 pb-1">
            <LoveLevel variant="red" size="lg" love={level?.love} className="mb-1" />
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

CharacterModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  character: PropTypes.object,
  setIsOpenConfirmModal: PropTypes.func,
  setOpenConfirmModal: PropTypes.func
}

const ModalBody = styled.div.attrs({
  className: 'w-full h-fit relative bg-white rounded-xl flex overflow-hidden'
})`
  background-size: 100% 100%;
`

ProfileItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  showBorder: PropTypes.bool
}
