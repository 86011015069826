import StoryDetail from 'src/models/StoryDetail'
import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'
const getFirstStory = async () => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Stories.OpeningStory.getFirstStory)

    const items = []
    for (const i of res.data.data.stories) {
      const item = new StoryDetail(
        i.id,
        i.details.type,
        i.details.is_blackout,
        i.details.blackout_color,
        i.details.address,
        i.details.character_name,
        i.details.description,
        i.details.character_voice_url,
        i.details.character_voice_repeat,
        i.details.background_image,
        i.details.bgm_url,
        i.details.bgm_audio_repeat,
        i.details.is_bgm_stop,
        i.details.se_url,
        i.details.se_audio_repeat,
        i.details.time_to_change || 0,
        i.details.time_to_leave || 0,
        i.details.character_image_thumbnail
      )
      items.push(item)
    }
    return items
  } catch (error) {
    return []
  }
}

export default getFirstStory
