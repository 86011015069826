import React from 'react'
import AccountPageContainer from 'src/pages/menu/AccountPageContainer'
import { HelpPage } from 'src/pages/menu/Help'
import InquiryPageContainer from 'src/pages/menu/InquiryPageContainer'
import ItemPageContainer from 'src/pages/menu/ItemPageContainer'
import { Menu } from 'src/pages/menu/Menu'
import MenuTermOfServicePage from 'src/pages/menu/MenuTermsOfService'
import { PrivacyPolicyPage } from 'src/pages/menu/PrivacyPolicy'
import { SpecifiedCommercialPage } from 'src/pages/menu/SpecifiedCommercial'
import UserProfilePageContainer from 'src/pages/menu/UserProfilePageContainer'

import { VoiceAlbums } from 'src/pages/myPage/Recommend/VoiceAlbum'
import { MangaList } from 'src/pages/menu/MangaList'

import { SideStoryMenu } from 'src/pages/menu/SideStoryMenu'
import { OpeningStoryMenuPage } from 'src/pages/menu/OpeningStoryMenuPage'
import { OpeningStoryPage } from 'src/pages/newUser/OpeningStory'

const MENU_PATHS = {
  ItemPage: '/item',
  VoiceAlbums: '/voicealbum',
  OpeningStoryMenuPage: '/opening-story-menu',
  MangaList: '/manga',
  AccountPage: '/account',
  UserProfilePage: '/profile',
  TermsOfServicePage: '/menu/terms-of-service',
  PrivacyPolicyPage: '/privacy-policy',
  SpecifiedCommercialPage: '/specified-commercial',
  HelpPage: '/help',
  InquiryPage: '/inquiry',
  InquiryDetailPage: '/inquiry/:id',
  Menu: '/menu',
  SideStoryMenu: '/side-story-menu',
  OpeningStoryDetail: '/opening-story/:id'
}
const MenuRouter = [
  { path: MENU_PATHS.ItemPage, element: <ItemPageContainer /> },
  { path: MENU_PATHS.VoiceAlbums, element: <VoiceAlbums /> },
  { path: MENU_PATHS.OpeningStoryMenuPage, element: <OpeningStoryMenuPage /> },
  { path: MENU_PATHS.MangaList, element: <MangaList /> },
  { path: MENU_PATHS.AccountPage, element: <AccountPageContainer /> },
  { path: MENU_PATHS.UserProfilePage, element: <UserProfilePageContainer /> },
  { path: MENU_PATHS.TermsOfServicePage, element: <MenuTermOfServicePage /> },
  { path: MENU_PATHS.PrivacyPolicyPage, element: <PrivacyPolicyPage /> },
  { path: MENU_PATHS.SpecifiedCommercialPage, element: <SpecifiedCommercialPage /> },
  { path: MENU_PATHS.HelpPage, element: <HelpPage /> },
  { path: MENU_PATHS.InquiryPage, element: <InquiryPageContainer /> },
  { path: MENU_PATHS.Menu, element: <Menu /> },
  { path: MENU_PATHS.SideStoryMenu, element: <SideStoryMenu /> },
  { path: MENU_PATHS.OpeningStoryDetail, element: <OpeningStoryPage /> }
]

export { MenuRouter, MENU_PATHS }
