import React from 'react'
import PropTypes from 'prop-types'

import ShopBlock from 'src/pages/main/shop/components/ShopBlock'
import ShopExchangeHeart from 'src/pages/main/shop/components/ShopHeartTab/ShopExchangeHeart'

const ShopHeartTab = ({ data }) => {
  return (
    <>
      <div className="flex flex-col items-center justify-center w-full gap-4">
        {data?.map((heartExchangeConfig, index) => {
          const isValidConfig =
            Number.isInteger(heartExchangeConfig.number_of_red_diamonds) &&
            Number.isInteger(heartExchangeConfig.number_of_hearts_exchanged)

          if (!isValidConfig) return <React.Fragment key={index} />

          return (
            <ShopBlock key={index}>
              <ShopExchangeHeart heartExchangeConfig={heartExchangeConfig} />
            </ShopBlock>
          )
        })}
      </div>
    </>
  )
}

ShopHeartTab.propTypes = {
  data: PropTypes.array
}

export default ShopHeartTab
