import PropTypes from 'prop-types'

const SectionHeader = ({
  title,
  button,
  className = 'border-b w-full border-menu-dark-blue',
  isRequired = false,
  style,
  addStyle = '',
  subText = '',
  subTextStyle = ''
}) => {
  return (
    <>
      <p
        className={`flex py-2 items-center ${className}`}
        style={{ textShadow: `${style}`, ...(addStyle ? { padding: `${addStyle}` } : {}) }}
      >
        {title}
        <span className={subTextStyle}>{subText}</span>
        {button}
        {isRequired === true && <span className="ml-2 text-red-500">*</span>}
      </p>
    </>
  )
}

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.element,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  style: PropTypes.string,
  addStyle: PropTypes.string,
  hideIcon: PropTypes.string,
  subText: PropTypes.string,
  subTextStyle: PropTypes.string
}

export default SectionHeader
