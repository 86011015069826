import { useNavigate } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react'
import InnerHTML from 'dangerously-set-html-content'

import { NEW_USER_PATHS } from 'src/routes/newUser'
import LegaleseWrapper from 'src/components/layouts/LegaleseWrapper'
import { MainLayout } from 'src/components/layouts/MainLayout'
import { getConditions } from 'src/services/get/getConditions'
import { getAdTag } from 'src/services/get/getAdTag'

import arrowImg from 'src/assets/img/common/blue_upward_arrow.png'
import check from 'src/assets/img/termofservice/check.png'

export const RegistTermOfServicePage = () => {
  const [accept, setAccept] = useState(false)
  const navigate = useNavigate()
  const [term, serTerm] = useState('')
  const [jsTag, serJsTag] = useState('')

  const fetchCondition = async () => {
    const data = await getConditions('condition')
    const content = data.find((item) => item?.condition?.status === 'is_public')?.condition?.content
    serTerm(content)
  }

  const resultToAsp = async () => {
    const tagData = await getAdTag()
    if (!tagData) return
    if (tagData.fire_type === 'js_tag') {
      serJsTag(tagData.content)
    }
  }

  useEffect(() => {
    fetchCondition()
    resultToAsp()
  }, [])

  const legaleseRef = useRef(null)

  const handleScrollToTop = () => {
    legaleseRef.current?.scrollToTop()
  }

  return (
    <MainLayout showHeader={false} showFooter={false}>
      <div
        className={
          'overflow-y-auto w-screen sm:w-desktop-width h-screen fixed sm:left-1/2 sm:-translate-x-1/2 flex flex-col items-center bg-center bg-no-repeat bg-cover bg-pale-blue pt-8'
        }
      >
        <LegaleseWrapper
          title="利用規約"
          content={term}
          theme="blue"
          margin="mt-8"
          ref={legaleseRef}
          hideScrollTop={true}
        />

        <div className="flex justify-center items-center mt-5 p-2 bg-white gap-2">
          <div
            className="text-center bg-white w-4 h-4"
            style={
              accept
                ? {
                    backgroundImage: `url(${check})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: '80% 80%',
                    border: '1px solid #6F9BFF'
                  }
                : { border: '1px solid #6F9BFF' }
            }
            onClick={() => setAccept(!accept)}
          />
          <div className="text-xs text-center font-Inter leading-6 font-bold text-cornflower-blue ">
            利用規約に同意します
          </div>
        </div>
        <button className="absolute bottom-[7.4rem] right-[15%]" onClick={handleScrollToTop}>
          <img src={arrowImg} />
        </button>
        <button
          disabled={!accept}
          className={`rounded-[24.5px] font-MPlus1 py-2 text-2xl mt-4 w-2/3 text-white bg-cornflower-blue ${
            accept ? '' : 'opacity-50'
          }`}
          onClick={() => navigate(NEW_USER_PATHS.OpeningPage)}
        >
          同意する
        </button>
      </div>
      {jsTag ? <InnerHTML html={jsTag} /> : null}
    </MainLayout>
  )
}
