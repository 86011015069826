import { useCallback } from 'react'
import PropTypes from 'prop-types'

import ItemThumbnail from 'src/components/menu/ItemThumbnail'
import Item from 'src/models/Item'
import ModalCommon from 'src/components/common/ModalCommon'

const ValueIndicator = ({ label, value, borderStyle, bgStyle, valueStyle }) => {
  return (
    <div
      className={`rounded border ${borderStyle} h-6 flex justify-center items-center font-Inter text-[0.4375rem]`}
    >
      <span className={`flex items-center h-full align-middle px-2 font-bold ${bgStyle} leading-3`}>
        {label}
      </span>
      <span className={`px-2 text-xs ${valueStyle}`}>{value}</span>
    </div>
  )
}

const ItemDetailsModal = ({ item, handleClose, open }) => {
  const isSettingItem = useCallback(
    (type) => {
      if (!type) return
      return type === 'SettingItem'
    },
    [item]
  )

  return (
    <ModalCommon
      buttons={[
        {
          label: '閉じる',
          onClick: () => handleClose()
        }
      ]}
      title={['r', 'sr', 'ssr'].includes(item.rarity) ? 'アイテム' : '特殊アイテム'}
      open={open}
      onClose={() => handleClose()}
      theme='pink'
    >
      <div className="flex flex-col items-center bg-white py-6 gap-3 text-candy-pink font-Inter">
        <ItemThumbnail
          image={item.image.url}
          className="w-[115px] h-[115px] rounded-sm mb-6 !drop-shadow-none !border-none"
          imgClassName={isSettingItem(item.type) ? 'w-full h-full' : 'w-[90%]'}
          rarity={isSettingItem(item.type)}
        />
        <div className="w-2/3 flex justify-center gap-3">
          <ValueIndicator
            label="LOVE度"
            value={item.increase_love || item.increaseLove}
            borderStyle="border-candy-pink"
            bgStyle="bg-candy-pink text-white"
          />
          <ValueIndicator
            label="所持数"
            value={item.count || item.amount}
            borderStyle="border-candy-pink"
            bgStyle="bg-candy-pink text-white"
          />
        </div>
        <h3 className="text-xl text-center font-Inter font-bold w-4/5">{item.name}</h3>
        <p className="text-sm font-Inter px-2 text-center font-semibold">{item.description}</p>
      </div>
    </ModalCommon>
  )
}

ItemDetailsModal.propTypes = {
  item: PropTypes.instanceOf(Item).isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

ValueIndicator.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  borderStyle: PropTypes.string.isRequired,
  bgStyle: PropTypes.string.isRequired,
  valueStyle: PropTypes.string
}

export default ItemDetailsModal
