import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import { useAuthState } from 'src/providers/AuthProviders'
import { postPaymentOrder, postPaymentOrderBitcash } from 'src/services/post/postPaymentOrder'

import ShopPurchaseResultScreen from 'src/pages/main/shop/components/ShopDiamondTab/ShopPurchaseResultScreen'
import GradientButton from 'src/components/common/GradientButton'

import { useNavigate } from 'react-router-dom'
import LoadingIcon from 'src/components/layouts/LoadingIcon'
import { renderButtonAmazonPay } from 'src/services/helper'

const ShopPaymentButtons = ({ method }) => {
  const { selectedItem, setModalContent, setTitle, setButtons, setModalType, closeModal } = useShopContext()
  const { user } = useAuthState()
  const navigate = useNavigate()

  useEffect(() => {
    renderButtonAmazonPay(user, selectedItem, method)
  }, [])

  const setModalTitleAndButton = () => {
    setModalType('window')
    setTitle('決済失敗')
    setButtons([
      {
        label: 'OK',
        onClick: closeModal
      }
    ])
  }

  const {
    data: orderConfigs,
    mutate: fetchOrderConfigs,
    isLoading
  } = useMutation(postPaymentOrder, {
    onError: () => {
      setModalContent(<ShopPurchaseResultScreen isError />)
      setModalTitleAndButton()
    }
  })

  const {
    data: otherData,
    mutate: fetchOrderBitcashConfigs,
    isLoading: isOtherDataLoading
  } = useMutation(postPaymentOrderBitcash, {
    onSuccess: (data) => {},
    onError: () => {
      setModalContent(<ShopPurchaseResultScreen isError />)
      setModalTitleAndButton()
    }
  })

  const onPurchase = useCallback(
    (methodName) => {
      fetchOrderConfigs({
        order: {
          user_id: user?.id || 1,
          product_id: selectedItem?.id,
          payment_amount: selectedItem?.listed_price,
          payment_method: methodName
        }
      })
    },
    [method, selectedItem, user?.id]
  )

  const handleClick = () => {
    const data = {
      selectedItem: selectedItem,
      setModalContent: setModalContent
    }
    localStorage.setItem('dataToSend', JSON.stringify(data))
    navigate('/payments/paymentOrderWebmoney')
  }

  const handleClickBitcash = useCallback(
    (methodName) => {
      fetchOrderBitcashConfigs({
        order: {
          user_id: user?.id || 1,
          product_id: selectedItem?.id,
          payment_amount: selectedItem?.listed_price,
          payment_method: methodName
        }
      })
    },
    [method, selectedItem, user?.id]
  )

  // open GMO payment url when api get order configs success and return url
  useEffect(() => {
    if (otherData?.url) {
      window.location.href = otherData.url
    } else if (orderConfigs?.link_payment) {
      window.location.href = orderConfigs?.link_payment
    }
  }, [orderConfigs?.link_payment, !otherData?.url])

  if (isLoading || isOtherDataLoading) return <></>

  switch (method) {
    case 'career':
      return (
        <>
          <GradientButton title="Softbank" onClick={() => onPurchase('sb')} />
          <GradientButton title="Docomo" onClick={() => onPurchase('docomo')} />
          <GradientButton title="AU" onClick={() => onPurchase('au')} />
        </>
      )
    case 'credit':
      return <GradientButton onClick={() => onPurchase(method)} />
    case 'amazon_pay':
      return (
        <div id="btn-amazon" className="my-3">
          <LoadingIcon />
        </div>
      )
    case 'web_money':
      return <GradientButton onClick={handleClick} />
    case 'bitcash':
      return <GradientButton onClick={() => handleClickBitcash('bitcash')} />
    default:
      return <GradientButton onClick={() => onPurchase(method)} />
  }
}

ShopPaymentButtons.propTypes = {
  method: PropTypes.string.isRequired
}

export default ShopPaymentButtons
