import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

export const fetchDataRecommend = createAsyncThunk(
  'data/fetchDataRecommend',
  async () => {
    const response = await axiosInstance.get(API_ROUTES.Recommend.Man)
    return response.data
  }
)

export const getRecommendMans = createSlice({
  name: 'getRecommendMans',
  initialState: {
    recommendMans: [],
    isLoading: true,
    errorMessage: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataRecommend.pending, (state) => {
        state.isLoading = true
      })

      .addCase(fetchDataRecommend.fulfilled, (state, action) => {
        state.isLoading = false
        state.recommendMans = action.payload
      })

      .addCase(fetchDataRecommend.rejected, (state, action) => {
        state.isLoading = false
        state.errorMessage = action.payload
      })
  }
})

export default getRecommendMans.reducer
