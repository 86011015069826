import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import useNewsHasNotifications from 'src/hooks/useNewsHasNotifications'
import useUserContactListChannel from 'src/services/common/useUserContactChannel'
import useNewsListChannel from 'src/services/common/useNewsListChannel'
import ModalSuccess from 'src/components/common/ModalSuccess'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import MenuItem from 'src/components/menu/MenuItem'
import PropTypes from 'prop-types'
import { MENU_PATHS } from 'src/routes/menu'
import MenuHeader from 'src/components/menu/MenuHeader'

import GreyHeaderBackground from 'src/assets/img/menu/background/grey_header_background.png'
import MenuIcon from 'src/assets/img/menu/tab_icon/menu_icon.png'
import folderIcon from 'src/assets/img/menu/folder.png'
import voiceIcon from 'src/assets/img/menu/voice.png'
import bellIcon from 'src/assets/img/menu/bell.png'
import speakerIcon from 'src/assets/img/menu/speaker.png'
import bookIcon from 'src/assets/img/menu/book.png'
import checkIcon from 'src/assets/img/menu/check.png'
import userIcon from 'src/assets/img/menu/user.png'
import fileIcon from 'src/assets/img/menu/file.png'
import inforIcon from 'src/assets/img/menu/infor.png'
import messageIcon from 'src/assets/img/menu/message.png'
import webLogoIcon from 'src/assets/img/menu/web_logo.png'
import xIcon from 'src/assets/img/menu/x_icon.png'
import instagramIcon from 'src/assets/img/menu/instagram.png'
import tiktokIcon from 'src/assets/img/menu/tiktok.png'
import diamondIcon from 'src/assets/img/menu/diamond.png'
import youtubeIcon from 'src/assets/img/menu/youtube.png'

import { MYPAGE_PATHS } from 'src/routes/myPage'
import ModalCommon from 'src/components/common/ModalCommon'

export const Menu = () => {
  const isNewMessageInquiry = useSelector((state) => state.getNotifications.isNewMessageInquiry)
  const inquiryNoti = useSelector((state) => state.getNotifications.inquiryNoti)
  const hasNews = useSelector((state) => state.getNotifications.hasNews)
  const hasNewsNotifications = useSelector((state) => state.getNotifications.hasNewsNotifications)

  const [isOpenVoiceAlbumModal, setIsOpenVoiceAlbumModal] = useState(false)
  const [isOpenExternalLinkModal, setIsOpenExternalLinkModal] = useState(false)
  const [buttons, setButtons] = useState([])
  const title = {
    text: 'MENU',
    textStyle: 'text-[#DEDEDE]'
  }
  const subTitle = {
    text: 'メニュー',
    textStyle: 'bg-[#FF86C8]'
  }

  const closeVoiceAlbumModal = useCallback(() => setIsOpenVoiceAlbumModal(false))
  const handleRedirect = (to) => {
    setIsOpenExternalLinkModal(true)
    setButtons([
      {
        label: 'キャンセル',
        onClick: () => setIsOpenExternalLinkModal(false),
        type: 'close'
      },
      {
        label: 'OK',
        onClick: () => {
          window.open(to, '_blank')
          setIsOpenExternalLinkModal(false)
        }
      }
    ])
  }
  useUserContactListChannel()
  useNewsListChannel()
  useNewsHasNotifications()

  return (
    <>
      {isOpenVoiceAlbumModal && (
        <ModalSuccess
          isOpen={isOpenVoiceAlbumModal}
          text={`こちらで現在準備中です\nサービス開始までお待ちください`} // eslint-disable-line quotes
          handleClose={closeVoiceAlbumModal}
        />
      )}
      {isOpenExternalLinkModal && (
        <ModalCommon
          open={isOpenExternalLinkModal}
          onClose={() => setIsOpenExternalLinkModal(false)}
          title={'確認'}
          buttons={buttons}
          theme="pink"
        >
          <div
            className="flex items-center justify-center font-inter font-bold bg-[#FFFFFF] px-2 py-8
              text-candy-pink
              "
          >
            外部サイトにリンクします。
          </div>
        </ModalCommon>
      )}
      <MainLayout className="bg-menu-bg" active="menu" classNameHeader="">
        <MenuWrapper className="mt-7" overflow={true}>
          <MenuHeader
            backgroundImage={GreyHeaderBackground}
            title={title}
            subTitle={subTitle}
            tabIcon={MenuIcon}
          />
          <div
            className="overflow-y-auto hidden-scroll"
            style={{ height: 'calc(100dvh - 200px)' }}
          >
            <MenuPart>
              <MenuItem src={folderIcon} label="所持アイテム" to={MENU_PATHS.ItemPage} />
              <MenuItem
                src={voiceIcon}
                label="ボイスアルバム"
                to={MENU_PATHS.VoiceAlbums}
                handleRedirect={() => setIsOpenVoiceAlbumModal(true)}
              />

              <MenuItem
                src={bellIcon}
                label="お知らせ"
                to={MYPAGE_PATHS.NewsListPage}
                showNotice={hasNewsNotifications || hasNews}
              />
              <MenuItem src={speakerIcon} label="イベント" to={MYPAGE_PATHS.EventListPage} />
              <MenuItem
                src={bookIcon}
                label="オープニングストーリーを見る"
                to={MENU_PATHS.OpeningStoryMenuPage}
              />
              <MenuItem
                src={bookIcon}
                label="サイドストーリーを見る"
                to={MENU_PATHS.SideStoryMenu}
              />
              <MenuItem src={bookIcon} label="生い立ち漫画を見る" to={MENU_PATHS.MangaList} />
              <MenuItem
                src={checkIcon}
                label="通知設定"
                to={MENU_PATHS.AccountPage}
                className="py-2.5"
              />

              <MenuItem
                src={userIcon}
                label="ID確認・名前変更"
                to={MENU_PATHS.UserProfilePage}
              />
              <MenuItem src={fileIcon} label="利用規約" to={MENU_PATHS.TermsOfServicePage} />
              <MenuItem
                src={fileIcon}
                label="プライバシーポリシー"
                to={MENU_PATHS.PrivacyPolicyPage}
              />
              <MenuItem
                src={fileIcon}
                label="特定商取引法"
                to={MENU_PATHS.SpecifiedCommercialPage}
              />
              <MenuItem src={inforIcon} label="ヘルプ・よくある質問" to={MENU_PATHS.HelpPage} />
              <MenuItem
                src={messageIcon}
                label="お問い合わせ"
                to={MENU_PATHS.InquiryPage}
                showNotice={inquiryNoti || isNewMessageInquiry}
              />
            </MenuPart>
            <MenuPart className="mt-8">
              <MenuItem
                isExternal
                src={diamondIcon}
                handleRedirect={handleRedirect}
                label="アイドルプリンスプロダクション公式サイト"
                to="https://ipri-official.jp/"
              />
              <MenuItem
                isExternal
                handleRedirect={handleRedirect}
                src={webLogoIcon}
                label="アイドルプリンス公式サイト"
                to="https://idol-p.jp/"
              />
              <MenuItem
                isExternal
                handleRedirect={handleRedirect}
                src={xIcon}
                label="アイドルプリンス公式X(旧Twitter)"
                to="https://twitter.com/ido1prince"
              />
              <MenuItem
                isExternal
                handleRedirect={handleRedirect}
                src={youtubeIcon}
                label="アイドルプリンス公式Youtube"
                to="https://www.youtube.com/@idolprinceproduction/videos"
              />
              <MenuItem
                isExternal
                handleRedirect={handleRedirect}
                src={instagramIcon}
                label="アイドルプリンス公式Instagram"
                to="https://www.instagram.com/idol.prince/"
              />
              <MenuItem
                isExternal
                handleRedirect={handleRedirect}
                src={tiktokIcon}
                label="アイドルプリンス公式TikTok"
                className="border-none"
                to="https://www.tiktok.com/@idol.prince"
              />
            </MenuPart>
          </div>
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

const MenuPart = ({ children, className }) => {
  return (
    <div
      className={clsx('mx-auto font-bold text-xs xs:text-sm flex flex-col items-center', className)}
    >
      {children}
    </div>
  )
}

MenuPart.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}
