import PropTypes from 'prop-types'

const NotificationIcon = ({ className = 'top-0.5 right-1', style }) => {
  return (
    <div
      className={`bg-[#ff9900] w-[5px] h-[5px] rounded-full absolute ${className}`}
      style={style}
    />
  )
}

NotificationIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
}

export default NotificationIcon
