import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import CancelableAPIRequest from 'src/models/CancelableAPIRequest'
import Inquiry from 'src/models/Inquiry'
import { setIsNewMessageInquiry, setInquiryNoti } from 'src/redux/notfication/getNotifications'

import InquiryPage from 'src/pages/menu/Inquiry'
import { useAuthState } from 'src/providers/AuthProviders'
import useUserContactListChannel from 'src/services/common/useUserContactChannel'
import getUserInquiries from 'src/services/get/getUserInquiries'
import postUserInquiry from 'src/services/post/postUserInquiry'
import putUpdateNotificationInquiry from 'src/services/put/putUpdateNotificationInquiry'

const InquiryPageContainer = () => {
  const dispatch = useDispatch()
  const isNewMessageInquiry = useSelector((state) => state.getNotifications.isNewMessageInquiry)
  const inquiryNoti = useSelector((state) => state.getNotifications.inquiryNoti)
  const [inquiries, setInquiries] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [hasNotifications, setHasNotifications] = useState(inquiryNoti)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams()

  const { user } = useAuthState()

  useUserContactListChannel()

  useEffect(() => {
    updateIndex(searchParams.get('tab'))

    const req = new CancelableAPIRequest(getUserInquiries, user?.id)
    req.invoke((resp) => {
      setInquiries(resp)
      if (resp.some((el) => !el.isReaded && el.adminResponse)) {
        setHasNotifications(true)
      }
      setIsLoading(false)
    })
    return () => {
      req.cancel()
    }
  }, [isNewMessageInquiry])

  const updateIndex = (value) => {
    value = parseInt(value)
    if (value !== 0 && value !== 1) return

    setSelectedIndex(value)
    setSearchParams({ tab: value }, { replace: true })
  }

  const sendInquiry = (categoryId, message, files) => {
    postUserInquiry(user?.id, message, categoryId, files).then(() => {
      getUserInquiries(user?.id).then((resp) => {
        setInquiries(resp)
      })
    })
  }

  const handleUpdateReadInquiry = async (inquiryId) => {
    await putUpdateNotificationInquiry(user?.id, inquiryId)
    const newInquiries = inquiries?.map((inquiry) =>
      inquiry.id === inquiryId
        ? new Inquiry(
          inquiry.id,
          inquiry.attachedFile,
          inquiry.contactKinds,
          inquiry.content,
          inquiry.adminResponse,
          inquiry.paymentAmount,
          inquiry.processStatus,
          inquiry.title,
          inquiry.createdAt,
          inquiry.adminResponseContent,
          true,
          inquiry.contentId,
          inquiry.messageFeedback,
          inquiry.attachedFileTwo,
          inquiry.attachedFileThree
        )
        : inquiry
    )
    setInquiries(newInquiries)
    dispatch(setIsNewMessageInquiry(false))
    const hasNotif = newInquiries?.some((el) => !el.isReaded && el.adminResponse) || false
    setHasNotifications(hasNotif)
    dispatch(setInquiryNoti(hasNotif))
  }

  return (
    <InquiryPage
      userId={user?.id}
      isLoading={isLoading}
      hasNotifications={hasNotifications || isNewMessageInquiry}
      sendInquiry={sendInquiry}
      inquiries={inquiries}
      selectedIndex={selectedIndex}
      setSelectedIndex={updateIndex}
      handleUpdateReadInquiry={handleUpdateReadInquiry}
    />
  )
}

export default InquiryPageContainer
