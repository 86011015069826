import styled, { keyframes } from 'styled-components'
import Crystal from 'src/assets/img/effect/common/crystal.png'
import Star from 'src/assets/img/effect/common/star.png'
import LevelUp from 'src/assets/img/effect/love_level/level_up.webp'
import LevelUpBg from 'src/assets/img/effect/love_level/level_up_bg.webp'
import { Modal } from 'src/components/common/Modal'
import PropTypes from 'prop-types'

const MultiLoveEffectModal = ({ character, isMultiLoveModalOpen, multiLevel, onClose }) => {
  return (
    <>
      {isMultiLoveModalOpen && (
        <Modal
          open={isMultiLoveModalOpen}
          onClose={() => null}
          className="w-full absolute top-[45%] left-1/2 -translate-x-2/4 -translate-y-2/4 transform transition-all max-w-[400px]"
        >
          <ModalBody>
            <ModalContainer>
              <ModalTitle>
                <img src={LevelUp} className="w-[220px]" />
              </ModalTitle>
              <ModalContent>
                <StarImage src={Crystal} top={23} left={7} delay={0} alt="crystal" />
                <StarImage src={Star} top={18} left={14} delay={2} alt="star" />
                <StarImage src={Crystal} top={18} right={14} delay={1} alt="crystal" />
                <StarImage src={Star} top={23} right={7} delay={4} alt="star" />
                <HeartIcon>
                  <img className="w-full" src={LevelUpBg} alt="heart" />
                </HeartIcon>
                <p
                  className="font-semibold text-[8rem] text-white z-50 absolute top-[28%]"
                  style={{ filter: 'drop-shadow(0px 4px 4px #FF46AB)' }}
                >
                  {multiLevel.nextLevel || '55'}
                </p>
                <p
                  className="absolute -bottom-[2%] mx-auto text-center font-semibold text-lg text-candy-pink"
                  style={{
                    textShadow:
                      '2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff'
                  }}
                >
                  レベルアップ報酬を
                  <br />
                  プレゼントBOXへ
                  <br />
                  お送りしました
                </p>
                <StarImage src={Crystal} bottom={0} left={9} delay={0} alt="crystal" />
                <StarImage src={Star} bottom={3} left={15} delay={1} alt="star" />
                <StarImage src={Crystal} bottom={0} right={9} delay={2} alt="crystal" />
                <StarImage src={Star} bottom={3} right={15} delay={4} alt="star" />
              </ModalContent>
            </ModalContainer>
            <button
              onClick={onClose}
              className="text-white bg-cornflower-blue font-MPlus1 font-bold text-2xl rounded-[58px] py-2 w-[143px] z-50 mx-auto absolute -bottom-[25%] left-1/2 transform -translate-x-1/2 cursor-pointer"
              style={{ boxShadow: '0px 4px 4px 0px #00000040' }}
            >
              閉じる
            </button>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default MultiLoveEffectModal

MultiLoveEffectModal.propTypes = {
  character: PropTypes.object,
  isMultiLoveModalOpen: PropTypes.bool.isRequired,
  multiLevel: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

const shine = keyframes`
  100% {
    left: 125%;
  }
`

const star = keyframes`
  0% {opacity:0;}
  5% {opacity:1;}
  10% {opacity:0;}
  55% {opacity:0;}
  60% {opacity:1;}
  95% {opacity:0;}
  100% {opacity:1;}
`

const heart = keyframes`
  20%{height: 60%;}
  35%{height: 100%;}
  40%{height: 70%;}
  90%{height: 20%;}
  100% {left: 125%;}
`
const StarImage = styled.img.attrs({
  className: 'absolute'
})`
  top: ${(props) => `${props.top}%`};
  bottom: ${(props) => `${props.bottom}%`};
  left: ${(props) => `${props.left}%`};
  right: ${(props) => `${props.right}%`};
  animation-name: ${star};
  animation-duration: 7s;
  animation-delay: ${(props) => `${props.delay}s`};
  animation-iteration-count: infinite;
`

const HeartIcon = styled.span.attrs({
  className:
    'absolute top-[25%] left-1/2 transform -translate-x-1/2 z-20 inline-block w-[257px] overflow-hidden'
})`
  &:before {
    content: '';
    position: absolute;
    top: 0%;
    left: -15%;
    width: 10%;
    height: 95%;
    z-index: 50;
    animation: ${heart};
    animation-delay: 1s;
    animation-duration: 1.2s;
  }
`

const ModalBody = styled.div.attrs({
  className: 'relative'
})``

const ModalContainer = styled.div.attrs({
  className: 'relative'
})``

const ModalTitle = styled.div.attrs({
  className: 'flex absolute top-[11%] left-[22%] z-50 overflow-x-hidden'
})`
  &:before {
    content: '';
    position: absolute;
    top: 10%;
    left: -15%;
    width: 10%;
    height: 80%;
    z-index: 50;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    transform: skewX(-25deg);
    animation: ${shine};
    animation-delay: 1s;
    animation-duration: 1.2s;
  }
`

const ModalContent = styled.div.attrs({
  className: 'h-[350px] relative flex justify-center'
})``
