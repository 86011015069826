import React from 'react'
import PropTypes from 'prop-types'
import ModalCommon from 'src/components/common/ModalCommon'

const ModalGift = ({ isOpen, onClose, present }) => {
  if (!present) return
  const modalButtons = [
    {
      label: '戻る',
      onClick: () => {
        onClose()
      },
      type: 'close'
    }
  ]

  return (
    <ModalCommon open={isOpen} onClose={onClose} title="プレゼント受け取り" buttons={modalButtons}>
      <div className="bg-[#FFFFFF] px-2 pt-6 pb-12 text-center relative overflow-auto">
        {present.length === 0 && (
          <div className="font-light text-sm text-center leading-[21px] mt-2 mb-2">
            メニュー＞アイテムから確認できます。
          </div>
        )}
        {present?.map((item, index) => (
          <div key={index}>
            <div>
              {item?.gift_image && (
                <div className="w-full h-full">
                  <img className="aspect-square object-cover" src={item?.gift_image} alt="gift" />
                </div>
              )}
            </div>
            <h5 className="font-vldLineGR mt-[3px] text-xl leading-[25px] text-center mb-4">
              {item?.gift_name}
            </h5>
          </div>
        ))}
      </div>
    </ModalCommon>
  )
}

ModalGift.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  present: PropTypes.array
}

export { ModalGift }
