import React, { useState } from 'react'
import { useAuthState } from 'src/providers/AuthProviders'
import PropTypes from 'prop-types'
import diamonRed from '../../../../assets/img/common/diamond-red.png'
import plus from 'src/assets/img/common/plus.png'
import { ShopModal } from 'src/pages/voiceLive/pages/DuringDelivery/_ShopModal'

const LiveRedDiamond = ({ handleClick }) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const { user } = useAuthState()

  return (
    <>
      <div className="rounded bg-white transform skew-x-[-20deg] shadow-custom w-[90px]">
        <div className="flex flex-row items-center justify-between px-1 skew-x-[20deg]">
          <div className="flex flex-row items-center gap-0.5">
            <img src={diamonRed} className="w-5" />
            <div className="text-[13px]">{user?.red_amount}</div>
          </div>
          <div onClick={() => setConfirmModal(true)} className="cursor-pointer">
            <img src={plus} alt="plus" className="h-3" />
          </div>
        </div>
      </div>

      <ShopModal open={confirmModal} setOpen={setConfirmModal} />
    </>
  )
}

LiveRedDiamond.propTypes = {
  handleClick: PropTypes.func
}

export default LiveRedDiamond
