import { useConversation } from 'src/pages/main/dmTalk/hooks/useConversation'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import LoadingIcon from '../layouts/LoadingIcon'
import { ItemChatReceived } from './ChatBox/MessageReceived'
import { ItemChatRealTalk } from 'src/pages/main/storyTalk/components/ItemChatRealTalk'
import useChatRoom from 'src/services/common/useChatRoom'
import { saveMessage } from 'src/services/patch/saveMessage'
import { authActionTypes, useAuthDispatch, useAuthState } from 'src/providers/AuthProviders'
import { useDispatch } from 'react-redux'
import { setDisplayFooter } from 'src/redux/layout/setDisplayLayout'
import { CHARACTER_NAVIGATOR_ID } from 'src/constants/characters'
import NoContent from 'src/components/layouts/NoContent'
import { MAIN_PATHS } from 'src/routes/main'

export default function MessageNavigator() {
  const { user } = useAuthState()
  const inputRef = useRef(null)
  const roomChatRef = useRef()
  const dispatch = useDispatch()
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const {
    data: conversationData,
    isFetching,
    addNewMessageToCache,
    isFetched
  } = useConversation('realtime_chat')
  const firstPage = conversationData?.pages?.find((page) => page?.first_page)
  const hasFirstPage = !(conversationData && conversationData.pages.length && conversationData.pages[0] === undefined)
  const { message, sendMessage } = useChatRoom(firstPage?.conversation_id)
  const authDispatch = useAuthDispatch()

  const handleNewMessageChange = (event) => {
    const value = event.target.value
    const messageLength = value.replace(/\n/g, '').length

    if (messageLength <= 100) {
      setNewMessage(value)
    }
  }
  const isNoContent = useMemo(
    () => isFetched && hasFirstPage && !firstPage?.character?.navigator,
    [firstPage, isFetched]
  )

  useEffect(() => {
    if (!message) return
    setMessages((prevMessages) => [...prevMessages, message])
    addNewMessageToCache(message)
  }, [message])

  const clearInput = () => {
    setNewMessage('')
    inputRef.current.value = null
  }

  useEffect(() => {
    dispatch(setDisplayFooter())
  }, [])

  const onSend = async () => {
    if (/^[\n\s]*$/.test(newMessage)) return
    try {
      clearInput()
      sendMessage(newMessage)
      await saveMessage(
        conversationData.pages[0].conversation_id,
        user.id,
        CHARACTER_NAVIGATOR_ID,
        newMessage
      )
      authDispatch({
        type: authActionTypes.SPEND_HEART,
        payload: 0
      })
    } catch {}
  }

  useEffect(() => {
    if (!conversationData) return

    setMessages(conversationData.pages.map((page) => page?.messages).flat())
  }, [conversationData])

  return (
    isNoContent ? <div className="h-full flex items-center">
      <NoContent isModal message={'直前のページに戻ります。\nナビゲーターにお知らせください'} redirectUrl={MAIN_PATHS.NavigatorPage.replace(':characterId', 6)}/>
    </div> : <>
      <div className="relative h-[calc(100dvh-160px)]">
        <div className="h-[calc(100%-68px)] overflow-auto hidden-scroll">
          <WrapperChatBox ref={roomChatRef}>
            {isFetching ? <LoadingIcon /> : <div id="start" />}
            {messages.map((message, i) => (
              <div key={i}>
                {message?.is_owner ? (
                  <ItemChatReceived data={message} className="!bg-[#B79EFF]" />
                ) : (
                  <ItemChatRealTalk
                    className="bg-[#F5EFFF]"
                    msg={message}
                    loveLvSettings={{}}
                    character={{}}
                    changeBgImage={() => {}}
                    isScrollOpenTalk={() => {}}
                    handleUpdateMessage={() => {}}
                    setMultiLevel={() => {}}
                    setIsMultiLoveModalOpen={() => {}}
                    handleOpenGift={() => {}}
                  />
                )}
                <br />
              </div>
            ))}
            <br />
          </WrapperChatBox>
        </div>
        <div className="bg-[#AF93FFE5]/80 py-3 px-4 w-full h-[68px]">
          <div className="flex items-end gap-x-2">
            <textarea
              id="input-message"
              ref={inputRef}
              value={newMessage}
              onChange={handleNewMessageChange}
              placeholder="メッセージを入力（最大100文字）"
              style={{
                width: 'calc(100% - 52px)',
                height: '2.25rem',
                maxHeight: 164,
                boxShadow: 'inset 0px 1px 3px rgba(0, 0, 0, 0.25)',
                minHeight: 36
              }}
              className="w-full h-full rounded-[3px] pl-3 py-2.5 placeholder-shown:text-xs placeholder-shown:text-[rgba(68,68,68,0.78)] placeholder-shown:font-light text-xs text-[#333333] font-light leading-[18px]"
              rows={1}
            />
            <div
              className="h-[2.25rem] w-[44px] bg-[#AF93FFE5] flex items-center justify-center rounded-[4px] cursor-pointer text-white"
              onClick={onSend}
            >
              送信
            </div>
          </div>
          <span className="text-white font-medium text-[13px] float-left">残り100文字</span>
        </div>
      </div>
    </>
  )
}

const WrapperChatBox = styled.div.attrs({
  className: 'overflow-y-auto overflow-x-hidden hidden-scroll'
})`
  padding: 0 4px 0 4px;
`
