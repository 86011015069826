import { useContext } from 'react'
import PropTypes from 'prop-types'

import { HistoryRouteContext } from 'src/routes'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import Loading from 'src/components/layouts/Loading'
import NoContent from 'src/components/layouts/NoContent'
import InnerHTML from 'dangerously-set-html-content'
import useLoading from 'src/components/layouts/hooks/useLoading'

import { removeNewlineCode } from 'src/lib/string'

import BtnReturn from 'src/assets/img/common/float_back_btn.png'

import { MYPAGE_PATHS } from 'src/routes/myPage'

const EventDetail = ({ event, isLoading }) => {
  const { redirectTo } = useContext(HistoryRouteContext)

  const handleClick = () => {
    redirectTo(MYPAGE_PATHS.EventListPage)
  }

  const { showLoading, progress } = useLoading([isLoading])

  return (
    <MenuWrapper className="flex flex-col items-center mt-[-2.5rem]" wrapperClass='pb-[2.2rem]'>
      {showLoading ? (
        <Loading progress={progress} />
      ) : !event ? (
        <NoContent
          isModal
          message={'直前のページに戻ります。\nナビゲーターにお知らせください'}
          redirectUrl={MYPAGE_PATHS.EventListPage}
        />
      ) : (
        <div className="overflow-y-auto relative w-full h-full">
          <button onClick={handleClick} className="absolute w-[4.75rem] h-[3.875rem] left-0 top-2 z-10">
            <img src={BtnReturn} className="w-[4.75rem]" alt="return button" />
          </button>

          <div className="bg-white w-full h-full overflow-y-auto hidden-scroll">
            {event.articleContentHtml && (
              <InnerHTML html={removeNewlineCode(event.articleContentHtml)} />
            )}
          </div>
        </div>
      )}
    </MenuWrapper>
  )
}

EventDetail.propTypes = {
  event: PropTypes.object.isRequired,
  isLoading: PropTypes.bool
}

export default EventDetail
