import PropTypes from 'prop-types'
import ItemThumbnail from 'src/components/menu/ItemThumbnail'

const ItemListButton = ({ image, name, onClick, type, rarity = false, countItem }) => {
  const position = () => (type === 'standard' ? 'bottom-9 right-4' : 'bottom-12 right-4')

  return (
    <button
      type="button"
      onClick={onClick}
      className={`overflow-hidden flex flex-col items-center relative ${
        type === 'standard' ? 'h-24' : 'h-28'
      }`}
    >
      <ItemThumbnail
        image={image}
        imgClassName={type === 'standard' ? 'w-[51px] aspect-square' : 'w-[56px] aspect-square'}
        rarity={rarity}
      />
      <div
        className={`absolute font-hiragino text-[0.6875rem] text-candy-pink font-semibold ${position()}`}
        style={{
          textShadow: `
            2px 0 #fff,
            -2px 0 #fff,
            0 2px #fff,
            0 -2px #fff,
            1px 1px #fff,
            -1px -1px #fff,
            1px -1px #fff,
            -1px 1px #fff
          `
        }}
      >
        x{countItem >= 100 ? '99+' : countItem}
      </div>
      <div
        className={`${
          type === 'standard' ? 'w-[51px]' : 'w-[56px]'
        } text-center line-clamp-2 text-[0.5625rem] text-candy-pink mt-1 font-bold`}
      >
        {name}
      </div>
    </button>
  )
}

ItemListButton.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  rarity: PropTypes.bool,
  countItem: PropTypes.number
}

export default ItemListButton
