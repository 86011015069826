export const getMessages = (characters, channelList) => {
  const isPlayableCharacter = characters.filter((character) => character.playable)
  const filterChannelList = channelList.filter((channel) =>
    isPlayableCharacter.some((character) => character.id === channel.character.id)
  )
  const _channels = filterChannelList
    .filter((item) => {
      return item.character || (item.characters && item.characters.length)
    })
    .map((item) => {
      return {
        ...item,
        character: item.character || (item.characters || [])[0] || {},
        user: item.user || (item.users || [])[0] || {}
      }
    })
  return _channels
}
