import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import BtnPurchase from 'src/assets/img/shop/btn_fee_bk.png'

const PurchaseButton = ({ cost, onClick, disabled }) => {
  return (
    <div className="flex flex-col items-center justify-center w-1/4">
      <Button disabled={disabled} bgImage={BtnPurchase} isDisabled={disabled} onClick={onClick}>
        購入
      </Button>
      <div
        className={`drop-shadow-md font-MPlus1 font-semibold text-bubblegum-pink flex ${
          cost >= 100000 ? 'text-sm' : 'text-[15px]'
        }`}
      >
        <p className="w-4/5 truncate">{Number(cost).toLocaleString()}</p>
        <p>円</p>
      </div>
    </div>
  )
}

PurchaseButton.propTypes = {
  cost: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default PurchaseButton

const Button = styled.button.attrs({
  className: 'text-white font-semibold font-Inter text-[0.8125rem] w-[61px] h-[55px] rounded-md'
})`
  background-image: url(${(props) => (props.isDisabled ? '' : props.bgImage)});
  background-size: 100% 100%;
  background-color: ${(props) => (props.isDisabled ? '#CCCCCC' : '#FF95CF')};
`
