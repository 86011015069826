import { useQuery } from 'react-query'
import getSideStoryList from 'src/services/get/getSideStoryList'

const useGetSideStoryList = () => {
  return useQuery({
    queryKey: ['useGetSideStoryList'],
    queryFn: getSideStoryList
  })
}

export { useGetSideStoryList }
