import { useState, useContext } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import useLoading from 'src/components/layouts/hooks/useLoading'

import { HistoryRouteContext } from 'src/routes'
import { MENU_PATHS } from 'src/routes/menu'

import { Wrapper } from 'src/components/common/Wrapper'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import MenuItem from 'src/components/menu/MenuItem'
import SubmenuHeader from 'src/components/menu/SubmenuHeader'
import ModalCommon from 'src/components/common/ModalCommon'
import Loading from 'src/components/layouts/Loading'

import { useGetOpeningStoryList } from 'src/pages/menu/hooks/useGetOpeningStoryList'

import bookIcon from 'src/assets/img/menu/book.png'

const MenuPart = ({ children, className }) => {
  return (
    <div
      className={clsx('mx-auto font-bold text-xs xs:text-sm flex flex-col items-center', className)}
    >
      {children}
    </div>
  )
}

export const OpeningStoryMenuPage = () => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const { data, isLoading, isError } = useGetOpeningStoryList()
  const [currentID, setCurrentID] = useState(null)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { showLoading, progress } = useLoading([isLoading, isError])
  const title = '確認'
  const buttons = [
    {
      label: 'キャンセル',
      onClick: () => setIsOpenModal(close),
      type: 'close'
    },
    {
      label: 'OK',
      onClick: () => redirectTo(MENU_PATHS.OpeningStoryDetail.replace(':id', currentID))
    }
  ]

  const handleRedirect = (id) => {
    setCurrentID(id)
    setIsOpenModal(true)
  }

  if (showLoading) {
    return (
      <Wrapper>
        <Loading progress={progress} />
      </Wrapper>
    )
  }

  return (
    <>
      {
        /* eslint-disable quotes */
        isOpenModal && (
          <ModalCommon
            buttons={buttons}
            title={title}
            theme={'pink'}
            onClose={() => setIsOpenModal(false)}
            open={isOpenModal}
          >
            <div
              className="flex items-center justify-center font-inter font-bold bg-[#FFFFFF] px-2 py-8
              text-candy-pink text-center whitespace-pre-line
              "
            >
              {`音量にご注意ください。\nマナーモードでも音が流れます。`}
            </div>
          </ModalCommon>
        )
      }
      <MainLayout className="bg-menu-bg" active="menu" classNameHeader="">
        <MenuWrapper className="" overflow={true}>
          <SubmenuHeader title={'オープニングストーリーを見る'} />
          <div className="overflow-y-auto calc(100dvh - 200px) hidden-scroll">
            <MenuPart>
              {data?.map((story) => (
                <MenuItem
                  handleRedirect={() => handleRedirect(story.id)}
                  src={bookIcon}
                  label={story.name}
                  to={MENU_PATHS.OpeningStoryDetail}
                  key={story.id}
                />
              ))}
            </MenuPart>
          </div>
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

MenuPart.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}
