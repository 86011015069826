export const getColorByRanking = (ranking) => {
  switch (ranking) {
    case 1:
      return 'text-golden'
    case 2:
      return 'text-silver'
    case 3:
      return 'text-brozen'
    default:
      return 'text-cornflower-blue'
  }
}
