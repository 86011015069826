import React, { useMemo, useState } from 'react'
import { authActionTypes, useAuthDispatch } from '../../../../providers/AuthProviders'
import { useAuthState } from 'src/providers/AuthProviders'
import { LiveModal } from '../../components/LiveModal'
import { LoveItem } from './_LoveItem'
import LiveItemAddition from '../../assets/live_item_addition.png'
import { useDuringContext } from './_Provider'
import _ from 'lodash'
import { postLiveTime } from '../../../../services/post/postLiveTime'
import { InsufficientDiamondModal } from './_InsufficientDiamondModal'
import PropTypes from 'prop-types'
import { ModalPassport } from '../../components/ModalPassport'
import closeLiveModalBlue from 'src/pages/voiceLive/assets/close_live_modal_blue.png'
import ChickenIcon from 'src/assets/img/chicken-icon.png'
import { AddLiveItemModal } from './_AddLiveItemModal'
import { ShopModal } from './_ShopModal'

const LiveItemModal = ({
  setIsMultiLoveModalOpen,
  setMultiLevel,
  normalPassportModal,
  canWorkWithFreePassport
}) => {
  const authDispatch = useAuthDispatch()
  const { user } = useAuthState()
  const [open, setOpen] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)
  const [insufficientDiamond, setInsufficientDiamond] = useState(false)
  const [openModalPassport, setOpenModalPassport] = useState(false)
  const [selected, setSelected] = useState({})
  const [liveItemModal, setLiveItemModal] = useState(false)
  const [itemAmount, setItemAmount] = useState(1)
  const [confirmModal, setConfirmModal] = useState(false)

  const onClose = () => {
    setIsAnimating(false)
    setTimeout(() => setOpen(false), 200)
  }

  const { liveItemsSocket, liveInfoQuery } = useDuringContext()
  const livestreamId = useMemo(() => liveInfoQuery.data?.live_stream?.id, [liveInfoQuery])
  const liveItems = useMemo(() => {
    if (!liveItemsSocket.activeItemData.changed) {
      return _.get(liveInfoQuery, 'data.live_items') || []
    }

    return liveItemsSocket.activeItemData.items
  }, [liveItemsSocket.activeItemData, liveInfoQuery])

  const openLiveModal = () => {
    setIsAnimating(true)
    setOpen(true)
  }

  const checkRedDiamond = (item, amount) => {
    const redAmount = user.red_amount - item.number_diamond * amount
    if (redAmount < 0) {
      setConfirmModal(true)
      return false
    }
    return true
  }

  const clickLiveItem = (liveItem) => {
    if (!normalPassportModal && !canWorkWithFreePassport) {
      setOpenModalPassport(true)
    } else {
      setSelected(liveItem)
      const isEnoughDiamond = checkRedDiamond(liveItem, 1)
      if (isEnoughDiamond) {
        setItemAmount(1)
        setLiveItemModal(true)
      }
    }
  }

  const sendItem = async (liveItem) => {
    const isEnoughDiamond = checkRedDiamond(liveItem, itemAmount)
    if (!isEnoughDiamond) {
      return
    }
    setLiveItemModal(false)
    if (user.red_amount < _.get(liveItem, 'number_diamond')) {
      onClose()
      setInsufficientDiamond(true)
    } else {
      try {
        onClose()
        const { item, currentLevel, nextLevel } = await postLiveTime({
          livestream_id: livestreamId,
          live_item_id: _.get(liveItem, 'id'),
          amount: itemAmount
        })
        authDispatch({
          type: authActionTypes.SPEND_DIAMOND_RED,
          payload: _.get(liveItem, 'number_diamond') * itemAmount
        })
        if (!item && (!currentLevel || !nextLevel)) return
        setMultiLevel({
          currentLevel: currentLevel,
          nextLevel: nextLevel
        })
        setIsMultiLoveModalOpen(true)
      } catch {}
    }
  }

  const countLiveItem = (number) => {
    setItemAmount((prev) => {
      const result = prev + number
      if (result < 1 || result > 99) return prev
      checkRedDiamond(selected, result)
      return result
    })
  }

  return (
    <div className="flex justify-center z-[9]" id="chicken-icon">
      <img
        src={LiveItemAddition}
        className="w-[60px] h-auto cursor-pointer"
        onClick={() => openLiveModal()}
      />
      {open && (
        <LiveModal
          onClose={onClose}
          isAnimating={isAnimating}
          closeImg={closeLiveModalBlue}
          bgColor="bg-[#FFFFFFF2]"
          description={
            <div className="flex justify-center mr-14">
              <div className="relative text-white text-left font-semibold text-[11px] bg-[#6F9BFF] rounded-xl p-2 pr-5">
                アイテムを送ると、名前が表示されてカレに覚えてもらいやすくなるヨ!また、大花火とプリンスキャッスルは、その他のアイテムより強調表示されるので、より一層覚えて貰えるコケ
                <img
                  src={ChickenIcon}
                  className="absolute -bottom-8 -right-20 w-[110px] h-auto -rotate-6"
                />
                <div className="absolute -bottom-3 right-14">
                  <svg
                    width="21"
                    height="19"
                    viewBox="0 0 21 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.3529 19C3.70588 19 0.514706 9.67612 0 5.01418C4.11765 1.01823 12.1059 -4.3763 11.1176 6.01317C10.1294 16.4026 17.2941 17.224 21 16.336C19.0235 17.9344 14.4118 18.778 12.3529 19Z"
                      fill="#6F9BFF"
                    />
                  </svg>
                </div>
              </div>
            </div>
          }
        >
          <div className="grid grid-cols-2">
            {liveItems.map((liveItem, index) => (
              <LoveItem item={liveItem} onClick={() => clickLiveItem(liveItem)} key={index} />
            ))}
          </div>
        </LiveModal>
      )}

      <ModalPassport open={openModalPassport} onClose={() => setOpenModalPassport(false)} />
      <InsufficientDiamondModal
        open={insufficientDiamond}
        onClose={() => setInsufficientDiamond(false)}
      />
      <AddLiveItemModal
        open={liveItemModal}
        onClose={() => setLiveItemModal(false)}
        user={user}
        selectedItem={selected}
        amount={itemAmount}
        countLiveItem={countLiveItem}
        onSubmit={() => sendItem(selected)}
      />
      <ShopModal open={confirmModal} setOpen={setConfirmModal} />
    </div>
  )
}

LiveItemModal.propTypes = {
  setIsMultiLoveModalOpen: PropTypes.func,
  setMultiLevel: PropTypes.func,
  normalPassportModal: PropTypes.bool,
  canWorkWithFreePassport: PropTypes.bool
}

export { LiveItemModal }
