import {
  createContext,
  useEffect,
  useState,
  Children,
  cloneElement,
  isValidElement,
  useContext
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useNewsListChannel from 'src/services/common/useNewsListChannel'
import { MYPAGE_PATHS } from 'src/routes/myPage'
import getUnreadNews from 'src/services/get/getUnreadNews'
import { useAuthState } from 'src/providers/AuthProviders'
import { HistoryRouteContext } from 'src/routes'

import { Tab } from '@headlessui/react'
import StandardPageWrapper from 'src/components/common/StandardPageWrapper'
import { MainLayout } from 'src/components/layouts/MainLayout'
import GradientHeader from 'src/components/common/GradientHeader'
import { setHasNewsNotifications } from 'src/redux/notfication/getNotifications'
import { Wrapper } from 'src/components/common/Wrapper'
import Loading from 'src/components/layouts/Loading'
import useLoading from 'src/components/layouts/hooks/useLoading'

const NoticeWrapper = styled.div.attrs({
  className: ''
})`
  height: calc(100dvh - 80px);
`

const Content = styled.div.attrs(({ height }) => ({
  className: `hidden-scroll overflow-y-auto font-medium my-6 ${height || 'h-[calc(100dvh-220px)]'}`
}))``

export const NoticePageContext = createContext()

const NoticeLayout = ({
  children,
  title,
  activetab,
  isHideHeader,
  height,
  classNameHeader = 'shadow-custom backdrop-blur-sm'
}) => {
  const [loading, setLoading] = useState(false)
  const { redirectTo } = useContext(HistoryRouteContext)
  const { user } = useAuthState()
  const dispatch = useDispatch()
  const hasNews = useSelector((state) => state.getNotifications.hasNews)
  const hasNewsNotifications = useSelector((state) => state.getNotifications.hasNewsNotifications)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [tabList, setTabList] = useState([
    {
      title: '運営から',
      hasNotification: false,
      index: 0,
      textStyle: 'text-base leading-[1rem] w-[90px] py-0.5',
      activeTextStyle: 'background: linear-gradient(90deg, #40E7FF 0%, #FF48E2 100%)'
    },
    {
      title: 'イベント',
      hasNotification: false,
      index: 1,
      textStyle: 'text-base leading-[1rem] w-[90px] py-0.5',
      activeTextStyle: 'background: linear-gradient(90deg, #40E7FF 0%, #FF48E2 100%)'
    }
  ])

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { title, activetab })
    }

    return child
  })
  const handleChangeTab = (tabIndex) => {
    setSelectedIndex(tabIndex)
    if (tabIndex === 0) redirectTo(MYPAGE_PATHS.NewsListPage)
    else if (tabIndex === 1) redirectTo(MYPAGE_PATHS.EventListPage)
  }

  const checkHasUnreadNews = async () => {
    const res = await getUnreadNews(user.id)
    dispatch(setHasNewsNotifications(res))
  }

  useEffect(() => {
    setSelectedIndex(activetab === 'events' ? 1 : 0)
    checkHasUnreadNews()
  }, [])

  useEffect(() => {
    setTabList((prevTabList) => {
      const updatedTabList = [...prevTabList]
      updatedTabList[0] = {
        ...updatedTabList[0],
        hasNotification: hasNewsNotifications || hasNews
      }
      return updatedTabList
    })
  }, [hasNewsNotifications, hasNews])
  useNewsListChannel()

  const { showLoading, progress } = useLoading([loading])

  return (
    <>
      <div className={!showLoading && 'hidden'} >
        <Wrapper>
          <Loading progress={progress} />
        </Wrapper>
      </div>

      <div className={`${showLoading && 'hidden'}`}>
        <MainLayout classNameHeader={classNameHeader}>
          <NoticePageContext.Provider
            value={{ checkHasUnreadNews, loading, setLoading }}
          >
            <StandardPageWrapper className="mt-10 pb-[40px]" overflow={true}>
              <NoticeWrapper>
                <Tab.Group selectedIndex={selectedIndex}>
                  {!isHideHeader && (
                    <GradientHeader
                      tabList={tabList}
                      onHandleTabChange={handleChangeTab}
                      title={title}
                    />
                  )}
                  <Tab.Panels className="mt-2 sm:w-desktop-width relative">
                    <Content height={height}>{childrenWithProps}</Content>
                  </Tab.Panels>
                </Tab.Group>
              </NoticeWrapper>
            </StandardPageWrapper>
          </NoticePageContext.Provider>
        </MainLayout>
      </div>
    </>
  )
}

NoticeLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  activetab: PropTypes.string,
  isHideHeader: PropTypes.bool,
  height: PropTypes.string,
  classNameHeader: PropTypes.string
}

export default NoticeLayout
