import React from 'react'
import PropTypes from 'prop-types'
import ModalCommon from 'src/components/common/ModalCommon'

const ModalExtraTime = ({ isOpen, onClose }) => {
  const modalButtons = [
    {
      label: 'OK',
      onClick: () => {
        onClose()
      }
    }
  ]

  return (
    <ModalCommon open={isOpen} onClose={onClose} title="EXTRAタイム中" buttons={modalButtons}>
      <div className="bg-[#FFFFFF] px-2 py-8 text-center mb-[16px]">
        <p className="font-bold text-[17px] font-Inter">EXTRAタイム中は入場出来ません。</p>
        <p className="font-bold text-[17px] font-Inter">次回の配信までお待ちください。</p>
      </div>
    </ModalCommon>
  )
}

ModalExtraTime.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
}

export { ModalExtraTime }
