import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tabs } from 'src/constants/common'
export const TabItem = ({ isActive, name, onClick }) => {
  return (
    <Wrapper
      className={`text-xs rounded-3xl leading-3 ${
        isActive
          ? 'font-bold bg-transparent text-cornflower-blue border-cornflower-blue border'
          : 'text-white bg-[#DDE7FF]'
      }`}
      onClick={() => {
        onClick && onClick()
      }}
    >
      <div>{name}</div>
    </Wrapper>
  )
}

TabItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

const Wrapper = styled.div.attrs({
  className: 'relative py-1 cursor-pointer'
})`
  background-image: url(${(props) => props.background});
  background-size: 100% 100%;
`

export const TimeTabs = ({ tabs, onChangeTab, gap = 'gap-1.5', activeKey }) => {
  const [activeTab, setActiveTab] = useState(() => {
    const tabIndex = Tabs.findIndex((tab) => tab.key === activeKey)
    return tabIndex !== -1 ? tabIndex : 0
  })
  const onClick = (index) => {
    setActiveTab(index)
    onChangeTab && onChangeTab(index)
  }
  const className = `py-4 px-2 grid font-hiragino text-sm text-[#532698] ${
    'grid-cols-' + tabs.length + ' ' + gap
  }`
  return (
    <div
      className={className}
      style={{ background: 'linear-gradient(103.54deg, #F8F4FF 21.27%, #EFFFFD 85.74%)' }}
    >
      {tabs.map((item, index) => (
        <TabItem
          key={index}
          isActive={activeTab === index}
          name={item.name}
          onClick={() => onClick(index)}
        />
      ))}
    </div>
  )
}

TimeTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onChangeTab: PropTypes.func,
  gap: PropTypes.string,
  activeKey: PropTypes.string
}
