import { createSlice } from '@reduxjs/toolkit'

export const getNotifications = createSlice({
  name: 'getNotifications',
  initialState: {
    isNewMessageInquiry: false,
    inquiryNoti: false,
    hasNews: false,
    hasNewsNotifications: false
  },
  reducers: {
    setIsNewMessageInquiry: (state, action) => {
      state.isNewMessageInquiry = action.payload
    },
    setInquiryNoti: (state, action) => {
      state.inquiryNoti = action.payload
    },
    setHasNews: (state, action) => {
      state.hasNews = action.payload
    },
    setHasNewsNotifications: (state, action) => {
      state.hasNewsNotifications = action.payload
    }
  }
})

export const { setIsNewMessageInquiry, setInquiryNoti, setHasNews, setHasNewsNotifications } = getNotifications.actions
export default getNotifications.reducer
