import React, { createContext, useContext } from 'react'

const CharacterLiveContext = createContext({
  characterLiveSocket: {}
})

const useCharacterLiveContext = () => {
  return useContext(CharacterLiveContext)
}

// eslint-disable-next-line react/prop-types
const CharacterLiveProvider = ({ children, value }) => {
  return <CharacterLiveContext.Provider value={value}>{children}</CharacterLiveContext.Provider>
}

export { CharacterLiveProvider, useCharacterLiveContext }
