import { useContext } from 'react'
import { useQuery } from 'react-query'

import PropTypes from 'prop-types'

import getCurrentEvents from 'src/services/get/getCurrentEvents'
import { MYPAGE_PATHS } from 'src/routes/myPage'
import { HistoryRouteContext } from 'src/routes'
import useLoading from 'src/components/layouts/hooks/useLoading'

import NoContentImage from 'src/components/layouts/NoContentImage'
import Loading from 'src/components/layouts/Loading'
import TipsModal from 'src/components/common/TipsModal'
import NoticeLayout from 'src/pages/myPage/Notice/NoticeLayout'
import EventItem from 'src/pages/myPage/Notice/Event/EventItem'
import { Wrapper } from 'src/components/common/Wrapper'

const EventList = () => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const { data: events, isLoading } = useQuery('events', () => getCurrentEvents())

  const { showLoading, progress } = useLoading([isLoading])
  if (showLoading || isLoading) {
    return (
      <Wrapper>
        <Loading progress={progress} />
      </Wrapper>
    )
  }

  return (
    <NoticeLayout
      title="開催中イベント"
      activetab="events"
      classNameHeader="!bg-gradient-sky-to-pink"
    >
      {events.length === 0 ? (
        <NoContentImage message="現在イベントは<br/>開催されていません" />
      ) : (
        <div className="w-11/12 mx-auto">
          <div
            className="w-full text-center overflow-y-auto hidden-scroll"
            style={{ height: 'calc(100dvh - 300px)' }}
          >
            {events.map((event) => (
              <EventItem key={event.id} event={event} />
            ))}
          </div>
          <div
            className="bg-sky-blue w-5/6 rounded-2xl text-white py-2 my-4 font-semibold mx-auto"
            onClick={() => redirectTo(MYPAGE_PATHS.EventArchiveListPage)}
          >
            過去イベントを見る
          </div>
        </div>
      )}
      <TipsModal type="events" />
    </NoticeLayout>
  )
}

EventList.propTypes = {
  events: PropTypes.array,
  setViewPastEvents: PropTypes.func
}

export default EventList
