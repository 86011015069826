import IdolEvent from 'src/models/Event'
import bannerImageDefault from 'src/assets/img/mypage/banner/banner.png'
import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const getHistoryEvents = async (currPage) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Events.getHistory, {
      params: { current_page: currPage }
    })
    if (res.data.success === false) return []

    const events = []
    for (const e of res.data.events) {
      const event = new IdolEvent(
        e.id,
        e.article_content_html,
        e.display_link,
        e.display_on_the_event_page,
        e.display_on_the_my_page,
        e.display_period_setting,
        e.start_time,
        e.end_time,
        e.image || bannerImageDefault,
        e.index,
        e.link,
        e.name
      )
      events.push(event)
    }

    return { ...res.data, events }
  } catch (error) {
    return []
  }
}

export default getHistoryEvents
