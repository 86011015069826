import { useCallback, useEffect, useRef, useState } from 'react'
import getUserPresents from 'src/services/get/getUserPresents'
import putReceivePresent from 'src/services/put/putReceivePresent'
import { authActionTypes, useAuthDispatch, useAuthState } from 'src/providers/AuthProviders'
import PresentPage from 'src/pages/myPage/Present'
import { updateMultipleItemAmount, updateSingleItemAmount } from 'src/lib/present'

const PresentPageContainer = () => {
  const [presents, setPresents] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currPage, setCurrPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const { user } = useAuthState()
  const authDispatch = useAuthDispatch()
  const listRef = useRef(null)

  const fetchUserPresents = async (page = undefined) => {
    try {
      setIsLoading(true)
      const res = await getUserPresents(user?.id, page ?? currPage, 'all')
      if (res) {
        if (page) setCurrPage(page)
        setTotalPages(res.total_pages)
        setPresents(res.user_presents)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleSetModalList = (res) => {
    const arr = []
    res.forEach((data) => {
      if (data.current_level < data.next_level) {
        arr.push(data)
      }
    })
  }

  const handleReceiveAll = useCallback(async () => {
    const res = await putReceivePresent(user?.id, null, true, 'all', currPage)
    if (!res) return
    handleSetModalList(res)

    updateMultipleItemAmount(presents, updateUserItem)
    if (currPage === 1) {
      fetchUserPresents(1)
    } else {
      setCurrPage(1)
    }
  }, [presents, currPage])

  const handleReceiveOne = useCallback(
    async (presentId, presentExpiredAt) => {
      if (new Date(presentExpiredAt) < new Date()) return

      const res = await putReceivePresent(user?.id, presentId)
      if (!res) return
      handleSetModalList(res)

      updateSingleItemAmount({ presents, updateUserItem, presentId })

      fetchUserPresents()
    },
    [presents]
  )

  const updateUserItem = useCallback(
    (itemType, amount) =>
      authDispatch({
        type: authActionTypes.ADD_ITEM,
        payload: { itemType, amount }
      }),
    []
  )

  useEffect(() => {
    fetchUserPresents()
  }, [currPage])

  useEffect(() => {
    setCurrPage(1)
    if (!listRef.current) return
    listRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [])

  return (
    <>
      <PresentPage
        presents={presents}
        isLoading={isLoading}
        handleReceiveAll={handleReceiveAll}
        handleReceiveOne={handleReceiveOne}
        totalPage={totalPages}
        currPage={currPage}
        setCurrPage={setCurrPage}
        listRef={listRef}
      />
    </>
  )
}

export default PresentPageContainer
