import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useInquiriesHasNotifications from 'src/hooks/useInquiriesHasNotifications'
import { setInquiryNoti } from 'src/redux/notfication/getNotifications'

const MenuWrapper = ({
  children,
  className = 'grid grid-cols-2 gap-2 items-center',
  overflow = false,
  containerRef,
  wrapperClass = 'pb-footer-offset'
}) => {
  const dispatch = useDispatch()

  const { hasNotifications } = useInquiriesHasNotifications()

  useEffect(() => {
    dispatch(setInquiryNoti(hasNotifications))
  }, [dispatch, hasNotifications])

  return (
    <div
      className={`w-screen h-full sm:w-desktop-width top-0 fixed sm:left-1/2 sm:-translate-x-1/2 ${
        overflow ? null : 'pt-menu-header-offset'
      } ${wrapperClass}`}
    >
      <div
        className={`mb-14 h-full w-full ${className} ${
          overflow ? 'overflow-y-scroll hidden-scroll' : ''
        }`}
        ref={containerRef}
      >
        {children}
      </div>
    </div>
  )
}

export default MenuWrapper

MenuWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  overflow: PropTypes.bool,
  containerRef: PropTypes.any,
  wrapperClass: PropTypes.string
}
