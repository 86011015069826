import { useEffect, useState } from 'react'
import { getConditions } from 'src/services/get/getConditions'

import SubmenuHeader from 'src/components/menu/SubmenuHeader'
import { MainLayout } from 'src/components/layouts/MainLayout'
import LegaleseWrapper from 'src/components/layouts/LegaleseWrapper'
import MenuWrapper from 'src/components/menu/MenuWrapper'

export const PrivacyPolicyPage = () => {
  const [condition, setConditions] = useState('')

  const fetchCondition = async () => {
    const data = await getConditions('term')
    const content = data.find((item) => item?.condition?.status === 'is_public')?.condition?.content
    setConditions(content)
  }

  useEffect(() => {
    fetchCondition()
  }, [])

  return (
    <>
      <MainLayout className="bg-menu-bg h-full" active="menu" classNameHeader="">
        <MenuWrapper className="flex items-center flex-col" overflow={true}>
          <SubmenuHeader title="プライバシーポリシー" />
          <LegaleseWrapper content={condition} />
        </MenuWrapper>
      </MainLayout>
    </>
  )
}
