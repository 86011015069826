import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

export const getScheduleToday = async () => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Live.ScheduleToday)

    return res?.data || null
  } catch {
    return null
  }
}
