const MangaInfoModal = () => {
  return (
    <div className="bg-white font-semibold text-lg w-full py-6 px-6 text-center font-Inter h-80 overflow-y-auto hidden-scroll">
      続きを読むには、「プラチナパスポート」が必要 です。ショップより、『プラチナ
      パスポート』をご購入の上、再度お越し下さい。
      <br />
      <br />『<span className="text-[#D16EFF]">プラチナパスポート</span>
      』は1回購入するだけで、永久に、
      <span className="text-[#0000cd]">
        無料ハートの回復量が増加・全てのキャラクターの生い立ち漫画を最後まで読めるようになる
      </span>
      などの特典が多数！
    </div>
  )
}

export default MangaInfoModal
