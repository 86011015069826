import { useEffect } from 'react'

import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'

const ShopExchangeInsufficientModal = () => {
  const { closeModal, handleChangeTab, setButtons, setTitle } = useShopContext()

  const handleBuyDiamonds = () => {
    closeModal()
    handleChangeTab(0)
  }

  useEffect(() => {
    setButtons([
      {
        label: 'キャンセル',
        onClick: closeModal,
        type: 'close'
      },
      {
        label: 'OK',
        onClick: handleBuyDiamonds
      }
    ])
    setTitle('確認')
  }, [])
  return (
    <>
      <div className="text-lg font-Inter font-semibold text-center whitespace-pre-line bg-white h-[120px] pt-4">
        赤ダイヤが不足しています。
        <br />
        ショップに移動しますか?
      </div>
    </>
  )
}

export default ShopExchangeInsufficientModal
