import PropTypes from 'prop-types'
import IconLove from 'src/assets/img/realtalk/love_icon.svg'
import { LvCharacter } from './LevelCharacter'

export const SettingLvCharacter = ({ loveLvSettings, character, expandTalk }) => {
  return (
    <>
      {loveLvSettings.level && character.name ? (
        <>
          <div
            className={`${
              !expandTalk ? 'right-0' : '-right-[100px]'
            } z-[100] absolute animation-expand-display top-[50px] right-0 text-[12px] font-MPlus1 text-left font-medium`}
          >
            <LvCharacter
              level={loveLvSettings.level.love.level}
              name={character.name}
              icon={IconLove}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

SettingLvCharacter.propTypes = {
  loveLvSettings: PropTypes.object,
  character: PropTypes.object,
  expandTalk: PropTypes.bool
}
