import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import SectionHeader from 'src/components/menu/SectionHeader'

const MessageInput = forwardRef(({ title, message, showPreview, handleEnterText }, ref) => {
  return (
    <>
      <SectionHeader
        title={title}
        className="font-semibold border-b w-full text-xs border-metallic-gray text-metallic-gray py-0"
      />
      {showPreview ? (
        <p className="text-candy-pink font-semibold text-left w-full break-words whitespace-pre-wrap">{message}</p>
      ) : (
        <textarea
          ref={ref}
          className="bg-white w-full p-2 mt-4 rounded border border-candy-pink flex justify-between items-center placeholder:text-candy-pink placeholder:text-medium placeholder:text-xs text-candy-pink font-semibold break-words overflow-auto"
          rows={6}
          onChange={(e) => handleEnterText(e.target.value)}
          value={message}
          placeholder="お問合せ内容をご入力ください。"
        />
      )}
    </>
  )
})

MessageInput.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  showPreview: PropTypes.bool,
  handleEnterText: PropTypes.func.isRequired
}
MessageInput.displayName = 'MessageInput'

export default MessageInput
