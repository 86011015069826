import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import HeaderTabButton from 'src/components/common/HeaderTabButton'

import purpleHeaderBackground from 'src/assets/img/menu/background/purple_header_background.png'
import pinkHeaderBackground from 'src/assets/img/menu/background/pink_header_background.png'
import blueHeaderBackground from 'src/assets/img/menu/background/blue_header_background.png'
import messageIcon from 'src/assets/img/menu/icon/message_icon.png'
import shopIcon from 'src/assets/img/menu/icon/shop_icon.png'
import returnIcon from 'src/assets/img/menu/icon/return_icon.png'

const ColorHeader = ({ onHandleTabChange, title, tabList }) => {
  const [headerStyle, setHeaderStyle] = useState({})
  const generateThemeColor = (title) => {
    switch (title) {
      case 'TALK':
        return {
          pageIcon: messageIcon,
          titleStyle: 'text-[#D4C5FF]',
          subTitleStyle: 'bg-[#AF93FF]',
          background: purpleHeaderBackground,
          subTitle: 'トーク'
        }
      case 'SHOP':
        return {
          pageIcon: shopIcon,
          titleStyle: 'text-[#FFE2F2]',
          subTitleStyle: 'bg-cotton-candy-pink',
          background: pinkHeaderBackground,
          subTitle: 'ショップ'
        }
      case 'GACHA':
        return {
          pageIcon: returnIcon,
          titleStyle: 'text-[#CFE5FF]',
          subTitleStyle: 'bg-cornflower-blue',
          background: blueHeaderBackground,
          subTitle: 'ガチャ'
        }
      default:
        break
    }
  }

  useEffect(() => {
    setHeaderStyle(generateThemeColor(title))
  }, [])

  return (
    <div
      className="flex justify-end relative"
      style={{ backgroundImage: `url(${headerStyle.background})`, backgroundSize: 'cover' }}
    >
      <div className="flex flex-col w-full">
        <div className="flex justify-between w-full z-10 pl-2 pr-4 items-center mt-6">
          <h1 className={`${headerStyle.titleStyle} font-extrabold text-[3.8125rem] font-Outfit`}>
            {title}
          </h1>
          <h2
            className={`${headerStyle.subTitleStyle} text-[1.375rem] font-bold font-Inter text-white rounded-[3rem] px-4`}
          >
            {headerStyle.subTitle}
          </h2>
        </div>
        {tabList && tabList.length > 0 && (
          <ul className="flex justify-center gap-2 mb-2">
            {tabList.map((tab) => (
              <li key={tab.index} className={tab.width}>
                <HeaderTabButton
                  title={tab.title}
                  onClick={() => onHandleTabChange(tab.index)}
                  hasNotification={tab.hasNotification}
                  textStyle={tab.textStyle}
                  backgroundClass={tab.backgroundClass}
                  textColor={tab.textColor}
                  activeTextStyle={tab.activeTextStyle}
                  disabledText={tab.disabledText}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
      <img src={headerStyle.pageIcon} className="absolute top-5 w-[103px]" />
    </div>
  )
}

ColorHeader.propTypes = {
  onHandleTabChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tabList: PropTypes.array
}

export default ColorHeader
