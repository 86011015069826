import PropTypes from 'prop-types'
import styled from 'styled-components'

const Loading = ({ progress = 0 }) => {
  return (
    <>
      <LoadingBack className="max-w-[212px]" progress={progress} aria-live="polite">
        <div className="w-[85%] ml-3">
          <TextBar>now loading...</TextBar>
          <ProgressBar>
            <LoadingFront progress={progress} />
          </ProgressBar>
        </div>
      </LoadingBack>
    </>
  )
}

Loading.propTypes = {
  type: PropTypes.oneOf(['spinner', 'processBar']),
  progress: PropTypes.number,
  onComplete: PropTypes.func
}

export default Loading

const TextBar = styled.div.attrs({
  className: 'text-xs text-left text-white font-bold font-Inter'
})``

const LoadingBack = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 30%;
  left: 0;
  width: 53%;
  height: 46px;
  background: ${({ progress }) => `linear-gradient(
    103.54deg,
    rgba(${Math.round(170 - progress)}, ${Math.round(117 + 1.16 * progress)}, ${Math.round(255 - 0.39 * progress)}, 1) 21.27%,
    rgba(${Math.round(90 + 0.8 * progress)}, ${Math.round(233 - 1.16 * progress)}, ${Math.round(216 + 0.39 * progress)}, 1) 85.74%
  )`};
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
  transition: background 0.1s linear, opacity 0.5s ease;
`

const ProgressBar = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 10px;
  overflow: hidden;
  height: 3px;
  margin-top: 4px;
`

const LoadingFront = styled.div`
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  background-color: white;
  border-radius: 10px 0 0 10px;
  transition: width 0.1s linear;
`
