import React from 'react'
import MascotDisableHourglass from '../../assets/mascot_disable_hourglass.png'
import PropTypes from 'prop-types'
import ModalCommon from 'src/components/common/ModalCommon'
import { closeModalButton } from 'src/constants/button_close'

const CantSendItemModal = ({ open, isExtraTime, onClose }) => {
  const notifyText = isExtraTime ? 'Extra' : 'ノーマル'

  return (
    <ModalCommon title="お知らせ" open={open} onClose={onClose} buttons={[closeModalButton('閉じる', onClose)]}>
      <div className="space-y-[30px] bg-[#FFFFFF] px-2 py-2 text-center mb-[16px]">
        <p className="text-center font-inter text-sm font-bold">
          今日の{notifyText}タイムでは、これ以上
          <br />
          <strong className="text-[#FF1D34]">【時間延長の砂】</strong>
          を
          <br />
          送れないコケッ！
        </p>

        <img src={MascotDisableHourglass} className="mx-auto w-[55%] h-[55%]" />
      </div>
    </ModalCommon>
  )
}

CantSendItemModal.propTypes = {
  open: PropTypes.bool,
  isExtraTime: PropTypes.bool,
  onClose: PropTypes.func
}

export { CantSendItemModal }
