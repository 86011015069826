import React from 'react'
import PropTypes from 'prop-types'
import ModalCommon from 'src/components/common/ModalCommon'
import BackGroundWattingOne from 'src/assets/img/realtalk/wating_back_1.png'
import BackGroundWattingTwo from 'src/assets/img/realtalk/wating_back_2.png'

const ModalChangeBg = ({ isOpen, onClose, listBackGround, onChangeBg }) => {
  const modalButtons = [
    {
      label: '閉じる',
      onClick: () => {
        onClose()
      }
    }
  ]

  return (
    <ModalCommon open={isOpen} onClose={onClose} title="背景設定" buttons={modalButtons}>
      <div
        className={
          'bg-[#FFFFFF] px-2 pt-6 pb-12 text-center mb-4 relative grid grid-cols-3 gap-4 h-[480px] overflow-auto'
        }
      >
        {listBackGround.map((item, index) => (
          <div key={index} onClick={() => {
            onChangeBg(item.room, index)
          }}>
            <img src={item?.room || ''} className="w-full h-full object-cover" />
          </div>
        ))}
        {Array(3)
          .fill()
          .map((_, index) => (
            <React.Fragment key={index}>
              <div>
                <img className="h-full w-full" src={BackGroundWattingOne} />
              </div>
              <div>
                <img className="h-full w-full" src={BackGroundWattingTwo} />
              </div>
            </React.Fragment>
          ))}
      </div>
    </ModalCommon>
  )
}

ModalChangeBg.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  listBackGround: PropTypes.array,
  onChangeBg: PropTypes.func
}

export { ModalChangeBg }
