import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'

import { getRankings, getRankingD6 } from 'src/services/ranking'
import { getColorByRanking } from 'src/lib/getColorByRanking'
import { Tabs } from 'src/constants/common'

export const useFetchRankingTabData = (rankingType) => {
  const [activeKey, setActiveKey] = useState(Tabs[0].key)

  const fetchRankings = async () => {
    let response
    if (rankingType === 'love') {
      response = await getRankings(rankingType, activeKey, 0)
    } else if (rankingType === 'talent') {
      response = await getRankingD6(activeKey)
    }
    return response?.rank || {}
  }

  const formatRankingData = useMemo(() => {
    return (rankObj, pageType) => {
      const formattedData = {}
      for (const [key, value] of Object.entries(rankObj)) {
        if ('character_id' in value) {
          if (pageType === 'talent' || (pageType === 'love' && value.total_love > 0)) {
            formattedData[key] = {
              ...value,
              color: getColorByRanking(value.rank)
            }
          }
        }
      }
      return formattedData
    }
  }, [])

  const {
    data: rankings = {},
    isLoading,
    error
  } = useQuery(['rankings', rankingType, activeKey], fetchRankings, {
    select: (data) => formatRankingData(data, rankingType)
  })

  const rankingCount = Object.keys(rankings).length

  return {
    activeKey,
    setActiveKey,
    rankings,
    rankingCount,
    isLoading,
    error
  }
}
