import React from 'react'
import PropTypes from 'prop-types'

import HourglassBlue from 'src/pages/voiceLive/assets/hourglass_blue_full.png'
import HourglassRed from 'src/pages/voiceLive/assets/hourglass_red_full.png'
import HourglassGold from 'src/pages/voiceLive/assets/hourglass_gold_full.png'
import HourglassRainbow from 'src/pages/voiceLive/assets/hourglass_rainbow_full.png'

const HourglassItem = ({
  diamondAmount = 0,
  itemType,
  additionalTime = 0,
  additionalLove = 0,
  onClick,
  image = ''
}) => {
  const getImageSource = (itemType) => {
    switch (itemType) {
      case 'blue_time':
        return HourglassBlue
      case 'red_time':
        return HourglassRed
      case 'gold_time':
        return HourglassGold
      case 'rainbow_time':
        return HourglassRainbow
      default:
        return ''
    }
  }

  return (
    <button className="mx-auto" onClick={onClick}>
      {image ? (
        <img src={getImageSource(itemType)} className="w-[166x] h-[255px] object-cover" />
      ) : (
        <div className="w-[88px] h-[144px] bg-white mx-auto font-hiragino font-bold p-4 flex items-center justify-center">
          砂時計のイラスト挿入箇所
        </div>
      )}
    </button>
  )
}

HourglassItem.propTypes = {
  diamondAmount: PropTypes.number,
  additionalTime: PropTypes.number,
  additionalLove: PropTypes.number,
  itemType: PropTypes.string,
  onClick: PropTypes.func,
  image: PropTypes.string
}

export { HourglassItem }
