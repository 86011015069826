import React from 'react'

import PropTypes from 'prop-types'
import clsx from 'clsx'

import forwardVectorIcon from 'src/assets/img/common/forward_rounded_vector.png'
import BtnNext from 'src/assets/img/common/pink_forward_rounded_vector.png'

const Pagination = ({ totalPage, currPage, setCurrPage, theme = 'blue' }) => {
  return (
    <div className="mt-2 flex justify-between w-2/5 mx-auto">
      <button
        onClick={() => setCurrPage(currPage - 1)}
        className={clsx('cursor-pointer w-5 h-6', { invisible: currPage === 1 })}
      >
        <img className="w-full h-full rotate-180" src={theme === 'blue' ? forwardVectorIcon : BtnNext} alt="before" />
      </button>
      <div className={`text-xl font-semibold ${theme === 'blue' ? 'text-sky-blue' : 'text-candy-pink'}`}>{totalPage ? currPage : 0}</div>
      <button
        onClick={() => setCurrPage(currPage + 1)}
        className={clsx('cursor-pointer w-5 h-6', { invisible: currPage === totalPage })}
      >
        <img className="w-full h-full" src={ theme === 'blue' ? forwardVectorIcon : BtnNext} alt="next" />
      </button>
    </div>
  )
}

Pagination.propTypes = {
  totalPage: PropTypes.number,
  currPage: PropTypes.number,
  setCurrPage: PropTypes.func,
  theme: PropTypes.string
}

export default Pagination
