import { useQuery } from 'react-query'
import { getSideStoryDetail } from 'src/services/get/getSideStoryDetail'

const useGetSideStoryDetail = (id) => {
  return useQuery({
    queryKey: ['useGetSideStoryDetail', id],
    queryFn: () => getSideStoryDetail(id)
  })
}

export { useGetSideStoryDetail }
