import News from 'src/models/News'
import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const getNews = async (userId, type = 'notice') => {
  try {
    const res = await axiosInstance.get(API_ROUTES.News.getNews, {
      params: { user_id: userId, action_type: 'get_all' }
    })
    if (res.data.success === false) return []
    const news = []

    for (const n of res.data.items) {
      if (n.new_type !== type && type !== 'all') continue

      const newsItem = new News(
        n.id,
        n.condition_for_display_user,
        n.content,
        n.display_location,
        n.display_setting,
        n.end_display_date,
        n.index,
        n.is_hidden,
        n.name,
        n.new_type,
        n.start_display_date,
        n.title,
        n.created_at,
        n.updated_at,
        n.unread_user_ids
      )
      news.push(newsItem)
    }

    return news
  } catch (error) {
    console.log(error)
    return []
  }
}

export default getNews
