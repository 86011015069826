import useProgress from 'src/components/layouts/hooks/useProgress'

const useLoading = (loadingStates) => {
  const isLoading = loadingStates.some((state) => state)
  const { progress, showLoading } = useProgress(isLoading)

  return { showLoading, progress }
}

export default useLoading
