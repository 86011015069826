import PropTypes from 'prop-types'
import HeaderTabButton from 'src/components/common/HeaderTabButton'
const MenuHeader = ({ backgroundImage, title, subTitle, tabList = [], onHandleTabChange, tabIcon }) => {
  return (
    <div
      className="flex justify-end bg-white relative"
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}
    >
      <div className="flex flex-col w-full">
        <div className="flex justify-between w-full z-10 px-1 items-center">
          <h1 className={`font-extrabold text-[3.4rem] xs:text-[3.8125rem] font-Outfit ${title.textStyle}`}>
            {title.text}
          </h1>
          <h2
            className={`text-[1.375rem] font-bold font-Inter text-white rounded-[3rem] px-4 ${subTitle.textStyle}`}
          >
            {subTitle.text}
          </h2>
        </div>
        {tabList.length > 0 && (
          <ul className="flex justify-center gap-2">
            {tabList.map((tab) => (
              <li key={tab.index}>
                <HeaderTabButton
                  title={tab.title}
                  onClick={() => onHandleTabChange(tab.index)}
                  hasNotification={tab.hasNotification}
                  textStyle={tab.textStyle}
                  backgroundClass={tab.backgroundClass}
                  textColor={tab.textColor}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
      <img src={tabIcon} className='absolute top-4 w-20 xs:w-24'/>
    </div>
  )
}

MenuHeader.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  title: PropTypes.shape({
    textStyle: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  subTitle: PropTypes.shape({
    textStyle: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      hasNotification: PropTypes.bool,
      textStyle: PropTypes.string,
      backgroundClass: PropTypes.string,
      textColor: PropTypes.string
    })
  ),
  messageIcon: PropTypes.string,
  onHandleTabChange: PropTypes.func,
  tabIcon: PropTypes.string.isRequired
}

export default MenuHeader
