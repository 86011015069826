import PropTypes from 'prop-types'
import { validateEmail, validatePassword, validatePasswordConfirm } from 'src/lib/validation'
import { ErrorMessage } from 'src/components/ErrorMessage'

const EmailEditBox = ({
  email,
  setEmail,
  password,
  setPassword,
  passwordConfirm,
  setPasswordConfirm,
  errors,
  setErrors
}) => {
  const handleChangeEmail = (value) => {
    setEmail(value)
    delete errors.email
    setErrors({ ...errors, ...validateEmail(value, true) })
  }

  const handleChangePassword = (value) => {
    setPassword(value)
    delete errors.password
    delete errors.passwordConfirm
    setErrors({
      ...errors,
      ...validatePassword(value, true),
      ...validatePasswordConfirm(value, passwordConfirm)
    })
  }

  const handleChangePasswordConfirm = (value) => {
    setPasswordConfirm(value)
    delete errors.passwordConfirm
    setErrors({ ...errors, ...validatePasswordConfirm(password, value) })
  }

  return (
    <div className="py-8 px-4 w-full flex flex-col justify-left items-center z-10 font-Inter font-semibold text-candy-pink bg-white max-h-[25rem] overflow-y-auto hidden-scroll xs:max-h-max">
      <span className="text-base text-left w-full">メールアドレス</span>
      <span className="text-[0.6875rem] text-left w-full">お間違えの無いようご入力ください。</span>
      <div className="w-full  flex flex-col items-start">
        <input
          type="text"
          name="email"
          id="email"
          aria-label="email"
          className="placeholder-[#FFE5F3] mt-2 p-2 bg-white border border-candy-pink focus:outline-none rounded-md w-full drop-shadow-md"
          placeholder="メールアドレス"
          value={email || ''}
          onChange={(e) => handleChangeEmail(e.target.value)}
        />
        <ErrorMessage errors={errors} field="email" />
        <span className="text-[0.5625rem] mt-2 w-full whitespace-pre-line">
          {
            '注意事項\nドメイン拒否、PCメール拒否をしていると登録確認メールを受け取れません。\nidolprince.games\nからのメールを受信できるよう設定の確認をお願いします。'
          }
        </span>
      </div>
      <span className="mt-4 text-sm text-left w-full">ログインパスワード</span>
      <span className="text-[0.6875rem] text-left w-full">(6文字~32文字の半角英数字)</span>
      <div className="w-full  flex flex-col items-start">
        <input
          type="password"
          name="password"
          id="password"
          aria-label="password"
          className="placeholder-[#FFE5F3]  mt-2 p-2 bg-white border border-candy-pink focus:outline-none rounded-md w-full drop-shadow-md"
          placeholder="ログインパスワード"
          value={password || ''}
          onChange={(e) => handleChangePassword(e.target.value)}
        />
        <ErrorMessage errors={errors} field="password" />
      </div>
      <span className="mt-2 text-sm text-left w-full">ログインパスワード</span>
      <span className="text-[0.6875rem] text-left w-full">(もう一度ご入力ください)</span>
      <div className="w-full  flex flex-col items-start">
        <input
          type="password"
          name="passwordConfirm"
          id="passwordConfirm"
          aria-label="passwordConfirm"
          className="placeholder-[#FFE5F3] mt-2 p-2 bg-white border border-candy-pink focus:outline-none rounded-md w-full drop-shadow-md"
          placeholder="パスワード"
          value={passwordConfirm || ''}
          onChange={(e) => handleChangePasswordConfirm(e.target.value)}
        />
        <ErrorMessage errors={errors} field="passwordConfirm" />
      </div>
    </div>
  )
}

EmailEditBox.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  passwordConfirm: PropTypes.string.isRequired,
  setPasswordConfirm: PropTypes.func.isRequired,
  apiErrors: PropTypes.object,
  errors: PropTypes.object,
  setErrors: PropTypes.func
}

export default EmailEditBox
