import { useQuery } from 'react-query'
import getOpeningStoryList from 'src/services/get/getOpeningStoryList'

const useGetOpeningStoryList = () => {
  return useQuery({
    queryKey: ['useGetOpeningStoryList'],
    queryFn: getOpeningStoryList
  })
}

export { useGetOpeningStoryList }
