import PropTypes from 'prop-types'
import useNavigateBack from 'src/hooks/useNavigateBack'

import whiteBackwardVector from 'src/assets/img/common/white_backward_rounded_vector.png'
import greyBackwardVector from 'src/assets/img/common/grey_backward_rounded_vector.png'

const BackButton = ({ vectorColor = 'white', backPath, className = '' }) => {
  const handleBack = useNavigateBack(backPath)
  return (
    <button className={className} onClick={handleBack}>
      <img
        className="w-full"
        src={vectorColor === 'white' ? whiteBackwardVector : greyBackwardVector}
        alt="back"
      />
    </button>
  )
}

BackButton.propTypes = {
  vectorColor: PropTypes.string,
  backPath: PropTypes.string,
  className: PropTypes.string
}

export default BackButton
