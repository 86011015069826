import axiosInstance from '../common/axiosInstance'
import { API_ROUTES } from '../common/routes'

export const liveStreamInfo = async (title, openId) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Live.LiveStreamInfo.replace(':open_id', openId).replace(':title', title))

    return res?.data || null
  } catch {
    return null
  }
}
