import { useEffect, useState, useContext, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAuthState } from 'src/providers/AuthProviders'
import { resetLayout, setDisplayFooter } from 'src/redux/layout/setDisplayLayout'
import styled from 'styled-components'

import { MessageItem } from 'src/components/message/Item'
import Loading from 'src/components/layouts/Loading'
import { StoryTalk } from 'src/pages/main/storyTalk/StoryTalk'
import MessageNavigator from 'src/components/message/MessageNavigator'
import TipsModal from 'src/components/common/TipsModal'
import NotificationIcon from 'src/components/common/NotificationIcon'
import { DMTalkContext } from 'src/pages/main/dmTalk/DMTalk'
import { HistoryRouteContext } from 'src/routes'
import { MAIN_PATHS } from 'src/routes/main'

import RainbowArrow from 'src/assets/img/realtalk/rainbow_arrow.webp'
import BGHeader from 'src/assets/img/menu/background/purple_header_background.png'
import messageIcon from 'src/assets/img/menu/icon/message_icon.png'

import { getChatList } from 'src/services/get/getChat'
import getCharacters from 'src/services/get/getCharacters'
import useConversationListChannel from 'src/services/common/useConversationListChannel'
import useLoading from 'src/components/layouts/hooks/useLoading'

const MessageContainer = ({ tab }) => {
  const [isAtBottom, setIsAtBottom] = useState(false)
  const location = useLocation()
  const { redirectTo } = useContext(HistoryRouteContext)
  const dispatch = useDispatch()
  const { user } = useAuthState()
  const { message } = useConversationListChannel()
  const [pageMessage, setPageMessage] = useState(tab)
  const [messages, setMessages] = useState([])
  const [checkActive, setCheckActive] = useState(false)
  const [checkUnreadStoryTalk, setCheckUnreadStoryTalk] = useState(false)
  const [unreadNavigator, setUnreadNavigator] = useState(false)

  const { hideNav, setLoadingMessage } = useContext(DMTalkContext)

  const contentRef = useRef(null)

  useEffect(() => {
    handleChannelList()
  }, [])

  const handleChannelList = async () => {
    const [channelList, characters] = await Promise.all([
      getChatList(user.id, 'realtime_chat'),
      getCharacters()
    ])
    setCheckUnreadStoryTalk(channelList.some((x) => x.unreadTotal > 0))
    setUnreadNavigator(channelList.find((x) => x.character.id === 6).unreadTotal > 0)
    const playableCharacters = characters.filter((character) => character.playable)
    const filteredChannels = playableCharacters.map((character) =>
      channelList.find((x) => x.character?.id === character.id)
    )

    const mappedChannels = filteredChannels.map((item) => ({
      ...item,
      character: item.character || item.characters?.[0] || {},
      user: item.user || item.users?.[0] || {}
    }))

    const newChannels = playableCharacters.map((character) => ({
      channel_id: `${Date.now().toString(36)}${Math.random().toString(36).substring(2)}`,
      character
    }))

    const uniqueChannels = [...mappedChannels, ...newChannels].filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (t) => t.character.id === item.character.id || t.character.name === item.character.name
        )
    )

    const finalChannels = uniqueChannels
      .map((item) => ({
        ...item,
        level: playableCharacters.find((char) => char.id === item.character.id)?.level || {}
      }))
      .filter((x) => x.character.id !== 6)

    setMessages(finalChannels)
  }

  useEffect(() => {
    if (messages.length > 0) {
      const checkUnread = messages
        .filter((x) => x.character.id !== 6)
        .some((item) => item.unreadTotal > 0)
      setCheckActive(checkUnread)
    }
  }, [messages])

  useEffect(() => {
    if (!message?.conversation_id) return

    const copyMessages = [...messages]
    handleChannelList()
    const index = copyMessages.findIndex((msg) => msg.conversation_id === message.conversation_id)
    if (index >= 0) {
      copyMessages[index] = { ...copyMessages[index], ...message }
      setMessages(copyMessages)
    }
  }, [message])

  const handleClickPage = (tab) => {
    if (tab === 'real') {
      redirectTo(MAIN_PATHS.RealtimePage, { replace: true })
    } else if (tab === 'story') {
      dispatch(resetLayout())
      redirectTo(MAIN_PATHS.MessagePage, { replace: true })
      setPageMessage(tab)
    } else if (tab === 'navigator') {
      setPageMessage(tab)
      redirectTo(MAIN_PATHS.NavigatorPage.replace(':characterId', 6), { replace: true })
    }
  }

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setIsAtBottom(true)
    } else {
      setIsAtBottom(false)
    }
  }

  const scrollToBottom = () => {
    contentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }

  const hasNotification = useMemo(
    () => messages.some((message) => message.unreadTotal > 0),
    [messages]
  )

  useEffect(() => {
    if (location.pathname === '/talk_story' || location.pathname === '/talk_real') {
      dispatch(resetLayout())
    } else {
      dispatch(setDisplayFooter())
    }
    setPageMessage(tab)
  }, [location])

  const { showLoading, progress } = useLoading([messages.length === 0])

  useEffect(() => {
    setLoadingMessage(showLoading)
  }, [showLoading])

  if (showLoading) {
    return (
      <div className="h-[calc(100dvh)]">
        <Loading progress={progress} />
      </div>
    )
  } else {
    return (
      <div>
        <div className="relative">
          {!hideNav && (
            <MessageHeader>
              <div className="flex absolute w-full justify-between top-1/2 items-center -translate-y-2/4 transform px-[5%] z-50">
                <h1 className="text-[#D4C5FF] font-black text-[3.8125rem]">TALK</h1>
                <p className="text-white bg-[#af93ff] rounded-[3rem] px-4 font-bold text-[22px]">
                  トーク
                </p>
              </div>
              <img src={messageIcon} className="absolute right-0 bottom-0" />
              <div className="z-50">
                <button
                  onClick={() => handleClickPage('story')}
                  className={`px-1 mr-[12px] parent-point-alert ${
                    pageMessage === 'story' ? 'btn-active-page gradient-box' : 'btn-no-active'
                  }`}
                >
                  <span className="text-gradient text-sm font-medium font-Inter tracking-tighter">
                    STORYTALK
                  </span>
                  {checkUnreadStoryTalk ? (
                    <>
                      <div className="point-alert" />
                    </>
                  ) : (
                    <></>
                  )}
                </button>
                <button
                  onClick={() => handleClickPage('real')}
                  className={`w-[80px] mr-[12px] parent-point-alert ${
                    pageMessage === 'real' ? 'btn-active-page gradient-box' : 'btn-no-active'
                  }`}
                >
                  <span className="text-gradient text-sm font-medium font-Inter tracking-tighter">
                    REALTALK
                  </span>
                  {checkActive && <div className="point-alert" />}
                </button>
                <button
                  onClick={() => {
                    handleClickPage('navigator')
                  }}
                  className={`w-[80px] parent-point-alert ${
                    pageMessage === 'navigator' ? 'btn-active-page gradient-box' : 'btn-no-active'
                  }`}
                >
                  <span className="text-gradient text-sm font-medium font-Inter tracking-tighter">
                    ニワ丸
                  </span>
                  {unreadNavigator && <div className="point-alert" />}
                </button>
              </div>
            </MessageHeader>
          )}
        </div>
        {pageMessage === 'real' && (
          <div className="relative hidden-scroll">
            <MessageContent onScroll={handleScroll}>
              {(messages || []).map((item, idx) => (
                <MessageItem
                  idx={idx}
                  key={idx}
                  data={item}
                  onPin={() => {
                    handleChannelList()
                  }}
                />
              ))}
              <div ref={contentRef} className="h-0" />
            </MessageContent>
            {!isAtBottom && (
              <div className="absolute bottom-2 z-[999] left-1/2 transform -translate-x-1/2">
                {hasNotification && <NotificationIcon />}
                <img src={RainbowArrow} onClick={scrollToBottom} className="w-24" />
              </div>
            )}

            <TipsModal type="message" />
          </div>
        )}
        {pageMessage === 'story' && <StoryTalk />}
        {pageMessage === 'navigator' && (
          <div className="mt-4">
            <MessageNavigator />
          </div>
        )}
      </div>
    )
  }
}

export default MessageContainer

MessageContainer.propTypes = {
  tab: PropTypes.string.isRequired
}

const MessageContent = styled.div.attrs({
  className: 'px-[20px] h-[calc(100dvh-230px)] overflow-auto hidden-scroll'
})``

const MessageHeader = styled.div.attrs({
  className:
    'fixed h-[143px] bg-white w-full top-0 z-[10] sm:w-[400px] flex items-end justify-center pb-2 overflow-hidden relative'
})`
  background-image: url(${BGHeader});
  background-size: cover;
`
