import React from 'react'
import PropTypes from 'prop-types'
import ModalCommon from 'src/components/common/ModalCommon'
import { closeModalButton } from 'src/constants/button_close'
import mascotChara from 'src/assets/img/character/mascot/good_mood.png'

const ModalPassport = ({ open, onClose }) => {
  if (!open) return <></>

  return (
    <>
      <ModalCommon
        title="機能セーブ中"
        open={open}
        onClose={onClose}
        buttons={[closeModalButton('閉じる', onClose)]}
      >
        <div className="relative space-y-4 bg-white px-1 pt-6 pb-12 text-center text-[15px] text-[#484848] font-bold">
          <p>
            プラチナパスポートを
            <br />
            お持ちでない為、一部機能をご利用
            <br />
            いただけないコケ…！
          </p>
          <p>
            ショップからプラチナパスポートをご
            <br />
            入いただくと、「LIVE配信」にて
            <br />
            ・コメントの送信
            <br />
            ・ギフトアイテムプレゼント
            <br />
            ・時間延長の砂の使用
            <br />
            が可能となり、視聴時間の制限もなく
            <br />
            なるコケ♪
          </p>
          <p>
            ショップで購入した時点から適用され
            <br />
            るので、今購入すると今日から配信を
            <br />
            100％楽しめるコケ♪
          </p>
          <img className="absolute bottom-1 right-4 w-[73px]" src={mascotChara} />
        </div>
      </ModalCommon>
    </>
  )
}

ModalPassport.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

export { ModalPassport }
