import { Switch } from '@headlessui/react'
import PropTypes from 'prop-types'

const ToggleSwitch = ({ onChange, srLabel, checked }) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={`${
        checked ? 'bg-menu-dark-blue' : 'bg-gray-200'
      } relative inline-flex h-7 w-14 items-center transition duration-200 ease-in-out rounded-full`}
    >
      <span className="sr-only">{srLabel}</span>
      <span
        className={`${
          checked ? 'translate-x-7' : 'translate-x-1'
        } inline-block h-6 w-6 transform rounded-full bg-white transition duration-200 ease-in-out`}
      />
    </Switch>
  )
}

ToggleSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  srLabel: PropTypes.string.isRequired,
  checked: PropTypes.bool
}

export default ToggleSwitch
