import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

export const getScheduleMonth = async (type) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Live.ScheduleMonth, {
      params: { type: type }
    })

    return res?.data || null
  } catch {
    return null
  }
}
