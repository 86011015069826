import React from 'react'
import { Navigate } from 'react-router-dom'
import CapsuleToyContainer from 'src/pages/main/capsule/CapsuleToyContainer'
import InProgress from 'src/pages/main/inProgress/InProgress'
import StoryTalkDetail from 'src/pages/main/storyTalk/StoryTalkDetail'
import { DmTalkDetail } from 'src/pages/main/dmTalk/DmTalkDetail'
import { DMTalk } from 'src/pages/main/dmTalk/DMTalk'
import { MyPage } from 'src/pages/main/MyPage'
import ShopWebMoney from 'src/pages/main/shop/components/ShopDiamondTab/ShopWebMoney'
import ShopPage from 'src/pages/main/shop/ShopPage'
import MiniGame from 'src/pages/MiniGame'
import MiniGamePlay from 'src/pages/MiniGame/play'
import NoContent from 'src/pages/NoContent'
import { LoadingPayment } from 'src/pages/main/LoadingPayment'

const MAIN_PATHS = {
  MessagePage: '/talk_story',
  RealtimePage: '/talk_real',
  MessageDetailPage: '/talk_real/detail/:characterId',
  CapsuleToyPage: '/capsule',
  RealTalkDetailPage: '/talk_story/detail/:characterId',
  NavigatorPage: '/talk_real/navigator/:characterId',
  MyPage: '/mypage',
  ShopPage: '/shop/buy',
  ShopPageSwap: '/shop/swap',
  InProgress: '/in-progress',
  NoContent: '/no-content',
  MiniGame: '/mini-game',
  MiniGamePlay: '/mini-game/play',
  ShopWebMoney: '/payments/paymentOrderWebmoney',
  LoadingPayment: '/loading-payment'
}

const MainRouter = [
  { path: MAIN_PATHS.MessagePage, element: <DMTalk tab="story" /> },
  { path: MAIN_PATHS.RealtimePage, element: <DMTalk tab="real" /> },
  { path: MAIN_PATHS.NavigatorPage, element: <DMTalk tab="navigator" /> },
  { path: MAIN_PATHS.MessageDetailPage, element: <DmTalkDetail /> },
  { path: MAIN_PATHS.CapsuleToyPage, element: <CapsuleToyContainer /> },
  { path: MAIN_PATHS.RealTalkDetailPage, element: <StoryTalkDetail /> },
  { path: MAIN_PATHS.MyPage, element: <MyPage /> },
  { path: MAIN_PATHS.ShopPage, element: <ShopPage tab="buy" /> },
  { path: MAIN_PATHS.ShopPageSwap, element: <ShopPage tab="swap" /> },
  { path: MAIN_PATHS.ShopWebMoney, element: <ShopWebMoney /> },
  { path: MAIN_PATHS.InProgress, element: <InProgress /> },
  { path: MAIN_PATHS.NoContent, element: <NoContent /> },
  { path: MAIN_PATHS.MiniGame, element: <MiniGame /> },
  { path: MAIN_PATHS.MiniGamePlay, element: <MiniGamePlay /> },
  { path: MAIN_PATHS.LoadingPayment, element: <LoadingPayment /> }
]

const NavigateToMyPage = [{ path: '*', element: <Navigate to={MAIN_PATHS.MyPage} /> }]

export { MainRouter, NavigateToMyPage, MAIN_PATHS }
