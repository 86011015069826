// @ts-nocheck
import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { authActionTypes } from './actionTypes'
import loginByLine from 'src/services/get/getUserInfoByLine'
import liff from '@line/liff'
import styled from 'styled-components'
import useLoading from 'src/components/layouts/hooks/useLoading'
import Loading from 'src/components/layouts/Loading'
import { authInitState } from 'src/providers/AuthProviders/initState'
import { authReducer } from 'src/providers/AuthProviders/reducers'
import patchUser from 'src/services/patch/patchUser'
import getUserInfo from 'src/services/get/getUserInfo'
import { getAccessToken } from 'src/services/common/storage'

const LIFF_ID = process.env.REACT_APP_LIFF_ID
const AuthContext = React.createContext(authInitState)

/**
 * @returns {{ isLoggedIn: boolean, user: object }}
 */
const useAuthState = () => {
  const { authDispatch, ...authState } = React.useContext(AuthContext)

  return authState
}

/**
 * @returns {(actions: {type: string, payload?: any}) => void}
 */
const useAuthDispatch = () => {
  const { authDispatch } = React.useContext(AuthContext)

  return authDispatch
}

const Wrapper = styled.div.attrs({
  className: 'sm:w-[400px] w-screen h-screen mx-auto relative text-center bg-center bg-cover bg-no-repeat'
})`
`

const AuthProvider = ({ children }) => {
  const [initializing, setInitializing] = React.useState(true)
  const [authState, authDispatch] = React.useReducer(authReducer, authInitState)

  const checkLoginByAccessToken = useCallback(async () => {
    try {
      const token = getAccessToken()
      if (!token) {
        return authDispatch({ type: authActionTypes.LOGOUT_USER })
      }
      const res = await getUserInfo()
      if (!res || typeof res.session_token === 'undefined') {
        authDispatch({ type: authActionTypes.LOGOUT_USER })
      } else {
        authDispatch({ type: authActionTypes.LOGIN_USER, payload: res })
        return setInitializing(false)
      }
    } catch (e) {
      console.log('[[GET USER INFO ERROR]]: ', e)
      authDispatch({ type: authActionTypes.LOGOUT_USER })
      setInitializing(false)
    }
  }, [])

  const loginWithLine = useCallback(async () => {
    if (window.location.pathname === '/login') {
      return setInitializing(false)
    }
    if (window.location.pathname === '/liff') {
      return setInitializing(false)
    }
    try {
      await liff.init({ liffId: LIFF_ID })
      const isLoggedIn = liff.isLoggedIn()
      if (!isLoggedIn) {
        liff.login()
        setInitializing(false)
        return
      }
      const idToken = liff.getIDToken()
      const accessToken = liff.getAccessToken()
      localStorage.setItem('idTokenRegister', idToken)
      localStorage.setItem('accessTokenRegister', accessToken)
      const res = await loginByLine(accessToken, idToken)
      if (!res || typeof res.session_token === 'undefined') {
        authDispatch({ type: authActionTypes.LOGOUT_USER })
      } else {
        liff.getFriendship().then((data) => {
          patchUser(res?.id, {
            is_add_friend: data?.friendFlag || false
          })
        }).catch(() => { })
        authDispatch({ type: authActionTypes.LOGIN_USER, payload: res })
      }
    } catch (e) {
      console.log('[[LOGIN LINE ERROR]]: ', e)
      checkLoginByAccessToken().finally(() => setInitializing(false))
    }
    setInitializing(false)
  }, [])

  useEffect(() => {
    const codeManagerId = new URLSearchParams(location.search).get('adcd') || ''
    if (codeManagerId) {
      localStorage.setItem('code_manager_id', new URLSearchParams(location.search).get('adcd') || '')
      localStorage.setItem('xuid', new URLSearchParams(location.search).get('xuid') || '')
    }
    loginWithLine()
  }, [])

  const { showLoading, progress } = useLoading([initializing])

  if (showLoading) {
    return (
      <Wrapper>
        <Loading progress={progress} />
      </Wrapper>
    )
  }

  return (
    <AuthContext.Provider value={{ ...authState, authDispatch: authDispatch }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export { AuthProvider, useAuthState, useAuthDispatch, authActionTypes }
