import { Carousel } from 'react-responsive-carousel'
import PropTypes from 'prop-types'
import useMultiAudio from 'src/components/common/UseMultiAudio'
import { useCallback, useEffect } from 'react'

import BtnBefore from 'src/assets/img/common/pink_backward_rounded_vector.png'
import BtnNext from 'src/assets/img/common/pink_forward_rounded_vector.png'

const MangaContents = ({
  data = [],
  currPage,
  setCurrPage,
  limitPageNumber,
  isOpen,
  toggleModal
}) => {
  const [players, toggle] = useMultiAudio(data.map((item) => item?.audio?.url || ''))

  useEffect(() => {
    players.forEach((player, idx) => {
      if (player.playing) {
        const togglePlayer = toggle(idx)
        togglePlayer()
      }
    })
  }, [currPage])

  const handleTap = useCallback((e, callback) => {
    e.preventDefault()
    callback && callback()
  }, [])

  const renderArrowNext = useCallback(
    (clickHandler, hasPrev) => {
      const handleClick = () => {
        if (limitPageNumber && limitPageNumber <= currPage) {
          return toggleModal()
        }

        if (currPage < data.length) setCurrPage(currPage + 1)
        setTimeout(() => {
          clickHandler()
        }, 100)
      }
      return (
        <>
          {hasPrev ? (
            <button
              onClick={(e) => handleTap(e, handleClick)}
              className="cursor-pointer w-[27dvw] h-8 absolute z-10 bottom-[0.5dvh]"
              style={{
                left: 'calc(50% + 55px)'
              }}
            >
              <img className="!w-4 h-6 absolute top-1.5 left-0" src={BtnNext} alt="next" />
            </button>
          ) : null}
        </>
      )
    },
    [currPage, isOpen]
  )

  const renderArrowPrev = useCallback(
    (clickHandler, hasNext) => {
      const handleClick = () => {
        if (currPage !== 0) setCurrPage(currPage - 1)
        setTimeout(() => {
          clickHandler()
        }, 100)
      }
      return (
        <>
          {hasNext ? (
            <button
              onClick={(e) => handleTap(e, handleClick)}
              className="cursor-pointer w-[27dvw] h-8 absolute z-10 bottom-[0.5dvh]"
              style={{
                right: 'calc(50% + 50px)'
              }}
            >
              <img className="!w-4 h-6 absolute right-0 top-1.5" src={BtnBefore} alt="before" />
            </button>
          ) : null}
        </>
      )
    },
    [currPage]
  )
  return (
    <>
      <Carousel
        swipeable={false}
        autoPlay={false}
        infiniteLoop={false}
        showThumbs={false}
        showStatus={false}
        showArrows={true}
        showIndicators={false}
        renderArrowPrev={renderArrowPrev}
        renderArrowNext={renderArrowNext}
        className="w-5/6 h-full mx-auto overflow-y-auto hidden-scroll"
      >
        {data.map((item, idx) => {
          if (currPage + 1 < item.page) return false
          return (
            <div key={idx} className="w-fit overflow-y-auto h-[90%] mx-auto">
              <img src={item.manga_url} className="w-fit h-full mx-auto" />
            </div>
          )
        })}
      </Carousel>
      <p className="absolute bottom-[0.5dvh] left-[50%] text-xl font-semibold text-candy-pink font-Inter">
        {currPage}
      </p>
    </>
  )
}

MangaContents.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  currPage: PropTypes.number,
  setCurrPage: PropTypes.func,
  limitPageNumber: PropTypes.number,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func
}

export default MangaContents
