import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

export const fetchDetailGacha = createAsyncThunk(
  'data/fetchDetailGacha',
  async (id) => {
    const response = await axiosInstance.get(API_ROUTES.GachasId.replace(':id', id))
    return response.data.items
  }
)

export const getDetailGacha = createSlice({
  name: 'getDetailGacha',
  initialState: {
    gacha: {},
    isLoading: true,
    errorMessage: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDetailGacha.pending, (state) => {
        state.isLoading = true
      })

    builder
      .addCase(fetchDetailGacha.fulfilled, (state, action) => {
        state.isLoading = false
        state.gacha = action.payload
      })

    builder
      .addCase(fetchDetailGacha.rejected, (state, action) => {
        state.isLoading = false
        state.errorMessage = action.payload
      })
  }
})

export default getDetailGacha.reducer
