import React, { useCallback } from 'react'
import ShopBlock from 'src/pages/main/shop/components/ShopBlock'
import PropTypes from 'prop-types'

import DialogBox from 'src/components/common/DialogBox'
import ShopItem from 'src/pages/main/shop/components/ShopDiamondTab/ShopItem'
import ShopMethodSelectModal from 'src/pages/main/shop/components/ShopDiamondTab/ShopMethodSelectModal'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'

const ShopDiamondTab = ({ data }) => {
  const { setSelectedItem, setModalContent } = useShopContext()

  const handleSelect = useCallback(
    (item) => {
      setSelectedItem(item)
      setModalContent(<ShopMethodSelectModal description={item.explanatory_text} />)
    },
    [data]
  )

  return (
    <div className="flex flex-col items-center justify-center gap-4 pt-6">
      {data?.map((shop, i) => {
        const purchaseLimit = shop.purchase_limit
        const purchaseLimitText = `${purchaseLimit}回限り`

        return (
          <div className="relative w-full" key={i}>
            {purchaseLimit && (
              <DialogBox
                text={purchaseLimitText}
                position="-top-1 left-[18.5%]"
                boxShadow="2px 2px #FFFFFF"
              />
            )}
            <ShopBlock boxShadow>
              <ShopItem handleSelect={() => handleSelect(shop)} shopDetails={shop} />
            </ShopBlock>
          </div>
        )
      })}
    </div>
  )
}

ShopDiamondTab.propTypes = {
  data: PropTypes.array
}

export default ShopDiamondTab
