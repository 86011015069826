import { createSlice } from '@reduxjs/toolkit'

const liveList = createSlice({
  name: 'liveList',
  initialState: {
    firstLive: null,
    secondLive: null
  },
  reducers: {
    setFirstLive: (state, action) => {
      state.firstLive = action.payload
    },
    setSecondLive: (state, action) => {
      state.secondLive = action.payload
    }
  }
})

export const { setFirstLive, setSecondLive } = liveList.actions
export default liveList.reducer
