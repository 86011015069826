import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { getRankingDisplay } from 'src/lib/getRankDisplay'

import CharacterRankingItem from 'src/components/ranking/CharacterRankingItem'

const RankingTabItem = ({ item }) => {
  const [rankTag, setRankTag] = useState('')

  useEffect(() => {
    if (item.ranking_in_all) {
      const rankingDisplay = getRankingDisplay(item.ranking_in_all, item.total_love, true)
      setRankTag(rankingDisplay)
    }
  }, [item.ranking_in_all, item.total_love])

  return (
    <Wrapper>
      <div>
        <CharacterRankingItem infor={item} currentPage="ranking" />
      </div>

      <div className="flex items-center gap-3 text-cornflower-blue">
        {rankTag && (
          <>
            <p className="text-[0.625rem] font-medium font-MPlus1">{rankTag}</p>
            <p className="text-black font-Inter font-bold">{item.ranking_in_all}</p>
            <p className="text-[0.625rem]">位</p>
          </>
        )}
        <p className={`font-Roboto ${item.color} pt-1 ${item.rank > 3 && 'text-sm'}`}>
          {item.total_love} PT
        </p>
      </div>
    </Wrapper>
  )
}

RankingTabItem.propTypes = {
  item: PropTypes.shape({
    rank: PropTypes.number.isRequired,
    character_id: PropTypes.number.isRequired,
    position: PropTypes.number,
    color: PropTypes.string.isRequired,
    total_love: PropTypes.number.isRequired,
    ranking_in_all: PropTypes.number
  }).isRequired,
  activeKey: PropTypes.string
}

const Wrapper = styled.li.attrs({
  className:
    'flex justify-between w-full border-b border-[#E1EFFF] px-8 pb-4 font-Inter items-center mt-2'
})``

export default RankingTabItem
