import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

const ShopBlock = ({ children, boxShadow }) => {
  return (
    <div
      className={clsx(
        'rounded-md w-5/6 m-auto overflow-hidden'
      )}
      style={{
        boxShadow: boxShadow ? '0px 4px 8px 0px #00000026, 0px 0px 1px 0px rgba(0, 0, 0, 0.2)' : 'none'
      }}
    >
      <div className="bg-[#FFFAFD]">{children}</div>
    </div>
  )
}

ShopBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  boxShadow: PropTypes.bool
}

export default ShopBlock
