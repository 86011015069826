import { useQuery } from 'react-query'
import { getShops } from 'src/services/get/getShops'

const useShopQuery = (userId, enabled) => {
  return useQuery({
    // queryFn: getShops(userId),
    queryFn: getShops,
    queryKey: 'get-shops',
    enabled: userId && enabled
  })
}

export { useShopQuery }
