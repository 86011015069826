import React from 'react'
import { DuringDelivery } from '../pages/voiceLive/pages/DuringDelivery'
import { LiveSchedulePage } from 'src/components/scheduleLivestream'

const VOICE_LIVE_PATH = {
  DuringDelivery: '/live/:title/detail/:channel_id',
  LiveSchedulePage: '/live_schedule',
  LiveScheduleNextPage: '/live_schedule_next'
}

const VoiceLivePath = [
  { path: VOICE_LIVE_PATH.DuringDelivery, element: <DuringDelivery /> },
  { path: VOICE_LIVE_PATH.LiveSchedulePage, element: <LiveSchedulePage /> },
  { path: VOICE_LIVE_PATH.LiveScheduleNextPage, element: <LiveSchedulePage /> }
]

export { VoiceLivePath, VOICE_LIVE_PATH }
