import { useNavigate } from 'react-router-dom'

const useNavigateBack = (backPath) => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(backPath ?? -1)
  }

  return handleBack
}

export default useNavigateBack
