import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Countdown from 'react-countdown'
import { formattedCountDownTime } from 'src/lib/date'
import styled from 'styled-components'

const LiveExtraTime = ({ time, className }) => {
  const [isVisible, setIsVisible] = useState(new Date(time) > new Date())

  return (
    <>
      {isVisible && (
        <Bubble
          className={clsx(
            'w-fit flex items-center gap-0.5 text-white bg-[#FF4747CC] rounded-xl px-2 py-0.5 -mt-0',
            className
          )}
        >
          <div className="text-[9px] font-medium">EXTRA TIMEまで</div>
          <span className="text-[11px] font-semibold">
            <Countdown
              date={new Date(time)}
              onComplete={() => setIsVisible(false)}
              renderer={({ hours, minutes, seconds }) => {
                return <>{formattedCountDownTime(hours, minutes, seconds)}</>
              }}
            />
          </span>
        </Bubble>
      )}
    </>
  )
}

LiveExtraTime.propTypes = {
  time: PropTypes.string,
  className: PropTypes.string
}

export { LiveExtraTime }

const Bubble = styled.div.attrs({
  className: ''
})`
  &::before {
    content: '';
    position: absolute;
    top: -4px;
    right: 20%;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 5px solid #ff4747cc;
  }
`
