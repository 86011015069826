import React from 'react'

import { OpeningStoryPage } from 'src/pages/newUser/OpeningStory'
import { SideStoryPageDetail } from 'src/pages/menu/SideStoryPageDetail'
const SIDE_STORY_PATHS = {
  OpeningStory: '/opening-story',
  SideStory: '/side-story/:id'
}

const EventRouter = [
  { path: SIDE_STORY_PATHS.OpeningStory, element: <OpeningStoryPage /> },
  { path: SIDE_STORY_PATHS.SideStory, element: <SideStoryPageDetail /> }
]

export { EventRouter, SIDE_STORY_PATHS }
