import { useState } from 'react'
import { DRAW_TYPE } from 'src/models/GachaInfo'
import PropTypes from 'prop-types'
import CapsuleCurrencyChangeDisplay from 'src/pages/main/capsule/CapsuleCurrencyChangeDisplay'
import ModalCommon from 'src/components/common/ModalCommon'

const CapsuleConfirmScreen = ({
  currencyType,
  currencyText,
  drawType,
  handleClose,
  handleCancel,
  handleConfirm,
  redCount,
  blueCount,
  ticketCount,
  ssrCount
}) => {
  const genConfirmText = () => {
    let d
    switch (drawType) {
      case DRAW_TYPE.Free:
        return '無料ガチャを1回引きますか？'
      case DRAW_TYPE.Eleven:
        d = '11'
        break
      case DRAW_TYPE.One:
      default:
        d = '1'
        break
    }

    const c = currencyText

    return `${c}で\nガチャを${d}回引きますか？`
  }

  const genButtons = () => {
    if (drawType === DRAW_TYPE.Free) {
      return [
        {
          label: 'キャンセル',
          type: 'close',
          onClick: () => {
            handleClose()
          }
        },
        {
          label: 'OK',
          onClick: () => {
            handleConfirm(true)
            setCheckModal(false)
          }
        }
      ]
    } else {
      return [
        {
          label: 'キャンセル',
          type: 'close',
          onClick: () => {
            handleCancel()
          }
        },
        {
          label: '決定',
          onClick: () => {
            handleConfirm(true)
            setCheckModal(false)
          }
        }
      ]
    }
  }

  const [checkModal, setCheckModal] = useState(true)

  return (
    <>
      {checkModal && (
        <ModalCommon buttons={genButtons()} title="確認" onClose={handleClose}>
          <div className="bg-white flex flex-col gap-2 items-center justify-center py-4">
            <div>
              <div className="font-bold text-center whitespace-pre-line text-[0.9375rem] text-cornflower-blue">
                {genConfirmText()}
              </div>
            </div>
            {drawType !== DRAW_TYPE.Free && (
              <CapsuleCurrencyChangeDisplay
                currencyType={currencyType}
                currencyText={currencyText}
                redCount={redCount}
                blueCount={blueCount}
                ticketCount={ticketCount}
                ssrCount={ssrCount}
              />
            )}
          </div>
        </ModalCommon>
      )}
    </>
  )
}

CapsuleConfirmScreen.propTypes = {
  currencyType: PropTypes.number.isRequired,
  currencyText: PropTypes.string.isRequired,
  drawType: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  redCount: PropTypes.number.isRequired,
  blueCount: PropTypes.number.isRequired,
  ticketCount: PropTypes.number.isRequired,
  ssrCount: PropTypes.number.isRequired
}

export default CapsuleConfirmScreen
