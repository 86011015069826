import { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import { MainLayout } from 'src/components/layouts/MainLayout'
import StandardPageWrapper from 'src/components/common/StandardPageWrapper'

import ShopPaymentButtons from 'src/pages/main/shop/components/ShopDiamondTab/ShopPaymentButtons'

import FloatBackButton from 'src/assets/img/common/float_back_btn.png'

const paymentTitle = {
  career: 'キャリア決済',
  credit: 'クレジット決済',
  paypay: 'Paypay',
  web_money: 'WebMoney',
  amazon_pay: 'Amazon Pay',
  bitcash: 'ビットキャッシュ'
}

const Wrapper = styled.div.attrs({
  className: ''
})`
  height: calc(100vh - 130px);
`

const ShopPaymentMethodPicker = ({ method, description }) => {
  const { closeModal, selectedItem, setModalType } = useShopContext()

  useEffect(() => {
    const shopModal = document.querySelector('.shop-modal')
    const className = shopModal?.getAttribute('class')
    shopModal?.removeAttribute('class')

    return () => {
      shopModal?.setAttribute('class', className || '')
    }
  }, [])

  useEffect(() => {
    setModalType('screen')
  })

  const handleBack = () => {
    setModalType('window')
    closeModal()
  }

  return (
    <>
      <MainLayout active="shop" classNameHeader="">
        <StandardPageWrapper
          className="relative h-full flex flex-col px-4 pt-12 pb-4 text-candy-pink font-semibold font-Inter"
          overflow={true}
        >
          <Wrapper>
            <div className="relative text-center mt-1">
              <button type="button" onClick={handleBack} className="absolute left-0 -top-2">
                <img src={FloatBackButton} className="w-[64px]" alt="return button" />
              </button>
              <h1 className="text-white text-xl bg-candy-pink rounded-[3rem] px-4 text-center inline-block">
                {paymentTitle[method]}
              </h1>
            </div>

            <div
              className="w-full p-4 overflow-y-scroll text-left bg-white/80 hidden-scroll mt-4 flex flex-col gap-2"
              style={{ height: 'calc(100vh - 190px)' }}
            >
              <h3 className="text-left text-xl">購入アイテム</h3>
              <div className="flex items-center gap-10">
                <img src={selectedItem.icon.url} className="w-28 h-20" />
                <div className="flex flex-col text-[0.875rem] text-bubblegum-pink">
                  <p className="leading-4">{selectedItem.first_name}</p>
                  <p className="leading-4">{selectedItem.second_name}</p>
                  <p className="leading-8">価格 {Number(selectedItem.listed_price).toLocaleString()}円</p>
                </div>
              </div>

              <h3 className="text-left text-xl">商品詳細</h3>
              <p className="whitespace-pre-wrap text-[0.875rem] text-cotton-candy-pink px-3">
                {selectedItem.explanatory_text}
              </p>
              <div className="flex flex-col gap-4 my-8">
                <ShopPaymentButtons method={method} />
              </div>

              <h3 className="text-left text-xl mb-2">資金決済法に基づく表記</h3>
              <ul className="text-[0.875rem] text-cotton-candy-pink px-6 list-disc">
                <li>商品の品質上、購入後の返品、キャンセルは出来ませんので予めご了承下さい。</li>
              </ul>
            </div>
          </Wrapper>
        </StandardPageWrapper>
      </MainLayout>
    </>
  )
}

ShopPaymentMethodPicker.propTypes = {
  method: PropTypes.string.isRequired,
  description: PropTypes.string
}

export default ShopPaymentMethodPicker
