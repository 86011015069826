import { useQuery } from 'react-query'
import { getHeartExchangeConfigs } from 'src/services/get/getHeartExchangeConfigs'

const useHeartExchangeList = (enabled) => {
  return useQuery({
    queryFn: () => getHeartExchangeConfigs(),
    queryKey: 'get-heart-exchange-configs',
    enabled: enabled
  })
}

export { useHeartExchangeList }
