import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setIsNewMessageInquiry } from 'src/redux/notfication/getNotifications'
import { ActionCableContext } from 'src/providers/ActionCableProvider'

const useUserContactListChannel = () => {
  const cable = useContext(ActionCableContext)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!cable) return

    const channel = cable.subscriptions.create(
      { channel: 'UserContactChannel' },
      {
        received: (msg) => {
          dispatch(setIsNewMessageInquiry(Boolean(msg?.is_new_message)))
        }
      }
    )

    return () => {
      channel.unsubscribe()
    }
  }, [cable, dispatch])
}

export default useUserContactListChannel
