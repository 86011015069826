import PropTypes from 'prop-types'

const TitleHeader = ({ title, className = 'mb-[1.7rem]' }) => {
  return (
    <div
      className={`px-6 py-1.5 w-fit min-w-[200px] mx-auto ${className}`}
    >
      <h3 className="text-2xl font-bold text-center leading-[126%] truncate text-candy-pink">
        {title}
      </h3>
    </div>
  )
}

TitleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default TitleHeader
