import React, { createRef, useContext, useEffect, useRef, useState } from 'react'
import { Wrapper } from 'src/components/common/Wrapper'
import moment from 'moment'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'

import { DEFAULT_GUEST_IMAGES, SCHEDULE_CHARACTER_IMAGES } from 'src/constants/characters'
import { queryClient } from 'src/providers/QueryProvider'
import { HistoryRouteContext } from 'src/routes'
import { VOICE_LIVE_PATH } from 'src/routes/live'
import { getScheduleMonth } from 'src/services/get/getScheduleMonth'
import ScheduleLayout from 'src/components/scheduleLivestream/ScheduleLayout'
import ItemSchedule from 'src/components/myPage/livestream/ItemSchedule'
import Loading from 'src/components/layouts/Loading'

import ArrowUp from 'src/assets/img/mypage/schedule/arrow_up.png'
import Calendar from 'src/assets/img/mypage/schedule/calendar.png'
import ChickenIcon from 'src/assets/img/chicken-icon.png'
import useLoading from 'src/components/layouts/hooks/useLoading'

export const LiveSchedulePage = () => {
  const location = useLocation()
  const { redirectTo } = useContext(HistoryRouteContext)
  const { data: scheduleMonthData, isLoading } = useQuery(
    [`liveSchedules/${location.state?.tab}`, location.state?.tab],
    () => getScheduleMonth(location.state?.tab || 'current_month')
  )
  const { showLoading, progress } = useLoading([isLoading])
  const [scheduleMonth, setScheduleMonth] = useState()
  const [description, setDescription] = useState([])
  const [showButtons, setShowButtons] = useState(false)
  const [activeTab, setActiveTab] = useState('current_month')

  const containerRef = useRef(null)
  const refs = useRef({})

  useEffect(() => {
    queryClient.invalidateQueries('liveSchedules/current_month')
    queryClient.invalidateQueries('liveSchedules/next_month')
  }, [])

  useEffect(() => {
    if (location.state?.tab) {
      setActiveTab(location.state.tab)
    }
  }, [location])

  useEffect(() => {
    if (scheduleMonthData) {
      if (scheduleMonthData.schedule_description) {
        setDescription(scheduleMonthData.schedule_description.split(/\r?\n/))
      } else {
        setDescription([])
      }
      const filteredScheduleMonthData = Object.keys(scheduleMonthData)
        .filter((key) => key !== 'schedule_description')
        .reduce((obj, key) => {
          obj[key] = scheduleMonthData[key]
          return obj
        }, {})
      setScheduleMonth(filteredScheduleMonthData)
      Object.keys(filteredScheduleMonthData).forEach((key) => {
        refs.current[key] = createRef()
      })
    }
  }, [scheduleMonthData])

  useEffect(() => {
    if (activeTab === 'current_month') {
      scrollToDate()
    } else {
      scrollToTop('auto')
    }
  }, [scheduleMonth])

  const toggleButtons = () => {
    setShowButtons(!showButtons)
  }

  const scrollToTop = (behavior) => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: behavior
      })
    }
  }

  const scrollToDate = () => {
    if (activeTab === 'next_month') {
      redirectTo(VOICE_LIVE_PATH.LiveSchedulePage, { state: { tab: 'current_month' } })
    } else if (scheduleMonth) {
      const currentDate = moment().format('YYYY-MM-DD')
      const isFirstKeyA = Object.keys(scheduleMonth)[0] === 'currentDate'
      if (isFirstKeyA) {
        scrollToTop('smooth')
      } else if (containerRef.current && refs.current[currentDate]?.current) {
        scrollToElementWithBrakeEffect(containerRef.current, refs.current[currentDate].current)
      }
    }
  }

  const scrollToElementWithBrakeEffect = (container, targetElement) => {
    targetElement.scrollIntoView({
      behavior: 'smooth'
    })

    function checkIfScrollFinished() {
      const targetPosition = targetElement.getBoundingClientRect().top
      const containerPosition = container.getBoundingClientRect().top

      if (Math.abs(targetPosition - containerPosition) < 1) {
        container.removeEventListener('scroll', checkIfScrollFinished)

        const initialScrollTop = container.scrollTop
        smoothScrollTo(container, initialScrollTop - 16, 800)
      }
    }

    container.addEventListener('scroll', checkIfScrollFinished)
  }

  const smoothScrollTo = (container, targetPosition, duration) => {
    const startScrollTop = container.scrollTop
    const distance = targetPosition - startScrollTop
    let startTime = null

    const animation = (currentTime) => {
      if (!startTime) startTime = currentTime
      const elapsed = currentTime - startTime

      const progress = Math.min(elapsed / duration, 1)
      const easeOut = 1 - Math.pow(1 - progress, 3)

      container.scrollTo(0, startScrollTop + distance * easeOut)
      if (elapsed < duration) {
        requestAnimationFrame(animation)
      }
    }

    requestAnimationFrame(animation)
  }

  if (showLoading) {
    return (
      <Wrapper>
        <Loading progress={progress} />
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <ScheduleLayout
        title="配信スケジュール"
        activetab={activeTab}
        classNameHeader="!bg-gradient-sky-to-pink"
      >
        <LiveScheduleMain ref={containerRef}>
          <div className="font-bold mt-4 text-[#484848] text-[22px]">
            {moment()
              .add(activeTab === 'current_month' ? 0 : 1, 'months')
              .format('YYYY年MM月')}
          </div>
          <div className="flex flex-col px-2">
            {scheduleMonth &&
              Object.entries(scheduleMonth)
                .sort(([dateA], [dateB]) => {
                  return new Date(dateA) - new Date(dateB)
                })
                .map(([key, schedules]) => (
                  <div key={key} ref={refs.current[key]} className="flex gap-1 py-4">
                    <DateDiv>
                      <span className="text-xl text-white">{new Date(key).getDate()}</span>
                      <div className="w-4 bg-white rounded-full text-[10px] text-[#6F9BFF]">月</div>
                    </DateDiv>
                    <div className="flex flex-col items-center gap-3 flex-1">
                      {schedules.map((schedule, index) => {
                        const character =
                          SCHEDULE_CHARACTER_IMAGES[schedule.character_id] ||
                          DEFAULT_GUEST_IMAGES[schedule.character_id]
                        return (
                          <ItemSchedule key={index} schedule={schedule} character={character} />
                        )
                      })}
                    </div>
                  </div>
                ))}
          </div>
          <div className="absolute bottom-3 right-5 z-10">
            <div className="relative">
              <button
                className="absolute bottom-0 right-0 z-20 flex items-center justify-center w-12 h-12 rounded-full text-white text-[36px] font-[900] shadow-md"
                onClick={toggleButtons}
                style={{
                  background: 'linear-gradient(to right, #C0E4FF 0, #D3B8FF 100%)'
                }}
              >
                ?
              </button>
              {showButtons && (
                <>
                  <div
                    className="absolute bottom-0 right-0 flex items-center gap-1 pl-1.5 pr-[1px] py-[1px] rounded-[24.5px]"
                    style={{ background: 'linear-gradient(to bottom, #D9D9D9, #A2AFB3)' }}
                  >
                    <ActionButton onClick={() => scrollToTop('smooth')}>
                      <img src={ArrowUp} className="w-6 object-cover" />
                    </ActionButton>
                    <div className="w-12 h-12 rounded-full" />
                  </div>
                  <div
                    className="absolute bottom-0 right-0 flex flex-col items-center gap-1 pt-1.5 pb-[1px] px-[1px] rounded-[24.5px]"
                    style={{ background: 'linear-gradient(to left, #D9D9D9, #A2AFB3)' }}
                  >
                    <ActionButton onClick={() => scrollToDate()}>
                      <img src={Calendar} className="w-full object-cover" />
                      <span className="absolute top-[15px] font-[900] text-white text-[11px]">
                        {new Date().getDate()}
                      </span>
                    </ActionButton>
                    <div className="w-12 h-12 rounded-full" />
                  </div>
                </>
              )}
            </div>
          </div>
          {description.length > 0 && (
            <div className="relative my-7 px-7">
              <Bubble>
                {description.map((item, index) => (
                  <div key={index} className="flex items-start gap-2">
                    <span className="bullet">•</span>
                    <div className="inline-block text-left">{item}</div>
                  </div>
                ))}
              </Bubble>
              <img src={ChickenIcon} className="w-30 float-right rotate-12 mt-6" />
            </div>
          )}
        </LiveScheduleMain>
      </ScheduleLayout>
    </Wrapper>
  )
}

const LiveScheduleMain = styled.div.attrs({
  className: 'h-full overflow-y-auto hidden-scroll'
})``

const DateDiv = styled.div.attrs({
  className: 'flex flex-col items-center font-semibold w-9 rounded-xl shrink-0'
})`
  background: linear-gradient(to bottom, #6f9bff, #dde8ff);
`

const Bubble = styled.div.attrs({
  className:
    'relative p-5 bg-white border-t-2 border-l-2 border-r-[3px] border-b-[3px] border-[#6F9BFF] rounded-2xl leading-5 text-base font-semibold text-[#6F9BFF]'
})`
  &::before,
  &::after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    transform: skew(25deg);
  }

  &::before {
    right: 30%;
    bottom: -30px;
    border: 15px solid;
    border-color: #6f9bff #6f9bff transparent transparent;
  }

  &::after {
    right: calc(30% + 7px);
    bottom: -22px;
    border: 15px solid;
    border-color: #fff #fff transparent transparent;
  }
`

const ActionButton = styled.button.attrs({
  className: 'relative flex items-center justify-center w-10 h-10 rounded-full'
})`
  background: linear-gradient(to bottom, #000000, #666666);
`
