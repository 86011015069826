import aoiDance from 'src/assets/img/mypage/recommend/group/aoi_dance_logo.png'
import d6Logo from 'src/assets/img/mypage/recommend/group/d6_logo.png'
import thearerGroupej from 'src/assets/img/mypage/recommend/group/theater_troupej_logo.png'

export const GROUP = [
  {
    name: 'dx6',
    japaneseName: 'Dx6',
    groupLogo: d6Logo
  },
  {
    name: 'theater_groupej',
    japaneseName: '劇団J\'s',
    groupLogo: thearerGroupej
  },
  {
    name: 'aoi_dance',
    japaneseName: '蒼生演舞',
    groupLogo: aoiDance
  },
  {
    name: 'solo',
    japaneseName: 'solo'
  }
]
