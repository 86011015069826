import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { HistoryRouteContext } from 'src/routes'
import { MAIN_PATHS } from 'src/routes/main'
import ModalCommon from 'src/components/common/ModalCommon'

const InsufficientDiamondModal = ({ open, onClose }) => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const modalButtons = [
    {
      label: 'キャンセル',
      onClick: () => onClose(),
      type: 'close'
    },
    {
      label: '決定',
      onClick: () => redirectTo(MAIN_PATHS.ShopPage)
    }
  ]

  return (
    <ModalCommon title="ダイヤ購入" open={open} onClose={onClose} buttons={modalButtons}>
      <div className="bg-white p-4 text-sm text-center flex flex-col gap-2">
        <p className="text-black text-[17px] font-semibold">ショップページへ移動します。</p>
        <p className="text-black text-[17px] font-semibold mt-3 mb-2">
          LIVE配信のページを離れても、
          <br />
          配信中のカウントダウンタイ
          <br />
          マーは一時停止しません。
        </p>
        <p className="text-[#6F9BFF] text-[13px] font-medium bg-[#F8F8F8] rounded-lg py-2">
          ※Extraタイム中は特に注意が必要だコ
          <br />
          ケッ!
          <br />
          ショップページから赤ダイヤをご購入し、
          <br />
          LIVE配信ページへ戻ってくる間にカウント
          <br />
          ダウンタイマーの残り時間が0秒になった
          <br />
          場合、再度LIVE配信ベージに入場する事
          <br />
          が出来なくなるコケッ
        </p>
      </div>
    </ModalCommon>
  )
}

InsufficientDiamondModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

export { InsufficientDiamondModal }
