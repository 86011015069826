import { useEffect, useState } from 'react'
import { getConditions } from 'src/services/get/getConditions'

import LegaleseWrapper from 'src/components/layouts/LegaleseWrapper'
import { MainLayout } from 'src/components/layouts/MainLayout'
import SubmenuHeader from 'src/components/menu/SubmenuHeader'
import MenuWrapper from 'src/components/menu/MenuWrapper'

export const SpecifiedCommercialPage = () => {
  const [law, setLaw] = useState('')

  const fetchCondition = async () => {
    const data = await getConditions('law')
    const content = data.find((item) => item?.condition?.status === 'is_public')?.condition?.content
    setLaw(content)
  }

  useEffect(() => {
    fetchCondition()
  }, [])

  return (
    <>
      <MainLayout className="bg-menu-bg h-full" active="menu" classNameHeader="">
        <MenuWrapper className="flex items-center flex-col" overflow={true}>
          <SubmenuHeader title="特定商取引法" />
          <LegaleseWrapper content={law} />
        </MenuWrapper>
      </MainLayout>
    </>
  )
}
