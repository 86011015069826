import PropTypes from 'prop-types'

export const LvCharacter = ({ level, name, icon }) => {
  return (
    <div className="relative">
      <div
        style={{ padding: '0 6px 0 2px' }}
        className="flex items-center justify-around rounded-l-2xl h-[32px] bg-white min-w-[100px] font-MPlus1"
      >
        <span>{name}</span>
        <div className="flex flex-col h-full">
          <span className="text-[7px] text-[#FF75C0]">LOVE度</span>
          <div
            className="flex h-full items-center justify-center relative text-center"
            style={{ backgroundImage: `url(${icon})`, backgroundRepeat: 'no-repeat' }}
          >
            <span className="font-Inter leading-[0px] text-white text-xs absolute left-1/2 -translate-x-1/2 transform top-2.5">{level}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

LvCharacter.propTypes = {
  level: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired
}
