import { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { countCharacters } from 'src/lib/validation'

import { ErrorMessage } from 'src/components/ErrorMessage'

const UserNameEditBox = ({ errors, setErrors, editUserName, setEditUserName }) => {
  useEffect(() => {
    handleNameChange(editUserName)
  }, [editUserName])

  const handleNameChange = useCallback(
    (value) => {
      if (countCharacters(value) <= 16) {
        setEditUserName(value)
        setErrors({})
      } else {
        setErrors({ ...errors, name: ['全角8文字以内/半角16文字以内で入力してください。'] })
      }
    },
    [setEditUserName, setErrors, editUserName]
  )

  return (
    <div className="py-8 px-4 w-full flex flex-col justify-center items-center z-10 bg-white text-candy-pink text-base font-semibold font-Inter">
      <span className="text-sm text-left w-full">彼に呼ばれたい名前を入力しよう！</span>
      <input
        type="text"
        name="username"
        id="username"
        aria-label="username"
        className="placeholder-[#FFE5F3] mt-4 p-2 bg-white border border-candy-pink focus:outline-none rounded-md w-full drop-shadow-md"
        placeholder="朝日空"
        value={editUserName}
        onChange={(e) => handleNameChange(e.target.value)}
      />
      <ErrorMessage errors={errors} field="name" />
      <span className="text-[0.625rem] mt-2 w-full">全角8文字以内/半角16文字以内</span>
    </div>
  )
}

UserNameEditBox.propTypes = {
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  editUserName: PropTypes.string,
  setEditUserName: PropTypes.func.isRequired
}

export default UserNameEditBox
