import React, { useEffect, useState } from 'react'
import { MainLayout } from 'src/components/layouts/MainLayout'
import Loading from 'src/components/layouts/Loading'
import styled from 'styled-components'
import MenuBar from 'src/components/menu/MenuBar'
import StandardizedButton from 'src/components/common/StandardizedButton'
import { useNavigate } from 'react-router-dom'
import { MAIN_PATHS } from 'src/routes/main'
import IdolEvent from 'src/models/Event'
import InnerHTML from 'dangerously-set-html-content'
import useLoading from 'src/components/layouts/hooks/useLoading'

import BgCommon from 'src/assets/img/common/bg_common.png'
import BtnBack from 'src/assets/img/common/btn_back.png'
import HeaderReward from 'src/assets/img/ranking/reward/header-reward.jpg'
import { useRankingRewardEventDetails } from 'src/services/hooks/useFetchRankingRewardEvents'

export const RankingRewardPage = () => {
  const [event, setEvent] = useState({})
  const { data: details = {}, isFetched, isFetching } = useRankingRewardEventDetails()
  // const time = details ? `${moment(details.start_time).format('YYYY年MM月DD日')}~${moment(details.end_time).format('YYYY年MM月DD日')}` : ''
  const isLoading = !isFetched && isFetching
  const navigate = useNavigate()

  useEffect(() => {
    handleDataEvent(details?.event || {})
    if (!isFetched || (details.id && details?.event?.is_active)) return
    navigate(MAIN_PATHS.NoContent)
  }, [isFetched])

  const handleReturnButton = () => { navigate(-1) }

  const handleDataEvent = (data) => {
    // eslint-disable-next-line camelcase
    const { id, name, image_url, start_time, end_time, article_content_html, created_at, updated_at } = data
    setEvent(new IdolEvent(id, name, image_url, start_time, end_time, article_content_html, created_at, updated_at))
  }

  const { showLoading, progress } = useLoading([isLoading])

  return (
    <Wrapper>
      <Header>
        <MenuBar title="event" />
      </Header>
      <MainLayout className="relative h-fit reward-page" active="mypage" showHeader={false} showFooter={true}>
        <div className='content'>
          <div>
            <img className='w-full h-full aspect-[2/1] object-cover' src={event?.image || HeaderReward} alt="" onError={(e) => { e.target.src = HeaderReward }}/>
          </div>

          {/* <div className="h-full my-[20px] px-[25px]">
            <div className="border-2 text-sm font-bold border-[#433f9c] grid grid-flow-row-dense grid-cols-3">
              <span className="bg-white text-[#433f9c] content-center ">開催期間</span>
              <span className="text-white col-span-2 bg-[#433f9c] ">{time}</span>
            </div>
          </div> */}
          {event?.articleContentHtml ? (
            <InnerHTML className="min-h-[35vh] h-full my-[20px] px-[25px] pointer-events-none" html={event?.articleContentHtml}/>
          ) : <></>}
        </div>

        <StandardizedButton
          img={BtnBack}
          alt="決済ページに戻る"
          className="w-32"
          onClick={handleReturnButton}
        />

        <div className="h-full pb-[112px] px-[14px]">
          {showLoading && <Loading progress={progress} />}
        </div>
      </MainLayout>
    </Wrapper>
  )
}

const Header = styled.div.attrs({
  className: 'relative w-full h-[80px] z-50'
})``

const Wrapper = styled.div.attrs({
  className: 'sm:w-desktop-width w-screen h-screen mx-auto relative bg-center bg-no-repeat bg-cover'
})`
  background-image: url(${BgCommon});
`
