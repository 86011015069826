export const CATEGORIES = [
  {
    id: 0,
    name: 'ダイヤ購入に関する不具合'
  },
  {
    id: 1,
    name: 'ゲームに関する不具合'
  },
  {
    id: 2,
    name: 'ログインや機種変更に関するお問い合わせ'
  },
  {
    id: 3,
    name: '【返信なし】キャラクターの発言に関してのご意見やご要望'
  },
  {
    id: 4,
    name: '【返信なし】ゲーム内仕様に関するご質問やご意見'
  },
  {
    id: 5,
    name: 'その他のお問い合わせ'
  },
  {
    id: 7,
    name: 'サポートからのお知らせ'
  }
]

export const Tabs = [
  {
    key: 'current_day',
    name: '今日',
    tag: '当日貢献度'
  },
  {
    key: 'current_week',
    name: '今週',
    tag: '週間貢献度'
  },
  {
    key: 'previous_week',
    name: '先週',
    tag: '先週貢献度'
  },
  {
    key: 'current_month',
    name: '今月',
    tag: '月間貢献度'
  },
  {
    key: 'previous_month',
    name: '先月',
    tag: '先月貢献度'
  }
]
