export const animateSlider = (carousel, rotateHandler) => {
  const rotateInt = parseInt(carousel?.dataset?.rotateInt || 0)
  const inner = carousel?.querySelector('.inner-carousel')
  const cards = carousel?.querySelectorAll('.inner-carousel > div')

  const size = cards?.length
  const panelSize = inner?.clientWidth
  const translateZ = Math.round(panelSize / 2 / Math.tan(Math.PI / size)) * 1.7
  const ry = 360 / size

  for (let i = 0; i < size; i++) {
    const z = rotateInt * ry + i * ry
    const child = cards[i]
    child.style.transform = `rotateY(${z}deg) translateZ(${translateZ}px) rotateY(${-z}deg)`
    child.classList.remove('clockwise', 'front', 'counterclockwise')

    child.removeEventListener('click', rotateHandler)

    const zz = z % 360

    if (zz === 0) {
      child.classList.remove('animation-slide')
      child.classList.add('front')
    } else if (zz === ry || zz === -360 + ry) {
      child.classList.add('clockwise')
      child.classList.remove('animation-slide')
      child.style.transform += ' translateY(42px) translateX(20px)'
    } else if (zz === 360 - ry || zz === 0 - ry) {
      child.classList.add('counterclockwise')
      child.classList.remove('animation-slide')
      child.style.transform += ' translateY(42px) translateX(-20px)'
    }
    if (
      !(
        child.classList.contains('clockwise') ||
        child.classList.contains('front') ||
        child.classList.contains('counterclockwise')
      )
    ) {
      child.classList.add('animation-slide')
    }
  }
}
