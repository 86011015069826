import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const getUnreadNews = async (userId) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.News.getNews, {
      params: { user_id: userId, action_type: 'get_unread' }
    })
    if (res.data.success === false) return false

    return res.data.has_unread_news
  } catch (error) {
    console.log(error)
    return false
  }
}

export default getUnreadNews
