import PropTypes from 'prop-types'
import ModalCommon from '../common/ModalCommon'
import { closeModalButton } from 'src/constants/button_close'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { MAIN_PATHS } from 'src/routes/main'

const NoContent = ({
  isModal = false,
  title = 'リンクを開けません',
  message = '探していたコンテンツが見つかりませんでした',
  handleCloseModal = null,
  redirectUrl = MAIN_PATHS.MyPage,
  replace = true
}) => {
  const navigate = useNavigate()

  const defaultCloseModal = () => {
    navigate(redirectUrl, { replace: replace })
  }

  return isModal ? (
    <ModalCommon
      title={<span className="text-[20px]">{title}</span>}
      open={true}
      onClose={() => handleCloseModal ? handleCloseModal() : defaultCloseModal()}
      buttons={[closeModalButton('OK', handleCloseModal || defaultCloseModal)]}
    >
      <div className={clsx('space-y-4 bg-[#FFFFFF] px-4 py-4 text-center mb-[16px] font-bold text-[#6F9BFF]')}>
        <p className={clsx('whitespace-pre-line', 'text-center', 'text-[17px]')}>{message}</p>
      </div>
    </ModalCommon>
  ) : (
    <div className="bg-white/50 py-12 px-4 w-full text-2xl rounded-md font-vldLineGR whitespace-pre-line">
      {message}
    </div>
  )
}

NoContent.propTypes = {
  isModal: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  handleCloseModal: PropTypes.func,
  redirectUrl: PropTypes.string,
  replace: PropTypes.bool
}

export default NoContent
