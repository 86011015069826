import { useState, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import useLoading from 'src/components/layouts/hooks/useLoading'

import { useGetSideStoryDetail } from 'src/pages/menu/hooks/useGetSideStoryDetail'
import { useGetSideStoryList } from 'src/pages/menu/hooks/useGetSideStoryList'

import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import Loading from 'src/components/layouts/Loading'
import SubmenuHeader from 'src/components/menu/SubmenuHeader'
import ModalCommon from 'src/components/common/ModalCommon'
import SettingsModal from 'src/components/Manga/SettingModal'
import Pagination from 'src/components/common/Pagination'
import { Wrapper } from 'src/components/common/Wrapper'

import GearIcon from 'src/assets/img/common/gear_icon.png'

export const SideStoryPageDetail = () => {
  const { id } = useParams()
  const ref = useRef(null)
  const { data: stories = [], isLoading } = useGetSideStoryDetail(id)
  const { data: sideStoryList = [], isLoadingList } = useGetSideStoryList()
  const [title, setTitle] = useState('')
  const [currPage, setCurrPage] = useState(1)
  const [setting, setSetting] = useState({
    bgm: true
  })
  const { showLoading, progress } = useLoading([isLoading])
  const [settingForm, setSettingForm] = useState({ ...setting })

  useEffect(() => {
    const story = sideStoryList.find((story) => story.id === Number(id))
    setTitle(story?.name)
  }, [sideStoryList, isLoadingList])
  const currentStory = useMemo(() => stories[currPage - 1] || null, [stories, currPage])

  const [isBGMModalOpen, setIsBGMModalOpen] = useState(false)

  useEffect(() => {
    if (!ref.current) return
    if (setting.bgm) {
      ref.current.play()
    } else {
      ref.current.pause()
    }
  }, [ref.current, setting, currentStory])

  const bgmModalButtons = [
    {
      label: 'OK',
      onClick: () => {
        setSetting(settingForm)
        setIsBGMModalOpen(false)
      }
    }
  ]

  if (showLoading) {
    return (
      <Wrapper>
        <Loading progress={progress} />
      </Wrapper>
    )
  }

  return (
    <>
      {currentStory?.bgmUrl && (
        <audio ref={ref} src={currentStory?.bgmUrl} autoPlay loop preload="auto" />
      )}
      {isBGMModalOpen && (
        <ModalCommon
          open={isBGMModalOpen}
          onClose={() => setIsBGMModalOpen(false)}
          title={'設定'}
          buttons={bgmModalButtons}
          theme="pink"
        >
          <SettingsModal theme="pink" setting={settingForm} setSetting={setSettingForm} />
        </ModalCommon>
      )}
      <MainLayout active="menu" classNameHeader={''}>
        <MenuWrapper className="flex flex-col" overflow={true}>
          <>
            <SubmenuHeader title={title || ''} />
            <div className="hidden-scroll overflow-y-auto h-[calc(100dvh-220px)]">
              <div className="text-candy-pink font-Inter font-medium text-[0.875rem] mb-4 px-4 text-start">
                {currentStory?.description &&
                  currentStory.description
                    .split(/\r?\n/)
                    .map((item, index) => <p key={index}>{item}</p>)}
              </div>
              <Pagination
                totalPage={stories.length}
                currPage={currPage}
                theme="pink"
                setCurrPage={setCurrPage}
              />
            </div>
            <button
              className="absolute bottom-20 right-5"
              onClick={() => setIsBGMModalOpen(true)}
              style={{
                cursor: 'pointer',
                opacity: 0.8,
                transition: 'opacity 0.3s ease'
              }}
            >
              <img src={GearIcon} />
            </button>
          </>
        </MenuWrapper>
      </MainLayout>
    </>
  )
}
