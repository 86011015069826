import PropTypes from 'prop-types'
import GoodFeeling from 'src/assets/img/character/mascot/good_mood.png'
import ModalCommon from 'src/components/common/ModalCommon'

const ModalSuccess = ({ isOpen = false, text = '', img = GoodFeeling, handleClose }) => {
  return (
    <ModalCommon
      theme="pink"
      title={'確認'}
      open={isOpen}
      onClose={handleClose}
      buttons={[
        {
          onClick: handleClose,
          label: '閉じる'
        }
      ]}
    >
      <div className="py-8 px-4 w-full flex flex-col justify-center items-center z-10 bg-white font-Inter whitespace-pre-line">
        <span className="font-bold text-base text-[#333333] text-center">{text}</span>
        <img className="w-[115px] mb-3" src={img} alt="in-progress" />
      </div>
    </ModalCommon>
  )
}

ModalSuccess.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.string,
  handleClose: PropTypes.func.isRequired
}

export default ModalSuccess
