import { useState } from 'react'
import PropTypes from 'prop-types'

function SwitchButton({ theme = 'blue', isOn, onToggle }) {
  return (
    <div
      onClick={onToggle}
      className={`w-16 h-8 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300  ${
        isOn ? 'bg-cornflower-blue' : 'bg-gray-300'
      }`}
    >
      <div
        className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform duration-300 ${
          isOn ? 'translate-x-8' : 'translate-x-0'
        }`}
      />
    </div>
  )
}

function SettingsModal({ theme = 'blue', setting, setSetting }) {
  const toggleSetting = (key) => {
    setSetting((prevSetting) => ({
      ...prevSetting,
      [key]: !prevSetting[key]
    }))
  }
  const [textStyle] = useState(theme === 'blue' ? 'text-cornflower-blue' : 'text-candy-pink')

  return (
    <div className="flex flex-col items-center bg-white font-Inter font-semibold py-5 text-xl">
      {Object.keys(setting).map((key) => (
        <div
          key={key}
          className="flex items-center space-x-4 my-2 w-3/4 justify-between bg-[#F8F8F8] px-4 py-1 rounded-lg"
        >
          <span className={`${textStyle}`}>{key.toUpperCase()}</span>
          <SwitchButton isOn={setting[key]} onToggle={() => toggleSetting(key)} theme={theme} />
        </div>
      ))}
    </div>
  )
}

SettingsModal.propTypes = {
  theme: PropTypes.string,
  setting: PropTypes.object.isRequired,
  setSetting: PropTypes.func.isRequired
}

SwitchButton.propTypes = {
  theme: PropTypes.string,
  isOn: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
}

export default SettingsModal
