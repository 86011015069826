import React, { useEffect, useRef, useState, useContext } from 'react'
import { HistoryRouteContext } from 'src/routes'
import { animateSlider } from 'src/lib/animationSlider'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import '../components/CardSlide.css'
import { MAIN_PATHS } from 'src/routes/main'
import NotificationIcon from 'src/components/common/NotificationIcon'
function Carousel({
  mypageImage,
  updateRecommendById,
  currentImage,
  message,
  messages,
  miniRecommendMan
}) {
  const divRef = useRef(null)
  const { redirectTo } = useContext(HistoryRouteContext)
  const [characterId, setCharacterId] = useState(currentImage)
  const [imgActive, setImgActive] = useState()
  const [imgLeft, setImgLeft] = useState()

  useEffect(() => {
    if (updateRecommendById) {
      updateRecommendById(mypageImage)
    }
  }, [currentImage])

  const rotateHandler = (evt) => {
    const carousel = evt.currentTarget.closest('.card-carousel')
    const rotateInt = parseInt(carousel.dataset.rotateInt || 0)
    let direction
    const newArr = mypageImage
    const currentIndex = mypageImage.findIndex((d) => d.recommend === true)
    if (evt.currentTarget.classList.contains('counterclockwise')) {
      direction = -1
      newArr[currentIndex].recommend = false
      currentIndex === newArr.length - 1
        ? (newArr[0].recommend = true)
        : (newArr[currentIndex + 1].recommend = true)
      const getId = newArr.find((item) => item.recommend === true)
      setCharacterId(getId.id)
      setImgActive(getId.imgButton)
      setImgLeft(getId.imgLeft)
    } else if (evt.currentTarget.classList.contains('clockwise')) {
      direction = 1
      newArr[currentIndex].recommend = false
      currentIndex === 0
        ? (newArr[newArr.length - 1].recommend = true)
        : (newArr[currentIndex - 1].recommend = true)
      const getId = newArr.find((item) => item.recommend === true)
      setCharacterId(getId.id)
      setImgActive(getId.imgButton)
      setImgLeft(getId.imgLeft)
    }
    carousel.dataset.rotateInt = rotateInt + direction
    animateSlider(carousel, rotateHandler)
    updateRecommendById(newArr)
  }

  useEffect(() => {
    if (mypageImage) {
      const carousels = document.querySelectorAll('.card-carousel')
      carousels?.forEach((carousel) => {
        const inner = carousel.querySelector('.inner-carousel')
        const cards = carousel.querySelectorAll('.inner-carousel > div')
        const size = cards.length
        const panelSize = inner.clientWidth
        const translateZ = Math.round(panelSize / 2 / Math.tan(Math.PI / size)) * 1.7
        inner.style.transform = `rotateY(0deg) translateZ(-${translateZ}px)`
        animateSlider(carousel, rotateHandler)
      })
    }
  }, [mypageImage])

  function calculateSteps(currentIndex, targetIndex, arrayLength) {
    if (currentIndex === targetIndex) {
      return { step: 0, isNext: false }
    }

    const prev = (currentIndex - targetIndex + arrayLength) % arrayLength
    const next = (targetIndex - currentIndex + arrayLength) % arrayLength

    return next > prev ? { step: prev, isNext: false } : { step: next, isNext: true }
  }

  const rotateNext = (id) => {
    setCharacterId(id)
    const newArr = mypageImage
    const currentIndex = mypageImage.findIndex((d) => d.recommend === true)
    const targetIndex = mypageImage.findIndex((d) => d.id === id)
    newArr[currentIndex].recommend = false
    newArr[targetIndex].recommend = true
    setImgActive(newArr[targetIndex].imgButton)
    setImgLeft(newArr[targetIndex].imgLeft)
    const carousel = document.querySelector('.card-carousel')
    let rotateInt = parseInt(carousel.dataset.rotateInt || 0)
    const value = calculateSteps(currentIndex, targetIndex, newArr.length)
    if (value.isNext) {
      rotateInt -= value.step
    } else {
      rotateInt += value.step
    }
    carousel.dataset.rotateInt = rotateInt
    animateSlider(carousel, rotateHandler)
    updateRecommendById(newArr)
  }

  useEffect(() => {
    const checkIconNew = mypageImage.find((item) => item.id === characterId)
    const conversation = messages.find((x) => x.conversation_id === message?.conversation_id)
    if (conversation?.character?.id === characterId) {
      checkIconNew.unreadTotal = 1
    }
  }, [characterId, message])

  return (
    <>
      {mypageImage.length > 0 && (
        <ResponSiveDisplayScreen className="card-carousel" ref={divRef}>
          <ResponSiveDisplaySlide className="inner-carousel h-full !top-0">
            {mypageImage?.map((item, index) => (
              <ResponSiveDisplay
                characterId={item.id}
                key={index}
                style={{ filter: !item.recommend ? '' : '' }}
                className={`ml-2 ${item.recommend === true ? 'first-slide' : ''}`}
              >
                <img
                  className="img-slide h-full object-cover"
                  src={item.recommend === true ? item.imageActive : item.imageGray}
                />
              </ResponSiveDisplay>
            ))}
          </ResponSiveDisplaySlide>
          <ResponSiveDisplayButton className="flex justify-between items-center w-[100%] bottom-[55%] absolute px-16">
            <button onClick={rotateHandler} className=" z-[100] clockwise ">
              <img className="w-[40px]" src={imgLeft || mypageImage[0].imgLeft} />
            </button>
            <button onClick={rotateHandler} className=" z-[100] counterclockwise  ">
              <img className="w-[40px] rotate-180" src={imgLeft || mypageImage[0].imgLeft} />
            </button>
          </ResponSiveDisplayButton>
          <ReponsiveDisplayName className="w-full flex justify-center absolute bottom-[8dvh]">
            <button
              className="z-[9] w-[220px]"
              onClick={() =>
                redirectTo(MAIN_PATHS.RealTalkDetailPage.replace(':characterId', characterId))
              }
            >
              <img
                className="z-[999] h-[100px] w-[220px]"
                src={imgActive || mypageImage[0].imgButton}
              />
            </button>
          </ReponsiveDisplayName>
          <ResponSiveDisplayIcon className="absolute bottom-0 right-0 w-[58px] flex flex-col gap-1 justify-around items-center py-4 rounded-tl-3xl">
            {miniRecommendMan.map((item, index) => (
              <div key={index} className="relative" onClick={() => rotateNext(item.id)}>
                <ResponSiveDisplayIconImg src={item.imgIcon} className="w-[44px]" />
                {item.unreadTotal > 0 && <NotificationIcon />}
              </div>
            ))}
          </ResponSiveDisplayIcon>
        </ResponSiveDisplayScreen>
      )}
    </>
  )
}

const ResponSiveDisplay = styled.div`
  @media (max-width: 460px) {
    top: -8dvh !important;
    height: 48dvh;
    width: 100%;
  }

  @media (max-width: 430px) {
    top: -9dvh !important;
    height: 55dvh;
    width: 100%;
  }

  @media (max-width: 400px) {
    top: -10dvh !important;
    height: 53dvh;
    width: 100%;
  }

  @media (max-width: 380px) {
    top: -9dvh !important;
    height: 50dvh;
    width: 100%;
    left: -20px !important;
  }

  top: 2dvh !important;
  height: 46dvh;
  width: 100%;
`

const ResponSiveDisplayIcon = styled.div`
  background-image: linear-gradient(to right, #c0e4ff, #d3b8ff);
  background-size: cover;
  background-blend-mode: overlay;
`

const ResponSiveDisplayIconImg = styled.img``

const ResponSiveDisplaySlide = styled.div`
  @media (max-width: 375px) {
    top: -7dvh !important;
  }
`
const ResponSiveDisplayButton = styled.div``

const ResponSiveDisplayScreen = styled.div`
  @media (max-width: 375px) {
  }
`
const ReponsiveDisplayName = styled.div`
  @media (min-width: 461px) {
    bottom: 2dvh;
    height: 46dvh;
    width: 100%;
  }
`

Carousel.propTypes = {
  mypageImage: PropTypes.array,
  defaultValue: PropTypes.array,
  currentImage: PropTypes.number,
  updateRecommendById: PropTypes.func,
  setCurrentImage: PropTypes.func,
  messages: PropTypes.array,
  setRecommendMan: PropTypes.func,
  miniRecommendMan: PropTypes.array,
  message: PropTypes.any
}

export default Carousel
