import PropTypes from 'prop-types'
import clsx from 'clsx'

import TalentItem from 'src/components/myPage/recommend/TalentItem'

export default function TalentGroup({ characterList, className, logo, handleUpdateRecommendMan }) {
  return (
    <div className={clsx('space-y-3', className)}>
      <div className="mx-auto flex items-center justify-center">
        {logo && <img src={logo} alt="group icon" className="h-[3.875rem] object-contain" />}
      </div>

      <div className="mx-[2.625rem] flex justify-center items-center flex-wrap gap-4">
        {characterList.map((character) => (
          <TalentItem
            key={character.id}
            character={character}
            handleUpdateRecommendMan={handleUpdateRecommendMan}
          />
        ))}
      </div>
    </div>
  )
}

TalentGroup.propTypes = {
  characterList: PropTypes.array,
  className: PropTypes.string,
  logo: PropTypes.string,
  handleUpdateRecommendMan: PropTypes.func
}
