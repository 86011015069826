import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import background from 'src/assets/img/bg-header.png'
import BackButton from 'src/components/common/BackButton'
import folderIcon from 'src/assets/img/menu/tab_icon/folderIcon.png'
import speakerIcon from 'src/assets/img/menu/tab_icon/speakerIcon.png'
import mangaIcon from 'src/assets/img/menu/tab_icon/mangaIcon.png'
import fileIcon from 'src/assets/img/menu/tab_icon/fileIcon.png'
import checkIcon from 'src/assets/img/menu/tab_icon/checkIcon.png'
import infoIcon from 'src/assets/img/menu/tab_icon/infoIcon.png'
import userIcon from 'src/assets/img/menu/tab_icon/userIcon.png'
import talkIcon from 'src/assets/img/menu/tab_icon/talkIcon.png'

const SubmenuHeader = ({ title, className = '' }) => {
  const [icon, setIcon] = useState({})

  const iconGenerate = (submenuTitle) => {
    switch (submenuTitle) {
      case '所持アイテム':
        return {
          style: 'h-[70%] top-[30%] right-[0%]',
          image: folderIcon
        }
      case 'ボイスアルバム':
        return {
          style: 'h-[5rem] top-4 right-0.5',
          image: speakerIcon
        }
      case 'ストーリーを見る':
        return {
          style: 'h-20 bottom-0 right-0.5',
          image: mangaIcon
        }
      case '生い立ち漫画':
        return {
          style: 'h-[80%] top-[30%] right-0.5',
          image: mangaIcon
        }
      case 'オープニングストーリーを見る':
        return {
          style: 'h-[80%] top-[30%] right-0.5',
          image: mangaIcon
        }
      case '蒼生演舞特別ストーリー':
        return {
          style: 'h-[80%] top-[30%] right-0.5',
          image: mangaIcon
        }
      case '利用規約':
        return {
          style: 'h-[70%] top-[30%] right-0.5',
          image: fileIcon
        }
      case 'プライバシーポリシー':
        return {
          style: 'h-[70%] top-[30%] right-0.5',
          image: fileIcon
        }
      case '特定商取引法':
        return {
          style: 'h-[70%] top-[30%] right-0.5',
          image: fileIcon
        }
      case '通知設定':
        return {
          style: 'h-[78%] top-[25%] right-0',
          image: checkIcon
        }
      case 'ヘルプ・よくある質問':
        return {
          style: 'h-24 top-[25%] right-0.5',
          image: infoIcon
        }
      case 'ID確認・名前変更':
        return {
          style: 'h-[80%] top-[25%] right-0.5',
          image: userIcon
        }
      case 'お問い合わせ':
        return {
          style: 'h-[90%] top-[25%] right-0',
          image: talkIcon
        }
      default:
        return {
          style: 'h-[80%] top-[30%] right-0.5',
          image: mangaIcon
        }
    }
  }

  useEffect(() => {
    setIcon(iconGenerate(title))
  }, [title])

  return (
    <div
      className={`mt-2 bg-cover bg-no-repeat flex items-center relative pt-8 w-full aspect-[391/119] ${className} overflow-hidden`}
      style={{
        backgroundImage: `url(${background})`
      }}
    >
      <BackButton vectorColor="grey" />
      <p
        className={`${
          title.length < 10 ? 'text-[1.375rem]' : 'text-lg'
        }  font-bold z-20 w-2/5 text-left line-clamp-2`}
      >
        {title}
      </p>
      <img src={icon.image} alt={title} className={`absolute ${icon.style}`} />
    </div>
  )
}

SubmenuHeader.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default SubmenuHeader
