import { createContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MessageContainer from 'src/components/message/MessageContainer'
import PropTypes from 'prop-types'

export const DMTalkContext = createContext(null)

export const DMTalk = ({ tab }) => {
  const [loadingRecommendMan, setLoadingRecommendMan] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState(false)
  const [hideNav, setHideNav] = useState(false)
  useEffect(() => {
    if (tab !== 'story') {
      setHideNav(loadingMessage)
    } else {
      setHideNav([loadingRecommendMan, loadingMessage].some((state) => state))
    }
  }, [loadingRecommendMan, loadingMessage, tab])

  return (
    <DMTalkContext.Provider
      value={{ loadingRecommendMan, setLoadingRecommendMan, loadingMessage, setLoadingMessage, hideNav }}
    >
      <Wrapper>
        <MainLayout
          className="h-fit"
          active="talk"
          classNameHeader=""
          showHeader={!hideNav}
          showFooter={!hideNav}
        >
          <MessageContainer tab={tab} />
        </MainLayout>
      </Wrapper>
    </DMTalkContext.Provider>
  )
}

DMTalk.propTypes = {
  tab: PropTypes.string.isRequired
}

const Wrapper = styled.div.attrs({
  className:
    'sm:w-desktop-width w-screen h-screen mx-auto relative overflow-y-auto bg-center bg-no-repeat bg-cover'
})``
