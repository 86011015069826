import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { ModalPassport } from '../components/ModalPassport'
import BtnSend from '../../../assets/img/message/detail/send-btn.png'
import BtnSendDisable from '../../../assets/img/message/detail/send-btn-disable.png'

export const LiveInput = ({ normalPassportModal, canWorkWithFreePassport, onSend }) => {
  const inputRef = useRef(null)
  const [newMessage, setNewMessage] = useState('')
  const [inputHeight, setInputHeight] = useState(38)
  const [isStretch, setIsStretch] = useState(false)
  const [openModalPassport, setOpenModalPassport] = useState(false)
  const countMessage = 100 - (newMessage || '').length

  const handleNewMessageChange = (event) => {
    if (event.target.value.length > 100) return

    const el = event.target
    el.style.height = ''
    el.style.height = (el.scrollHeight || 38) + 'px'
    if (parseInt(el.style.height) > inputHeight) {
      setIsStretch(true)
    } else if (parseInt(el.style.height) < inputHeight) {
      setIsStretch(false)
    }
    setInputHeight(parseInt(el.style.height))
    setNewMessage(event.target.value || '')
  }

  const sendMsg = () => {
    if (!newMessage) return
    onSend?.(newMessage)
    setNewMessage('')
    setInputHeight(38)
    setIsStretch(false)
    document.getElementById('box-item').style.bottom = '82px'
  }

  useEffect(() => {
    const boxItem = document.getElementById('box-item')
    if (boxItem.style.bottom === '82px' && !isStretch) return
    if (isStretch) {
      boxItem.style.bottom = parseInt(boxItem.style.bottom) + 16 + 'px'
    } else {
      boxItem.style.bottom = parseInt(boxItem.style.bottom) - 16 + 'px'
    }
  }, [isStretch, inputHeight])

  return (
    <div className="relative z-[7] bg-[#FFFFFFB2] py-2 px-4 rounded-2xl">
      <div className="flex items-center gap-x-2">
        <div className="flex flex-col gap-1 flex-1 w-full">
          <textarea
            autoFocus
            id="input-message"
            maxLength={100}
            ref={inputRef}
            value={newMessage}
            onChange={handleNewMessageChange}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault()
                if (!normalPassportModal && !canWorkWithFreePassport) {
                  setOpenModalPassport(true)
                } else {
                  sendMsg()
                }
              }
            }}
            style={{
              boxShadow: 'inset 0px 1px 3px rgba(0, 0, 0, 0.25)',
              height: inputHeight
            }}
            className="w-full h-full rounded-[3px] px-3 py-2 placeholder-shown:text-[10px] placeholder-shown:text-[rgba(68,68,68,0.78)] placeholder-shown:font-light text-xs text-[#333333] font-light leading-[18px]"
            placeholder="メッセージを入力（最大100文字）"
            rows={1}
          />
          <div className="pt-1 text-left text-xs text-[#333333] leading-[15px] font-semibold">
            残り文字数<span className="text-[#FF0000]">{countMessage >= 0 ? countMessage : 0}</span>
            字
          </div>
        </div>
        <button
          className="w-[39px] h-[39px]"
          disabled={!newMessage.trim()}
          onClick={() => {
            if (!normalPassportModal && !canWorkWithFreePassport) {
              setOpenModalPassport(true)
            } else {
              sendMsg()
            }
          }}
        >
          <img
            className="object-contain w-full h-full"
            src={!newMessage.trim() ? BtnSendDisable : BtnSend}
            alt="send"
          />
        </button>
      </div>

      <ModalPassport open={openModalPassport} onClose={() => setOpenModalPassport(false)} />
    </div>
  )
}

LiveInput.propTypes = {
  normalPassportModal: PropTypes.bool,
  canWorkWithFreePassport: PropTypes.bool,
  onSend: PropTypes.func
}
