import { useState, useEffect, memo } from 'react'
import { Wrapper } from 'src/components/common/Wrapper'
import useLoading from 'src/components/layouts/hooks/useLoading'
import PropTypes from 'prop-types'
import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import Slider from 'react-slick'
import { DRAW_TYPE, GACHA_TYPES } from 'src/models/GachaInfo'
import FadeTransition from 'src/components/common/transitions/FadeTransition'
import { MainLayout } from 'src/components/layouts/MainLayout'
import StandardPageWrapper from 'src/components/common/StandardPageWrapper'
import Loading from 'src/components/layouts/Loading'
import CapsuleTab from 'src/pages/main/capsule/CapsuleTab'
// import CapsuleMixerTab from 'src/pages/main/capsule/CapsuleMixerTab'
import { Modal } from 'src/components/common/Modal'
import CapsuleDrawModal from 'src/pages/main/capsule/CapsuleDrawModal'
import Item, { ITEM_TYPE } from 'src/models/Item'
import TipsModal from 'src/components/common/TipsModal'
import GrowEffectModal from 'src/components/Effect/GrowEffectModal'
import Improgress from './components/Improgress'

import blueHeaderBackground from 'src/assets/img/menu/background/blue_header_background.png'
import returnIcon from 'src/assets/img/menu/icon/return_icon.png'

import 'src/pages/main/capsule/scroller.css'

// TODO: Uncomment new tabs when their designs are ready

const ThumbnailScroller = ({ gachas, onChangeTab }) => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    pauseOnHover: false,
    cssEase: 'linear',
    autoplaySpeed: 5000
  }

  return (
    <section className="z-10 thumbnail-scroller">
      <Slider {...settings}>
        {gachas.map((tab, index) => (
          <div key={tab.id} className="slider-item" onClick={() => onChangeTab(index)}>
            <img src={tab.banner_image} alt={tab.name} />
          </div>
        ))}
      </Slider>
    </section>
  )
}

const GachaHeader = memo(({ gachas, onChangeTab }) => {
  return (
    <div
      className="flex justify-end relative mt-3 aspect-[390/147]"
      style={{ backgroundImage: `url(${blueHeaderBackground})`, backgroundSize: 'cover' }}
    >
      <div className="flex flex-col w-full items-center relative">
        <div className="flex justify-between w-full z-10 pl-2 pr-4 items-center absolute top-[8%] xs:top-[15%] sm:top-[15%]">
          <h1 className="text-[#CFE5FF] font-extrabold text-[3.8125rem] font-Outfit">GACHA</h1>
          <h2 className="bg-cornflower-blue text-[1.375rem] font-bold font-Inter text-white rounded-[3rem] px-4">
            ガチャ
          </h2>
        </div>
        <div className="absolute bottom-0 z-10 w-[351px]">
          {gachas?.length > 3 ? (
            <ThumbnailScroller gachas={gachas} onChangeTab={onChangeTab} />
          ) : (
            <div className="justify-center flex gap-2">
              {gachas?.map((tab, index) => (
                <img
                  className="w-[107px]"
                  src={tab.banner_image}
                  key={tab.id}
                  alt={tab.name}
                  onClick={() => onChangeTab(index)}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <img src={returnIcon} className="absolute h-[100%] top-1" />
    </div>
  )
})

GachaHeader.displayName = 'GachaHeader'

const CapsuleToyPage = ({
  isLoading,
  itemsTicket,
  selectedIndex,
  setSelectedIndex,
  redCount,
  blueCount,
  gachas,
  setItemsUpdate,
  items
}) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const [showDrawModal, setShowDrawModal] = useState(false)
  const [drawType, setDrawType] = useState(DRAW_TYPE.Free)
  // const [mixerGacha, setMixerGacha] = useState(null)
  const [clothesGacha, setClothesGacha] = useState(null)
  const [itemsGacha, setItemsGacha] = useState(null)
  // const [scenesGacha, setScenesGacha] = useState(null)
  const [isVideoPlaying, setIsVideoPlaying] = useState(true)
  const [ticket, setTicket] = useState(false)
  const [idItem, setIdItem] = useState(0)
  const [rollFree, setRollFree] = useState([])
  const [countTimeRollCurrent, setcountTimeRoll] = useState({})
  const [gachaDetail, setGachaDetail] = useState({})
  const [regularTicket, setRegularTicket] = useState([])
  const [SSRTicket, setSSRTicket] = useState([])
  const [typeGacha, setTypeGacha] = useState('item')
  const [isGrowModalOpen, setIsGrowModalOpen] = useState(false)
  const [modals, setModals] = useState([])
  const [currentModal, setCurrentModal] = useState({})
  const [maxHeight, setMaxHeight] = useState('max-h-80')

  useEffect(() => {
    if (!gachas) return

    if (Array.isArray(gachas)) {
      // const mixer = gachas.filter(
      //   (el) => el.gacha_type === GACHA_TYPES.Mixer && el.status !== 'disable'
      // )
      const clothes = gachas.filter(
        (el) => el.gacha_type === GACHA_TYPES.Clothes && el.status !== 'disable'
      )
      const itemsGacha = gachas.filter(
        (el) => el.gacha_type === GACHA_TYPES.Items && el.status !== 'disable'
      )
      // const stills = gachas.filter(
      //   (el) => el.gacha_type === GACHA_TYPES.Still && el.status !== 'disable'
      // )
      // setMixerGacha(mixer)
      setClothesGacha(clothes)
      setItemsGacha(itemsGacha)
      setIdItem(itemsGacha[0]?.id)
      // setScenesGacha(stills)
    }
  }, [gachas])

  useEffect(() => {
    const filterTicketItems = itemsTicket?.filter(
      (el) => el.type === ITEM_TYPE.Special && el.itemKind === 'gacha_ticket'
    )

    const filterSSRItems = itemsTicket?.filter(
      (el) => el.type === ITEM_TYPE.Special && el.itemKind === 'ssr_ticket'
    )
    setRegularTicket(filterTicketItems)
    setSSRTicket(filterSSRItems)
  }, [itemsTicket])

  useEffect(() => {
    if (!currentModal) return
    currentModal.id && setIsGrowModalOpen(true)
  }, [currentModal])

  const openDrawModal = (type) => {
    setDrawType(type)
    setShowDrawModal(true)
  }

  const closeDrawModal = () => {
    setShowDrawModal(false)
  }

  const handleSetModalList = (res) => {
    const arr = []
    res.forEach((data) => {
      if (data.current_level < data.next_level) {
        arr.push(data)
      }
    })
    if (arr.length > 0) {
      setModals(arr)
      setCurrentModal(arr[0])
    }
  }

  const closeGrowModal = () => {
    if (modals && currentModal && currentModal.id !== modals[modals.length - 1].id) {
      const index = modals.findIndex((d) => d.id === currentModal.id)
      setIsGrowModalOpen(false)
      setTimeout(() => {
        setCurrentModal(modals[index + 1])
      }, 100)
    } else {
      setIsGrowModalOpen(false)
      setCurrentModal({})
    }
  }
  const { showLoading, progress } = useLoading([isLoading])
  if (showLoading) {
    return (
      <Wrapper>
        <Loading progress={progress} />
      </Wrapper>
    )
  }
  return (
    <>
      <MainLayout active="gacha" classNameHeader="">
        <StandardPageWrapper
          className={clsx('h-full flex flex-col', isLoading && 'justify-center p-4')}
        >
          <>
            <Tab.Group
              selectedIndex={selectedIndex}
              onChange={(index) => {
                setSelectedIndex(index)
                if (index === 0) {
                  setIdItem(clothesGacha[0]?.id)
                  setTypeGacha('cloth')
                }
                if (index === 1) {
                  setIdItem(itemsGacha[0]?.id)
                  setTypeGacha('item')
                }
              }}
            >
              <GachaHeader gachas={itemsGacha} onChangeTab={setSlideIndex} />
              <Tab.List className="h-auto mt-4 px-2 w-full flex gap-1 items-around" />

              {gachas && gachas.length === 0 ? (
                <Loading />
              ) : (
                <>
                  {/* {clothesGacha?.length > 0 && selectedIndex === 0 ? (
                      <CapsuleTab
                        gachas={clothesGacha}
                        isShown={selectedIndex === 0}
                        showDrawModal={openDrawModal}
                        setTicket={setTicket}
                        setIdItem={setIdItem}
                        rollFree={rollFree}
                        setGachaDetail={setGachaDetail}
                        gachaDetail={gachaDetail}
                        countTimeRollCurrent={countTimeRollCurrent}
                        setcountTimeRoll={setcountTimeRoll}
                      />
                    ) : (
                      <FadeTransition
                        show={selectedIndex === 0}
                        className="flex justify-center h-[100vh] flex-col p-4 pointer-events-none"
                      >
                        <Improgress />
                      </FadeTransition>
                    )} */}
                  {itemsGacha?.length > 0 && selectedIndex === 1 ? (
                    <CapsuleTab
                      slideIndex={slideIndex}
                      setSlideIndex={setSlideIndex}
                      gachas={itemsGacha}
                      isShown={selectedIndex === 1}
                      showDrawModal={openDrawModal}
                      setTicket={setTicket}
                      setIdItem={setIdItem}
                      rollFree={rollFree}
                      setGachaDetail={setGachaDetail}
                      gachaDetail={gachaDetail}
                      countTimeRollCurrent={countTimeRollCurrent}
                      setcountTimeRoll={setcountTimeRoll}
                    />
                  ) : (
                    <FadeTransition
                      show={selectedIndex === 1}
                      className="flex justify-center h-[100vh] flex-col p-4 pointer-events-none"
                    >
                      <Improgress />
                    </FadeTransition>
                    // <Loading />
                  )}
                  {/* {scenesGacha?.length > 0 && selectedIndex === 2 ? (
                      <CapsuleTab
                        gachas={scenesGacha}
                        isShown={selectedIndex === 2}
                        showDrawModal={openDrawModal}
                        setTicket={setTicket}
                      />
                    ) : (
                      <FadeTransition
                        show={selectedIndex === 2}
                        className="flex justify-center h-[100vh] flex-col p-4 pointer-events-none"
                      >
                        <Improgress />
                      </FadeTransition>
                    )}
                    {mixerGacha?.length > 0 && selectedIndex === 3 ? (
                      <CapsuleMixerTab
                        isShown={selectedIndex === 3}
                        showDrawModal={openDrawModal}
                        startDate={mixerGacha.startDate}
                        endDate={mixerGacha.endDate}
                        image={mixerGacha.image}
                        items={itemsTicket}
                        drawMixerGacha={drawMixerGacha}
                        resultItem={resultItems?.[0]}
                      />
                    ) : (
                      <FadeTransition
                        show={selectedIndex === 3}
                        className="flex justify-center h-[100vh] flex-col p-4 pointer-events-none"
                      >
                        <Improgress />
                      </FadeTransition>
                    )} */}
                </>
              )}
            </Tab.Group>
            <Modal
              maxHeight={maxHeight}
              focusOutside={false}
              open={showDrawModal}
              onClose={isVideoPlaying ? () => false : closeDrawModal}
              isVideoPlaying={isVideoPlaying}
              className="sm:w-[360px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg font-hiragino flex items-center justify-center"
            >
              <CapsuleDrawModal
                setMaxHeight={setMaxHeight}
                drawType={drawType}
                handleClose={closeDrawModal}
                redCount={redCount}
                blueCount={blueCount}
                ticketCount={regularTicket[0]?.amount || 0}
                ssrCount={SSRTicket[0]?.amount || 0}
                isVideoPlaying={isVideoPlaying}
                setIsVideoPlaying={setIsVideoPlaying}
                ticket={ticket}
                idItem={idItem}
                setRollFree={setRollFree}
                setGachaDetail={setGachaDetail}
                setItemsTicketUpdate={setItemsUpdate}
                itemsUpdate={itemsTicket}
                itemsTicketRoll={items}
                setcountTimeRoll={setcountTimeRoll}
                typeGacha={typeGacha}
                handleSetModalList={handleSetModalList}
              />
            </Modal>
            {currentModal && currentModal.id && (
              <GrowEffectModal
                character={currentModal}
                isGrowModalOpen={isGrowModalOpen}
                level={{
                  currentLevel: currentModal.current_level,
                  nextLevel: currentModal.next_level
                }}
                onClose={closeGrowModal}
              />
            )}
            <TipsModal type="capsule" />
          </>
        </StandardPageWrapper>
      </MainLayout>
    </>
  )
}

ThumbnailScroller.propTypes = {
  gachas: PropTypes.array,
  onChangeTab: PropTypes.func
}

GachaHeader.propTypes = {
  gachas: PropTypes.array,
  onChangeTab: PropTypes.func
}

CapsuleToyPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.instanceOf(Item)).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  redCount: PropTypes.number.isRequired,
  blueCount: PropTypes.number.isRequired,
  gachas: PropTypes.array,
  itemsTicket: PropTypes.array,
  setItemsUpdate: PropTypes.func
}

export default CapsuleToyPage
