import { useContext } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { HistoryRouteContext } from 'src/routes'
import useLoading from 'src/components/layouts/hooks/useLoading'

import { SIDE_STORY_PATHS } from 'src/routes/sidestory'

import { Wrapper } from 'src/components/common/Wrapper'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import MenuItem from 'src/components/menu/MenuItem'
import SubmenuHeader from 'src/components/menu/SubmenuHeader'
import Loading from 'src/components/layouts/Loading'

import { useGetSideStoryList } from 'src/pages/menu/hooks/useGetSideStoryList'

import bookIcon from 'src/assets/img/menu/book.png'

const MenuPart = ({ children, className }) => {
  return (
    <div
      className={clsx('mx-auto font-bold text-xs xs:text-sm flex flex-col items-center', className)}
    >
      {children}
    </div>
  )
}

export const SideStoryMenu = () => {
  const { data, isLoading, isError } = useGetSideStoryList()
  const { showLoading, progress } = useLoading([isLoading, isError])
  const { redirectTo } = useContext(HistoryRouteContext)
  const handleRedirect = (story) => {
    const path = SIDE_STORY_PATHS.SideStory.replace(':id', story.id)
    redirectTo(path)
  }

  if (showLoading) {
    return (
      <Wrapper>
        <Loading progress={progress} />
      </Wrapper>
    )
  }

  return (
    <>
      <MainLayout className="bg-menu-bg" active="menu" classNameHeader="">
        <MenuWrapper className="" overflow={true}>
          <SubmenuHeader title={'サイドストーリーを見る'} />
          <div className="overflow-y-auto calc(100dvh - 200px) hidden-scroll">
            <MenuPart>
              {data?.map((story) => (
                <MenuItem
                  handleRedirect={() => handleRedirect(story)}
                  src={bookIcon}
                  label={story.name}
                  to={SIDE_STORY_PATHS.SideStory.replace(':id', story.id)}
                  key={story.id}
                />
              ))}
            </MenuPart>
          </div>
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

MenuPart.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}
