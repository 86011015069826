import React, { forwardRef, useRef } from 'react'
import PropTypes from 'prop-types'
import HTMLReactParser from 'html-react-parser'

import { removeNewlineCode } from 'src/lib/string'

import arrowImg from 'src/assets/img/common/pink_upward_arrow.png'

const LegaleseWrapper = forwardRef(({
  content,
  children,
  theme = 'pink',
  margin = 'my-4',
  hideScrollTop = false
}, ref) => {
  const contentRef = useRef(null)

  React.useImperativeHandle(ref, () => ({
    scrollToTop: () => {
      contentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }))

  return (
    <div
      className="bg-white w-full flex flex-col items-center justify-center rounded-md"
      style={{ height: 'calc(100dvh - 200px)', position: 'relative' }}
    >
      <div
        className={`${
          theme === 'pink' ? 'text-candy-pink' : 'text-cornflower-blue'
        } pl-6 mr-4 font-Inter font-medium text-xs text-justify leading-6 overflow-y-auto whitespace-pre-line hidden-scroll text-[0.875rem]`}
      >
        <div ref={contentRef} />
        <div className={margin}>{HTMLReactParser(removeNewlineCode(content))}</div>
      </div>
      {children}
      {!hideScrollTop && (
        <button
          className="absolute bottom-0 right-5"
          onClick={() => {
            contentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }}
          style={{
            cursor: 'pointer',
            opacity: 0.8,
            transition: 'opacity 0.3s ease'
          }}
        >
          <img src={arrowImg} />
        </button>
      )}
    </div>
  )
})

LegaleseWrapper.propTypes = {
  content: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  theme: PropTypes.string,
  margin: PropTypes.string,
  hideScrollTop: PropTypes.bool
}

LegaleseWrapper.displayName = 'LegaleseWrapper'

export default LegaleseWrapper
