import PropTypes from 'prop-types'
import { useState, useContext, useMemo } from 'react'
import { HistoryRouteContext } from 'src/routes'

import ModalCommon from 'src/components/common/ModalCommon'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import MenuItem from 'src/components/menu/MenuItem'
import SubmenuHeader from 'src/components/menu/SubmenuHeader'
import MenuPart from 'src/pages/menu/MenuPart'

import bookIcon from 'src/assets/img/menu/book.png'

import { MANGA_PATHS } from 'src/routes/manga'
import { CHARACTERS } from 'src/constants/characters'

const BGMNotif = () => {
  return (
    <div className="bg-white font-semibold text-lg w-full py-6 text-candy-pink text-center">
      <h3>
        BGMが流れます。
        <br />
        音量にご注意ください。
      </h3>
    </div>
  )
}

export const MangaList = () => {
  const { redirectTo } = useContext(HistoryRouteContext)

  const [isOpen, setIsOpen] = useState(false)
  const [selectedLink, setSelectedLink] = useState('')

  const closeModal = () => {
    setIsOpen(false)
    setSelectedLink('')
  }

  const buttons = useMemo(
    () => [
      {
        type: 'close',
        label: 'キャンセル',
        onClick: () => {
          closeModal()
        }
      },
      {
        label: 'OK',
        onClick: () => {
          redirectTo(selectedLink)
        }
      }
    ],
    [selectedLink, closeModal]
  )

  const getMangaUrl = (id, name) => {
    return MANGA_PATHS.MangaList.replace(':id', id).replace(':name', name || 'noname')
  }

  const handleRedirect = (to) => {
    setIsOpen(true)
    setSelectedLink(to)
  }

  return (
    <>
      <MainLayout className="bg-menu-bg" active="menu" classNameHeader="">
        <MenuWrapper className="" overflow={true}>
          <SubmenuHeader title={'生い立ち漫画'} />
          <div className="overflow-y-auto mt-2 h-[calc(100dvh-220px)] hidden-scroll">
            <MenuPart>
              {isOpen && (
                <ModalCommon
                  theme="pink"
                  onClose={() => closeModal()}
                  title={'確認'}
                  buttons={buttons}
                  open={isOpen}
                >
                  <BGMNotif />
                </ModalCommon>
              )}
              {CHARACTERS.map((character) => {
                return (
                  character.id < 6 && (
                    <MenuItem
                      handleRedirect={handleRedirect}
                      onClick={handleRedirect}
                      key={character.id}
                      src={bookIcon}
                      label={character.name}
                      to={getMangaUrl(character.id, character.name)}
                    />
                  )
                )
              })}
            </MenuPart>
          </div>
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

MenuPart.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}
