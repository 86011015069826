import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useGetStoryDetail } from 'src/pages/newUser/hooks/useGetStoryDetail'
import { HistoryRouteContext } from 'src/routes'
import { postReadOpeningStory } from 'src/services/post/postSkipOpeningStory'
import useLoading from 'src/components/layouts/hooks/useLoading'

import StartLoading from 'src/components/layouts/StartLoading'
import BGAutoBtn from 'src/assets/img/opening-story/btn-auto.png'
import BGHistoryBtn from 'src/assets/img/opening-story/btn-history.png'
import BGSkipBtn from 'src/assets/img/opening-story/btn-skip.png'
import BGVoicePlay from 'src/assets/img/opening-story/bg-voice-play.png'
import ArrowDownIcon from 'src/assets/img/opening-story/arrow-down.png'
import BtnAutoCancel from 'src/assets/img/opening-story/btn_auto_cancel.png'
import FloatBackButton from 'src/assets/img/common/float_back_btn.png'

import Loading from 'src/components/layouts/Loading'
import HistoryModal from 'src/components/menu/HistoryModal'
import { MENU_PATHS } from 'src/routes/menu'
import ModalCommon from 'src/components/common/ModalCommon'

export const OpeningStoryPage = () => {
  const { id } = useParams()

  const {
    stories,
    currentStory,
    blackoutBg,
    isRepeatBgm,
    openHistory,
    nextStory,
    audioSettings,
    handleHistoryAction,
    handleAutoAction,
    redirectTo,
    ref,
    isLastStory,
    isAuto,
    setOpenHistory,
    setStoryIndex,
    storyIndex,
    audioRef,
    isReady,
    audioSeRef,
    audioCharacterRef,
    wrapperRef,
    containerRef,
    blackoutColorAnimation,
    openModal,
    setOpenModal,
    handleBlackout
  } = useGetStoryDetail(id)

  const { redirectTo: redirectBack } = useContext(HistoryRouteContext)
  const { showLoading, progress } = useLoading([!isReady])

  const handleClickHistoryBottom = () => {
    if (isLastStory) {
      postReadOpeningStory()
      return redirectTo()
    }
    if (isAuto) return
    nextStory?.isBlackout ? handleBlackout() : setStoryIndex((prev) => prev + 1)
  }

  const modalButtons = [
    {
      type: 'close',
      label: 'キャンセル',
      onClick: () => setOpenModal(false)
    },
    {
      label: 'スキップ',
      onClick: () => {
        redirectTo()
      }
    }
  ]

  if (showLoading) {
    if (localStorage.getItem('isFromOpening')) {
      return (
        <LoadingWrapper backgroundImage={'white'}>
          <StartLoading progress={progress} />
        </LoadingWrapper>
      )
    } else {
      return (
        <LoadingWrapper backgroundImage={'white'}>
          <Loading progress={progress} />
        </LoadingWrapper>
      )
    }
  } else {
    return (
      <div
        style={{ backgroundColor: `${blackoutColorAnimation}` }}
        className="sm:w-[400px] w-[100dvw] mx-auto relative bg-no-repeat bg-center bg-cover h-[100dvh]"
        ref={containerRef}
      >
        {openHistory && (
          <HistoryModal
            isOpen={openHistory}
            setIsOpen={setOpenHistory}
            stories={stories}
            storyIndex={storyIndex}
          />
        )}
        <Wrapper
          backgroundImage={currentStory?.backgroundImage}
          blackout={blackoutBg}
          timeTrasition={'2s'}
          ref={wrapperRef}
        >
          {currentStory && (
            <>
              <audio
                ref={audioCharacterRef}
                src={currentStory.character_voice_url ? currentStory.character_voice_url : ''}
                loop={currentStory.isCharacterVoiceRepeat}
                {...(audioSettings.voice && { muted: true })}
              />
              <audio
                style={{ visibility: 'hidden' }}
                ref={audioRef}
                autoPlay={true}
                src={!currentStory?.isBgmStop ? currentStory?.bgmUrl : ''}
                loop={isRepeatBgm}
                controls
                {...(audioSettings.bgm && { muted: true })}
              />
              {currentStory?.characterImageThumbnail && (
                <CharacterThumbContainer>
                  <CharacterThumb CharacterThumb={currentStory?.characterImageThumbnail} />
                </CharacterThumbContainer>
              )}
              {currentStory?.seUrl && (
                <audio
                  ref={audioSeRef}
                  autoPlay={true}
                  src={currentStory.seUrl}
                  loop={currentStory.isSeAudioRepeat}
                  {...(audioSettings.se && { muted: true })}
                />
              )}

              {currentStory?.address && (
                <div className="absolute top-6 left-0">
                  <Address length={currentStory.address.length}>{currentStory.address}</Address>
                  <button
                    onClick={() => redirectBack(MENU_PATHS.Menu)}
                    className="w-[4.75rem] h-[3.875rem] absolute left-1"
                  >
                    <img src={FloatBackButton} className="w-[4.75rem]" />
                  </button>
                </div>
              )}
            </>
          )}
          <VoicePlay />
          <div className="absolute z-50 bottom-10 left-4 right-4">
            <UserContainer>
              <UserName>
                <p>{currentStory?.characterName}</p>
              </UserName>
              <UserActions>
                <HistoryBtn onClick={handleHistoryAction}>
                  <div className="my-1 border-r border-sky-blue w-[30px] h-[26px] flex items-center justify-center">
                    <img src={BGHistoryBtn} className="w-[16px] h-[22.53px] ml-0.5" />
                  </div>
                </HistoryBtn>
                <AutoBtn isAuto={isAuto} onClick={handleAutoAction}>
                  <div className="my-1 border-r border-sky-blue w-[30px] h-[26px] flex items-center justify-center">
                    <img
                      src={isAuto ? BtnAutoCancel : BGAutoBtn}
                      className="w-[16px] h-[22.53px] "
                    />
                  </div>
                </AutoBtn>
                <SkipBtn
                  onClick={() => {
                    setOpenModal(true)
                  }}
                >
                  <div className="my-1 w-[30px] h-[26px] flex items-center justify-center">
                    <img src={BGSkipBtn} className="w-[16px] h-[22.53px] mt-0.5" />
                  </div>
                </SkipBtn>
              </UserActions>
            </UserContainer>
            <HistoryBottom onClick={() => handleClickHistoryBottom()}>
              {currentStory && (
                <div className="focus:outline-0 w-full h-full text-xs font-Inter font-medium overflow-y-auto break-words">
                  <p ref={ref} />
                </div>
              )}
              {(isLastStory && isAuto) || isAuto || (
                <TriangleDown>
                  <img src={ArrowDownIcon} className="h-8" alt="arrow down" />
                </TriangleDown>
              )}
            </HistoryBottom>
          </div>{' '}
        </Wrapper>
        <ModalCommon
          open={openModal}
          onClose={() => setOpenModal(false)}
          title={'確認'}
          buttons={modalButtons}
        >
          <div className="bg-[#FFFFFF] px-2 py-8 text-center mb-[16px]">
            <p className="text-center pb-10 font-bold">ストーリーをスキップしますか?</p>
          </div>
        </ModalCommon>
      </div>
    )
  }
}

const LoadingWrapper = styled.div.attrs({
  className: 'sm:w-[400px] w-[100dvw] h-[100dvh] mx-auto relative bg-no-repeat bg-center bg-cover'
})`
  background: ${(props) => props.backgroundImage};
  z-index: 0;
`

const Wrapper = styled.div.attrs({
  className: 'sm:w-[400px] w-[100dvw] h-[100dvh] mx-auto relative bg-no-repeat bg-center bg-cover'
})`
  background-image: url(${(props) => props.backgroundImage});
  z-index: 0;
  background-color: ${(props) => props.blackout};
  transition: background-color ${(props) => props.timeTrasition}, opacity 2s;
`

const CharacterThumbContainer = styled.div.attrs({
  className: 'w-[100%] h-[100%] absolute left-[0px] bottom-[0px]'
})`
  height: calc(100vh - 100px);
  z-index: 10;
`

const CharacterThumb = styled.div.attrs({
  className: 'w-full h-full absolute'
})`
  background-image: url(${(props) => props.CharacterThumb});
  background-size: 150% 150%;
  background-repeat: no-repeat;
  background-position: 50% -25%;
`

const Address = styled.div.attrs({
  className:
    'w-32 text-white text-sm whitespace-nowrap bg-cornflower-blue text-xs font-Inter font-bold rounded-r-[3rem]'
})`
  line-height: 28px;
`

const UserContainer = styled.div.attrs({
  className:
    'flex absolute -top-4 -left-2 w-[101%] z-10 justify-between font-xs font-Inter text-medium'
})``
const UserName = styled.div.attrs({
  className:
    'text-[0.875rem] font-Inter text-white font-semibold whitespace-nowrap w-36 rounded-[2.5rem] flex items-center justify-center h-7'
})`
  background: linear-gradient(90deg, #8ca7f4 0%, #fd49e2 100%);
`

const UserActions = styled.div.attrs({
  className: 'flex border border-sky-blue rounded-2xl'
})`
  background: #ffffffe5;
`

const HistoryBottom = styled.div.attrs({
  className: 'relative text-left px-8 pt-7 pb-8 flex rounded-xl'
})`
  height: 150px;
  background: #ffffffe5;
  border: 3px solid #ffffff;
`

const TriangleDown = styled.span.attrs({
  className: 'absolute right-1 bottom-0 cursor-pointer'
})``

const HistoryBtn = styled.div.attrs({
  className: 'cursor-pointer'
})``

const AutoBtn = styled.div.attrs({
  className: 'cursor-pointer flex items-center justify-center'
})``

const SkipBtn = styled.div.attrs({
  className: 'cursor-pointer flex items-end justify-center'
})``

const VoicePlay = styled.div.attrs({
  className: 'flex absolute bottom-52 right-3 w-[120px] h-[40px]'
})`
  background-image: url('${BGVoicePlay}');
  background-size: 100% 100%;
`
