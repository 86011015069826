import SideStory from 'src/models/SideStory'
import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

export const getSideStoryDetail = async (id) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Stories.SideStory.getDetail.replace(':id', id))
    const items = []
    for (const i of res.data.data.stories) {
      const item = new SideStory(
        i.id,
        i.details.description,
        i.details.bgm_url,
        i.details.bgm_audio_repeat
      )
      items.push(item)
    }
    return items
  } catch (error) {
    return []
  }
}
