/* eslint-disable quotes */
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import clsx from 'clsx'

import Inquiry from 'src/models/Inquiry'

import { Tab } from '@headlessui/react'
import SubmenuHeader from 'src/components/menu/SubmenuHeader'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import NotificationIcon from 'src/components/common/NotificationIcon'
import InquiryForm from 'src/pages/menu/InquiryForm'
import InquiryHistory from 'src/pages/menu/InquiryHistory'
import TipsModal from 'src/components/common/TipsModal'

import { CATEGORIES } from 'src/constants/common'

const TabContent = ({ isSelected, content, hasNotifications = false }) => {
  return (
    <div
      className={clsx(
        'text-xs w-full py-2 rounded-t-2xl font-bold',
        isSelected ? 'bg-white text-light-pink' : 'bg-menu-gray text-white'
      )}
    >
      <p>{content}</p>
      {hasNotifications && <NotificationIcon className="top-2 right-3" />}
    </div>
  )
}
const InquiryPage = ({
  userId,
  sendInquiry,
  hasNotifications,
  setSelectedIndex,
  isLoading,
  inquiries,
  selectedIndex,
  handleUpdateReadInquiry
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [message, setMessage] = useState('')
  const [showPreview, setShowPreview] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [file1, setFile1] = useState('')
  const [file2, setFile2] = useState('')
  const [file3, setFile3] = useState('')
  const [files, setFiles] = useState({
    file1: '',
    file2: '',
    file3: ''
  })

  useEffect(() => {
    if (isSubmitted) {
      setIsSubmitted(false)
      setShowPreview(false)
    }
  }, [selectedIndex])

  return (
    <>
      <MainLayout active="menu" classNameHeader="">
        <MenuWrapper className="mt-2" overflow={true}>
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <SubmenuHeader title={'お問い合わせ'} />
            <Tab.List className="flex justify-between bg-[#E9E9E9] pt-2 px-4 gap-1">
              <Tab className="w-full">
                {({ selected }) => (
                  <TabContent isSelected={selected} content="お問い合わせフォーム" />
                )}
              </Tab>
              <Tab className="w-full relative">
                {({ selected }) => (
                  <TabContent
                    isSelected={selected}
                    content="お問い合わせ履歴と返信"
                    hasNotifications={hasNotifications}
                  />
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels className="font-Inter h-[calc(100dvh-240px)] bg-[#E9E9E9] overflow-y-auto hidden-scroll px-4">
              <Tab.Panel className={'h-[calc(100dvh-250px)] overflow-y-auto hidden-scroll'}>
                <InquiryForm
                  onBack={() => {
                    setIsSubmitted(false)
                    setShowPreview(false)
                  }}
                  isSubmitted={isSubmitted}
                  onEdit={() => setShowPreview(false)}
                  onSubmit={() => {
                    sendInquiry(selectedCategory, message, files)
                    setIsSubmitted(true)
                    setSelectedCategory(null)
                    setMessage('')
                    setFile1('')
                    setFile2('')
                    setFile3('')
                  }}
                  showPreview={showPreview}
                  onConfirm={() => setShowPreview(true)}
                  categories={CATEGORIES}
                  userId={userId}
                  setSelectedCategory={setSelectedCategory}
                  setMessage={setMessage}
                  selectedCategory={selectedCategory}
                  message={message}
                  showHistory={() => setSelectedIndex(1)}
                  setFile1={setFile1}
                  setFile2={setFile2}
                  setFile3={setFile3}
                  file1={file1}
                  file2={file2}
                  file3={file3}
                  files={files}
                  setFiles={setFiles}
                />
              </Tab.Panel>
              <Tab.Panel
                className={clsx(
                  'h-[calc(100dvh-250px)] overflow-y-auto hidden-scroll',
                  isLoading ? 'h-full flex items-center' : null
                )}
              >
                <InquiryHistory
                  isLoadingList={isLoading}
                  inquiries={inquiries}
                  handleUpdateReadInquiry={handleUpdateReadInquiry}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
          <TipsModal type="inquiry" />
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

InquiryPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  sendInquiry: PropTypes.func.isRequired,
  hasNotifications: PropTypes.bool.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  userId: PropTypes.number,
  inquiries: PropTypes.arrayOf(PropTypes.instanceOf(Inquiry)),
  selectedIndex: PropTypes.number,
  handleUpdateReadInquiry: PropTypes.func.isRequired
}

TabContent.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  hasNotifications: PropTypes.bool
}

export default InquiryPage
