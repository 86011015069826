import React from 'react'
import PropTypes from 'prop-types'
import closeLiveModal from '../assets/close_live_modal.png'
import { ModalTitle } from '../../../components/common/ModalWithBg'

const LiveModal = ({ onClose, title, children, isAnimating, description, bgColor, closeImg }) => {
  return (
    <div className="fixed inset-0 flex items-end justify-center z-40" onClick={onClose}>
      <div
        className={`w-full md:w-[400px] max-h-[calc(100vh-40px)] flex flex-col z-50 rounded-t-2xl pt-[10px] transition-all duration-300 transform ${
          isAnimating ? 'animate-slide-up' : 'animate-slide-down'
        } ${bgColor ?? 'bg-[#000000cc]'}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={`sticky top-0 px-[20px] pb-6 ${bgColor ?? 'bg-[#000000cc]'}`}>
          <div className="w-full text-right sticky top-0 z-50">
            <img
              src={closeImg ?? closeLiveModal}
              className="inline w-[18px] h-auto"
              onClick={onClose}
            />
          </div>

          {/* @ts-expect-error */}
          {title && <ModalTitle className="mb-0">{title}</ModalTitle>}
          {description}
        </div>

        <div className="hidden-scroll px-[20px] overflow-auto">{children}</div>
      </div>
    </div>
  )
}

LiveModal.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  isAnimating: PropTypes.bool,
  description: PropTypes.node,
  bgColor: PropTypes.string,
  closeImg: PropTypes.string
}

export { LiveModal }
