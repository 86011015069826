import { useContext } from 'react'
import PropTypes from 'prop-types'

import { MAIN_PATHS } from 'src/routes/main'
import { HistoryRouteContext } from 'src/routes'

import ModalCommon from 'src/components/common/ModalCommon'

const CapsuleInsufficientScreen = ({ content, handleRechoose, handleClose }) => {
  const { redirectTo } = useContext(HistoryRouteContext)
  return (
    <ModalCommon
      isContainedByModal={false}
      onClose={handleClose}
      title={'確認'}
      buttons={[
        {
          type: 'close',
          label: 'キャンセル',
          onClick: () => {
            handleRechoose()
          }
        },
        {
          label: 'OK',
          onClick: () => {
            redirectTo(MAIN_PATHS.ShopPage)
          }
        }
      ]}
    >
      <div className="bg-white font-semibold w-full py-6 text-black text-center whitespace-pre-line font-Inter text-base">
        {content}
      </div>
    </ModalCommon>
  )
}

CapsuleInsufficientScreen.propTypes = {
  content: PropTypes.string.isRequired,
  handleRechoose: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default CapsuleInsufficientScreen
