import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ModalCommon from 'src/components/common/ModalCommon'
import { HistoryRouteContext } from 'src/routes'
import { MAIN_PATHS } from 'src/routes/main'

const ShopModal = ({ open, setOpen }) => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const modalButtons = [
    {
      label: 'キャンセル',
      onClick: () => {
        setOpen(false)
      },
      type: 'close'
    },
    {
      label: 'ショップへ',
      onClick: () => {
        redirectTo(MAIN_PATHS.ShopPage)
      }
    }
  ]

  return (
    <ModalCommon title="確認" onClose={() => setOpen(false)} open={open} buttons={modalButtons}>
      <div className="bg-white text-center text-[#6F9BFF] font-semibold px-1 py-8 mb-4">
        ショップに移動しますか？
      </div>
    </ModalCommon>
  )
}

ShopModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
}

export { ShopModal }
