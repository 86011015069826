import { useRef } from 'react'
import PropTypes from 'prop-types'

import PlayVoice from 'src/assets/img/realtalk/voice.svg'

const StoryWithVoice = ({ story }) => {
  const audio = useRef()
  const storyArrays = (story.description || '').split('\n')

  return (
    <div key={story.id} className="mb-5">
      {story.character_voice_url && (
        <label
          onClick={(e) => {
            audio.current?.play?.()
          }}
        >
          <img src={PlayVoice} />
          <audio ref={audio} className="preview-CharacterVoice" src={story.character_voice_url} />
        </label>
      )}
      <div className="mt-1">
        {storyArrays.length > 1 ? (
          storyArrays.map((p, index) => {
            if (index === 0) {
              return (
                <div key={index}>
                  <p>
                    {story.characterName} 『{p}
                  </p>
                </div>
              )
            }
            if (index === storyArrays.length - 1) {
              return (
                <p className="mb-1" key={index}>
                  {p}』
                </p>
              )
            }
            return (
              <p className="mb-1" key={index}>
                {p}
              </p>
            )
          })
        ) : (
          <p>
            {story.characterName} 『{storyArrays[0]}』
          </p>
        )}
      </div>
    </div>
  )
}

StoryWithVoice.propTypes = {
  story: PropTypes.object.isRequired
}

export default StoryWithVoice
