import PropTypes from 'prop-types'
import clsx from 'clsx'
import Present from 'src/models/Present'
import styled from 'styled-components'
import { calculateDifference } from 'src/lib/date'
import { getItemImage } from 'src/lib/present'

const PresentItemThumbnail = ({ present, isNormalItem, isModalItem }) => {
  const rarity = isNormalItem(present.presentType)
  return (
    <div
      className={clsx(
        'absolute',
        isModalItem ? 'w-9' : ' w-[49px]',
        'flex justify-center items-center relative aspect-square rounded-md',
        !rarity && 'border-2 drop-shadow-md bg-white'
      )}
      style={{
        background:
          'linear-gradient(103.54deg, rgba(211, 184, 255, 0.4) 21.27%, rgba(171, 255, 245, 0.4) 85.74%)'
      }}
    >
      <img
        src={getItemImage(present)}
        className={clsx(rarity ? 'w-full' : 'w-4/5', 'aspect-square')}
      />
      <p
        className="absolute -bottom-2.5 -right-2 font-bold font-Inter text-[0.875rem] text-cornflower-blue -translate-x-1/5"
        style={{
          textShadow: '-2px 0 white, 2px 0 white, 0 2px white, 0 -2px white',
          filter: 'drop-shadow(0px 0px 2px #ffffff)'
        }}
      >
        x{present.amount}
      </p>
    </div>
  )
}

const PresentItem = ({ present, handleReceive, isNormalItem, isModalItem, isOnlyItem }) => {
  return (
    <li className="w-full rounded  font-Inter relative">
      <Content isOnlyItem={isOnlyItem}>
        <PresentItemThumbnail
          present={present}
          isNormalItem={isNormalItem}
          isModalItem={isModalItem}
        />
        <div className="text-left flex flex-col items-start">
          <div className="text-base text-cornflower-blue">{present.title}</div>
          {!isModalItem && <PresentText>{present.boxMessage}</PresentText>}
        </div>
        {handleReceive && (
          <div className="font-semibold text-cornflower-blue w-full flex flex-col items-center justify-center">
            <button
              className="w-[89px] h-9 text-[0.9375rem] border-[3px] border-cornflower-blue rounded-[49px]"
              onClick={handleReceive}
            >
              受け取る
            </button>
            <div className="mt-0.5 whitespace-pre-line text-[0.5625rem]">
              {calculateDifference(present.expiredAt)}
            </div>
          </div>
        )}
      </Content>
    </li>
  )
}

PresentItem.propTypes = {
  present: PropTypes.instanceOf(Present).isRequired,
  handleReceive: PropTypes.func,
  isNormalItem: PropTypes.func.isRequired,
  isModalItem: PropTypes.bool,
  isOnlyItem: PropTypes.bool.isRequired
}

PresentItemThumbnail.propTypes = {
  present: PropTypes.instanceOf(Present).isRequired,
  isNormalItem: PropTypes.func.isRequired,
  isModalItem: PropTypes.bool
}

export default PresentItem

const Content = styled.div.attrs(({ isOnlyItem }) => ({
  className: `bg-white py-2 w-full w-[95%] mx-auto ${!isOnlyItem && 'border-b border-sky-blue'}`
}))`
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  gap: 10px;
`

const PresentText = styled.p.attrs({
  className: 'leading-tight text-sky-blue font-semibold'
})`
  font-size: 0.625rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  letter-spacing: 0.06em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`
