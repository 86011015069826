import React from 'react'
import PropTypes from 'prop-types'
import ModalCommon from 'src/components/common/ModalCommon'
import Mascot from 'src/assets/img/character/mascot/bye_bye.png'

const ModalResend = ({ isOpen, onClose, handleResendMessage }) => {
  const modalButtons = [
    {
      label: '閉じる',
      onClick: () => {
        handleResendMessage()
        onClose()
      }
    }
  ]

  return (
    <ModalCommon open={isOpen} onClose={onClose} title="エラーメッセージ" buttons={modalButtons}>
      <div className="bg-white py-2 px-1 text-center font-bold font-Inter text-[#484848] pb-20">
        <div className="mt-6 text-lg truncate mb-6">
          <p className="text-[15px]">
            今、貴女が送信したメッセージは
            <br />
            通信環境の関係で送信出来なかったョ！
            <br />
            その為、下記再送信ボタンを押して
            <br />
            再度メッセージを送信して欲しいコケ！
          </p>
          <p className="text-[15px]">
            もし、それでも送信出来なかった場合は、
            <br />
            少し時間をおいてから試して欲しいコケ。
            <br />
            その他に分からない事や気になる事
            <br />
            があったら、遠慮なくナビゲーター
            <br />
            である僕の所まで聞きに来て欲しい
            <br />
            コケ！
          </p>
        </div>
        <img
          src={Mascot}
          alt="mascot"
          className="absolute w-24 mt-2 top-[65%] left-[60%] mr-10 min-h-600:hidden"
        />
      </div>
    </ModalCommon>
  )
}

ModalResend.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleResendMessage: PropTypes.func
}

export default ModalResend
