import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { HistoryRouteContext } from 'src/routes'
import { MYPAGE_PATHS } from 'src/routes/myPage'

import CharacterRankingItem from 'src/components/ranking/CharacterRankingItem'

import TalentDiamondIcon from 'src/assets/img/mypage/talent/talent_diamond.png'
import TalentCrownIcon from 'src/assets/img/mypage/talent/talent_crown.png'

export const TopRanking = ({ characters }) => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const numberOfCharacters = Object.keys(characters).length
  return (
    <div className="flex flex-col gap-6 items-center justify-center my-6">
      <Link
        to={MYPAGE_PATHS.RecommendPage}
        className="relative p-1 w-fit rounded-3xl bg-gradient-to-r-custom"
      >
        <div className="flex items-center gap-1 bg-white rounded-3xl px-3.5 py-1">
          <img src={TalentDiamondIcon} className="w-4" />
          <span className="font-bold text-transparent bg-clip-text bg-gradient-to-r-custom">
            TALENT一覧
          </span>
        </div>
      </Link>
      <div className="flex flex-col gap-2 items-center justify-center w-full">
        <div className="flex items-center gap-1">
          <img src={TalentCrownIcon} className="w-[15px]" />
          <span className="text-sm font-medium text-[#6F9BFF]">TALENT週間ランキング</span>
        </div>
        <div
          className="grid grid-cols-3 gap-1 w-full px-3 cursor-pointer"
          onClick={() => redirectTo(MYPAGE_PATHS.MyTalentPage, { state: { tab: 'talent' } })}
        >
          {numberOfCharacters > 0 &&
            Array.from({ length: numberOfCharacters }, (_, index) => (
              <CharacterRankingItem
                key={index}
                infor={characters[`rank_${index + 1}`]}
                currentPage="mypage"
              />
            ))}
        </div>
      </div>
    </div>
  )
}

TopRanking.propTypes = {
  characters: PropTypes.any.isRequired
}

export default TopRanking
