import { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import useNewsListChannel from 'src/services/common/useNewsListChannel'
import useNewsHasNotifications from 'src/hooks/useNewsHasNotifications'
import { HistoryRouteContext } from 'src/routes'
import { MYPAGE_PATHS } from 'src/routes/myPage'

import NewsFeedIcon from 'src/assets/img/mypage/news_feed.png'
import NewsFeedNotiIcon from 'src/assets/img/mypage/news_feed_noti.png'
import SpeakerIcon from 'src/assets/img/mypage/speaker.png'
import PresentBoxIcon from 'src/assets/img/mypage/present_box.png'
import PresentBoxNotiIcon from 'src/assets/img/mypage/present_box_noti.png'
import RankingIcon from 'src/assets/img/mypage/ranking_icon.png'

export const NotificationArea = ({ presents }) => {
  const hasNews = useSelector((state) => state.getNotifications.hasNews)
  const hasNewsNotifications = useSelector((state) => state.getNotifications.hasNewsNotifications)
  const { redirectTo } = useContext(HistoryRouteContext)

  const LINKS = [
    {
      label: 'お知らせ',
      icon: NewsFeedIcon,
      notiIcon: NewsFeedNotiIcon,
      to: MYPAGE_PATHS.NewsListPage,
      hasNoti: hasNewsNotifications || hasNews
    },
    {
      label: 'イベント',
      icon: SpeakerIcon,
      to: MYPAGE_PATHS.EventListPage,
      hasNoti: false
    },
    {
      label: 'プレゼント',
      icon: PresentBoxIcon,
      notiIcon: PresentBoxNotiIcon,
      to: MYPAGE_PATHS.PresentPage,
      hasNoti: presents?.length > 0
    },
    {
      label: 'ランキング',
      icon: RankingIcon,
      to: MYPAGE_PATHS.MyTalentPage,
      hasNoti: false
    }
  ]

  useNewsListChannel()
  useNewsHasNotifications()

  return (
    <ul className="fixed bottom-[74px] flex font-extrabold text-[9px]">
      {LINKS.map((link, index) => (
        <li
          key={index}
          onClick={() => redirectTo(link.to)}
          className={`relative flex items-center justify-center bg-white px-1 py-2 rounded-tr-md cursor-pointer ${
            index === 0
              ? 'shadow-none'
              : index === LINKS.length - 1
                ? 'shadow-left-right'
                : 'shadow-left'
          }`}
        >
          <img
            src={link.hasNoti ? link.notiIcon : link.icon}
            alt={link.label}
            className="h-6 absolute -top-4"
          />
          <span>{link.label}</span>
        </li>
      ))}
    </ul>
  )
}

NotificationArea.propTypes = {
  presents: PropTypes.array
}
