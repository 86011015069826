import { useRef, useState, useEffect, useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'

import PropTypes from 'prop-types'
import { authActionTypes, useAuthDispatch, useAuthState } from 'src/providers/AuthProviders'
import useChatRoom from 'src/services/common/useChatRoom'
import { saveMessage, saveMessageImg, saveMessageItem } from 'src/services/patch/saveMessage'
import { SpendHeartModal } from 'src/components/message/ChatBox/SpendHeartModal'
import { Modal } from 'src/components/common/Modal'
import { ForbiddenMessageModal } from 'src/pages/menu/ForbiddenMessageModal'
import { CheckForbiddenMessage } from 'src/services/post/checkForbiddenMessage'
import { canSpendHeart, userPossessionHeart } from 'src/lib/message'
import SettingIcon from 'src/assets/img/realtalk/setting_icon.png'
import BtnSend from 'src/assets/img/realtalk/btn_send.png'
import BtnActiveSetting from 'src/assets/img/realtalk/btn_setting_active.png'
import BtnActiveImg from 'src/assets/img/realtalk/btn_img_active.png'
import BtnActiveItem from 'src/assets/img/realtalk/btn_item_active.png'
import BtnSubmit from 'src/assets/img/realtalk/btn_submit.png'
import BtnbackFile from 'src/assets/img/ranking/icon-back.png'
import ModalListItem from 'src/pages/main/storyTalk/components/ModalListItem'
import ReactPlayer from 'react-player'
import ModalResend from 'src/components/common/ModalResend'

export const InputDMTalk = ({
  senderId,
  conversationId,
  handleNewMessages,
  setSizeInput,
  characterId,
  loveLvSettings,
  character,
  setCheckClickIcon,
  setCheckClickIconBg,
  setIsClickIconBg,
  setCheckClickIconSetting,
  setFiles,
  files,
  fileVideo,
  setFileVideo,
  setIsMultiLoveModalOpen,
  setMultiLevel
}) => {
  const inputRef = useRef(null)
  const { user } = useAuthState()
  const authDispatch = useAuthDispatch()
  const [isOpenSpendHeartModal, setIsOpenSpendHeartModal] = useState(false)
  const { message, sendMessage, sendMessageItem, sendMessageImg } = useChatRoom(conversationId)
  const [countMessage, setCountMessage] = useState(100)
  const [newMessage, setNewMessage] = useState('')
  const [heightText, setHeightText] = useState(0)
  const [userHeart, setUserHeart] = useState(0)
  const [canSpendHeartState, setCanSpendHearState] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModalResend, setShowModalResent] = useState(false)
  const [selectIndex, setSelectIndex] = useState(null)
  const [dataItem, setDataItem] = useState(null)
  const [itemitem, setitemitem] = useState()
  const [selectEvent, setSelectEvent] = useState({})
  const [checkStatus, setCheckStatus] = useState('')

  const refInput = useRef(null)

  const handleClose = () => {
    setShowModal(false)
  }

  const handleCloseResend = () => {
    localStorage.removeItem('payload')
    setShowModalResent(false)
  }

  const handleModal = () => {
    setShowModal(true)
  }

  const spendHeartAmount = loveLvSettings.chat_heart
  useEffect(() => {
    setUserHeart(userPossessionHeart(user))
    setCanSpendHearState(canSpendHeart(userHeart, spendHeartAmount))
  }, [user, userHeart, spendHeartAmount])

  const closeSpendHeartModal = useCallback(() => {
    setIsOpenSpendHeartModal(false)
  }, [])

  useEffect(() => {
    setSizeInput(heightText + 60)
  }, [heightText])

  useEffect(() => {
    handleNewMessages(message, checkStatus)
  }, [message])

  useEffect(() => {
    if (!newMessage) {
      const h = inputRef.current.scrollHeight
      inputRef.current.style.height = 'inherit'
      inputRef.current.style.height = `${h}px`
      setHeightText(h - 20)
    } else {
      setHeightText(0)
    }
  }, [newMessage])

  const clearInput = () => {
    setNewMessage('')
    setCountMessage(100)
    inputRef.current.style.height = '40px'
    inputRef.current.value = null
    setHeightText(0)
  }

  const handleNewMessageChange = (event) => {
    const value = event.target.value
    const messageLength = value.replace(/\n/g, '').length

    if (messageLength <= 100) {
      setNewMessage(value)
      setCountMessage(100 - messageLength)
    }
  }

  const payload = JSON.parse(localStorage.getItem('payload')) || {}

  const handleResendMessageCallApi = async () => {
    sendMessage(newMessage)
    const responseData = await saveMessage(
      conversationId,
      senderId,
      characterId,
      newMessage || payload.content,
      payload.uuid || uuidv4()
    )
    if (responseData.isOpenResend) {
      setShowModalResent(true)
      setCheckStatus('message')
    } else {
      const { currentLevel, nextLevel, uuid } = responseData
      authDispatch({
        type: authActionTypes.SPEND_HEART,
        payload: spendHeartAmount
      })
      if (uuid === payload.uuid) {
        localStorage.removeItem('payload')
      }
      if (!currentLevel || !nextLevel) return
      setMultiLevel({
        currentLevel: currentLevel,
        nextLevel: nextLevel
      })
      setIsMultiLoveModalOpen(true)
    }
  }

  const handleSendMessage = async () => {
    closeSpendHeartModal()
    if (/^[\n\s]*$/.test(newMessage)) return
    try {
      const isForbiddenMessage = await CheckForbiddenMessage(newMessage)
      if (isForbiddenMessage) return handleModal()
      clearInput()
      handleResendMessageCallApi()
    } catch {}
  }

  const handleSendMessageItem = async (amount, idItem) => {
    try {
      const times = user.dream_compass ? dataItem.increase_love_dream_compass : 1
      sendMessageItem({ ...dataItem, count: amount, increase_love: dataItem.increase_love * times })
      const responseDataItem = await saveMessageItem(
        conversationId,
        senderId,
        characterId,
        amount || payload.amount,
        idItem || payload.item_id,
        payload.uuid || uuidv4()
      )
      if (responseDataItem.isOpenResend) {
        setShowModalResent(true)
        setCheckStatus('messageItem')
      } else {
        const { currentLevel, nextLevel, uuid } = responseDataItem
        if (uuid === payload.uuid) {
          localStorage.removeItem('payload')
        }
        setSelectIndex(null)
        if (!currentLevel || !nextLevel) return
        setMultiLevel({
          currentLevel: currentLevel,
          nextLevel: nextLevel
        })
        setIsMultiLoveModalOpen(true)
      }
    } catch {}
  }

  const handleCloseSend = () => {
    setFiles('')
    setSelectIndex(null)
  }

  const handleSendMessageImg = async () => {
    try {
      setFiles('')
      sendMessageImg(files)
      const formData = new FormData()
      formData.append('message[sender_id]', user?.id)
      formData.append('message[character_id]', characterId)
      formData.append('message[attachment_url]', selectEvent || itemitem)

      const responseDataImg = await saveMessageImg(
        conversationId,
        formData,
        payload.uuid || uuidv4(),
        selectEvent,
        setitemitem
      )

      if (responseDataImg.isOpenResend) {
        setShowModalResent(true)
        setCheckStatus('messageImg')
      } else {
        const { currentLevel, nextLevel, uuid } = responseDataImg
        authDispatch({
          type: authActionTypes.SPEND_HEART,
          payload: spendHeartAmount
        })
        if (uuid === payload.uuid) {
          localStorage.removeItem('payload')
        }
        if (!currentLevel || !nextLevel) return
        setMultiLevel({
          currentLevel: currentLevel,
          nextLevel: nextLevel
        })
        setIsMultiLoveModalOpen(true)
      }
    } catch {}
  }

  const ta = document.getElementById('input-message')

  let height = ta?.clientHeight

  const Up = () => {
    if (ta?.clientHeight !== height) {
      const h = document.getElementById('input-message').clientHeight
      setHeightText(h - 20)
    }
    height = ta?.clientHeight
  }

  const fileSelectedHandler = (e) => {
    const selectedFiles = e.target.files[0]
    setSelectIndex(null)
    if (selectedFiles?.size > 5 * 1024 * 1024) {
      alert('ファイルサイズが大きすぎます。5MB 未満のファイルを選択してください。')
      e.target.value = ''
    } else {
      const allowedExtensions = ['jpeg', 'png', 'mp4', 'wav', 'heic', 'gif', 'mov', 'h.264', 'jpg']
      const fileName = selectedFiles?.name.toLowerCase()
      const fileExtension = fileName.split('.').pop()

      if (allowedExtensions.includes(fileExtension)) {
        setSelectEvent(selectedFiles)
        const reader = new FileReader()
        reader.onload = (e) => {
          const imageURL = e.target.result
          setFiles(imageURL)
        }
        reader.readAsDataURL(selectedFiles)
      }
    }
  }

  const [openModal, setOpenModal] = useState(false)

  const handleCloseItem = () => {
    setOpenModal(false)
    setSelectIndex(null)
  }

  const handleCheckFile = () => {
    setFiles('')
    setSelectIndex(null)
  }

  const handleResendMessage = () => {
    if (checkStatus === 'messageImg') {
      handleSendMessageImg()
    } else if (checkStatus === 'messageItem') {
      handleSendMessageItem()
    } else {
      handleResendMessageCallApi()
    }
    setShowModalResent(false)
  }

  const handleSendFile = () => {
    handleCloseSend()
    setIsOpenSpendHeartModal(true)
  }

  return (
    <>
      <div className="bg-[#ACD1FDE5] py-2 px-4">
        <div className="flex w-full justify-between items-center">
          <div className="flex w-[20px] mb-[26px]">
            <img
              style={{ filter: 'brightness(0) invert(1)' }}
              className="object-contain w-full h-[20px] mr-[10px]"
              src={selectIndex !== 1 ? SettingIcon : BtnActiveSetting}
              onClick={() => {
                setCheckClickIcon(false)
                setCheckClickIconBg(false)
                setIsClickIconBg(true)
                setSelectIndex(1)
                setCheckClickIconSetting(true)
              }}
            />
            <img
              style={{ filter: 'brightness(0) invert(1)' }}
              className="object-contain w-full h-[20px] mr-[10px]	"
              src={BtnActiveImg}
              onClick={() => {
                refInput.current.click()
                setSelectIndex(2)
              }}
            />
            <input
              type="file"
              id="fileInput"
              ref={refInput}
              multiple
              accept="image/*, video/*"
              onChange={fileSelectedHandler}
              style={{ display: 'none' }}
            />
            <img
              style={{ filter: 'brightness(0) invert(1)' }}
              className="object-contain w-full h-[20px] mr-[10px]	"
              src={BtnActiveItem}
              onClick={() => {
                setOpenModal(true)
                setSelectIndex(3)
              }}
            />
          </div>
          <div className="basis-56 ml-[60px]">
            <textarea
              onMouseUp={() => Up()}
              id="input-message"
              maxLength="100"
              ref={inputRef}
              value={newMessage}
              onChange={handleNewMessageChange}
              style={{
                width: '95%',
                height: 43,
                maxHeight: 164,
                boxShadow: 'inset 0px 1px 3px rgba(0, 0, 0, 0.25)',
                minHeight: 36
              }}
              className="hidden-scroll w-full h-full rounded-[3px] pl-3 py-2.5 placeholder-shown:text-xs placeholder-shown:text-[#CFCFCF] placeholder-shown:font-light text-sm text-[#2A2A2A] font-medium leading-[18px]"
              placeholder="メッセージを入力（最大100文字）"
              rows={1}
            />
          </div>
          <button
            className="h-[52px] w-[54px] rounded-[4px] text-[#6C6C6C] font-bold text-[14px]"
            onClick={() => setIsOpenSpendHeartModal(true)}
          >
            <img src={BtnSubmit} alt="" className="" />
          </button>
        </div>
        <div className="pt-1 mr-[88px] text-[13px] text-[#FFF] leading-[15px] font-medium">
          残り<span>{countMessage >= 0 ? countMessage : 0}</span>文字
        </div>
        <SpendHeartModal
          isOpen={isOpenSpendHeartModal}
          closeModal={closeSpendHeartModal}
          onConfirm={handleSendMessage}
          userHeart={userHeart}
          spendHeartAmount={spendHeartAmount}
          canSpendHeart={canSpendHeartState}
          isNewConversation
          isSendRealtimeTalk
        />

        <Modal
          open={showModal}
          onClose={handleClose}
          className="sm:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg font-hiragino flex items-center justify-center"
        >
          <ForbiddenMessageModal handleClose={handleClose} />
        </Modal>
        <ModalResend
          isOpen={showModalResend}
          onClose={handleCloseResend}
          handleResendMessage={handleResendMessage}
        />
        {openModal && (
          <ModalListItem
            handleClose={handleCloseItem}
            show={openModal}
            characterId={characterId}
            setSelectIndex={setSelectIndex}
            handleSendMessageItem={handleSendMessageItem}
            setDataItem={setDataItem}
          />
        )}
      </div>
      {files && (
        <div className="">
          <div className="fixed top-[10%] left-[5%] z-[1001]" onClick={handleCheckFile}>
            <img className="w-[30px]" src={BtnbackFile} />
          </div>
          <div className="sm:w-[360px] fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg font-hiragino flex items-center justify-center bg-[black] h-screen w-screen z-[1000]">
            <div className="block">
              {files.includes('image') ? (
                <img className="w-full h-full" src={files} />
              ) : (
                <ReactPlayer height="100%" width="100%" controls url={files} />
              )}
            </div>
            <div className="absolute bottom-[9%] right-[5%]" onClick={() => handleSendFile()}>
              <img className="w-[50px]" src={BtnSend} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
InputDMTalk.propTypes = {
  conversationId: PropTypes.number,
  senderId: PropTypes.number.isRequired,
  handleNewMessages: PropTypes.func,
  setSizeInput: PropTypes.func,
  characterId: PropTypes.number,
  loveLvSettings: PropTypes.object,
  character: PropTypes.object,
  setCheckClickIcon: PropTypes.func,
  setCheckClickIconBg: PropTypes.func,
  setIsClickIconBg: PropTypes.func,
  setCheckClickIconSetting: PropTypes.func,
  setFiles: PropTypes.func,
  files: PropTypes.array,
  fileVideo: PropTypes.array,
  setFileVideo: PropTypes.func,
  setIsMultiLoveModalOpen: PropTypes.func,
  setMultiLevel: PropTypes.func
}
