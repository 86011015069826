import { combineReducers } from '@reduxjs/toolkit'
import historyRoute from 'src/redux/route/historyRoute'
import getDataGacha from 'src/redux/gacha/getDataGacha'
import getDetailGacha from 'src/redux/gacha/getDetailGacha'
import getRecommendMans from 'src/redux/character/getRecommendMans'
import displayLayout from 'src/redux/layout/setDisplayLayout'
import liveList from 'src/redux/live/setLiveList'
import getNotifications from 'src/redux/notfication/getNotifications'

const appReducer = combineReducers({
  historyRoute: historyRoute,
  getDataGacha: getDataGacha,
  getDetailGacha: getDetailGacha,
  getRecommendMans: getRecommendMans,
  liveList: liveList,
  displayLayout: displayLayout,
  getNotifications: getNotifications
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
