import { useCallback, useEffect } from 'react'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import ShopPaymentMethodPicker from 'src/pages/main/shop/components/ShopDiamondTab/ShopPaymentMethodPicker'
import PropTypes from 'prop-types'

import BtnCareer from 'src/assets/img/shop/btn_career.png'
import BtnCredit from 'src/assets/img/shop/btn_credit.png'
import BtnPaypay from 'src/assets/img/shop/btn_paypay.png'
import BtnWebmoney from 'src/assets/img/shop/btn_webmoney.png'
import BtnAmazon from 'src/assets/img/shop/btn_amazon.png'
import BtnBitcash from 'src/assets/img/shop/btn_bitcash.png'
// TODO コメント箇所は今後実装する予定

const paymentMethodButtons = {
  career: BtnCareer,
  credit: BtnCredit,
  paypay: BtnPaypay,
  web_money: BtnWebmoney,
  amazon_pay: BtnAmazon,
  bitcash: BtnBitcash
}

const ShopMethodSelectModal = ({ description }) => {
  const { closeModal, selectedItem, setModalContent, setButtons, setTitle } =
    useShopContext()

  const handleSelect = useCallback(
    (methodName) => {
      if (!selectedItem?.listed_price) return
      setModalContent(<ShopPaymentMethodPicker method={methodName} description={description} />)
    },
    [selectedItem?.listed_price, description]
  )

  useEffect(() => {
    setButtons([
      {
        label: 'OK',
        onClick: closeModal
      }
    ])
    setTitle('購入方法')
  }, [])

  return (
    <>
      <div className="grid grid-cols-2 gap-2 bg-white p-2">
        {Object.keys(paymentMethodButtons).map((methodName) => (
          <button onClick={() => handleSelect(methodName)} key={methodName}>
            <img src={paymentMethodButtons[methodName]} />
          </button>
        ))}
      </div>
    </>
  )
}

export default ShopMethodSelectModal

ShopMethodSelectModal.propTypes = {
  description: PropTypes.string
}
