import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'
import Story from 'src/models/Story'

const getOpeningStoryList = async () => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Stories.OpeningStory.getList)

    const items = []

    for (const i of res.data.items) {
      const item = new Story(
        i.id,
        i.name
      )
      items.push(item)
    }

    return items
  } catch (error) {
    return []
  }
}

export default getOpeningStoryList
