import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Countdown from 'react-countdown'
import { useDuringContext } from '../pages/DuringDelivery/_Provider'
import iconStartedExtraTime from '../assets/icon_started_extra_time.png'
import { formattedCountDownTime } from 'src/lib/date'

const HiddenExtraTime = (
  { isShow, time, livestreamId, className, onComplete, characterLiveSocket }
) => {
  const [displayTime, setDisplayTime] = useState(time)
  const { liveItemsSocket } = useDuringContext()

  useEffect(() => {
    setDisplayTime(time)
  }, [time])

  useEffect(() => {
    if (
      liveItemsSocket.extraWatchableTime === undefined ||
      liveItemsSocket.extraWatchableTime === '' ||
      new Date(displayTime) > new Date(liveItemsSocket.extraWatchableTime)
    ) {
      return
    }

    setDisplayTime(liveItemsSocket.extraWatchableTime)
  }, [displayTime, liveItemsSocket.extraWatchableTime])

  useEffect(() => {
    if (
      characterLiveSocket.extraWatchableTime === undefined ||
      characterLiveSocket.extraWatchableTime === '' ||
      characterLiveSocket.livestreamId !== livestreamId ||
      new Date(displayTime) > new Date(characterLiveSocket.extraWatchableTime)
    ) {
      return
    }

    setDisplayTime(characterLiveSocket.extraWatchableTime)
  }, [characterLiveSocket.livestreamId, characterLiveSocket.extraWatchableTime])

  return (
    <>
      {isShow && (
        <div className={clsx('absolute w-[fit-content]', className)}>
          <img src={iconStartedExtraTime} className="w-[140px] h-auto]" />
          <span className="absolute bottom-[2px] text-[9px] left-[55%] translate-x-[-50%] text-white font-hiragino">
            <Countdown
              date={new Date(displayTime)}
              onComplete={onComplete}
              renderer={({ hours, minutes, seconds }) => {
                return <>{formattedCountDownTime(hours, minutes, seconds)}</>
              }}
            />
          </span>
        </div>
      )}
    </>
  )
}

HiddenExtraTime.propTypes = {
  isShow: PropTypes.bool,
  time: PropTypes.string,
  livestreamId: PropTypes.number,
  className: PropTypes.string,
  onComplete: PropTypes.func,
  characterLiveSocket: PropTypes.object
}

// @ts-ignore
export default React.forwardRef(HiddenExtraTime)
