import { useState, useEffect } from 'react'

const useProgress = (isLoading) => {
  const [progress, setProgress] = useState(0)
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    let interval
    if (isLoading) {
      setShowLoading(true)
      interval = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 1 : prev))
      }, 30)
    } else {
      setProgress(100)
      setTimeout(
        () => {
          localStorage.removeItem('isFromOpening')
          setShowLoading(false)
        },
        localStorage.getItem('isFromOpening') ? 700 : 300
      )
    }

    return () => clearInterval(interval)
  }, [isLoading])

  return { progress, showLoading }
}

export default useProgress
