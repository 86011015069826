export const API_ROUTES = {
  Stories: {
    OpeningStory: {
      getList: '/stories',
      getDetail: '/stories/:id',
      getFirstStory: 'stories/first_story'
    },
    SideStory: {
      getList: '/stories/list_boy_theater_themes',
      getDetail: '/stories/:id?type=boy_theater_theme'
    }
  },
  Events: {
    getCurrent: '/events/organizing',
    getHistory: '/events/history',
    getDetail: '/events/:id'
  },
  Users: '/users',
  UserInfo: '/users/user_info',
  LoginByEmail: '/users/login_with_email',
  LoginByLine: '/users/login_with_line',
  SaveToken: '/users/save_adcd',
  UserRegister: '/users/register_user',
  AdTag: '/ad_tag',
  CodeManages: '/code_managers',
  ParameterName: '/ad_tag/get_parameter_name',
  UserItemAnalytics: '/user_item_analytics',
  Ranking: {
    Love: '/rankings/user_love_level_rankings',
    Grow: '/rankings/user_grow_level_rankings',
    Detail: '/rankings/detail',
    d6: '/rankings/user_love_level_rankings/character_rank',
    Events: '/ranking_reward_pages',
    RewardInfo: 'ranking_reward_pages/reward_info',
    EventDetails: '/ranking_reward_pages/:id'
  },
  Character: {
    filter: '/characters/filtering',
    history: '/characters/:characterId/manga',
    cloths: '/characters/:characterId/character_cloths',
    RegisterCloth: '/characters/:id/character_cloths/:character_cloth_id/register'
  },
  Chat: {
    characters: '/characters/chat',
    getChat: '/chat/get_chat',
    getChannelInfo: '/conversations/channel_info',
    getChatList: '/conversations/list_channel',
    spendHeart: '/conversations/spend_heart',
    getConversationItem: '/conversations/get_conversation_item',
    saveMessage: '/conversations/{id}/save_message',
    checkForbiddenMessage: '/conversations/check_forbidden_characters'
  },
  Voice: {
    getVoice: '/voice/get_voice'
  },
  Present: '/presents',
  ReceivePresent: '/receive_present',
  Item: {
    spendItem: '/item/spend_item'
  },
  Recommend: {
    Man: '/characters/recommend_mans',
    StillAlbum: '/characters/id/still_albums',
    VoiceAlbum: '/characters/character_id/character_voices',
    Characters: '/characters',
    RecommendUpdate: '/recommend_update'
  },
  Conditions: '/conditions',
  Notification: {
    List: '/notification_settings/list_setting',
    Update: '/notification_settings/update_setting',
    UpdateGlobal: '/notification_settings/update_global_setting'
  },
  News: {
    getNews: '/new_announcements',
    readAll: '/new_announcements/read_all'
  },
  Gachas: '/gachas',
  GachasId: '/gachas/:id',
  Itemgacha: '/gachas/:id/random_item',
  Inquiries: '/user_contacts',
  Payments: {
    Order: 'payments/payment_order',
    OrderWebMoney: 'payments/payment_order_webmoney',
    Status: 'payments/payment_status',
    AmazonPaymentsVerify: 'payments/result_amazon_pay',
    OrderBitcash: 'payments/payment_order_bitcash'
  },
  OrderDetails: 'orders/:order_id',
  ConfirmFeedback: '/confirm_feedback',
  SendContentFeedback: '/send_content_feedback',
  Shops: '/shops',
  HeartExchangeConfigs: '/shops/heart_exchange_settings',
  ExchangeHeartByRedDiamond: '/shops/exchange_heart_by_red_diamond?id=:id',
  faqs: '/question_and_answers',
  Maintenance: '/maintenances',
  Tracking: {
    ActionAccessLine: '/action_access_line'
  },
  Live: {
    ListLiveView: '/meetups/live_views/set_list_live_view',
    LiveStreamInfo: '/meetups/live_streams/live_stream_info?open_id=:open_id&title=:title',
    LiveSendMessage: '/meetups/live_chats/send_message',
    LiveTime: 'meetups/live_items/give_item',
    ContinueExtraTime: 'meetups/live_views/set_countinue_extra_time',
    ListLivestreams: '/meetups/live_streams/list_livestreams',
    ScheduleToday: '/meetups/live_streams/schedule_today',
    ScheduleMonth: '/meetups/live_streams/schedule_month'
  },
  settingitems: 'setting_items',
  HistoryItems: 'users/:id/get_user_setting_item',
  CountTimeRoll: 'gachas/:id/count_times_roll',
  RealTalk: {
    GetListItemChacter: '/characters/:character_id/items',
    PostBackGround: '/conversations/set_background_character'
  },
  StoryMessage: {
    PostNoticeMessage: '/conversations/set_check_read_character'
  },
  LiveSchedule: '/live_schedule_pages'
}
