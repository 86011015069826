import PropTypes from 'prop-types'
import Item from 'src/models/Item'
import BtnPlus from 'src/assets/img/common/btn_plus.svg'
import BtnMinus from 'src/assets/img/realtalk/btn_minus.svg'
import Heart from 'src/assets/img/realtalk/heart.svg'
import { useEffect, useState } from 'react'
import { useAuthState } from 'src/providers/AuthProviders'
import clsx from 'clsx'
import ModalCommon from 'src/components/common/ModalCommon'

const ModalItemDetail = ({
  item,
  handleCloseModalDetail,
  handleSendMessageItem,
  handleClose,
  open
}) => {
  const { user } = useAuthState()

  const [countItem, setCountItem] = useState(0)
  const [totalItemLove, setTotalItemLove] = useState(item.increase_love)
  const handleCountPlusItem = () => {
    setCountItem(countItem + 1)
  }
  const handleCountMinusItem = () => {
    setCountItem(countItem - 1)
  }
  useEffect(() => {
    const times = user.dream_compass ? item.increase_love_dream_compass : 1
    setTotalItemLove(item.increase_love * countItem * times)
  }, [countItem])

  const modalButtons = [
    {
      label: 'キャンセル',
      onClick: () => {
        handleClose()
      },
      type: 'close'
    },
    {
      label: '決定',
      onClick: () => {
        if (countItem === 0) return
        handleCloseModalDetail()
        handleClose()
        handleSendMessageItem(countItem, item.item_id)
      }
    }
  ]

  return (
    <ModalCommon title="アイテム送信" open={open} onClose={handleClose} buttons={modalButtons}>
      <div className="bg-[#FFFFFF] px-2 pt-6 pb-12 text-center relative overflow-auto h-[350px] hidden-scroll">
        <h1 className="mb-5 font-Inter text-[#484848] font-bold text-[15px]">
          アイテムを何個送りますか？
        </h1>
        <div>
          <div className="flex gap-5 items-center justify-center">
            <button onClick={handleCountMinusItem} disabled={countItem <= 0}>
              <img
                className={`w-[30px] ${countItem <= 0 ? 'filter grayscale' : ''}`}
                src={BtnMinus}
              />
            </button>
            <ItemThumbnail
              image={item.image}
              imgClassName="w-[120px] h-[120px] object-cover"
              className=""
              rarity
              loveAmount={item.increase_love}
            />
            <div>
              <button disabled={item.amount === countItem} onClick={handleCountPlusItem}>
                <img
                  className={`${item.amount === countItem ? 'filter grayscale' : ''} w-[30px]`}
                  src={BtnPlus}
                />
              </button>
            </div>
          </div>
          <div className="text-center my-2 font-Inter text-[#6F9BFF] font-semibold">
            贈るアイテム数 x<span className="text-[#484848] text-[19px] ml-2">{countItem}</span>
          </div>
          <button
            className="bg-[#6F9BFF] w-[136px] p-1 rounded-[7px] text-white font-bold text-[15px]"
            onClick={() => setCountItem(item.amount)}
          >
            全て追加
          </button>
          <p className="w-[136px] m-auto justify-center font-bold bg-[#F7F7F7] font-Inter p-[5px] rounded-[7px] text-[10px] text-center mt-2 text-[#6F9BFF]">
            所持アイテム数
            <span className="text-[#484848] ml-2">{item.amount}</span>
          </p>
        </div>
        <div className="flex justify-center mt-8">
          <div className="flex justify-around w-4/5 p-3 bg-[#F7F7F7] rounded-[7px] text-[#FF86C8] font-bold text-[15px] font-Inter">
            <div className="flex">
              <img className="w-[30px]" src={Heart} />
              <span className="ml-1">LOVE度上昇</span>
            </div>
            <span>+ {totalItemLove}</span>
          </div>
        </div>
        <div className="mt-2">
          <p className="text-[10px] text-[#6F9BFF] font-semibold font-Inter">
            夢のコンパス有効期限内は更に{item.increase_love_dream_compass}倍LOVE度加算
          </p>
        </div>
      </div>
    </ModalCommon>
  )
}

ModalItemDetail.propTypes = {
  item: PropTypes.instanceOf(Item).isRequired,
  handleCloseModalDetail: PropTypes.func.isRequired,
  handleSendMessageItem: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

const ItemThumbnail = ({
  image,
  rarity,
  loveAmount,
  className = 'w-[72px] h-[80px] border-menu-dark-blue rounded-sm',
  imgClassName = 'w-[60px]'
}) => {
  return (
    <div
      className={clsx(
        'flex justify-center items-center relative',
        className,
        !rarity && 'border-2 drop-shadow-md bg-white'
      )}
    >
      <img className={clsx(imgClassName)} src={image} />
    </div>
  )
}

ItemThumbnail.propTypes = {
  image: PropTypes.string.isRequired,
  rarity: PropTypes.bool,
  loveAmount: PropTypes.number,
  className: PropTypes.string,
  imgClassName: PropTypes.string
}

const ValueIndicator = ({ label, value, bgStyle, marginRight }) => {
  return (
    <div
      className={`rounded border h-8 flex text-xs font-hiragino bg-[#DAEBFF] w-[150px] ${marginRight} mb-1`}
    >
      <span className=" w-full flex items-center justify-center h-full align-middle px-2 text-center">
        {label}
      </span>
    </div>
  )
}

ValueIndicator.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  marginRight: PropTypes.string.isRequired,
  bgStyle: PropTypes.string.isRequired
}

export default ModalItemDetail
