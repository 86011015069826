import React from 'react'
import PropTypes from 'prop-types'
import ModalCommon from 'src/components/common/ModalCommon'
import PassportIcon from 'src/assets/img/passport-icon.png'
import ChickenIcon from 'src/assets/img/chicken-icon.png'
import ModalBg from 'src/assets/img/common/modal_bg.png'

const ModalPassport = ({ isOpen, onClose }) => {
  const modalButtons = [
    {
      label: '閉じる',
      onClick: () => {
        onClose()
      }
    }
  ]

  return (
    <ModalCommon open={isOpen} onClose={onClose} title="プラチナパスポート" buttons={modalButtons}>
      <div className="bg-white py-2 px-1 text-center font-bold font-Inter">
        <div
          className="relative h-[150px] bg-no-repeat bg-cover bg-center rounded-xl mx-1"
          style={{
            backgroundImage: `url(${ModalBg})`
          }}
        >
          <img
            src={PassportIcon}
            alt=""
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
          <img className="absolute right-0 bottom-0 w-[73px] h-[73px]" src={ChickenIcon} alt="" />
        </div>
        <p className="mt-2 mb-3 font-bold text-[15px]">
          このアイコンは、｢プラチナパスポート｣所有済のアイコンだコケッ！
        </p>
        <p className="text-xs mb-2">プラチナパスポートを所持済の貴女は…</p>
        <p className="text-[15px]">
          ・LIVE配信の視聴時間制限の解除
          <br />
          ・LIVE配信でコメントが出来る(無料)
          <br />
          ・回復ハートのMAX値上昇
          <br />
          ・D×6の生い立ち漫画が無料で読める
        </p>
        <p className="text-xs mt-1">などの特典を受け取っているコケ！</p>
        <p className="text-xs">引き続きアイドルプリンスを楽しんでネ♪</p>
      </div>
    </ModalCommon>
  )
}

ModalPassport.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
}

export default ModalPassport
