import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'src/providers/QueryProvider'
import { getRecommendMans } from 'src/services/get/getRecommend'
import putUpdateRecommendMan from 'src/services/put/putUpdateRecommendMan'

export const useGetRecommendsData = (types) => {
  const recommends = useQuery(
    ['recommends', types],
    () => getRecommendMans(types),
    {
      initialData: () => {
        return queryClient.getQueryData(['recommends', types])
      }
    }
  )
  return recommends
}

export const useUpdateRecommendsMan = () => {
  const mutation = useMutation((value) => putUpdateRecommendMan(value), {
    onSuccess: (_, id) => queryClient.invalidateQueries('recommends')
  })

  return mutation
}
