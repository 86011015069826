import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import ModalCommon from 'src/components/common/ModalCommon'
import StoryWithVoice from 'src/pages/newUser/StoryWithVoice'

const HistoryModal = ({ isOpen, setIsOpen, stories, storyIndex }) => {
  const title = '履歴'
  const buttons = []
  const messagesEndRef = useRef(null)

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({})
    }
  }, [stories])

  return (
    <ModalCommon title={title} buttons={buttons} open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="h-[60dvh] overflow-y-auto px-4 pl-6 pr-4 py-6 mb-4 bg-[rgba(255,255,255,0.95)] hidden-scroll">
        {stories && (
          <div className="pr-2 font-Inter text-[0.6875rem] font-normal text-cornflower-blue">
            {stories.map((story, i) => {
              if (storyIndex < i || story.isBlackout) return null
              return <StoryWithVoice story={story} key={story.id} />
            })}
            <div ref={messagesEndRef} />
          </div>
        )}
      </div>
    </ModalCommon>
  )
}

HistoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  storyIndex: PropTypes.number.isRequired
}

export default HistoryModal
