import MyPageContainer from 'src/components/myPage/MyPageContainer'
import { MainLayout } from 'src/components/layouts/MainLayout'

import BGMaintenanceModalTitle from 'src/assets/img/common/bg-modal-title.png'
import BGModal from 'src/assets/img/opening/bg-modal.png'
import { Modal } from 'src/components/common/Modal'
import { useEffect, useRef, useState } from 'react'
import { useMaintenanceContext } from 'src/providers/MaintenanceProviders'
import HTMLReactParser from 'html-react-parser'
import { removeNewlineCode } from 'src/lib/string'
import styled from 'styled-components'

export const MyPage = () => {
  const { maintenance } = useMaintenanceContext()
  const [isOpen, setIsOpen] = useState(maintenance?.maint_mode || false)

  const contentRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      if (!contentRef.current) return
      contentRef.current.scrollTop = 0
    }, 1)
  }, [contentRef.current])

  useEffect(() => setIsOpen(maintenance?.maint_mode || false), [maintenance, isOpen])

  return (
    <MainLayout
      active="mypage"
      className="bg-center bg-cover bg-no-repeat overflow-y-auto h-full hidden-scroll bg-white"
      showHeader={false}
      showFooter={false}
    >
      <MyPageContainer />
      <Modal
        open={isOpen}
        onClose={() => null}
        className="w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg sm:w-full z-[99999]"
      >
        <ModalBody>
          <div className="py-4 w-full max-h-full flex flex-col items-center justify-center rounded-md">
            <ModalTitle>メンテナンス中</ModalTitle>
            <div
              className="h-[304px] mb-4 styled-scroll px-1 font-hiragino font-light text-xs text-[#000000] text-justify leading-6 overflow-y-auto whitespace-pre-line"
              ref={contentRef}
            >
              {HTMLReactParser(removeNewlineCode(maintenance?.content))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </MainLayout>
  )
}

const ModalBody = styled.div.attrs({
  className: 'w-full h-fit px-3 py-5 relative'
})`
  background-image: url(${BGModal});
  background-size: 100% 100%;
`

const ModalTitle = styled.div.attrs({
  className:
    'h-fit px-8 py-[7px] relative text-center text-white text-xl font-vldLineGR leading-[25px] mx-4 mb-4'
})`
  background-image: url(${BGMaintenanceModalTitle});
  background-size: 100% 100%;
`
