import React, { useState } from 'react'
import iconHelp from '../../assets/btn_help_2.png'
import PropTypes from 'prop-types'
import ModalCommon from 'src/components/common/ModalCommon'
import { closeModalButton } from 'src/constants/button_close'
import TimeHelperImage from 'src/pages/voiceLive/assets/time_helper.png'
import mascotChara from 'src/assets/img/character/mascot/good_mood.png'

const TimeHelper = ({ normalPassportModal, freeMinutes }) => {
  const [infoModal, setInfosModal] = useState(false)

  const handleCloseModal = () => {
    setInfosModal(false)
  }

  return (
    <>
      <img
        src={iconHelp}
        id="icon-help"
        className="inline w-6 h-6 cursor-pointer"
        onClick={() => setInfosModal(true)}
      />
      <ModalCommon
        title={normalPassportModal ? 'ヘルプ' : '残り時間について'}
        open={infoModal}
        onClose={() => setInfosModal(false)}
        buttons={[closeModalButton('OK', handleCloseModal)]}
      >
        <>
          {normalPassportModal ? (
            <div className="bg-white px-2 py-2 text-sm text-left font-semibold text-[#6F9BFF] flex flex-col gap-3">
              <img src={TimeHelperImage} />
              <div className="space-y-4 px-4">
                <p>
                  「時間延長の砂」を彼に送ること
                  <br />
                  で、配信時間を延長してあげる事が
                  <br />
                  出来るコケッ!
                  <br />
                  ▼ノーマルタイム
                  <br />
                  誰かが「時間延長の砂」を使えば、
                  <br />
                  全員の視聴時間が平等に延長され
                  <br />
                  るョ!
                </p>
                <p>
                  ▼Extraタイム
                  <br />
                  「時間延長の砂」を使用した視聴者
                  <br />
                  様にのみ、時間延長が与えられるコ
                  <br />
                  ケッ!
                  <br />
                  残りの配信時間が0秒になってから
                  <br />
                  はコンテニュー以外での再入場は出
                  <br />
                  来ないから、赤ダイヤの補充はお早
                  <br />
                  めにだコケェッ!
                </p>
              </div>
            </div>
          ) : (
            <div className="relative space-y-4 bg-white px-1 pt-6 pb-12 text-center text-[15px] text-[#484848] font-bold">
              <p className="px-5">
                右上に表示されてる「残り時間」は
                <br />
                プラチナパスポート所有者専用の視聴
                <br />
                時間だコケ！
              </p>
              <p>
                プラチナパスポートを所有していない
                <br />
                方は表示されている「残り時間」に関
                <br />
                わらず、滞在時間が<span className="text-[#FF0000]">{freeMinutes}分</span>
                を過ぎると強
                <br />
                制的に退場となるョ！
              </p>
              <img className="absolute bottom-1 right-4 w-[73px]" src={mascotChara} />
            </div>
          )}
        </>
      </ModalCommon>
    </>
  )
}

TimeHelper.propTypes = {
  normalPassportModal: PropTypes.bool,
  freeMinutes: PropTypes.number
}

export { TimeHelper }
