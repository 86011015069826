import PropTypes from 'prop-types'
import React from 'react'

const ExchangeButton = ({ onClick }) => {
  return (
    <button className="text-white bg-cotton-candy-pink font-Inter text-lg px-4 py-2 rounded-md" onClick={onClick}>
      交換する
    </button>
  )
}

ExchangeButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default ExchangeButton
