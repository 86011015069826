import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { DRAW_TYPE } from 'src/models/GachaInfo'
import ReactPlayer from 'react-player'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import ItemThumbnail from 'src/components/menu/ItemThumbnail'

const TextWithShadow = ({ text, width, fontSize = 'text-xs', animationDelay }) => {
  return (
    <p
      className={`font-Inter mt-1 ${fontSize} font-bold text-cornflower-blue text-center mx-auto`}
      style={{
        width,
        textShadow: '-2px 0 white, 2px 0 white, 0 2px white, 0 -2px white',
        filter: 'drop-shadow(0px 0px 2px #ffffff)',
        ...(animationDelay && { animationDelay })
      }}
    >
      {text}
    </p>
  )
}

const VideoCharacterGacha = ({
  handleClose,
  isVideoPlaying,
  setIsVideoPlaying,
  randomValue,
  drawType,
  items,
  handleConfirm,
  videoEnded,
  setVideoEnded
}) => {
  const handleVideoEnd = () => {
    setIsVideoPlaying(false)
    setVideoEnded(true)
  }
  return (
    <>
      {!_.isEmpty(randomValue) && (
        <div className="custom-react-player-container">
          {isVideoPlaying && (
            <ReactPlayer
              width="100vw"
              height="100vh"
              playing={true}
              onEnded={handleVideoEnd}
              url={randomValue?.video}
            />
          )}
          {videoEnded && (
            <MainLayout active="menu" classNameHeader="" showFooter={false}>
              <div
                className="w-full h-screen px-4 pt-8 pb-16 bg-popup-md bg-fill bg-no-repeat relative flex flex-col items-center justify-between font-hiragino"
                style={{
                  backgroundImage: `url('${randomValue?.img}')`
                }}
              >
                <MenuWrapper className="mt-7" overflow={true}>
                  <div className="flex flex-col items-center justify-center h-full">
                    <h1 className="bg-cornflower-blue rounded-[3rem] text-white font-bold font-Inter px-8 text-2xl">
                      結果
                    </h1>
                    <div
                      className="w-full h-[320px] bg-white/80 mt-6"
                      style={{
                        backdropFilter: 'blur(4px)'
                      }}
                    >
                      {items?.length === 1 ? (
                        <div
                          className="h-full w-full flex flex-col items-center justify-center opacity-0 animate-fadeIn"
                          style={{
                            animationDelay: '0.5s' // Optional: you can adjust the delay for the whole container
                          }}
                        >
                          <div className="text-center">
                            <ItemThumbnail
                              image={items[0].item_image_url}
                              className=""
                              imgClassName="w-[78px] h-[78px]"
                              rarity={items[0].rarity}
                            />
                          </div>
                          <TextWithShadow text={items[0].name} width="79px" />
                        </div>
                      ) : (
                        <ul className="grid grid-cols-4 gap-1 my-4">
                          {items?.map((el, index) => {
                            return (
                              <li
                                className="flex flex-col opacity-0 animate-fadeIn"
                                key={index}
                                style={{
                                  animationDelay: `${index * 0.5}s`
                                }}
                              >
                                <ItemThumbnail
                                  image={el.item_image_url}
                                  rarity={el.rarity}
                                  className=""
                                  imgClassName="w-[60px]"
                                />
                                <TextWithShadow
                                  text={el.name}
                                  width="60px"
                                  fontSize="text-[0.5rem]"
                                />
                              </li>
                            )
                          })}
                        </ul>
                      )}
                    </div>
                    <div className={'flex w-4/5 justify-center gap-4 mt-4'}>
                      <button
                        className={
                          'w-36 text-white text-xl font-bold text-center rounded-[32px] py-2 font-MPlus1 bg-metallic-gray '
                        }
                        onClick={handleClose}
                      >
                        キャンセル
                      </button>
                      {drawType !== DRAW_TYPE.Free && (
                        <button
                          className={
                            'w-36 text-white text-xl font-bold text-center rounded-[32px] py-2 font-MPlus1 bg-cornflower-blue '
                          }
                          onClick={() => {
                            setVideoEnded(false)
                            handleConfirm()
                          }}
                        >
                          もう１度引く
                        </button>
                      )}
                    </div>
                  </div>
                </MenuWrapper>
              </div>
            </MainLayout>
          )}
        </div>
      )}
    </>
  )
}

TextWithShadow.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  animationDelay: PropTypes.string
}

VideoCharacterGacha.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isVideoPlaying: PropTypes.bool,
  setIsVideoPlaying: PropTypes.func.isRequired,
  randomValue: PropTypes.object.isRequired,
  drawType: PropTypes.number,
  items: PropTypes.array,
  handleConfirm: PropTypes.func,
  videoEnded: PropTypes.bool,
  setVideoEnded: PropTypes.func
}

export default VideoCharacterGacha
