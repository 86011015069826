import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const getHeartExchangeConfigs = async () => {
  try {
    const { data } = await axiosInstance.get(API_ROUTES.HeartExchangeConfigs)
    return data || []
  } catch (e) {
    console.log(e)
    return []
  }
}

const getExchangeHeartByRedDiamond = async (id) => {
  try {
    const { data } = await axiosInstance.get(
      API_ROUTES.ExchangeHeartByRedDiamond.replace(':id', id)
    )
    return data || {}
  } catch {
    return {}
  }
}

export { getHeartExchangeConfigs, getExchangeHeartByRedDiamond }
