import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useAuthState } from 'src/providers/AuthProviders'
import ShopPaymentItem from 'src/pages/main/shop/components/ShopDiamondTab/ShopPaymentItem'
import PurchaseButton from 'src/pages/main/shop/components/ShopDiamondTab/PurchaseButton'

const ShopItem = ({ handleSelect, shopDetails }) => {
  const { user } = useAuthState()

  const includeItemDreamCompass = useMemo(
    () => shopDetails.payment_items.some((item) => item.item_kind === 'dream_compass'),
    [shopDetails]
  )

  return (
    <div className="h-full">
      <div className="flex items-center justify-between h-full px-2 py-1 rounded-md">
        <ShopPaymentItem shopDetails={shopDetails} />
        <PurchaseButton
          cost={shopDetails?.listed_price}
          onClick={handleSelect}
          disabled={user.dream_compass && includeItemDreamCompass}
        />
      </div>
    </div>
  )
}

ShopItem.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  shopDetails: PropTypes.object
}

export default ShopItem
