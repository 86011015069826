import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { authActionTypes, useAuthDispatch, useAuthState } from 'src/providers/AuthProviders'
import { LiveModal } from 'src/pages/voiceLive/components/LiveModal'
import { HourglassItem } from 'src/pages/voiceLive/pages/DuringDelivery/_HourglassItem'
import { useDuringContext } from 'src/pages/voiceLive/pages/DuringDelivery/_Provider'
import { postLiveTime } from 'src/services/post/postLiveTime'
import { CantSendItemModal } from 'src/pages/voiceLive/pages/DuringDelivery/_CantSendItemModal'
import { InsufficientDiamondModal } from 'src/pages/voiceLive/pages/DuringDelivery/_InsufficientDiamondModal'
import { ModalPassport } from 'src/pages/voiceLive/components/ModalPassport'
import { AddHourglassModal } from 'src/pages/voiceLive/pages/DuringDelivery/_AddHourglassModal'

import HourGlassItemAddition from 'src/pages/voiceLive/assets/hourglass_item_addition.png'
import ChickenIcon from 'src/assets/img/chicken-icon.png'
import { ShopModal } from './_ShopModal'

const HourglassModal = ({
  isExtraTime,
  setIsMultiLoveModalOpen,
  setMultiLevel,
  normalPassportModal
}) => {
  const authDispatch = useAuthDispatch()
  const { user } = useAuthState()
  const [open, setOpen] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)
  const [openModalPassport, setOpenModalPassport] = useState(false)
  const onClose = () => {
    setIsAnimating(false)
    setTimeout(() => setOpen(false), 200)
  }
  const onCloseSendModal = () => {
    onClose()
  }
  const [cantSendModal, setCantSendModal] = useState(false)
  const [selected, setSelected] = useState({})
  const { liveItemsSocket, liveInfoQuery } = useDuringContext()
  const liveExtraTimes = _.get(liveInfoQuery, 'data.live_extra_times') || []
  const [insufficientDiamond, setInsufficientDiamond] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [hourglassModal, setHourglassModal] = useState(false)
  const [itemAmount, setItemAmount] = useState(1)
  const livestreamId = useMemo(() => liveInfoQuery.data?.live_stream?.id, [liveInfoQuery])

  const openLiveModal = () => {
    setIsAnimating(true)
    setOpen(true)
  }

  const checkRedDiamond = (item, amount) => {
    const redAmount = user.red_amount - item.number_diamond * amount
    if (redAmount < 0) {
      setConfirmModal(true)
      return false
    }
    return true
  }

  const clickTimeItem = (item) => {
    if (normalPassportModal) {
      if (liveItemsSocket.limittedHourglass) {
        setCantSendModal(true)
      } else {
        setSelected(item)
        const isEnoughDiamond = checkRedDiamond(item, 1)
        if (isEnoughDiamond) {
          setItemAmount(1)
          setHourglassModal(true)
        }
      }
      setOpen(false)
    } else {
      setOpenModalPassport(true)
    }
  }

  const sendExtraTime = async () => {
    const isEnoughDiamond = checkRedDiamond(selected, itemAmount)
    if (!isEnoughDiamond) {
      return
    }
    setHourglassModal(false)
    if (user.red_amount < _.get(selected, 'number_diamond')) {
      onCloseSendModal()
      setInsufficientDiamond(true)
    } else {
      try {
        onCloseSendModal()
        const { item, flag, currentLevel, nextLevel } = await postLiveTime({
          livestream_id: livestreamId,
          live_item_id: _.get(selected, 'id'),
          amount: itemAmount
        })
        if (flag) {
          setCantSendModal(true)
        } else {
          authDispatch({
            type: authActionTypes.SPEND_DIAMOND_RED,
            payload: _.get(selected, 'number_diamond') * itemAmount
          })
          if (!item && (!currentLevel || !nextLevel)) return
          setMultiLevel({
            currentLevel: currentLevel,
            nextLevel: nextLevel
          })
          setIsMultiLoveModalOpen(true)
        }
      } catch {}
    }
  }

  const countHourglass = (number) => {
    setItemAmount((prev) => {
      const result = prev + number
      if (result < 1 || result > 99) return prev
      checkRedDiamond(selected, result)
      return result
    })
  }

  return (
    <div className="flex justify-center z-[9]" id="hourglass-icon">
      <img
        src={HourGlassItemAddition}
        className="w-[52px] h-auto cursor-pointer"
        onClick={() => openLiveModal()}
      />
      {open && (
        <LiveModal
          onClose={onClose}
          isAnimating={isAnimating}
          bgColor="bg-[#6F9BFFF2]"
          description={
            <div className="flex justify-center mr-14">
              <div className="relative text-[#A9A9A9] text-left font-semibold bg-white rounded-xl p-2">
                <div className="text-sm">
                  <span className="text-[#FF4747]">「時間延長の砂」</span>を送って
                  <span className="text-[#6F9BFF]">彼の配信時間</span>
                  <br />を<span className="text-[#6F9BFF]">延長</span>してあげることが出来るョ！
                </div>
                <div className="text-[11px]">
                  <span className="text-[#FF4747]">時間延長の砂</span>
                  <span className="text-[#FFC700]">(金)</span>と
                  <span className="text-[#FC5CFF]">(虹)</span>
                  はとってもお得だコケ♪
                </div>
                <img
                  src={ChickenIcon}
                  className="absolute -bottom-8 -right-20 w-[110px] h-auto -rotate-6"
                />
                <div className="absolute -bottom-3 right-7">
                  <svg
                    width="21"
                    height="19"
                    viewBox="0 0 21 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.3529 19C3.70588 19 0.514706 9.67612 0 5.01418C4.11765 1.01823 12.1059 -4.3763 11.1176 6.01317C10.1294 16.4026 17.2941 17.224 21 16.336C19.0235 17.9344 14.4118 18.778 12.3529 19Z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </div>
              </div>
            </div>
          }
        >
          <div className="grid grid-cols-2 gap-y-2 py-4 overflow-auto">
            {liveExtraTimes.slice().map((extra, index) => (
              <HourglassItem
                onClick={() => clickTimeItem(extra)}
                itemType={_.get(extra, 'item_type')}
                diamondAmount={_.get(extra, 'number_diamond')}
                additionalTime={_.get(extra, 'extra_time')}
                additionalLove={_.get(extra, 'extra_love')}
                image={_.get(extra, 'image')}
                key={index}
              />
            ))}
          </div>
        </LiveModal>
      )}
      <ModalPassport open={openModalPassport} onClose={() => setOpenModalPassport(false)} />

      <CantSendItemModal
        open={cantSendModal}
        isExtraTime={isExtraTime}
        onClose={() => {
          setCantSendModal(false)
          onClose()
        }}
      />

      <InsufficientDiamondModal
        open={insufficientDiamond}
        onClose={() => setInsufficientDiamond(false)}
      />

      <AddHourglassModal
        open={hourglassModal}
        onClose={() => setHourglassModal(false)}
        user={user}
        selectedItem={selected}
        amount={itemAmount}
        countHourglass={countHourglass}
        onSubmit={sendExtraTime}
      />

      <ShopModal open={confirmModal} setOpen={setConfirmModal} />
    </div>
  )
}

HourglassModal.propTypes = {
  isExtraTime: PropTypes.bool,
  normalPassportModal: PropTypes.bool,
  setIsMultiLoveModalOpen: PropTypes.func,
  setMultiLevel: PropTypes.func
}

export { HourglassModal }
