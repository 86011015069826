import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import ModalItemDetail from 'src/pages/main/storyTalk/components/ModalItemDetail'
import { getListItemCharacter } from 'src/services/get/getListItemCharacter'
import PointHeart from 'src/assets/img/message/point_heart.svg'
import ModalCommon from 'src/components/common/ModalCommon'
import clsx from 'clsx'

const ModalListItem = ({ show, handleClose, characterId, handleSendMessageItem, setDataItem }) => {
  const [modalItemDetail, setModalItemDetail] = useState(null)
  const [modalItemOpen, setModalItemOpen] = useState(false)
  const [itemCharacter, setItemCharacter] = useState([])
  const handleModalItem = (item) => {
    setDataItem(item)
    setModalItemDetail(item)
    setModalItemOpen(true)
  }
  const [selectedOption, setSelectedOption] = useState('rarity_desc')
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const handleCloseModalDetail = () => {
    setModalItemOpen(false)
  }

  useEffect(() => {
    getListItemCharacter(characterId, selectedOption).then((res) => setItemCharacter(res?.items))
  }, [selectedOption])

  const modalButtons = [
    {
      label: '閉じる',
      onClick: () => { handleClose() }
    }
  ]

  return (
    <>
      <ModalCommon title="アイテム選択" open={show} onClose={handleClose} buttons={modalButtons}>
        <div className="bg-[#FFFFFF] px-2 py-8 text-center mb-[16px]">
          <div className="reletive">
            <SelectOption
              className="absolute right-[20px] top-[14%] bg-white text-[#6F9BFF] border-indigo-500/50 text-center font-vldLineGR text-xs mt-1"
              value={selectedOption}
              onChange={handleSelectChange}
            >
              <option className="text-center" value="rarity_desc">
                レア度が高い順
              </option>
              <option className="text-center" value="rarity_asc">
                レア度が低い順
              </option>
              <option value="date_newest" className="text-center">
                入手日新しい順
              </option>
              <option className="text-center" value="date_oldest">
                入手日古い順
              </option>
            </SelectOption>
          </div>
          <div className="overflow-y-scroll w-full h-[300px] mt-6 hidden-scroll">
            <div className="grid grid-cols-3 gap-2 w-full">
              {itemCharacter.map((item, index) => {
                return (
                  <ItemDetails
                    key={item.id}
                    image={item.image}
                    className=""
                    imgClassName="w-[70px]"
                    amount={item.amount}
                    name={item.name}
                    loveAmount={item.increase_love}
                    rarity
                    onClick={() => {
                      handleModalItem(item)
                    }}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </ModalCommon>

      {modalItemDetail &&
      <ModalItemDetail
        open={modalItemOpen}
        item={modalItemDetail}
        handleCloseModalDetail={handleCloseModalDetail}
        handleSendMessageItem={handleSendMessageItem}
        handleClose={handleClose}
      />
      }
    </>
  )
}

ModalListItem.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  setOpenModal: PropTypes.func,
  characterId: PropTypes.number,
  handleSendMessageItem: PropTypes.func,
  setDataItem: PropTypes.func
}

const ItemDetails = ({ image, name, amount, loveAmount, onClick, key }) => {
  return (
    <div className="font-Inter flex flex-col items-center" key={key} onClick={onClick}>
      <ItemThumbnail image={image} className="" imgClassName="w-[70px]" amount={amount} rarity />
      <div className="font-bold font-Inter pt-1 text-[9px] text-[#6F9BFF] text-center line-clamp-2 text-ellipsis">
        {name}
      </div>
      <div className="relative">
        <img src={PointHeart} alt="" className="" />
        <span className="absolute text-[9px] bottom-[1px] left-3.5 text-[#FF86C8] font-bold">
          +{loveAmount}
        </span>
      </div>
    </div>
  )
}

const ItemThumbnail = ({
  image,
  rarity,
  amount,
  className = 'w-[72px] h-[80px] border-menu-dark-blue rounded-sm',
  imgClassName = 'w-[60px]'
}) => {
  return (
    <div
      className={clsx(
        'flex justify-center items-center relative',
        className,
        !rarity && 'border-2 drop-shadow-md bg-white'
      )}
    >
      <img className={clsx(imgClassName)} src={image} />
      <span className="absolute bottom-0 right-1 text-[#6F9BFF] font-bold text-[12px] text-shadow">x{amount}</span>
    </div>
  )
}

ItemThumbnail.propTypes = {
  image: PropTypes.string.isRequired,
  rarity: PropTypes.bool,
  amount: PropTypes.number,
  className: PropTypes.string,
  imgClassName: PropTypes.string
}

ItemDetails.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  amount: PropTypes.number,
  loveAmount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  key: PropTypes.number
}

export default ModalListItem

const SelectOption = styled.select`
  border: 1px solid #c1e2ff;
  padding: 5px;
  border-radius: 5px;
`
