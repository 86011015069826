import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { LoveLevel } from 'src/components/common/LevelProgress'
import { HistoryRouteContext } from 'src/routes'

import MenuHeartIcon from 'src/assets/img/mypage/talent/menu_heart.png'
import WhiteLightningIcon from 'src/assets/img/mypage/talent/white_lightning.png'

import { Tabs } from 'src/constants/common'
import { MYPAGE_PATHS } from 'src/routes/myPage'
import { getRankingDisplay } from 'src/lib/getRankDisplay'

const getRankingTagByActiveKey = (activeKey) => {
  const tab = Tabs.find((tab) => tab.key === activeKey)
  return tab.tag
}

export const RecommendedMan = ({ infor, isHideTitle = false, activeKey = 'current_week' }) => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const characterRank = infor.current_rank || 9999

  const tag = activeKey && getRankingTagByActiveKey(activeKey)
  const rankingName = getRankingDisplay(characterRank, infor.total_love, infor.playable)

  return (
    <div
      className="flex flex-col gap-2 items-center justify-center w-full cursor-pointer"
      onClick={() => {
        redirectTo(MYPAGE_PATHS.RecommendPage)
      }}
    >
      {!isHideTitle && (
        <div className="flex items-center gap-1">
          <img src={MenuHeartIcon} className="w-[0.9375rem]" />
          <span className="text-sm font-medium text-cornflower-blue">推しメニュー</span>
        </div>
      )}
      <div className="flex items-center gap-2 w-full py-2 px-3 bg-[#F5FAFF]">
        <div
          className="relative w-16 h-16 p-0.5 shrink-0 rounded-full top-2"
          style={{ background: 'linear-gradient(147.44deg, #9BC8FF 35.34%, #F95FFF 103.03%)' }}
        >
          <img src={infor.imageSrc} className="w-full h-full rounded-full" />
        </div>
        <div className="flex flex-col gap-1.5 font-MPlus1 flex-1">
          <div className="flex items-center justify-between">
            <div className="xs:text-[1.375rem] text-base font-medium">{infor.name}</div>
            <div className="flex flex-col gap-y-1 w-32">
              <LoveLevel size="xl" love={infor.level.love} />
              <div className="flex text-[0.625rem] text-cornflower-blue font-medium w-full justify-between">
                <div>{tag}</div>
                <div className={characterRank >= 9999 ? 'flex-grow ' : ''}>{rankingName}</div>
                {characterRank < 9999 && (
                  <>
                    <strong className="text-sm text-black leading-none font-bold">
                      {characterRank}
                    </strong>
                    <div>位</div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between gap-4 text-cornflower-blue font-Inter">
            <p className="xs:text-[0.6875rem] text-[0.625rem] font-medium font-MPlus1">
              ▶︎次の配信は
            </p>
            {!infor.earliest_live_date ? (
              <strong className="text-xs bg-[#DEE8FF] flex-1 font-Inter font-bold flex-grow rounded-md py-0.5">
                Coming Soon!
              </strong>
            ) : (
              <>
                <div className="flex items-center gap-1 xs:gap-4">
                  <strong className="xs:text-[0.9375rem] text-xs font-bold leading-none text-black">
                    {infor.earliest_live_date}
                  </strong>
                  <div className="flex items-center gap-0.5 text-xs text-white font-semibold bg-[#6F9BFF] rounded-sm px-1 py-[1px] min-w-fit">
                    <img src={WhiteLightningIcon} className="w-[7px]" />
                    <span className="text-[0.625rem] xs:text-[0.6875rem]">
                      {infor.earliest_live_type}
                    </span>
                  </div>
                  <div className="flex items-center gap-1 font-semibold">
                    <div className="xs:text-[0.9375rem] text-xs text-black">
                      {infor.earliest_live_start_time}
                    </div>
                    <div className="text-[#ADADAD] text-[0.625rem] xs:text-[13px] leading-4">
                      -{infor.earliest_live_end_time}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

RecommendedMan.propTypes = {
  infor: PropTypes.object,
  isHideTitle: PropTypes.bool,
  activeKey: PropTypes.string,
  isShowRankingTag: PropTypes.bool
}

export default RecommendedMan
