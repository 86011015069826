import { useContext } from 'react'
import ModalCommon from 'src/components/common/ModalCommon'
import PropTypes from 'prop-types'
import { MAIN_PATHS } from 'src/routes/main'
import { HistoryRouteContext } from 'src/routes'

export const ModalNoticeDiamond = ({ isOpen, onClose }) => {
  const { redirectTo } = useContext(HistoryRouteContext)

  const modalButtons = [
    {
      label: 'キャンセル',
      onClick: () => {
        onClose()
      },
      type: 'close'
    },
    {
      label: 'ショップへ',
      onClick: () => redirectTo(MAIN_PATHS.ShopPage)
    }
  ]
  return (
    <ModalCommon title="ダイヤ不足" open={isOpen} onClose={onClose} buttons={modalButtons}>
      <div className="flex items-center justify-center h-[110px] font-inter font-bold bg-[#FFFFFF] px-2 py-2 mb-[16px]">
        赤ダイヤが不足しています
      </div>
    </ModalCommon>
  )
}

ModalNoticeDiamond.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}
