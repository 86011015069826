import { useEffect, useState } from 'react'
import Loading from 'src/components/layouts/Loading'
import { MainLayout } from 'src/components/layouts/MainLayout'
import { postPaymentOrderAmazon } from 'src/services/post/postPaymentOrder'
import FormAmazonPayStart from './shop/components/FormAmazonPayStart'
import useLoading from 'src/components/layouts/hooks/useLoading'

export const LoadingPayment = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const valueCheckOutId = searchParams.get('amazonCheckoutSessionId')
  const [dataRes, setDataRes] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const handleLoading = async (itemPay) => {
    await postPaymentOrderAmazon({
      amazonCheckoutSessionId: valueCheckOutId,
      order: JSON.parse(itemPay)
    }).then((res) => setDataRes(res.data))
    setIsLoading(false)
  }

  useEffect(() => {
    const itemPay = localStorage.getItem('item-pay')

    if (itemPay) {
      localStorage.removeItem('item-pay')
      handleLoading(itemPay)
    }
  }, [])

  const { showLoading, progress } = useLoading([isLoading])

  return (
    <MainLayout className="bg-center bg-cover bg-no-repeat" showHeader={false} showFooter={false}>
      {showLoading ? (
        <Loading progress={progress} />
      ) : (
        <FormAmazonPayStart data={dataRes} />
      )}
    </MainLayout>
  )
}
