import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAuthState } from 'src/providers/AuthProviders'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'

import DiamondRed from 'src/assets/img/common/diamond-red.png'
import Life from 'src/assets/img/common/life.png'

const InfoBox = ({ title, imgSrc, amount, imgPostion }) => {
  return (
    <div>
      <p>{title}</p>
      <div className="flex items-center justify-center relative">
        <img src={imgSrc} className={`w-10 absolute ${imgPostion}`} />
        <p className="my-3 font-bold">{amount}</p>
      </div>
    </div>
  )
}

const ShopExchangeCompleteModal = () => {
  const { user } = useAuthState()
  const { closeModal, setButtons, setTitle } = useShopContext()

  const currentRedDiamonds = user?.red_amount
  const currentPossessionHeart = user.possession_heart

  useEffect(() => {
    setButtons([
      {
        label: 'OK',
        onClick: closeModal
      }
    ])
    setTitle('確認')
  }, [])

  return (
    <>
      <div className="bg-white h-full flex flex-col w-full font-semibold font-Inter">
        <div className="text-lg font-bold text-center whitespace-pre-line py-4">
          アイテムを交換しました。
        </div>
        <div className="bg-[#FFF7F7] text-base text-candy-pink flex flex-col items-center gap-6 py-2 mx-3 mb-6 rounded-sm">
          <InfoBox
            title="赤ダイヤ所持数"
            imgSrc={DiamondRed}
            amount={currentRedDiamonds}
            imgPostion="left-[-2rem]"
          />
          <InfoBox
            title="ピンクハート所持数"
            imgSrc={Life}
            amount={currentPossessionHeart}
            imgPostion="left-[-1.0rem]"
          />
        </div>
      </div>
    </>
  )
}

InfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  imgPostion: PropTypes.string.isRequired
}

export default ShopExchangeCompleteModal
