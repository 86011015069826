import PropTypes from 'prop-types'
import clsx from 'clsx'

import BgLove from 'src/assets/img/gacha/bg_love.png'

const ItemThumbnail = ({
  image,
  rarity,
  loveAmount,
  className = ' border-menu-dark-blue rounded-sm',
  imgClassName = 'w-[60px]'
}) => {
  return (
    <div
      className={clsx(
        'flex justify-center items-center relative mt-2',
        className,
        !rarity && 'border-2 drop-shadow-md bg-white'
      )}
    >
      <img className={clsx(imgClassName)} src={image} />
      {loveAmount && (
        <div
          className="text-white truncate absolute right-0 bottom-1 w-[56px] h-[18px] flex justify-center items-center"
          style={{ backgroundImage: `url('${BgLove}')`, backgroundSize: '100% 100%' }}
        >
          <div className="leading-3">
            <span className="text-[6px] pl-1">LOVE度</span>
            <span className="text-[9px]">{loveAmount}</span>
          </div>
        </div>
      )}
    </div>
  )
}

ItemThumbnail.propTypes = {
  image: PropTypes.string.isRequired,
  rarity: PropTypes.bool,
  loveAmount: PropTypes.number,
  className: PropTypes.string,
  imgClassName: PropTypes.string
}

export default ItemThumbnail
