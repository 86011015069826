import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useAuthState } from 'src/providers/AuthProviders'
import getUserItems from 'src/services/get/getUserItems'
import { fetchData } from 'src/redux/gacha/getDataGacha'
import CapsuleToyPage from 'src/pages/main/capsule/CapsuleToy'

export const MIXER_ITEM_AMOUNTS = { R20: 20, SR10: 10, SSR5: 5 }

/**
 * TODO:
 * このページで使用されているテキストの多くはプレースホルダーです。
 * API経由でテキストを取得する必要がありますが、モックアップ/デザインが不明です。
 * リリース前にページを見直し、すべての表示テキストが正しいことを確認してください。
 * 特にガチャ倍率などは本文をご覧ください。
 */

const CapsuleToyContainer = () => {
  const [selectedIndex, setSelectedIndex] = useState(1)
  const [items, setItems] = useState([])

  const { user } = useAuthState()

  const dispatch = useDispatch()
  const { gachas, isLoading } = useSelector((state) => state.getDataGacha)
  useEffect(() => {
    if (gachas.length <= 0) {
      dispatch(fetchData())
    }
  }, [dispatch, gachas])

  useEffect(() => {
    const loadItem = async () => {
      try {
        const response = await getUserItems(user.id)
        setItems(response)
      } catch (error) {
        console.error('Error fetching items:', error)
      }
    }
    loadItem()
  }, [])

  return (
    <CapsuleToyPage
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
      isLoading={isLoading}
      itemsTicket={items}
      redCount={user.red_amount}
      blueCount={user.blue_amount}
      gachas={gachas}
      setItemsUpdate={setItems}
      items={items}
    />
  )
}

export default CapsuleToyContainer
