import clsx from 'clsx'
import PropTypes from 'prop-types'

const sizeClass = {
  sm: {
    fontSizeTitle: 'text-[0.5625rem]',
    fontSizeLevel: 'text-[0.5625rem]',
    heightBar: 'h-0.5'
  },
  md: {
    fontSizeTitle: 'text-[0.625rem]',
    fontSizeLevel: 'text-[0.875rem]',
    heightBar: 'h-1'
  },
  lg: {
    fontSizeTitle: 'text-base',
    fontSizeLevel: 'text-xl',
    paddingBottom: '2px',
    heightBar: 'h-2'
  },
  xl: {
    fontSizeTitle: 'text-[0.625rem]',
    fontSizeLevel: 'text-2xl',
    heightBar: 'h-2.5'
  }
}

const progressWrapperClass = {
  gradient: 'rounded-sm bg-[#D6D3D3]',
  red: 'rounded-full bg-[#FFFFFFE5]'
}

const progressBarClass = {
  gradient: 'rounded-sm bg-gradient-to-r-custom',
  red: 'rounded-full bg-[#FF4747]'
}

const progressWidth = (current, total) => {
  if (total === 0) return 0
  const percent = Math.round((current / total) * 100)
  return percent > 100 ? 100 : percent
}

export const LoveLevel = ({ love, className, size = 'sm', variant = 'gradient' }) => {
  const _size = sizeClass[size]

  const progressWrapper = progressWrapperClass[variant]
  const progressBar = progressBarClass[variant]

  return (
    <div className={`flex flex-col gap-1 ${className}`}>
      <div className="flex items-end justify-between">
        <div className={`font-bold ${_size.fontSizeTitle}`}>LOVE度</div>
        <div className="level-text font-bold leading-none">
          <span className="text-[10px]">LV.</span>
          <span className={`${_size.fontSizeLevel} leading-none`}>{love?.level}</span>
        </div>
      </div>
      <div className={clsx(`w-full p-[1px] ${_size.heightBar}`, progressWrapper)}>
        <div
          className={clsx('h-full rounded-l-full bg-[#FF4747]', progressBar)}
          style={{
            width: `${progressWidth(love?.current_items, love?.total_items)}%`
          }}
        />
      </div>
    </div>
  )
}

LoveLevel.propTypes = {
  love: PropTypes.shape({
    level: PropTypes.number.isRequired,
    total_items: PropTypes.number.isRequired,
    current_items: PropTypes.number.isRequired
  }),
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  variant: PropTypes.oneOf(['gradient', 'red'])
}

export const GrowthLevel = ({ grow, className, size = 'sm' }) => {
  const _size = sizeClass[size]
  return (
    <div className={`status ${className}`}>
      <div
        className={`flex items-center justify-between leading-[12px] pb-[${_size?.paddingBottom}]`}
      >
        <div className={`status-text text-[#00335b] font-semibold ${_size.fontSizeTitle}`}>
          育て度
        </div>
        <div className={`level-text font-light ${_size.fontSizeTitle}`}>
          <small>lv.</small>
          <span className={`${_size.fontSizeLevel}`}>{grow?.level}</span>
        </div>
      </div>
      <div className={`w-full rounded-[50px] bg-[#86e0db] ${_size.heightBar}`}>
        <div
          className={`bg-[#105285] rounded-[50px] ${_size.heightBar}`}
          style={{
            width: `${progressWidth(grow?.current_items, grow?.total_items)}%`
          }}
        />
      </div>
    </div>
  )
}

GrowthLevel.propTypes = {
  grow: PropTypes.shape({
    level: PropTypes.number.isRequired,
    total_items: PropTypes.number.isRequired,
    current_items: PropTypes.number.isRequired
  }),
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
}

const LevelProgress = ({ level, size = 'lg' }) => {
  return (
    <div className="font-hiragino status w-full">
      <LoveLevel size={size} love={level?.love} className="mb-1" />
      <GrowthLevel size={size} grow={level?.grow} />
    </div>
  )
}

LevelProgress.propTypes = {
  level: PropTypes.shape({
    love: PropTypes.shape({
      level: PropTypes.number.isRequired,
      total_items: PropTypes.number.isRequired,
      current_items: PropTypes.number.isRequired
    }).isRequired,
    grow: PropTypes.shape({
      level: PropTypes.number.isRequired,
      total_items: PropTypes.number.isRequired,
      current_items: PropTypes.number.isRequired
    }).isRequired
  }),
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
}

export default LevelProgress
