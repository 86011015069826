import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import ShopMethodSelectModal from 'src/pages/main/shop/components/ShopDiamondTab/ShopMethodSelectModal'

const ShopExplanatoryModal = ({ shopDetails }) => {
  const { closeModal, setModalContent, setButtons, setTitle } = useShopContext()

  const handleOpenMethodSelect = () => {
    setModalContent(<ShopMethodSelectModal />)
  }

  useEffect(() => {
    setButtons([
      {
        label: '閉じる',
        onClick: closeModal,
        type: 'close'
      },
      {
        label: '購入',
        onClick: handleOpenMethodSelect
      }
    ])
    setTitle('購入特典')
  }, [])

  return (
    <div className="bg-white font-semibold text-xs w-full py-6 text-candy-pink">
      <div className="text-xl font-semibold text-center whitespace-pre-line mb-4">
        {shopDetails.first_name}
      </div>
      <div className="whitespace-pre-wrap mx-4 p-4 bg-[#FFF7FC] rounded-md min-h-[100px]">
        {shopDetails.explanatory_text}
      </div>
    </div>
  )
}

ShopExplanatoryModal.propTypes = {
  shopDetails: PropTypes.object.isRequired
}

export default ShopExplanatoryModal
