import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { useAuthState } from 'src/providers/AuthProviders'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import getOrderDetails from 'src/services/get/getOrderDetails'
import ExchangeResult from 'src/pages/main/shop/components/ShopDiamondTab/ExchangeResult'

import DiamondRed from 'src/assets/img/common/diamond-red.png'

const ShopPurchaseResultScreen = ({ isError }) => {
  const { user } = useAuthState()
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('order_id')
  const { closeModal } = useShopContext()

  const { data: order, isFetched } = useQuery({
    queryFn: () => {
      if (!orderId) return
      return getOrderDetails(orderId)
    },
    queryKey: ['orderDetails', orderId],
    staleTime: Infinity,
    enabled: Boolean(orderId) && !isError,
    refetchOnWindowFocus: false
  })

  const paymentAmount = order?.payment_amount

  const paymentStatus = useMemo(() => {
    switch (order?.status) {
      case 'PAYSUCCESS':
        return 'success'
      case 'ERROR':
      case 'EXPIRED':
      case 'INVALID':
      case 'PAYSTART':
        return 'error'
      default:
        return 'pending'
    }
  }, [order?.status])

  useEffect(() => {
    if (!isFetched || paymentStatus !== 'pending') return

    closeModal()
  }, [isFetched, paymentStatus])

  if (!isFetched && !isError) return <></>

  return (
    <>
      {!isError && paymentStatus === 'success' && (
        <>
          <div className="bg-white h-full flex flex-col w-full font-semibold">
            <div className="text-base xs:text-lg font-bold text-center whitespace-pre-line my-4">
              アイテムの購入が完了しました！
            </div>

            <div className="bg-[#FFF7F7] text-base text-candy-pink flex flex-col items-center gap-6 py-2 mx-3 mb-6 rounded-sm">
              <ul className="list-disc text-[0.875rem] leading-3">
                <li>{order?.first_name}</li>
                {order?.second_name && (
                  <li className="scale-90 tracking-tighter mt-1">{order?.second_name}</li>
                )}
              </ul>

              <div className="text-center">
                <p>所持赤ダイヤ</p>

                {paymentAmount && (
                  <ExchangeResult
                    theme='pink'
                    imgSrc={DiamondRed}
                    from={user?.red_amount - (order?.red_diamond_amount || 0) || 0}
                    to={user?.red_amount || 0}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {(isError || paymentStatus === 'error') && (
        <>
          <div className="bg-white h-full flex flex-col w-full font-semibold">
            <div className="text-lg font-bold text-center whitespace-pre-line my-4">
              {'決済に失敗しました。\nもう一度やり直してください。'}
            </div>
          </div>
        </>
      )}
    </>
  )
}

ShopPurchaseResultScreen.propTypes = {
  isError: PropTypes.bool
}

export default ShopPurchaseResultScreen
