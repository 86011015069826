import { useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { authActionTypes, useAuthDispatch, useAuthState } from 'src/providers/AuthProviders'
import useChatRoom from 'src/services/common/useChatRoom'
import { saveMessage } from 'src/services/patch/saveMessage'
import { SpendHeartModal } from 'src/components/message/ChatBox/SpendHeartModal'
import { Modal } from 'src/components/common/Modal'
import { ForbiddenMessageModal } from 'src/pages/menu/ForbiddenMessageModal'
import { CheckForbiddenMessage } from 'src/services/post/checkForbiddenMessage'
import { canSpendHeart, userPossessionHeart } from 'src/lib/message'

import BtnSubmit from 'src/assets/img/realtalk/btn_submit.png'

export const InputStoryTalk = ({
  senderId,
  conversationId,
  handleNewMessages,
  setSizeInput,
  characterId,
  loveLvSettings,
  character,
  setIsMultiLoveModalOpen,
  setMultiLevel
}) => {
  const inputRef = useRef(null)
  const { user } = useAuthState()
  const authDispatch = useAuthDispatch()
  const [isOpenSpendHeartModal, setIsOpenSpendHeartModal] = useState(false)
  const { message, sendMessage } = useChatRoom(conversationId)
  const [newMessage, setNewMessage] = useState('')
  const [heightText, setHeightText] = useState(0)
  const [userHeart, setUserHeart] = useState(0)
  const [canSpendHeartState, setCanSpendHearState] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const handleClose = () => {
    setShowModal(false)
  }

  const handleModal = () => {
    setShowModal(true)
  }

  const spendHeartAmount = loveLvSettings?.chat_heart

  useEffect(() => {
    setUserHeart(userPossessionHeart(user))
    setCanSpendHearState(canSpendHeart(userHeart, spendHeartAmount))
  }, [user, userHeart, spendHeartAmount])

  const closeSpendHeartModal = useCallback(() => {
    setIsOpenSpendHeartModal(false)
  }, [])

  useEffect(() => {
    setSizeInput(heightText + 75)
  }, [heightText])

  useEffect(() => {
    handleNewMessages(message)
  }, [message])

  useEffect(() => {
    if (!newMessage) {
      const h = inputRef.current.scrollHeight
      inputRef.current.style.height = 'inherit'
      inputRef.current.style.height = `${h}px`
      setHeightText(h - 20)
    } else {
      setHeightText(0)
    }
  }, [newMessage])

  const clearInput = () => {
    setNewMessage('')
    inputRef.current.style.height = '40px'
    inputRef.current.value = null
    setHeightText(0)
  }

  const handleNewMessageChange = (event) => {
    const value = event.target.value
    const messageLength = value.replace(/\n/g, '').length

    if (messageLength <= 100) {
      setNewMessage(value)
    }
  }

  const handleSendMessage = async () => {
    closeSpendHeartModal()
    if (/^[\n\s]*$/.test(newMessage)) return
    try {
      const isForbiddenMessage = await CheckForbiddenMessage(newMessage)
      if (isForbiddenMessage) return handleModal()

      clearInput()
      sendMessage(newMessage)
      const { currentLevel, nextLevel } = await saveMessage(
        conversationId,
        senderId,
        characterId,
        newMessage
      )
      authDispatch({
        type: authActionTypes.SPEND_HEART,
        payload: spendHeartAmount
      })
      if (!currentLevel || !nextLevel) return
      setMultiLevel({
        currentLevel: currentLevel,
        nextLevel: nextLevel
      })
      setIsMultiLoveModalOpen(true)
    } catch {}
  }

  const ta = document.getElementById('input-message')

  let height = ta?.clientHeight

  const Up = () => {
    if (ta?.clientHeight !== height) {
      const h = document.getElementById('input-message').clientHeight
      setHeightText(h - 20)
    }
    height = ta?.clientHeight
  }

  return (
    <div className="bg-[#9AC8FFCC]/80 py-3 px-4">
      <div className="flex items-end gap-x-2">
        <textarea
          onMouseUp={() => Up()}
          id="input-message"
          ref={inputRef}
          value={newMessage}
          onChange={handleNewMessageChange}
          placeholder="メッセージを入力（最大100文字）"
          style={{
            width: 'calc(100% - 52px)',
            height: '2.25rem',
            maxHeight: 164,
            boxShadow: 'inset 0px 1px 3px rgba(0, 0, 0, 0.25)',
            minHeight: 36
          }}
          className="hidden-scroll w-full h-full rounded-[3px] pl-3 py-2.5 placeholder-shown:text-xs placeholder-shown:text-[rgba(68,68,68,0.78)] placeholder-shown:font-light text-xs text-[#333333] font-light leading-[18px]"
          rows={1}
        />
        <button
          disabled={newMessage.length === 0}
          className="h-[3rem] w-[44px] flex items-center justify-center rounded-[4px] cursor-pointer text-white font-bold mt-1 relative"
          onClick={() => setIsOpenSpendHeartModal(true)}
        >
          <img src={BtnSubmit} className="h-full w-full absolute top-2" />
        </button>
      </div>

      <SpendHeartModal
        isOpen={isOpenSpendHeartModal}
        closeModal={closeSpendHeartModal}
        onConfirm={handleSendMessage}
        userHeart={userHeart}
        spendHeartAmount={spendHeartAmount}
        canSpendHeart={canSpendHeartState}
        isNewConversation
        isSendTalk
      />

      <Modal
        open={showModal}
        onClose={handleClose}
        className="sm:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg font-hiragino flex items-center justify-center"
      >
        <ForbiddenMessageModal handleClose={handleClose} />
      </Modal>
    </div>
  )
}
InputStoryTalk.propTypes = {
  conversationId: PropTypes.number,
  senderId: PropTypes.number.isRequired,
  handleNewMessages: PropTypes.func,
  setSizeInput: PropTypes.func,
  characterId: PropTypes.number,
  loveLvSettings: PropTypes.object,
  character: PropTypes.object,
  setIsMultiLoveModalOpen: PropTypes.func,
  setMultiLevel: PropTypes.func
}
