import { CHARACTERS } from 'src/constants/characters'
import CrownIcon from 'src/assets/img/mypage/talent/crown.png'
import PropTypes from 'prop-types'

const getPageStyles = (currentPage) => ({
  background: currentPage === 'mypage' ? 'bg-sky-50' : 'bg-white',
  textStyle:
    currentPage === 'mypage'
      ? 'text-black text-[0.65rem] xs:text-sm '
      : 'text-cornflower-blue text-xs',
  display: currentPage === 'mypage' ? 'text-left' : 'hidden',
  avatar: currentPage === 'mypage' ? 'w-7 h-7 xs:h-9 xs:w-9' : 'h-9 w-9'
})

const CharacterInfo = ({ infor, character, pageStyle, isTruncatedName }) => (
  <>
    <div className="w-fit text-white font-semibold rounded-sm px-0.5 bg-cornflower-blue text-[0.625rem]">
      {character.groupName}
    </div>
    {isTruncatedName ? (
      <FormattedName name={character.name} />
    ) : (
      <p className={`font-semibold ${pageStyle.textStyle}`}>{character.name}</p>
    )}
    <div className={`text-[0.5625rem] text-cornflower-blue ${pageStyle.display}`}>
      {infor.total_love?.toLocaleString()} PT
    </div>
  </>
)

const TruncatedCharacterName = ({ character }) => (
  <div className="flex gap-1">
    <p className="w-fit text-white font-semibold rounded-sm px-0.5 bg-cornflower-blue text-[0.625rem]">
      {character.groupName}
    </p>
    <FormattedName name={character.name} />
  </div>
)

const FormattedName = ({ name }) => (
  <p className="text-cornflower-blue text-xs font-semibold">
    {name.substring(0, 2)} {name.substring(2)}
  </p>
)

const CharacterRankingItem = ({ infor, currentPage }) => {
  if (!infor.rank) return null

  const pageStyle = getPageStyles(currentPage)
  const character = CHARACTERS.find(({ id }) => id === infor.character_id) || {}
  const isTopRank = infor.rank < 4

  return (
    <div
      className={`flex items-center justify-center gap-1 sm:gap-3 rounded p-2 ${pageStyle.background}`}
    >
      <div className="relative">
        {isTopRank && <img src={CrownIcon} className="w-2 absolute" alt="Crown" />}
        <div className="text-lg font-semibold">{infor.rank}</div>
      </div>
      <div className="flex items-center justify-center gap-1">
        {isTopRank && (
          <img src={character.avatar} className={`${pageStyle.avatar} rounded-full`} alt="Avatar" />
        )}
        <div className={isTopRank ? 'flex flex-col items-start justify-center' : 'block'}>
          {isTopRank && character ? (
            <CharacterInfo
              infor={infor}
              character={character}
              pageStyle={pageStyle}
              isTruncatedName={currentPage !== 'mypage'}
            />
          ) : (
            <TruncatedCharacterName character={character} />
          )}
        </div>
      </div>
    </div>
  )
}

CharacterInfo.propTypes = {
  infor: PropTypes.object.isRequired,
  character: PropTypes.object,
  pageStyle: PropTypes.object.isRequired,
  isTruncatedName: PropTypes.bool.isRequired
}

TruncatedCharacterName.propTypes = {
  character: PropTypes.object
}

CharacterRankingItem.propTypes = {
  infor: PropTypes.object.isRequired,
  currentPage: PropTypes.string.isRequired
}

FormattedName.propTypes = {
  name: PropTypes.string.isRequired
}

export default CharacterRankingItem
