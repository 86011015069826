import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

export const fetchData = createAsyncThunk(
  'data/fetchData',
  async () => {
    const response = await axiosInstance.get(API_ROUTES.Gachas)
    return response.data.gachas
  }
)

export const getDataGacha = createSlice({
  name: 'getDataGacha',
  initialState: {
    gachas: [],
    isLoading: true,
    errorMessage: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.isLoading = true
      })

      .addCase(fetchData.fulfilled, (state, action) => {
        state.isLoading = false
        state.gachas = action.payload
      })

      .addCase(fetchData.rejected, (state, action) => {
        state.isLoading = false
        state.errorMessage = action.payload
      })
  }
})

export default getDataGacha.reducer
