import PropTypes from 'prop-types'
import styled from 'styled-components'
import LightningIcon from 'src/assets/img/common/lightning_icon.png'
import TagName from 'src/assets/img/common/tag_name.png'
import clsx from 'clsx'

const ItemSchedule = ({ schedule, character }) => {
  return (
    <div
      className={`flex w-full h-fit rounded-xl ${
        schedule?.is_premium_time ? 'bg-[#424242]' : character?.color
      } pl-3 py-2`}
    >
      <div className="flex flex-col gap-1.5 flex-1">
        <div className="flex flex-row gap-1">
          <DivTagName>
            <img src={LightningIcon} className="w-[7.45px] h-[10px]" />
            <span className="text-white text-[12px]">{schedule.live_type}</span>
          </DivTagName>
          <div className="flex gap-0.5 h-[19px] rounded-2xl bg-white items-center p-1.5">
            <span className="font-bold text-[16px] text-[#49595E]">{schedule.start_time}</span>
            <span className="relative bottom-0.5 text-[16px] text-[#49595E]">-</span>
            {schedule?.is_premium_time ? (
              <div className="flex items-center rounded-2xl gap-1 bg-[#000000] px-1 py-0.5 whitespace-nowrap xs:text-[9px] text-[8px]">
                <SpanYellowText>Premium {schedule.extra_time}分</SpanYellowText>
              </div>
            ) : (
              <>
                <div className="flex items-center justify-center rounded-2xl gap-1 bg-[#6F9BFF] px-1 py-0.5 whitespace-nowrap xs:text-[9px] text-[8px]">
                  <SpanWhiteText>Normal</SpanWhiteText>
                  <span className="text-white font-bold">{schedule.extra_time}分</span>
                </div>
                <div className="flex items-center justify-center rounded-2xl gap-1 bg-[#ff4648] px-1 py-0.5 whitespace-nowrap xs:text-[9px] text-[8px]">
                  <SpanWhiteText>Extra</SpanWhiteText>
                  <span className="text-white font-bold">{schedule.range_extra_time}分</span>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-1.5 flex-1 justify-center text-left">
          <div
            className={clsx(
              !schedule?.note_1 && !schedule?.note_2 && !schedule?.note_3 ? 'my-auto' : '',
              'flex text-white text-[19px] font-semibold'
            )}
          >
            {character?.name}
          </div>
          {schedule?.note_1 && (
            <div className="bg-[#49595E] rounded-xl border-solid border py-1 pl-2 items-center text-sm font-bold border-white text-white">
              {schedule.note_1}
            </div>
          )}
          {schedule.note_2 && (
            <div className="bg-[#ffdddb] rounded-2xl py-[3px] pl-2 items-center text-[11px] text-[#49595E] font-semibold">
              {schedule.note_2}
            </div>
          )}
          {schedule.note_3 && (
            <div className="text-white text-[9px] font-semibold break-all">※ {schedule.note_3}</div>
          )}
        </div>
      </div>
      <div className="w-[61px] -ml-1">
        <img
          src={schedule?.character_image_url}
          alt={character?.name}
          className="w-full h-auto"
        />
      </div>
    </div>
  )
}

ItemSchedule.propTypes = {
  schedule: PropTypes.object,
  character: PropTypes.object
}

export default ItemSchedule

const DivTagName = styled.div.attrs({
  className: 'flex flex-row w-[64px] h-[15px] items-center gap-0.5 pl-1'
})`
  background-image: url(${TagName});
  background-size: cover;
  background-repeat: no-repeat;
`

const SpanWhiteText = styled.span.attrs({
  className: 'font-bold'
})`
  color: rgba(255, 255, 255, 0.7);
`
const SpanYellowText = styled.span.attrs({
  className: 'font-bold'
})`
  color: rgba(253, 255, 149, 1);
`
