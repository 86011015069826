import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Header } from 'src/components/layouts/Header'
import { Footer } from 'src/components/layouts/Footer'
import { useSelector } from 'react-redux'
import { Container } from 'src/components/layouts/animation'

export const MainLayout = ({
  children,
  active,
  className,
  classNameHeader,
  style,
  showHeader = true,
  showFooter = true,
  useContainer = true
}) => {
  const { footer } = useSelector((state) => state.displayLayout)

  const LayoutWrapper = useContainer ? Container : 'div'

  return (
    <LayoutWrapper
      className={clsx(
        'sm:w-desktop-width w-screen h-screen m-h-screen mx-auto bg-white relative',
        className
      )}
      style={style}
    >
      {showHeader ? <Header className={classNameHeader} /> : null}
      <main>{children}</main>
      {footer && showFooter ? <Footer active={active} /> : null}
    </LayoutWrapper>
  )
}

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  active: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  classNameHeader: PropTypes.string,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  useContainer: PropTypes.bool
}
