import React from 'react'
import PropTypes from 'prop-types'
import ModalCommon from 'src/components/common/ModalCommon'
import { closeModalButton } from 'src/constants/button_close'
import iconReload from '../assets/btn_reload.png'
import mascotChara from 'src/assets/img/character/mascot/bye_bye.png'

const ModalPassportRejoin = ({ open, onClose }) => {
  if (!open) return <></>

  return (
    <ModalCommon
      title="入場制限"
      open={open}
      onClose={onClose}
      buttons={[closeModalButton('OK', onClose)]}
    >
      <div className="relative space-y-4 bg-white px-4 pt-6 pb-12 text-center text-[17px] text-[#6F9BFF] font-semibold">
        <p>
          <span className="text-[#FF0000]">制限時間</span>が切れました。
        </p>
        <p>プラチナパスポートをお持ちでない為、これ以上視聴することはできないコケ…。</p>
        <p>ショップから「プラチナパスポート」を購入した場合、再度参加が可能になるコケ！</p>
        <p className="text-[#FF0000]">
          ※既に「プラチナパスポート」を購入している場合、画面下部の「
          <img src={iconReload} className="inline w-5 pb-1" />
          」マークを押下して画面を更新してくださいコケ♪
        </p>
        <img className="absolute bottom-1 right-5 w-[73px]" src={mascotChara} />
      </div>
    </ModalCommon>
  )
}

ModalPassportRejoin.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

export { ModalPassportRejoin }
