import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import chroma from 'chroma-js'
import { keyframes } from 'styled-components'
import { getUserMiniGame, updateUserMiniGame } from 'src/services/hooks/useUserMiniGames'
import { useMutation, useQuery } from 'react-query'
import { authActionTypes, useAuthDispatch, useAuthState } from 'src/providers/AuthProviders'
import { queryClient } from 'src/providers/QueryProvider'
import useSound from 'use-sound'
import GameBgm from 'src/assets/bgms/mini_game_bgm.wav'
import ClickSound from 'src/assets/ses/mini_game_screen_tap.mp3'
import GameOverSound from 'src/assets/ses/game_over.mp3'
import { useConversation } from 'src/pages/main/dmTalk/hooks/useConversation'
import useChatRoom from 'src/services/common/useChatRoom'
import { HistoryRouteContext } from 'src/routes'

const MAX_STOCK_ARROW = 6
const DEFAULT_SPEED = 3

export const useMiniGamePlay = () => {
  const { redirectTo, locationState } = useContext(HistoryRouteContext)
  const { user } = useAuthState()

  const animationStartTime = useRef(Date.now())
  const containerRef = useRef(null)
  const targetRef = useRef(null)
  const targetCanvasRef = useRef(null)
  const arrowRef = useRef(null)

  const location = useLocation()
  const characterId = locationState?.characterId || location.state?.characterId

  const { data: conversationData, refetch } = useConversation('realtime_chat')
  const { message } = useChatRoom(conversationData?.conversation_id)

  const { data } = useQuery(['userMiniGameData', user.id], () => getUserMiniGame(user.id))
  const authDispatch = useAuthDispatch()
  const { mutate } = useMutation((data) => updateUserMiniGame(data), {
    onSuccess: ({ data }) => {
      if (!data) return
      authDispatch({ type: authActionTypes.ADD_HEART, payload: data })
      queryClient.invalidateQueries(['userMiniGameData', user.id])
      queryClient.invalidateQueries(['useConversation', user.id, characterId])
    }
  })

  const [step, setStep] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [arrowStockCount, setArrowStockCount] = useState(MAX_STOCK_ARROW)
  const [arrows, setArrows] = useState([{ id: 0 }])
  const [score, setScore] = useState(0)
  const [hitArrows, setHitArrows] = useState(0)
  const [stage, setStage] = useState(1)
  const [speed, setSpeed] = useState(DEFAULT_SPEED)
  const [receivedMsgLength, setReceivedMsgLength] = useState(0)
  const [isGameOver, setIsGameOver] = useState(false)
  const [isArrowFlying, setIsArrowFlying] = useState(false)
  const [playBgm, { stop: stopBgm }] = useSound(GameBgm, { volume: 0.3, loop: true })
  const [playClickSound] = useSound(ClickSound, { volume: 0.5 })
  const [playGameOverSound] = useSound(GameOverSound, { volume: 0.5 })
  const windowHeight = window.innerHeight
  const initialBottomPercentage = 5

  useEffect(() => {
    refetch(message)
  }, [message, refetch])

  useEffect(() => {
    if (!conversationData) return
    const newMsgLength = conversationData.character_messages?.length
    if (!receivedMsgLength) return setReceivedMsgLength(newMsgLength)
    if (receivedMsgLength < newMsgLength) gameOver(3)
  }, [conversationData, setReceivedMsgLength])

  useEffect(() => {
    playBgm()
    return () => {
      stopBgm()
    }
  }, [playBgm, stopBgm])

  const nextStage = useCallback(
    (initialBottomPixels) => {
      setStage((prev) => prev + 1)
      setArrowStockCount(MAX_STOCK_ARROW)
      setSpeed(() => DEFAULT_SPEED - 0.2 * stage)
    },
    [setStage, setArrows, setArrowStockCount, setSpeed, stage]
  )

  useEffect(() => {
    setArrows([{ id: 0 }])
  }, [stage])

  useEffect(() => {
    const initialBottomPixels = (windowHeight * initialBottomPercentage) / 100
    arrowRef.current.style.bottom = `${initialBottomPixels}px`
    arrowRef.current.classList.remove('hit')
    arrowRef.current.style.animation = 'none'
  }, [arrows])

  const colorDifference = useCallback((color1, color2) => {
    const lab1 = chroma.rgb(color1.r, color1.g, color1.b).lab()
    const lab2 = chroma.rgb(color2.r, color2.g, color2.b).lab()

    const deltaL = lab1[0] - lab2[0]
    const deltaA = lab1[1] - lab2[1]
    const deltaB = lab1[2] - lab2[2]

    return Math.sqrt(deltaL ** 2 + deltaA ** 2 + deltaB ** 2)
  }, [])

  const classifyColor = useCallback(
    (color) => {
      const redReference = { r: 255, g: 0, b: 0 }
      const blackReference = { r: 0, g: 0, b: 0 }

      const threshold = 40

      const colorDifferenceRed = colorDifference(color, redReference)
      const colorDifferenceBlack = colorDifference(color, blackReference)

      if (colorDifferenceRed < threshold) {
        validScore(30)
      } else if (colorDifferenceBlack !== 0 && colorDifferenceBlack < threshold) {
        gameOver()
      } else {
        validScore(10)
      }
    },
    [score, playGameOverSound]
  )

  const validScore = useCallback(
    (score) => {
      setScore((prev) => prev + score)
      setHitArrows((prev) => prev + 1)
    },
    [setArrows, setHitArrows]
  )

  const gameOver = useCallback(
    (modalStep = 2) => {
      stopBgm()
      targetCanvasRef.current.classList.add('stopped')
      containerRef.current
        .querySelectorAll('.hit')
        .forEach((arrow) => (arrow.style.animationPlayState = 'paused'))
      setArrowStockCount(0)
      gameOverOpenModal(modalStep)
      playGameOverSound()
      setIsGameOver(true)
    },
    [score, playBgm, playGameOverSound]
  )

  const gameOverOpenModal = useCallback(
    (modalStep) => {
      setModalOpen(true)
      if (data?.score <= score && data?.remaining_number !== 0) return setStep(1)
      setStep(modalStep)
    },
    [score]
  )

  const calculateCurrentRotation = useCallback(() => {
    const timeElapsed = Date.now() - animationStartTime.current
    const animationDuration = speed * 1000
    const rotationPerCycle = 360

    const currentCycleProgress = (timeElapsed % animationDuration) / animationDuration
    const currentRotation = rotationPerCycle * currentCycleProgress
    return currentRotation
  }, [speed])

  const getTargetColorAtPoint = useCallback((img, width, height, angle) => {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')

    ctx.save()
    ctx.translate(canvas.width / 2, canvas.height / 2)
    ctx.rotate((Math.PI / 180) * angle)

    const imgRatio = img.width / img.height
    const canvasRatio = canvas.width / canvas.height
    let newWidth, newHeight

    if (canvasRatio > imgRatio) {
      newWidth = canvas.height * imgRatio
      newHeight = canvas.height
    } else {
      newWidth = canvas.width
      newHeight = canvas.width / imgRatio
    }

    ctx.drawImage(img, -newWidth / 2, -newHeight / 2, newWidth, newHeight)
    ctx.restore()
    const imageData = ctx.getImageData(width / 2, height - 4, 1, 1)
    const [r, g, b, a] = imageData.data
    return { r, g, b, a }
  }, [])

  const handleClick = () => {
    if (isGameOver) return
    if (!isArrowFlying) playClickSound()
    if (!arrowRef.current || isArrowFlying || arrowStockCount < 1) return
    setIsArrowFlying(true)
  }

  useEffect(() => {
    if (isArrowFlying) {
      const initialBottomPixels = (windowHeight * initialBottomPercentage) / 100

      const interval = setInterval(() => {
        if (!arrowRef.current) return
        if (arrowRef.current.style.bottom === '') {
          arrowRef.current.style.bottom = `${initialBottomPixels}px`
        } else {
          const currentBottom = parseInt(arrowRef.current.style.bottom)
          arrowRef.current.style.bottom = `${currentBottom + 10}px`
        }
        if (!(arrowRef.current || targetRef.current)) return
        const arrowRect = arrowRef.current.getBoundingClientRect()
        const targetRect = targetRef.current.getBoundingClientRect()

        const arrowTipY = arrowRect.top
        const centerX = targetRect.left + targetRect.width / 2
        const centerY = targetRect.top + targetRect.height / 2

        const hitArrow = Array.from(containerRef.current.querySelectorAll('.hit')).some((arrow) => {
          const hitArrowRect = arrow.getBoundingClientRect()
          return (
            arrowRect.top <= hitArrowRect.bottom - 4 &&
            arrowRect.left + arrowRect.width / 2 >= hitArrowRect.left &&
            arrowRect.left + arrowRect.width / 2 + 12 <= hitArrowRect.right
          )
        })

        if (hitArrow) {
          clearInterval(interval)
          gameOver()
          setIsArrowFlying(false)
        }

        if (arrowTipY <= centerY + 100) {
          clearInterval(interval)
          arrowRef.current.classList.add('hit')

          const rotationCenterX = centerX - arrowRect.left
          const rotationCenterY = centerY - arrowRect.top
          arrowRef.current.style.transformOrigin = `${rotationCenterX}px ${rotationCenterY}px`

          arrowRef.current.style.animation = `${rotation.name} ${speed}s linear infinite`
          setArrowStockCount((prev) => prev - 1)

          if (arrowStockCount > 1) {
            setArrows((prevArrows) => [...prevArrows, { id: prevArrows.length }])
            setIsArrowFlying(false)
          } else {
            setTimeout(() => {
              setIsArrowFlying(false)
            }, 1000)
          }

          const targetImg = targetCanvasRef.current
          const angle = calculateCurrentRotation()

          const pointColor = getTargetColorAtPoint(
            targetImg,
            targetRect.width,
            targetRect.height,
            angle
          )
          classifyColor(pointColor)

          if (arrowStockCount !== 1) return
          if (stage < 10) return nextStage(initialBottomPixels)
          if (stage !== 10) return
          gameOverOpenModal()
        }
      }, 10)
    }
  }, [isArrowFlying])

  const navigateToPage = useCallback(
    (path) => {
      mutate({ userId: user.id, score })
      redirectTo(path)
    },
    [score]
  )

  return {
    containerRef,
    targetRef,
    targetCanvasRef,
    arrowRef,
    speed,
    characterId,
    stage,
    hitArrows,
    score,
    arrows,
    arrowStockCount,
    modalOpen,
    step,
    rotation,
    navigateToPage,
    handleClick
  }
}

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
