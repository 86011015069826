import React, { useState, useEffect, useRef } from 'react'
import { LiveInput } from '../../components/LiveInput'
import { postLiveComment } from '../../../../services/post/postLiveComment'
import clsx from 'clsx'
import { useDuringContext } from './_Provider'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useAuthState } from 'src/providers/AuthProviders'
import lovePointIcon from 'src/pages/voiceLive/assets/love_point.png'

const LiveComments = ({ normalPassportModal, canWorkWithFreePassport, livestreamId }) => {
  const { user } = useAuthState()
  const msgList = useRef()
  const [items, setItems] = useState([])
  const { liveItemsSocket, setAnimationItems } = useDuringContext()
  const [itemsExpensive, setItemsExpensive] = useState([])
  const [queueItems, setQueueItems] = useState([])

  const submitComment = async (msg) => {
    try {
      await postLiveComment({
        content: msg,
        livestream_id: livestreamId
      })
    } catch {}
  }

  const isSelf = (userId) => {
    return user?.id === userId
  }

  const getContent = (item) => {
    let textData = ''
    if (item.type === 'comment') {
      textData += `：${item.content}`
    } else if (item.type === 'joined-user') {
      textData += 'が参加しました'
    } else if (item.type === 'rejoined-user') {
      textData += 'が再度参加しました'
    }
    return textData
  }

  const bgItem = (userId) => {
    return isSelf(userId) ? 'bg-[#FFFFFFF2]' : 'bg-[#2A2A2A]'
  }

  const typeHourglass = (itemType) => {
    switch (itemType) {
      case 'blue_time':
        return '青'
      case 'red_time':
        return '赤'
      case 'gold_time':
        return '金'
      case 'rainbow_time':
        return '虹'
      default:
        return ''
    }
  }

  useEffect(() => {
    if (liveItemsSocket.item) {
      setItems([...items, liveItemsSocket.item])
    }
  }, [liveItemsSocket.item])

  useEffect(() => {
    if (!liveItemsSocket.expensiveItem) return
    if (_.isEmpty(liveItemsSocket.expensiveItem)) {
      setItemsExpensive([...itemsExpensive.slice(1)])
      setAnimationItems([...itemsExpensive.slice(1)])
    } else {
      if (items.length === 3) {
        setQueueItems([...queueItems, liveItemsSocket.expensiveItem])
      } else {
        setItemsExpensive([...itemsExpensive, liveItemsSocket.expensiveItem])
        setAnimationItems([...itemsExpensive, liveItemsSocket.expensiveItem])
        removeFirstItem()
      }
    }
  }, [liveItemsSocket.expensiveItem])

  useEffect(() => {
    if (items.length < 3 && queueItems.length > 0) {
      setItemsExpensive([...itemsExpensive, queueItems[0]])
      setAnimationItems([...itemsExpensive, queueItems[0]])
      removeFirstItem()
      setQueueItems([...queueItems.slice(1)])
    }
  }, [items])

  const removeFirstItem = () => {
    setTimeout(() => {
      liveItemsSocket.setExpensiveItem({})
    }, 9000)
  }

  useEffect(() => {
    if (msgList?.current) {
      msgList.current.scrollTop = msgList.current.scrollHeight
      if (msgList.current.scrollHeight > msgList.current.clientHeight) {
        console.log('')
      }
    }
  }, [items])

  return (
    <>
      {/* @ts-expect-error */}
      <div
        className="w-full h-[45vh] overflow-y-auto hidden-scroll flex flex-col gap-2 text-left mb-2 pr-3 pl-0.5"
        id="box-item"
        ref={msgList}
      >
        <div className="flex-1" />
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {['comment', 'joined-user', 'rejoined-user'].includes(item.type) ? (
              <div
                className={clsx(
                  'relative flex items-center break-all rounded-2xl px-2 py-1.5 font-hiragino text-[10px] text-left',
                  index === items.length - 1 && 'animation-fade-in',
                  `${bgItem(item.user_id)}`
                )}
              >
                <div className="flex items-center gap-1">
                  {item?.image_url && <img src={item?.image_url} className="w-[35px] h-auto" />}
                  <div>
                    <span
                      className={`font-bold ${
                        isSelf(item.user_id) ? 'text-[#6F9BFF]' : 'text-[#C0D3FF]'
                      }`}
                    >
                      {isSelf(item.user_id) && item.type === 'comment' ? 'me' : item.user_name}
                    </span>
                    <span
                      className={`font-medium ${
                        isSelf(item.user_id) ? 'text-[#6F9BFF]' : 'text-white'
                      }`}
                    >
                      {getContent(item)}
                    </span>
                  </div>
                </div>
              </div>
            ) : ['item', 'expensive-item', 'extra-time'].includes(item.type) ? (
              <div
                className={clsx(
                  'relative w-fit min-w-[128px] max-w-[80%] rounded-3xl px-3 py-1 font-hiragino text-[10px] bg-black drop-shadow-[0_0_2px_#fff]',
                  index === items.length - 1 && 'animation-fade-in'
                )}
                style={{
                  background:
                    item.type === 'extra-time'
                      ? 'linear-gradient(to right, #FFFFFF, #FFFFFFB2)'
                      : item.type === 'expensive-item'
                        ? 'linear-gradient(to right, #7747FF, #E40EDE, #FFC0CBB2)'
                        : 'linear-gradient(to right, #3836AD, #3836AD, #3632FF, #87CEFA)',
                  color: item.type === 'extra-time' ? '#6F9BFF' : 'white'
                }}
              >
                <div className="flex items-end gap-1.5">
                  {item?.image_url && (
                    <div className={`flex ${item.amount > 1 ? 'pl-4' : 'pl-11'}`}>
                      <img
                        src={item.type === 'expensive-item' ? item.animation_url : item.image_url}
                        className={`absolute top-1/2 left-2 -translate-y-1/2 ${
                          item.type === 'extra-time' ? 'w-10' : 'w-12'
                        }`}
                      />
                      {item.amount > 1 && (
                        <div className="flex justify-end items-end gap-1 text-white z-10 translate-y-2">
                          <span
                            className="text-lg font-semibold"
                            style={{
                              WebkitTextStroke: '1px black'
                            }}
                          >
                            x
                          </span>
                          <span
                            className="text-xl font-semibold"
                            style={{
                              WebkitTextStroke: '1px black'
                            }}
                          >
                            {item.amount}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="flex flex-col text-left overflow-hidden">
                    <span className="font-bold text-[17px] whitespace-nowrap text-ellipsis overflow-hidden">
                      {item.user_name}
                    </span>
                    <div className="flex items-center gap-1 text-[9px] font-semibold">
                      <div className="flex items-center text-[#878787] bg-[#F1F7FF] rounded-lg px-1">
                        +
                        <img src={lovePointIcon} />
                        <span className="text-[#FF7070]">{item.extra_love}</span>
                      </div>
                      <span>
                        {item.type === 'extra-time'
                          ? `砂（${typeHourglass(item.item_type)}）`
                          : item.item_name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </React.Fragment>
        ))}
      </div>

      <LiveInput
        normalPassportModal={normalPassportModal}
        canWorkWithFreePassport={canWorkWithFreePassport}
        onSend={(msg) => submitComment(msg)}
      />
    </>
  )
}

LiveComments.propTypes = {
  normalPassportModal: PropTypes.bool,
  canWorkWithFreePassport: PropTypes.bool,
  livestreamId: PropTypes.number
}

export { LiveComments }
