import { useContext, useEffect, useState } from 'react'
import { NoticePageContext } from 'src/pages/myPage/Notice/NoticeLayout.jsx'

import PropTypes from 'prop-types'
import clsx from 'clsx'

import { genDateString } from 'src/lib/date'
import { removeNewlineCode } from 'src/lib/string'
import getNewsDetail from 'src/services/get/getNewDetail'

import useLoading from 'src/components/layouts/hooks/useLoading'

import InnerHTML from 'dangerously-set-html-content'
import Loading from 'src/components/layouts/Loading'
import NoContent from 'src/components/layouts/NoContent'

import BtnNext from 'src/assets/img/common/forward_rounded_vector.png'
import FloatBackButton from 'src/assets/img/common/float_back_btn.png'
import { MYPAGE_PATHS } from 'src/routes/myPage'

const News = ({ id, user, newsList, currPage, setCurrPage, currNewsIndex, handleChangeNews }) => {
  const { checkHasUnreadNews } = useContext(NoticePageContext)
  const [isLoading, setIsLoading] = useState(false)
  const [news, setNews] = useState({})

  const getNewsDetailWrapper = async () => {
    if (!user) return
    const newsId = id || newsList[currNewsIndex]?.id
    if (!id && currNewsIndex === 0 && newsId) {
      handleChangeNews(0, id)
    }
    if (newsId) {
      setIsLoading(true)
      const res = await getNewsDetail(user.id, newsId)
      const findNews = res.find((news) => news.id === Number(newsId))
      setNews(findNews)
      setIsLoading(false)
      await checkHasUnreadNews()
    }
  }

  const handleChangePage = async (offset) => {
    const index = currNewsIndex + offset
    if (index === newsList.length) {
      setCurrPage(currPage + 1)
      handleChangeNews(0, null)
    } else if (index < 0) {
      setCurrPage(currPage - 1)
    } else handleChangeNews(index, newsList[index].id)
    // TODO: process redirecting from the last news of current page to the first news of next page and reverse
  }

  useEffect(() => {
    getNewsDetailWrapper()
  }, [user, newsList, currNewsIndex])

  const { showLoading, progress } = useLoading([isLoading])

  return (
    <>
      {showLoading ? (
        <div className="h-[calc(100dvh-200px)] flex items-center">
          <Loading progress={progress} />
        </div>
      ) : !news ? (
        <NoContent
          isModal
          message={'直前のページに戻ります。\nナビゲーターにお知らせください'}
          redirectUrl={MYPAGE_PATHS.NewsListPage}
        />
      ) : (
        <>
          <div className="w-11/12 mx-auto">
            <div className="flex flex-row items-start border-b border-b-sky-blue h-[5rem] relative">
              <button
                onClick={() => handleChangeNews(null, null)}
                className="relative top-1/3 -translate-y-1/2 transform"
              >
                <img src={FloatBackButton} className="w-[4.75rem]" />
              </button>
              <div className="text-left font-semibold w-5/6">
                <div className="flex items-center h-12 mb-1">
                  <p className="text-lg text-cornflower-blue text-left">{news?.title}</p>
                </div>
                <p className="text-xs text-sky-blue">{genDateString(news?.startDisplayDate)}</p>
              </div>
            </div>
            <div
              className="overflow-y-auto hidden-scroll px-4 pt-6"
              style={{ height: 'calc(100dvh - 220px)' }}
            >
              <div className="whitespace-pre-line text-left">
                {news?.content && <InnerHTML html={removeNewlineCode(news?.content)} />}
              </div>
            </div>
          </div>
          <div className="absolute top-[55%] w-full flex justify-between px-2.5 z-[9999]">
            <button
              className={clsx(currNewsIndex === 0 ? 'invisible' : null)}
              onClick={() => handleChangePage(-1)}
            >
              <img src={BtnNext} alt="before" className="rotate-180 w-4" />
            </button>
            <button
              className={clsx(currNewsIndex === newsList.length - 1 ? 'invisible' : null)}
              onClick={() => handleChangePage(1)}
            >
              <img src={BtnNext} alt="next" className="w-4" />
            </button>
          </div>
        </>
      )}
    </>
  )
}

News.propTypes = {
  id: PropTypes.string,
  user: PropTypes.object,
  newsList: PropTypes.array,
  currPage: PropTypes.number,
  setCurrPage: PropTypes.func,
  currNewsIndex: PropTypes.number,
  handleChangeNews: PropTypes.func
}

export default News
