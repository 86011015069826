import { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { HistoryRouteContext } from 'src/routes'

const EventItem = ({ event }) => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const getLinkTo = useMemo(
    () => (event.displayLink ? `/${event.link}` : `/notice/events/detail/${event.id}`),
    [event]
  )

  return (
    <div
      onClick={() => {
        if (event.pastEventsCurrPage) {
          localStorage.setItem('pastEventsCurrPage', event.pastEventsCurrPage)
        }
        redirectTo(getLinkTo)
      }}
    >
      <img className="w-full mb-1.5" src={event.image} alt={event.name} />
    </div>
  )
}

EventItem.propTypes = {
  event: PropTypes.object.isRequired
}

export default EventItem
