import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { MainLayout } from 'src/components/layouts/MainLayout'
import PresentItem from 'src/components/menu/PresentItem'
import { Tab } from '@headlessui/react'

import { Wrapper } from 'src/components/common/Wrapper'
import Loading from 'src/components/layouts/Loading'
import Present from 'src/models/Present'
import PresentAcceptModal from 'src/components/menu/PresentAcceptModal'
import styled from 'styled-components'
import Pagination from 'src/components/common/Pagination'
import useLoading from 'src/components/layouts/hooks/useLoading'

import BlueTick from 'src/assets/img/common/blue_tick.png'
import TipsModal from 'src/components/common/TipsModal'
import GradientHeader from 'src/components/common/GradientHeader'
import StandardPageWrapper from 'src/components/common/StandardPageWrapper'

import { isNormalPresent } from 'src/lib/present'

const Content = styled.div.attrs(({ height }) => ({
  className: `hidden-scroll overflow-y-auto font-medium mt-3 ${height || 'h-[calc(100dvh-320px)]'}`
}))``

const PresentPage = ({
  presents,
  isLoading,
  handleReceiveAll,
  handleReceiveOne,
  totalPage = 0,
  currPage,
  setCurrPage,
  listRef,
  classNameHeader = 'shadow-custom backdrop-blur-sm'
}) => {
  const [showModal, setShowModal] = useState(false)
  const [modalPresent, setModalPresent] = useState([])

  const onClickReceiveAll = useCallback(() => {
    setModalPresent(presents)
    handleReceiveAll('all')
    setShowModal(true)
  }, [setShowModal, handleReceiveAll, presents])

  const isNormalItem = useCallback((type) => isNormalPresent(type), [presents])

  const onClickReceiveOne = (present) => {
    handleReceiveOne(present.id, present.expiredAt)
    setModalPresent([present])
    setShowModal(true)
  }

  const { showLoading, progress } = useLoading([isLoading])

  if (showLoading) {
    return (
      <Wrapper>
        <Loading progress={progress} />
      </Wrapper>
    )
  }

  return (
    <MainLayout classNameHeader="!bg-gradient-sky-to-pink" active="mypage" className="">
      <StandardPageWrapper className="mt-10 pb-[20px]" overflow={true}>
        <Tab.Group>
          <GradientHeader tabList={[]} title="プレゼントBOX" />
          <Content>
            {presents.length === 0 ? (
              <div className="flex w-full justify-center flex-col items-center gap-4">
                <img
                  src={BlueTick}
                  className="border-[5px] border-cornflower-blue px-2 py-3 rounded-full"
                />
                <p className="text-cornflower-blue text-[0.8125rem]">
                  プレゼントは全て受け取り済みです。
                </p>
              </div>
            ) : (
              <>
                <ul className="hidden-scroll overflow-y-auto h-[calc(100dvh-330px)]">
                  <div ref={listRef} />
                  {presents.map((present) => {
                    const isNotExpired = new Date(present.expiredAt) > new Date()
                    return (
                      isNotExpired && (
                        <PresentItem
                          key={present.id}
                          present={present}
                          handleReceive={() => onClickReceiveOne(present)}
                          isNormalItem={isNormalItem}
                          isOnlyItem={presents.length === 1}
                        />
                      )
                    )
                  })}
                </ul>
              </>
            )}
          </Content>
          {presents.length > 0 ? (
            <div className="my-3">
              <Pagination totalPage={totalPage} currPage={currPage} setCurrPage={setCurrPage} />
            </div>
          ) : (
            <div className="h-12" />
          )}
          <button
            className={`w-5/6 rounded-[3rem] text-white py-2 font-semibold mx-auto ${
              presents.length === 0 ? 'bg-button-gray' : 'bg-sky-blue'
            }`}
            onClick={onClickReceiveAll}
            disabled={presents.length === 0}
          >
            {presents.length === 0 ? '一括受け取り' : 'ページ内一括受け取り'}
          </button>
        </Tab.Group>
      </StandardPageWrapper>

      {showModal && (
        <PresentAcceptModal
          show={showModal}
          onClose={() => {
            setShowModal(false)
            setModalPresent(null)
          }}
          presents={modalPresent}
          isNormalItem={isNormalItem}
        />
      )}
      <TipsModal type="present" />
    </MainLayout>
  )
}

PresentPage.propTypes = {
  classNameHeader: PropTypes.string,
  presents: PropTypes.arrayOf(PropTypes.instanceOf(Present)),
  isLoading: PropTypes.bool.isRequired,
  handleReceiveAll: PropTypes.func.isRequired,
  handleReceiveOne: PropTypes.func.isRequired,
  totalPage: PropTypes.number,
  currPage: PropTypes.number,
  setCurrPage: PropTypes.func,
  listRef: PropTypes.any
}

export default PresentPage
