import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import ModalCommon from 'src/components/common/ModalCommon'
import diamonRed from 'src/assets/img/common/diamond-red.png'
import ArrowRight from 'src/assets/img/common/arrow_right.png'
import PinkHeart from 'src/assets/img/common/heart-pink.png'
import BlueClock from 'src/assets/img/common/clock-blue.png'

import HourglassBlue from '../../assets/hourglass_circle_blue.png'
import HourglassRed from '../../assets/hourglass_circle_red.png'
import HourglassGold from '../../assets/hourglass_circle_gold.png'
import HourglassRainbow from '../../assets/hourglass_circle_rainbow.png'

const AddHourglassModal = ({
  open,
  selectedItem,
  onClose,
  user,
  amount,
  countHourglass,
  onSubmit
}) => {
  const typeHourglass = () => {
    const itemType = _.get(selectedItem, 'item_type')
    switch (itemType) {
      case 'blue_time':
        return '青'
      case 'red_time':
        return '赤'
      case 'gold_time':
        return '金'
      case 'rainbow_time':
        return '虹'
      default:
        return '虹'
    }
  }

  const getImageSource = () => {
    const itemType = _.get(selectedItem, 'item_type')
    switch (itemType) {
      case 'blue_time':
        return HourglassBlue
      case 'red_time':
        return HourglassRed
      case 'gold_time':
        return HourglassGold
      case 'rainbow_time':
        return HourglassRainbow
      default:
        return HourglassBlue
    }
  }

  const modalButtons = [
    {
      label: 'キャンセル',
      onClick: () => {
        onClose()
      },
      type: 'close'
    },
    {
      label: '決定',
      onClick: () => onSubmit()
    }
  ]

  return (
    <ModalCommon title="確認" open={open} onClose={onClose} buttons={modalButtons}>
      <div className="flex flex-col items-center justify-center bg-[#FFFFFF] text-[#6F9BFF] text-center font-bold px-6 pt-4 pb-6 mb-1">
        <div className="text-[17px]">時間延長の砂（{typeHourglass()}）</div>
        <div className="w-fit flex items-center gap-3 text-lg text-[#878787] bg-[#F8F8F8] rounded-lg px-2 py-0.5">
          <img src={diamonRed} className="w-6 h-6" />x
          <span>{selectedItem.number_diamond * amount}</span>
        </div>
        <div className="w-full flex items-center justify-between text-6xl font-[200] mt-1">
          <button disabled={amount === 1} onClick={() => countHourglass(-1)}>
            -
          </button>
          <img src={getImageSource()} className="w-20" />
          <button disabled={amount === 99} onClick={() => countHourglass(1)}>
            +
          </button>
        </div>
        <div className="text-[15px] my-5">
          贈るアイテム数×
          <span className="text-[19px] text-black font-medium ml-3">{amount}</span>
        </div>
        <div className="w-full flex items-center text-[#FF86C8] bg-[#F7F7F7] pl-3 pr-1 py-1 text-left rounded-md">
          <div className="flex items-center gap-1 text-[15px] w-3/5">
            <img src={PinkHeart} className="w-6 h-auto" />
            LOVE度
          </div>
          <div className="text-xl bg-white px-2 py-1 w-2/5 rounded whitespace-nowrap">
            +{selectedItem.extra_love * amount}
          </div>
        </div>
        <div className="w-full flex items-center bg-[#F7F7F7] pl-3 pr-1 py-1 text-left rounded-md mt-1 mb-6">
          <div className="flex items-center gap-1 text-[15px] w-3/5">
            <img src={BlueClock} className="w-6 h-auto" />
            追加時間
          </div>
          <div className="text-xl bg-white px-2 py-1 w-2/5 rounded whitespace-nowrap">
            +{selectedItem.extra_time * amount}秒
          </div>
        </div>
        <div className="w-full flex gap-3 bg-[#F3F9FF] rounded-lg pt-1 pl-3 pb-2">
          <img src={diamonRed} className="w-10 h-10" />
          <div className="flex flex-col items-start">
            <div className="text-[17px] font-semibold">所持赤ダイヤ</div>
            <div className="flex items-center gap-4 text-[15px]">
              <span className="text-[#878787]">{user.red_amount}</span>
              <img src={ArrowRight} />
              <span className="text-[#FF4747]">
                {user.red_amount - selectedItem.number_diamond * amount}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ModalCommon>
  )
}

AddHourglassModal.propTypes = {
  open: PropTypes.bool,
  selectedItem: PropTypes.object,
  onClose: PropTypes.func,
  user: PropTypes.object,
  amount: PropTypes.number,
  countHourglass: PropTypes.func,
  onSubmit: PropTypes.func
}

export { AddHourglassModal }
