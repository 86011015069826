import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  locations: [] // array element structure: {pathname, state}
}

export const historyRoute = createSlice({
  name: 'historyRoute',
  initialState,
  reducers: {
    saveCurrLocation: (state, action) => {
      const newLocation = action.payload
      if (
        state.locations.length === 0 ||
        state.locations[state.locations.length - 1].pathname !== newLocation.pathname
      ) {
        state.locations.push(newLocation)
      }
    },
    popLocation: (state) => {
      state.locations.pop()
    },
    resetLocations: (state) => {
      state.locations = []
    }
  }
})

export const { saveCurrLocation, popLocation, resetLocations } = historyRoute.actions

export default historyRoute.reducer
