import { createContext, useEffect, useMemo } from 'react'
import { useRoutes, useSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from 'src/providers/AuthProviders'
import { useMaintenanceContext } from 'src/providers/MaintenanceProviders'
import { MainRouter, NavigateToMyPage } from 'src/routes/main'
import { MenuRouter } from 'src/routes/menu'
import { MyPageRouter } from 'src/routes/myPage'
import {
  LoginRouter,
  LoginLineRouter,
  LiffRouter,
  NewUserRouter,
  TermRouter,
  MaintenanceRouter,
  NEW_USER_PATHS
} from 'src/routes/newUser'
import { popLocation, saveCurrLocation } from 'src/redux/route/historyRoute'
import { VoiceLivePath } from 'src/routes/live'
import { MangaRouter } from 'src/routes/manga'
import { EventRouter } from 'src/routes/sidestory'

export const HistoryRouteContext = createContext()

function useSyncHistory() {
  const location = useLocation()
  const dispatch = useDispatch()
  const historyLocations = useSelector((state) => state.historyRoute.locations)

  useEffect(() => {
    const lastLocation = historyLocations[historyLocations.length - 1]
    if (lastLocation && lastLocation.pathname !== location.pathname) {
      dispatch(popLocation())
    }
  }, [location, historyLocations, dispatch])
}

const IndexRouter = () => {
  const { isLoggedIn, user } = useAuthState()
  const { maintenance } = useMaintenanceContext()
  const [searchParams] = useSearchParams()
  const isActive = user.is_active
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const prevLocation = useSelector(
    (state) => state.historyRoute.locations[state.historyRoute.locations.length - 1]
  )

  useSyncHistory()

  const redirectTo = (pathname, options) => {
    const state = options?.state
    if (pathname === -1) {
      const prevPathname = prevLocation?.pathname
      if (prevPathname) {
        navigate(prevPathname)
        dispatch(popLocation())
      } else navigate(-1)
    } else {
      dispatch(
        saveCurrLocation({ pathname: window.location.pathname + window.location.search, state })
      )
      navigate(pathname, { state })
    }
  }

  useEffect(() => {
    const codeManagerId = searchParams.get('code_manager_id')

    localStorage.setItem('code_manager_id', codeManagerId || '')
  }, [])

  const routes = useMemo(() => {
    if (!isLoggedIn && location.pathname === NEW_USER_PATHS.LoginPage) return LoginRouter
    if (location.pathname === NEW_USER_PATHS.LiffPage) return LiffRouter
    if (!isLoggedIn) return LoginLineRouter
    if (!isActive) return [...NewUserRouter, ...TermRouter]
    if (maintenance?.maint_mode) return MaintenanceRouter

    return [
      ...MainRouter,
      ...MenuRouter,
      ...MyPageRouter,
      ...TermRouter,
      ...VoiceLivePath,
      ...NavigateToMyPage,
      ...MangaRouter,
      ...EventRouter
    ]
  }, [isLoggedIn, isActive, maintenance])

  return (
    <HistoryRouteContext.Provider value={{ redirectTo, locationState: prevLocation?.state }}>
      {useRoutes(routes)}
    </HistoryRouteContext.Provider>
  )
}

export { IndexRouter }
