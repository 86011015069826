export default class SideStory {
  constructor(
    id,
    description,
    bgmUrl,
    isBgmAudioRepeat
  ) {
    this.id = id
    this.description = description
    this.bgmUrl = bgmUrl
    this.isBgmAudioRepeat = isBgmAudioRepeat
  }
}
