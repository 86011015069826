import React from 'react'
import { StillAlbumContainer } from 'src/containers/StillAlbumContainer'
import EventList from 'src/pages/myPage/Notice/Event/EventList'
import EventArchiveList from 'src/pages/myPage/Notice/Event/EventArchiveList'
import EventDetailContainer from 'src/pages/myPage/Notice/Event/EventDetailContainer'
import NewsListContainer from 'src/pages/myPage/Notice/News/NewsListContainer'
import PresentPageContainer from 'src/pages/myPage/PresentPageContainer'
import { RankingEventsPage } from 'src/pages/myPage/Ranking/Events'
import { RankingRewardPage } from 'src/pages/myPage/Ranking/Reward'
import { RecommendMangaPage } from 'src/pages/myPage/Recommend/Manga'
import { RecommendProfilePage } from 'src/pages/myPage/Recommend/Profile'
import { RecommendPage } from 'src/pages/myPage/Recommend/Recommend'
import { RecommendSettingPage } from 'src/pages/myPage/Recommend/Setting'
import { RecommendVoiceAlbumPage } from 'src/pages/myPage/Recommend/VoiceAlbum'
import { SupportPage } from 'src/pages/myPage/Support'
import { TheaterPage } from 'src/pages/myPage/Theater'
import MyLoveLevelContainer from 'src/pages/myPage/Ranking/MyLoveLevel/MyLoveLevelContainer'
import TalentRankingContainer from 'src/pages/myPage/Ranking/TalentRanking/TalentRankingContainer'
import { RecommendDressUpPage } from 'src/pages/myPage/Recommend/DressUp'

const MYPAGE_PATHS = {
  RecommendDressUpPage: '/talent/dressup/:id',
  RankingRewardPage: '/ranking/award/:id',
  RankingEventsPage: '/ranking/events',
  RecommendPage: '/talent',
  RecommendMangaPage: '/talent/manga/:id/:name',
  RecommendProfilePage: '/talent/profile/:id/:name',
  RecommendSettingPage: '/talent/setting',
  RecommendVoiceAlbumPage: '/talent/voice-album/:id/:name',
  RecommendVoiceAlbumNonamePage: '/talent/voice-album/:id',
  RecommendStillAlbumPage: '/talent/still-album/:characterId/:name',
  RecommendStillAlbumDetailPage: '/talent/still-album/detail/:characterId/:name/:id',
  EventListPage: '/notice/events',
  EventDetailPage: '/notice/events/detail/:id',
  EventArchiveListPage: '/events/past',
  NewsListPage: '/notice/news',
  NewsDetailPage: '/notice/news/detail/:id',
  PresentPage: '/present',
  TheaterPage: '/theater',
  MyLoveLevelPage: '/ranking/mylevelranking',
  MyTalentPage: '/ranking/talent'
}
const MyPageRouter = [
  { path: MYPAGE_PATHS.RecommendDressUpPageRankingPage, element: <RecommendDressUpPage /> },
  { path: MYPAGE_PATHS.RankingRewardPage, element: <RankingRewardPage /> },
  { path: MYPAGE_PATHS.RankingEventsPage, element: <RankingEventsPage /> },
  { path: MYPAGE_PATHS.RecommendPage, element: <RecommendPage /> },
  { path: MYPAGE_PATHS.RecommendSettingPageRankingPage, element: <RecommendSettingPage /> },
  { path: MYPAGE_PATHS.RecommendStillAlbumPageRankingPage, element: <StillAlbumContainer /> },
  { path: MYPAGE_PATHS.RecommendStillAlbumDetailPageRankingPage, element: <StillAlbumContainer /> },
  { path: MYPAGE_PATHS.RecommendProfilePage, element: <RecommendProfilePage /> },
  { path: MYPAGE_PATHS.RecommendVoiceAlbumPageRankingPage, element: <RecommendVoiceAlbumPage /> },
  {
    path: MYPAGE_PATHS.RecommendVoiceAlbumNonamePage,
    element: <RecommendVoiceAlbumPage />
  },
  { path: MYPAGE_PATHS.RecommendMangaPage, element: <RecommendMangaPage /> },
  { path: MYPAGE_PATHS.PresentPage, element: <PresentPageContainer /> },
  {
    path: MYPAGE_PATHS.SupportPage,
    element: <SupportPage />
  },
  { path: MYPAGE_PATHS.TheaterPage, element: <TheaterPage /> },
  { path: MYPAGE_PATHS.EventListPage, element: <EventList /> },
  { path: MYPAGE_PATHS.EventArchiveListPage, element: <EventArchiveList /> },
  { path: MYPAGE_PATHS.EventDetailPage, element: <EventDetailContainer /> },
  { path: MYPAGE_PATHS.NewsListPage, element: <NewsListContainer /> },
  { path: MYPAGE_PATHS.NewsDetailPage, element: <NewsListContainer /> },
  { path: MYPAGE_PATHS.MyLoveLevelPage, element: <MyLoveLevelContainer /> },
  { path: MYPAGE_PATHS.MyTalentPage, element: <TalentRankingContainer /> }
]

export { MyPageRouter, MYPAGE_PATHS }
