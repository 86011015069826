import PropTypes from 'prop-types'
import clsx from 'clsx'

const DialogBox = ({
  text,
  background = '#FF75C0',
  position = 'top-[-1rem] left-[18%]',
  border = '',
  textStyle = 'text-[0.6875rem] text-white font-Inter',
  bottomBorder = '',
  rounded = 'rounded-md',
  boxShadow = ''
}) => {
  return (
    <div
      className={clsx(
        'absolute px-2 transform -translate-x-1/2 z-20 whitespace-nowrap',
        rounded,
        position,
        textStyle
      )}
      style={{
        backgroundColor: background,
        border: border,
        boxShadow: boxShadow
      }}
    >
      <p className="font-semibold">{text}</p>
      <div
        className={clsx(
          'z-20 absolute bottom-[-3px] left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-[3px] border-l-transparent border-r-[3px] border-r-transparent'
        )}
        style={{
          borderTop: `3px solid ${background}`
        }}
      />
      {bottomBorder && (
        <div
          className={clsx(
            'z-19 absolute bottom-[-5px] left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-[3px] border-l-transparent border-r-[3px] border-r-transparent z'
          )}
          style={{
            borderTop: `5px solid ${bottomBorder}`
          }}
        />
      )}
    </div>
  )
}

DialogBox.propTypes = {
  text: PropTypes.string.isRequired,
  background: PropTypes.string,
  position: PropTypes.string,
  border: PropTypes.string,
  textStyle: PropTypes.string,
  bottomBorder: PropTypes.string,
  rounded: PropTypes.string,
  boxShadow: PropTypes.string
}

export default DialogBox
