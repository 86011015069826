import React from 'react'
import PropTypes from 'prop-types'

import ModalCommon from 'src/components/common/ModalCommon'
import diamonRed from 'src/assets/img/common/diamond-red.png'
import ArrowRight from 'src/assets/img/common/arrow_right.png'
import PinkHeart from 'src/assets/img/common/heart-pink.png'

const AddLiveItemModal = ({
  open,
  selectedItem,
  onClose,
  user,
  amount,
  countLiveItem,
  onSubmit
}) => {
  const modalButtons = [
    {
      label: 'キャンセル',
      onClick: () => {
        onClose()
      },
      type: 'close'
    },
    {
      label: '決定',
      onClick: () => onSubmit()
    }
  ]

  return (
    <ModalCommon title="確認" open={open} onClose={onClose} buttons={modalButtons}>
      <div className="flex flex-col items-center justify-center bg-[#FFFFFF] text-[#6F9BFF] text-center font-bold px-8 py-4 mb-1">
        <div className="text-[17px]">時間延長の砂</div>
        <div className="w-fit flex items-center gap-3 text-lg text-[#878787] bg-[#F8F8F8] rounded-lg px-2 py-0.5">
          <img src={diamonRed} className="w-6 h-6" />x
          <span>{selectedItem.number_diamond * amount}</span>
        </div>
        <div className="w-full flex items-center justify-between text-6xl font-[200] mt-1">
          <button disabled={amount === 1} onClick={() => countLiveItem(-1)}>
            -
          </button>
          <img src={selectedItem.image?.url || ''} className="w-20" />
          <button disabled={amount === 99} onClick={() => countLiveItem(1)}>
            +
          </button>
        </div>
        <div className="text-[15px] my-4">
          贈るアイテム数×
          <span className="text-[19px] text-black font-medium ml-3">{amount}</span>
        </div>
        <div className="w-full flex items-center justify-between text-[#FF86C8] bg-[#F7F7F7] pl-3 pr-1 py-1 text-left rounded-md mb-2">
          <div className="flex items-center gap-1 text-[15px]">
            <img src={PinkHeart} className="w-6 h-auto" />
            LOVE度
          </div>
          <div className="text-xl bg-white px-3 py-1 rounded whitespace-nowrap">
            +{selectedItem.extra_love * amount}
          </div>
        </div>
        <div className="w-full flex gap-3 bg-[#F3F9FF] rounded-lg pt-1 pl-3 pb-2">
          <img src={diamonRed} className="w-10 h-10" />
          <div className="flex flex-col items-start">
            <div className="text-[17px] font-semibold">所持赤ダイヤ</div>
            <div className="flex items-center gap-4 text-[15px]">
              <span className="text-[#878787]">{user.red_amount}</span>
              <img src={ArrowRight} />
              <span className="text-[#FF4747]">
                {user.red_amount - selectedItem.number_diamond * amount}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ModalCommon>
  )
}

AddLiveItemModal.propTypes = {
  open: PropTypes.bool,
  selectedItem: PropTypes.object,
  onClose: PropTypes.func,
  user: PropTypes.object,
  amount: PropTypes.number,
  countLiveItem: PropTypes.func,
  onSubmit: PropTypes.func
}

export { AddLiveItemModal }
