import React from 'react'
import PropTypes from 'prop-types'
import diamondRed from 'src/assets/img/common/diamond-red.png'
import _ from 'lodash'

const LoveItem = ({ item, onClick }) => {
  return (
    <button
      className="space-y-1 active:border-[#00FFD1] border-[3px] border-transparent p-2 rounded-[4px]"
      onClick={onClick}
    >
      <div className="w-20 h-20 mx-auto font-bold p-1 flex items-center justify-center">
        <img src={item.image.url} />
      </div>

      <div className="mx-auto font-semibold text-[11px]">{_.get(item, 'name')}</div>

      <div className="flex items-center justify-center gap-2 text-xs font-semibold mt-4">
        <img src={diamondRed} className="inline w-6" />x<span>{_.get(item, 'number_diamond')}</span>
      </div>

      <div className="flex items-center justify-center gap-1 text-[#FF79C1]">
        <span className="font-medium text-[10px]">
          LOVE度
        </span>
        <span className="font-semibold text-xs">
          ＋{_.get(item, 'extra_love')}
        </span>
      </div>
    </button>
  )
}

LoveItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func
}

export { LoveItem }
