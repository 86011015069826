import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const MenuPart = ({ children, className }) => {
  return (
    <div
      className={clsx('mx-auto font-bold text-xs xs:text-sm flex flex-col items-center', className)}
    >
      {children}
    </div>
  )
}

MenuPart.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default MenuPart
