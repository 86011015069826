export default class StoryDetail {
  constructor(
    id,
    type,
    isBlackout,
    blackoutColor,
    address,
    characterName,
    description,
    characterVoiceUrl,
    isCharacterVoiceRepeat,
    backgroundImage,
    bgmUrl,
    isBgmAudioRepeat,
    isBgmStop,
    seUrl,
    isSeAudioRepeat,
    timeToChange,
    timeToLeave,
    characterImageThumbnail
  ) {
    this.id = id
    this.type = type
    this.isBlackout = isBlackout
    this.blackoutColor = blackoutColor
    this.address = address
    this.characterName = characterName
    this.description = description
    this.character_voice_url = characterVoiceUrl
    this.isCharacterVoiceRepeat = isCharacterVoiceRepeat
    this.backgroundImage = backgroundImage
    this.bgmUrl = bgmUrl
    this.isBgmAudioRepeat = isBgmAudioRepeat
    this.isBgmStop = isBgmStop
    this.seUrl = seUrl
    this.isSeAudioRepeat = isSeAudioRepeat
    this.timeToChange = timeToChange
    this.timeToLeave = timeToLeave
    this.characterImageThumbnail = characterImageThumbnail
  }
}
