import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import SpeakerIcon from 'src/assets/img/message/detail/speaker-icon.png'
import PauseIcon from 'src/assets/img/message/detail/pause-icon.png'
import { useAuthState } from 'src/providers/AuthProviders'
import useAudio from 'src/components/common/UseAudio'
import { SpendHeartModal } from 'src/components/message/ChatBox/SpendHeartModal'
import { formatDate } from 'src/lib/date'
import { canSpendHeart, userPossessionHeart } from 'src/lib/message'
import HTMLReactParser from 'html-react-parser'
import ModalPayShop from './ModalPayShop'
import { GiftDetail } from 'src/components/message/ChatBox/GiftDetail'
import { renderAvatar } from 'src/constants/avatar'
import { useParams } from 'react-router-dom'

export const ItemChatRealTalk = ({
  msg,
  loveLvSettings,
  character,
  changeBgImage,
  isScrollOpenTalk,
  handleUpdateMessage,
  indexMes,
  handleOpenGift,
  className
}) => {
  const { characterId } = useParams()
  const { advices } = msg
  const { user } = useAuthState()
  const [isOpenSpendHeartModal, setIsOpenSpendHeartModal] = useState(false)
  const [canViewDetail, setCanViewDetail] = useState(false)
  const [playing, playAudio, pauseAudio] = useAudio(msg.character_voice)
  const [userHeart, setUserHeart] = useState(0)
  const [canSpendHeartState, setCanSpendHearState] = useState(false)
  useEffect(() => {
    setUserHeart(userPossessionHeart(user))
    setCanSpendHearState(canSpendHeart(userHeart, loveLvSettings?.open_chat_heart))
  }, [user, userHeart])

  useEffect(() => {
    setCanViewDetail(msg.is_heart_consumption || character.navigator)
  }, [msg.is_heart_consumption, character])

  const onSpendHeartConfirm = () => {
    setIsOpenSpendHeartModal(false)
    setCanViewDetail(true)
  }

  const [openModal, setOpenModal] = useState(false)

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleCopy = (event) => {
    event.preventDefault()
  }

  const handleClickModal = () => {
    setOpenModal(true)
  }

  const { avatar, name } = character
  const urlImage = renderAvatar(avatar, name)
  const isAvailable = user.platinum_passport || characterId === '6'

  return (
    <div className="flex">
      <Avatar image={urlImage} />
      <div className="flex mt-2 flex-col w-[90%]">
        <DivRespon>
          <div
            style={{ border: '1px solid #EEF6FF' }}
            className={`order-first rounded-lg relative ${
              className || 'bg-[#EEF6FF]'
            } w-[75%] h-full px-2 pl-3 pt-[15px] ml-3.5 mt-2.5 z-10 break-words text-[#2A2A2A] shadow-xl`}
          >
            {!isAvailable && (
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                <button
                  className="z-50 bg-[#FF787E] h-[30px] text-xs w-[150px] rounded-full opacity-100"
                  onClick={handleClickModal}
                >
                  メッセージを表示する
                </button>
              </div>
            )}
            <ChatItem />
            {msg?.attachment_url && (
              <img className="w-full h-full object-contain" src={msg?.attachment_url} />
            )}
            <div>
              <div
                className={`text-justify text-[#2a2a2a] text-[0.875rem] font-normal whitespace-pre-line font-Inter leading-5 ${
                  !canViewDetail ? 'pb-3' : ''
                } ${isAvailable ? '' : 'blur-[4px]'}`}
                style={indexMes === 0 ? { filter: 'blur(0px)' } : {}}
                onCopy={isAvailable ? null : handleCopy}
              >
                {HTMLReactParser(msg?.title)} {''}
                <span dangerouslySetInnerHTML={{ __html: msg?.description }} />
              </div>
              {canViewDetail && advices?.length ? (
                <div className="mt-2.5">
                  <div className="bg-[linear-gradient(90deg,#FBED96_0%,#ABECD6_100%)] -mr-[8.5px] -ml-[12.5px] text-left pl-3.5 py-1 text-[#325282] text-[10px] font-medium">
                    {advices[0].title}
                  </div>
                  <div className="text-[#FAED97] text-[10px] text-justify pt-[7px] leadding-[15px]">
                    {advices[0].content}
                  </div>
                </div>
              ) : null}
              <div className="gap-6 mx-2">
                {(((msg?.character_voice_id || msg?.character_voice) && isAvailable) ||
                  ((msg?.character_voice_id || msg?.character_voice) && indexMes === 0)) && (
                  <div
                    style={{
                      background: playing
                        ? 'linear-gradient(90deg, #FFE259 0%, #FFA751 100%)'
                        : '#fff',
                      color: playing ? '#fff' : '#325282',
                      fontWeight: playing ? '600' : '500'
                    }}
                    className="relative rounded-[3px] shadow-[0px_2px_6px_rgba(0,0,0,0.25)] text-[9px] py-[3.2px] pl-5 pr-2 cursor-pointer mb-1 mt-2 h-5 flex items-center"
                  >
                    {playing ? (
                      <div onClick={pauseAudio}>
                        <IconWrapper
                          className="absolute top-0 -left-2"
                          background="linear-gradient(180deg, #FFE259 0%, #FFA751 107.5%)"
                        >
                          <img src={PauseIcon} alt="speaker" />
                        </IconWrapper>
                        ボイス再生中
                      </div>
                    ) : (
                      <div onClick={playAudio}>
                        <IconWrapper
                          className="absolute top-0 -left-2"
                          background="linear-gradient(180deg, #FFE259 0%, #FFA751 107.5%)"
                        >
                          <img src={SpeakerIcon} alt="speaker" />
                        </IconWrapper>
                        ボイスを聞く
                      </div>
                    )}
                  </div>
                )}
                {((msg?.setting_item_id && isAvailable) ||
                  (msg?.setting_item_id && indexMes === 0)) && (
                  <GiftDetail
                    msg={msg}
                    isReceived={msg?.is_received_present}
                    giftType="setting_item"
                    handleOpenGift={handleOpenGift}
                  />
                )}
                {(((msg?.character_cloth_id?.length > 0 || msg?.character_cloth?.length > 0) &&
                  isAvailable) ||
                  ((msg?.character_cloth_id?.length > 0 || msg?.character_cloth?.length > 0) &&
                    indexMes === 0)) && (
                  <GiftDetail
                    msg={msg}
                    isReceived={msg?.is_received_cloth}
                    giftType="character_cloth"
                    handleOpenGift={handleOpenGift}
                  />
                )}
              </div>
              <div
                className={`text-[12px] font-medium font-Inter mt-2 ${
                  className ? 'text-[#D9BBFF]' : 'text-[#9FBCFF]'
                } text-right`}
              >
                <span>{formatDate(new Date(msg?.created_at))}</span>
              </div>
            </div>
          </div>
          <SpendHeartModal
            isOpen={isOpenSpendHeartModal}
            closeModal={() => setIsOpenSpendHeartModal(false)}
            onConfirm={onSpendHeartConfirm}
            userHeart={userHeart}
            spendHeartAmount={loveLvSettings?.open_chat_heart}
            canSpendHeart={canSpendHeartState}
            isOpenRealtimeTalk
            isScrollOpenTalk={isScrollOpenTalk}
          />

          {openModal && <ModalPayShop show={openModal} handleClose={handleClose} />}
        </DivRespon>
      </div>
    </div>
  )
}

ItemChatRealTalk.propTypes = {
  msg: PropTypes.object.isRequired,
  loveLvSettings: PropTypes.object,
  character: PropTypes.object,
  changeBgImage: PropTypes.func,
  isScrollOpenTalk: PropTypes.func,
  handleUpdateMessage: PropTypes.func,
  indexMes: PropTypes.number,
  handleOpenGift: PropTypes.func,
  className: PropTypes.string
}

const IconWrapper = styled.div.attrs({
  className:
    'flex items-center justify-center h-5 w-5 rounded-[50%] overflow-hidden border border-white p-[4px]'
})`
  background: ${(props) => props.background};
`
const ChatItem = styled.div`
  position: absolute;
  top: 18px;
  left: -18px;
  clip-path: polygon(100% 0, 0 49%, 100% 100%);
  width: 18px;
  height: 14px;
  background-color: #eef6ff;
  z-index: 0;
  ::before {
    position: absolute;
    content: '';
    top: 0.5px;
    left: -0.5px;
    width: 20px;
    height: 13px;
    background: #eef6ff;
    clip-path: polygon(100% 0, 0 49%, 100% 100%);
  }
`
const DivRespon = styled.div.attrs({
  className: 'flex items-end w-full font-hiragino ml-2'
})`
  @media (max-width: 360px) {
    width: 85%;
  }
  @media (max-width: 320px) {
    width: 75%;
  }
`

const Avatar = styled.div.attrs({
  className: 'w-[40px] h-[40px] rounded-[50%] bg-[#D9D9D9] mt-[15px]'
})`
  background-image: url(${(props) => `${props.image}`});
  background-size: cover;
`
