import styled from 'styled-components'
import BackGround from 'src/assets/img/mini_game/background.png'
import ArrowTarget from 'src/assets/img/mini_game/target_arrow.png'
import ArrowTargetStock from 'src/assets/img/mini_game/target_arrow_stock.png'
import ScoreBoard from 'src/assets/img/mini_game/score_board.svg'
import HeartGet from 'src/assets/img/mini_game/title/title_geart_get.png'
import GameOver from 'src/assets/img/mini_game/title/title_gameover.png'
import FloatBackButton from 'src/assets/img/common/float_back_btn.png'
import { useContext } from 'react'
import { HistoryRouteContext } from 'src/routes'
import { useAuthState } from 'src/providers/AuthProviders'
import { RecoverHeart } from 'src/components/common/RecoverHeart'
import { PossessionHeart } from 'src/components/common/PossessionHeart'
import { useMiniGamePlay } from 'src/pages/MiniGame/hooks/useMiniGamePlay'
import { Modal } from 'src/components/common/Modal'

const MiniGamePlay = () => {
  const {
    containerRef,
    targetRef,
    targetCanvasRef,
    arrowRef,
    speed,
    stage,
    hitArrows,
    score,
    arrows,
    arrowStockCount,
    modalOpen,
    step,
    rotation,
    navigateToPage,
    handleClick
  } = useMiniGamePlay()
  const { user } = useAuthState()
  const {
    dream_compass: dreamCompass,
    platinum_passport: platinumPassport,
    possession_heart_recovery_or_maximum: heartRecoveryMaximum
  } = user
  const { redirectTo } = useContext(HistoryRouteContext)

  return (
    <Wrapper>
      <Container ref={containerRef} onClick={handleClick}>
        <div className="relative">
          <button
            className="absolute z-[100] top-5 left-0"
            onClick={() => {
              redirectTo(-1)
            }}
          >
            <img src={FloatBackButton} alt="IconBack" />
          </button>
          <div className="relative">
            <PossessionHeart classNameLife={'absolute w-6 top-3 right-24'} classNamePossessionHeart={'absolute top-3 left-[76%] grid content-center text-sm text-center text-black'}/>
            <div className="w-fit z-50 absolute right-[7.5rem] top-1">
              <RecoverHeart
                height={'h-9'}
                max={
                  platinumPassport && dreamCompass ? 12 : platinumPassport && !dreamCompass ? 8 : 4
                }
                heartRecoveryMaximum={heartRecoveryMaximum}
              />
            </div>
          </div>
        </div>
        <TargetContainer ref={targetRef} rotation={rotation} speed={speed}>
          <img
            src={require(`../../assets/img/mini_game/target/niwamaru/target_lv_${stage}.png`)}
            ref={targetCanvasRef}
            className="block w-52 h-52  xs:w-56 xs:h-56 mx-auto object-contain"
          />
        </TargetContainer>
        <Score>
          <ScoreContent>
            <img src={ScoreBoard} alt="score-board" className="w-36" />
            <p className="absolute top-[11%] left-1 font-semibold text-white">ステージ</p>
            <p className="absolute top-[43%] left-1 font-semibold text-white">刺さった弓矢</p>
            <p className="absolute top-[75%] left-1 font-semibold text-white">スコア</p>
            <p className="absolute top-[11%] right-7 font-semibold text-[#FF86C8]">{stage}</p>
            <p className="absolute top-[43%] right-6 font-semibold text-[#FF86C8]">{hitArrows}本</p>
            <p className="absolute top-[75%] right-6 font-semibold text-[#FF86C8]">{score}点</p>
          </ScoreContent>
        </Score>
        {arrows.map((arrow) => {
          return (
            <Arrow key={arrow.id} ref={arrow.id === arrows.length - 1 ? arrowRef : null}>
              <img src={ArrowTarget} alt="arrow" className="w-7" />
            </Arrow>
          )
        })}
        <ArrowStock>
          {[...Array(Math.max(arrowStockCount - 1, 0))].map((_, index) => (
            <img key={index} src={ArrowTargetStock} alt="arrow-target-stock" className="w-20" />
          ))}
        </ArrowStock>
        <Modal
          open={modalOpen}
          onClose={() => null}
          className="md:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg sm:w-full font-hiragino flex items-center justify-center"
        >
          <Content>
            {step === 1 ? (
              <>
                <img src={HeartGet} alt="heart-get" />
              </>
            ) : (
              <>
                <img src={GameOver} alt="game-over" className="cursor-pointer" />
              </>
            )}
            <button
              className={
                'w-[50%] text-white text-[24px] mt-6 font-bold text-center rounded-[32px] py-2 bg-[#6F9BFF]'
              }
              onClick={() => navigateToPage(-1)}
            >
              OK
            </button>
          </Content>
        </Modal>
      </Container>
    </Wrapper>
  )
}

export default MiniGamePlay

const Wrapper = styled.div.attrs({
  className:
    'sm:w-desktop-width w-[100dvw] h-[100dvh] mx-auto bg-no-repeat bg-center bg-cover text-[#333333] relative overflow-hidden'
})`
  background-image: url(${BackGround});
`

const Container = styled.div.attrs({
  className: 'w-full max-w-[400px] h-[100dvh]'
})``

const TargetContainer = styled.div.attrs({
  className: 'absolute mt-10 w-56 mx-auto z-20 left-1/2 transform -translate-x-1/2'
})`
  img {
    animation: ${(props) => props.rotation} ${(props) => props.speed}s infinite linear;
  }
  .stopped {
    animation-play-state: paused;
  }
`

const Score = styled.div.attrs({
  className: 'absolute bottom-10 left-4'
})``

const Arrow = styled.div.attrs({
  className: 'absolute left-1/2 z-10'
})`
  .hit {
    animation-name: none;
    pointer-events: none;
  }
`

const ArrowStock = styled.div.attrs({
  className: 'absolute right-3 bottom-8'
})``

const ScoreContent = styled.div.attrs({
  className: 'relative text-xs'
})``

const Content = styled.div.attrs({
  className: 'flex flex-col items-center'
})``
