import moment from 'moment'

export const formatDate = (date) => {
  if (!date) return 'Just now'
  const p = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }).formatToParts(date).reduce((acc, part) => {
    acc[part.type] = part.value
    return acc
  }, {})

  return `${p.month}/${p.day} ${p.hour}:${p.minute}`
}

export const formatTime = (time) => {
  if (!time) return ''
  return moment(new Date(time))
    .format('A h:mm')
    .replace('AM', '午前')
    .replace('PM', '午後')
}
