import PropTypes from 'prop-types'
import { Modal } from 'src/components/common/Modal'
import styled from 'styled-components'
import { HistoryRouteContext } from 'src/routes'

import { useCallback, useEffect, useRef, useState, useContext } from 'react'
import { isNextDayAfter5AM, TOKYO_TIME } from 'src/lib/date'
import { removeNewlineCode } from 'src/lib/string'
import InnerHTML from 'dangerously-set-html-content'

import Speaker from 'src/assets/img/speaker.png'
import BtnClose from 'src/assets/img/white-btn-close.png'
import { useLocation } from 'react-router-dom'
import { useAuthState } from 'src/providers/AuthProviders'
import getNews from 'src/services/get/getNews'
import { useQuery } from 'react-query'
import { filterDisplayTips } from 'src/lib/news'
import { MYPAGE_PATHS } from 'src/routes/myPage'

const TipsModal = (props) => {
  const { type, isDetail } = props
  const [isTipsModalOpen, setIsTipsModalOpen] = useState(false)
  const [isTipsDisplaySetting, setIsTipsDisplaySetting] = useState(false)
  const contentRef = useRef(null)
  const location = useLocation()
  const user = useAuthState()
  const news = useQuery('news', () => getNews(user.id, 'all'))
  const [tips, setTips] = useState([])
  const [countTips, setCountTips] = useState(0)
  const { redirectTo } = useContext(HistoryRouteContext)

  useEffect(() => {
    const data = news?.data
    if (!data) return

    let pathname = ''
    if (isDetail) {
      const pagePath = location.pathname.split('/')
      pagePath.pop()
      pathname = pagePath.join('/') + '/'
    } else {
      pathname = location.pathname
    }
    setTips(filterDisplayTips(data, 'tips', pathname))
  }, [news.data, setTips])

  useEffect(() => {
    if (tips.length > 0) {
      const scrollToTop = () => {
        if (!contentRef.current) return
        contentRef.current.scrollTop = 0
      }
      setTimeout(() => {
        scrollToTop()
      }, 100)
    }
  }, [contentRef, tips])

  useEffect(() => {
    if (tips.length > 0) {
      const storage = JSON.parse(localStorage.getItem('tips'))
      if (!storage || !Object.keys(storage).includes(type)) return setIsTipsModalOpen(true)
      if (!isNextDayAfter5AM(new Date(storage[type].time))) return setIsTipsModalOpen(false)
      setIsTipsModalOpen(true)
      const saveStorageData = JSON.stringify({ ...storage })
      localStorage.setItem('tips', saveStorageData)
    }
  }, [setIsTipsModalOpen, tips])

  const handleTipsDisplaySetting = useCallback(() => {
    setCountTips(countTips + 1)
    if (!isTipsDisplaySetting) return
    const storage = JSON.parse(localStorage.getItem('tips'))
    const saveStorageData = JSON.stringify({
      ...storage,
      [type]: { time: TOKYO_TIME, isSetting: true }
    })
    localStorage.setItem('tips', saveStorageData)
  }, [setIsTipsDisplaySetting, isTipsDisplaySetting, countTips])

  const replacePathParam = (path, paramName, paramValue) => {
    if (!path || !paramName) return path
    return path.replace(`:${paramName}`, paramValue)
  }

  return (
    <>
      {countTips < tips.length && (
        <Modal
          open={isTipsModalOpen}
          onClose={() => null}
          className="z-[9999] w-[85%] sm:w-[350px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 shadow-xl transform transition-all font-Inter rounded-xl overflow-y-auto bg-[#EEF6FF]"
        >
          <div
            onClick={handleTipsDisplaySetting}
            className="w-5 aspect-square absolute top-2 right-2 cursor-pointer z-[1002]"
          >
            <img src={BtnClose} alt="" onClick={handleTipsDisplaySetting} />
          </div>
          <ModalBody>
            <div className="w-full flex flex-col items-center justify-center rounded-md">
              <ModalTitle>
                <p className="relative z-[1000]">{tips[countTips]?.name}</p>
                <img src={Speaker} className="absolute top-2 left-2/3" />
              </ModalTitle>
              <div
                ref={contentRef}
                className="h-80 font-Inter font-light text-xs text-black text-justify leading-6 overflow-y-auto whitespace-pre-line hidden-scroll"
              >
                {tips.map((item, index) => (
                  <div key={item.id || index}>
                    {countTips === index && <InnerHTML html={removeNewlineCode(item.content)} />}
                  </div>
                ))}
              </div>
            </div>

            <div
              className={`bg-[#DAEBFF] flex justify-center items-center flex-col h-28 ${
                tips[countTips]?.displaySetting && 'pb-4'
              }`}
            >
              {tips[countTips]?.displaySetting && (
                <div className="w-full h-12 flex font-bold justify-center items-center text-cornflower-blue">
                  <input
                    className="mr-1 bg-sky-blue"
                    type="checkbox"
                    onChange={() => setIsTipsDisplaySetting(!isTipsDisplaySetting)}
                    checked={isTipsDisplaySetting}
                  />
                  <p className="text-xs">次の午前5時まで表示しない</p>
                </div>
              )}
              <button
                className="h-12 rounded-3xl font-semibold font-MPlus1 w-[90%] mx-auto bg-cornflower-blue xs:text-2xl text-white
                text-xl
                "
                onClick={() =>
                  redirectTo(
                    replacePathParam(MYPAGE_PATHS.EventDetailPage, 'id', tips[countTips]?.id)
                  )
                }
              >
                イベントページをCheck!
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

TipsModal.propTypes = {
  type: PropTypes.string.isRequired,
  isDetail: PropTypes.bool
}

export default TipsModal

const ModalBody = styled.div.attrs({
  className: 'w-full relative bg-light-gray'
})`
  background-size: 100% 100%;
`

const ModalTitle = styled.div.attrs({
  className:
    'w-full min-h-[68px] px-8 py-[7px] relative text-center text-white text-xl font-semibold font-Inter leading-[24.2px] mx-4 py-4 z-[1000]'
})`
  background: linear-gradient(94.15deg, #6f9bff 26.03%, #9ac8ff 67.73%);
  background-size: 100% 100%;
`
