import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getEventDetail } from 'src/services/get/getEventDetail'

import { MainLayout } from 'src/components/layouts/MainLayout'
import EventDetail from 'src/pages/myPage/Notice/Event/EventDetail'
import NoticeLayout from 'src/pages/myPage/Notice/NoticeLayout'

const EventDetailContainer = () => {
  window.scrollTo(0, 0)
  const { id } = useParams()
  const [currentEvent, setCurrentEvent] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const fetchEventDetail = async () => {
    setIsLoading(true)
    const res = await getEventDetail(id)
    setIsLoading(false)
    setCurrentEvent(res)
  }

  useEffect(() => {
    fetchEventDetail()
  }, [])

  return (
    <>
      <NoticeLayout title="お知らせ" activetab="news" isHideHeader={true} height="100%" classNameHeader=''>
        <MainLayout active="menu" showHeader={false}>
          <EventDetail event={currentEvent} isLoading={isLoading} />
        </MainLayout>
      </NoticeLayout>
    </>
  )
}

export default EventDetailContainer
