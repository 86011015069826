import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  header: true,
  footer: true
}

const displayLayout = createSlice({
  name: 'displayLayout',
  initialState,
  reducers: {
    setDisplayHeader: (state) => {
      state.header = !state.header
    },
    setDisplayFooter: (state) => {
      state.footer = false
    },
    resetLayout: () => initialState
  }
})

export const { setDisplayHeader, setDisplayFooter, resetLayout } = displayLayout.actions
export default displayLayout.reducer
