// @ts-nocheck
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import ExchangeButton from 'src/pages/main/shop/components/ShopHeartTab/ExchangeButton'
import ShopExchangeConfirmModal from 'src/pages/main/shop/components/ShopHeartTab/ShopExchangeConfirmModal'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'

import DiamondRed from 'src/assets/img/common/diamond-red.png'
import Life from 'src/assets/img/common/life.png'
import LightPink from 'src/assets/img/shop/light_pink_arrow.png'

const ContentBox = styled.div.attrs({
  className:
    'flex flex-col items-center justify-center w-16 p-1.5 my-2 border border-light-pinkish rounded-md text-sm text-bubblegum-pink font-bold font-Inter'
})``

const ShopExchangeHeart = ({ heartExchangeConfig }) => {
  const { setModalContent, setSelectedHeartExchange } = useShopContext()

  const numberOfRedDiamonds = parseInt(heartExchangeConfig?.number_of_red_diamonds)
  const numberOfHeartsExchanged = parseInt(heartExchangeConfig?.number_of_hearts_exchanged)

  const onSelect = () => {
    setSelectedHeartExchange(heartExchangeConfig)
    setModalContent(<ShopExchangeConfirmModal />)
  }

  return (
    <li className="flex items-center justify-between w-full h-full pl-2 pr-6">
      <div className="flex gap-2">
        <div className="flex items-center">
          <ContentBox>
            <img src={DiamondRed} className="w-9" />
            <span>x {numberOfRedDiamonds}</span>
          </ContentBox>
          <img src={LightPink} className="w-4 h-4" />
        </div>
        <ContentBox>
          <img src={Life} className="w-9" />
          <span>x {numberOfHeartsExchanged}</span>
        </ContentBox>
      </div>

      <ExchangeButton onClick={onSelect} />
    </li>
  )
}

ShopExchangeHeart.propTypes = {
  heartExchangeConfig: PropTypes.object.isRequired
}

export default ShopExchangeHeart
