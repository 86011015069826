import PropTypes from 'prop-types'
import { useEffect, useState, useContext } from 'react'
import clsx from 'clsx'

import { HistoryRouteContext } from 'src/routes'
import ModalCommon from 'src/components/common/ModalCommon'
import postUserItemAnalytics from 'src/services/post/postUserItemAnalytics'
import { useAuthState } from 'src/providers/AuthProviders'

import Life from 'src/assets/img/common/life.png'
import ArrowRight from 'src/assets/img/message/detail/arrow-right.svg'

import { MAIN_PATHS } from 'src/routes/main'

export const SpendHeartModal = ({
  isOpen,
  closeModal,
  onConfirm,
  userHeart,
  canSpendHeart,
  spendHeartAmount,
  isNewConversation = false,
  isSendTalk = false,
  isOpenTalk = false,
  isSendRealtimeTalk = false,
  isOpenRealtimeTalk = false,
  isScrollOpenTalk
}) => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const { user } = useAuthState()
  const [step, setStep] = useState(1)
  useEffect(() => {
    if (isOpen && !canSpendHeart) {
      postUserItemAnalytics(user.id, {
        is_user_show_modal_heart: true,
        ...(isSendTalk && { reason: 'send_talk' }),
        ...(isOpenTalk && { reason: 'open_talk' }),
        ...(isSendRealtimeTalk && { reason: 'send_realtime_talk' }),
        ...(isOpenRealtimeTalk && { reason: 'open_realtime_talk' })
      })
    }
  }, [isOpen, canSpendHeart])

  const modalButtons = [
    {
      label: 'キャンセル',
      onClick: () => {
        closeModal()
      },
      type: 'close'
    },
    {
      label: 'OK',
      onClick: () => {
        if (step === 1) {
          if (canSpendHeart) {
            onConfirm()
            if (isScrollOpenTalk) {
              isScrollOpenTalk()
            }
          } else {
            setStep(2)
          }
        } else {
          redirectTo(MAIN_PATHS.ShopPage)
        }
      }
    }
  ]

  return (
    <ModalCommon open={isOpen} onClose={closeModal} title="確認" buttons={modalButtons}>
      <div className={clsx('bg-[#FFFFFF] px-2 pt-6 text-center relative overflow-auto font-Inter', step === 1 ? 'pb-12' : 'pb-6')}>
        <h6 className="font-Inter text-center text-base text-[#484848] font-semibold mb-3">
          {step !== 1 ? (
            canSpendHeart && isNewConversation ? (
              <>
                トークの送信にはハートを{spendHeartAmount}個
                <br />
                消費します。送信しますか？
              </>
            ) : (
              <>
                トークの閲覧にはハートを{spendHeartAmount}個
                <br />
                消費します。閲覧しますか？
              </>
            )
          ) : (
            <>
              ハートが不足しています。<br />
              ショップに移動しますか？
            </>
          )}
        </h6>
        {step === 1 && (
          <>
            <div className="flex justify-center items-center mb-8">
              <div className="flex gap-2 text-[#878787] items-center justify-center bg-[#F8F8F8] rounded-xl w-2/5 p-1">
                <img className="w-10 h-10 mr-2" src={Life} alt="life" />
                <span className="font-bold">x</span>
                <span className="font-bold">{spendHeartAmount}</span>
              </div>
            </div>

            <div className="flex justify-center items-center flex-col bg-[#F3F9FF] rounded-md p-3 gap-3">
              <span className="font-Inter text-[#6F9BFF] font-semibold text-[17px]">
                所持ピンクハート
              </span>
              <div className="flex justify-center items-center gap-6">
                <img className="w-10 h-10 mr-2" src={Life} alt="life" />
                <span className="font-bold text-[#878787]">{userHeart}</span>
                <img className="w-3 h-3 mx-1.5" src={ArrowRight} alt="arrow" />
                <span className="font-bold text-[#FF4747]">
                  {canSpendHeart ? userHeart - spendHeartAmount : 0}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </ModalCommon>
  )
}

SpendHeartModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  userHeart: PropTypes.number.isRequired,
  canSpendHeart: PropTypes.bool.isRequired,
  spendHeartAmount: PropTypes.number,
  isNewConversation: PropTypes.bool,
  isSendTalk: PropTypes.bool,
  isOpenTalk: PropTypes.bool,
  isSendRealtimeTalk: PropTypes.bool,
  isOpenRealtimeTalk: PropTypes.bool,
  isScrollOpenTalk: PropTypes.func
}
