import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled, { keyframes } from 'styled-components'

import mascotChara from 'src/assets/img/character/mascot/good_mood.png'
import CommonBg from 'src/assets/img/common/bg_common.png'
import tagCharacterLive from 'src/pages/voiceLive/assets/tag_character_live.png'
import tagNormalTime from 'src/pages/voiceLive/assets/tag_normaltime.png'
import tagExtraTime from 'src/pages/voiceLive/assets/tag_extratime.png'
import tagPremiumTime from 'src/pages/voiceLive/assets/tag_premiumtime.png'
import lightningIcon from 'src/pages/voiceLive/assets/lightning_icon.png'
import lovePointIcon from 'src/pages/voiceLive/assets/love_point.png'
import clockIcon from 'src/pages/voiceLive/assets/clock_icon.png'

import Countdown from 'react-countdown'
import HiddenExtraTime from 'src/pages/voiceLive/components/HiddenExtraTime'
import Booth from 'src/components/myPage/livestream/Booth'
import ConditionModal from 'src/pages/voiceLive/components/ConditionModal'
import { ModalPassportRejoin } from 'src/pages/voiceLive/components/ModalPassportRejoin'
import ModalExtraContinue from 'src/components/myPage/livestream/ModalExtraContinue'

import { formattedCountDownTime } from 'src/lib/date'
import { postLiveTime } from 'src/services/post/postLiveTime'
import { VOICE_LIVE_PATH } from 'src/routes/live'
import { authActionTypes, useAuthDispatch } from 'src/providers/AuthProviders'
import { TIME_CONTINUE_MODAL } from 'src/constants/livestream'
import { postCountinueExtraTime } from 'src/services/post/postContinueExtraTime'
import { setFirstLive, setSecondLive } from 'src/redux/live/setLiveList'
import useCharacterListChannel from 'src/services/common/userCharacterListChannel'
import { ModalNoticeDiamond } from 'src/components/myPage/livestream/ModalNoticeDiamond'
import { ModalNoticeJoin } from 'src/components/myPage/livestream/ModalNoticeJoin'
import mascotSorry from 'src/assets/img/character/mascot/sorry.png'

const textModalOk =
  'この枠は限定配信だョ!\n' +
  'その為、条件を満たしてないユー\n' +
  'ザー様は参加することが出来ない\n' +
  'コケ。\n' +
  '限定配信の参加条件や詳しい内容\n' +
  'についてはお知らせやイベント\n' +
  'ページを確認するコケ!\n' +
  '参加できるはずなのに参加できな\n' +
  'い場合や参加条件が分からない場\n' +
  '合は、ニワ丸ことぼくの所まで聞\n' +
  'きに来て欲しいコケ！'

const textModalNg =
  'あなたはLIVE配信に参加できない\n' +
  'コケ・・・。\n' +
  '詳しくは、お知らせ内の \n' +
  '<a style="color:red" href="/news/detail/53">【重要】LIVE配信視聴時の注意事\n' +
  '項について </a> \n' +
  'をご確認くださいコケ!\n\n' +
  '上記お知らせにも記載があるよう \n' +
  '通り、参加できない理由について \n' +
  'はお問い合わせがあった際にもお\n' +
  '答えすることはできないコケ。'

const getChannelDetail = (liveSetting, livestream, user) => {
  const isHasPassport = Boolean(_.get(user, 'platinum_passport'))
  const isHasDreamcompass = Boolean(_.get(user, 'dream_compass'))
  const isAbsence = !livestream
  const incomming = !isAbsence && _.get(livestream, 'status') === 'incomming'
  const completion = !isAbsence && _.get(livestream, 'status') === 'completion'
  const flag = _.get(livestream, 'can_continue_extra')

  const currentTime = new Date()
  const isStartLive = new Date(_.get(livestream, 'start_time')) <= currentTime
  const isDisplayBlockingExtraTime =
    new Date(_.get(livestream, 'extra_watchable_time')) > currentTime
  const isDisplayBlockingWatchableTime = new Date(_.get(livestream, 'watchable_time')) > currentTime
  const isContinueExtra = new Date(_.get(livestream, 'extra_continue_time')) > currentTime
  const isStillContinueExtra =
    new Date(_.get(livestream, 'end_time')) - currentTime > TIME_CONTINUE_MODAL
  const isExtraTime = _.get(livestream, 'is_extra_time')
  const isRequirePassport = _.get(liveSetting, 'is_platinum_passport')
  const canJoinExtra = isRequirePassport ? isHasPassport : true
  const canJoinNormal =
    isStartLive && (isDisplayBlockingWatchableTime || (canJoinExtra && isDisplayBlockingExtraTime))

  const whiteList = _.get(liveSetting, 'white_list')
  const isNotInWhiteList = whiteList?.length > 0 && !whiteList.includes(_.get(user, 'id'))

  const blackList = _.get(liveSetting, 'black_list')
  const isInBlackList = blackList?.length > 0 && blackList.includes(_.get(user, 'id'))
  const canJoinWithDreamcompass = _.get(liveSetting, 'is_dream_compass') ? isHasDreamcompass : true

  const entranceImage = _.get(livestream, 'entrance_image')
  const typeVideo = entranceImage && entranceImage.includes('.mp4')
  const waitingImage = _.get(livestream, 'waiting_image')

  return {
    isAbsence,
    flag,
    incomming,
    completion,
    isStartLive,
    isDisplayBlockingExtraTime,
    isDisplayBlockingWatchableTime,
    isContinueExtra,
    isStillContinueExtra,
    isExtraTime,
    isRequirePassport,
    canJoinExtra,
    canJoinNormal,
    isNotInWhiteList,
    isInBlackList,
    canJoinWithDreamcompass,
    entranceImage,
    typeVideo,
    waitingImage,
    whiteList,
    blackList
  }
}

const LiveBooth = ({
  setOpenExtraModal,
  handleScrollToSchedule,
  livestream,
  refetch,
  user,
  liveSetting,
  title,
  isFirst
}) => {
  const navigate = useNavigate()
  const authDispatch = useAuthDispatch()
  const imgClassName = 'absolute object-cover w-full h-full'
  const boothContainer = 'relative overflow-hidden border-[1.5px] border-solid rounded-lg'

  const [open, setOpen] = useState(false)
  const [textModal, setTextModal] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [openModalPassportRejoin, setOpenModalPassportRejoin] = useState(false)
  const [modalType, setModalType] = useState('PLATINUM_PASSPORT')
  const [freeMinutes, setFreeMinutes] = useState('')
  const [sendModal, setSendModal] = useState(false)
  const [openModalContinue, setOpenModalContinue] = useState(false)
  const [openWaitingModal, setOpenWaitingModal] = useState(false)
  const [isLive, setIsLive] = useState(false)

  const isHasPassport = Boolean(_.get(user, 'platinum_passport'))
  const isHasDreamcompass = Boolean(_.get(user, 'dream_compass'))
  const isPremiumTime = _.get(liveSetting, 'is_premium_time')

  const {
    isAbsence,
    flag,
    incomming,
    isDisplayBlockingExtraTime,
    isContinueExtra,
    isStillContinueExtra,
    isExtraTime,
    canJoinExtra,
    isNotInWhiteList,
    isInBlackList,
    canJoinWithDreamcompass,
    entranceImage,
    typeVideo,
    waitingImage,
    isStartLive,
    completion,
    whiteList,
    blackList,
    canJoinNormal
  } = getChannelDetail(liveSetting, livestream, user)

  const dispatch = useDispatch()
  const characterLiveSocket = useCharacterListChannel({ liveSettingId: liveSetting.id })

  useEffect(() => {
    const startTime = _.get(liveSetting, 'start_time')
    if (new Date(startTime) < new Date()) {
      setIsLive(true)
    }
  }, [liveSetting])

  useEffect(() => {
    if (characterLiveSocket.reFetch) {
      refetch()
      characterLiveSocket.setReFetch(false)
    }
  }, [characterLiveSocket.reFetch])

  useEffect(() => {
    if (
      !_.isEmpty(characterLiveSocket.loveData) &&
      livestream.id === characterLiveSocket.loveData.livestream_id
    ) {
      const newChannel = { ...livestream, love_point: characterLiveSocket.loveData.love_point }
      if (isFirst) {
        dispatch(setFirstLive(newChannel))
      } else {
        dispatch(setSecondLive(newChannel))
      }
    }
  }, [characterLiveSocket.loveData])

  useEffect(() => {
    if (
      characterLiveSocket.watchableTime === undefined ||
      characterLiveSocket.watchableTime === '' ||
      characterLiveSocket.livestreamId !== livestream.id
    ) {
      return
    }
    const newChannel = { ...livestream, watchable_time: characterLiveSocket.watchableTime }
    if (isFirst) {
      dispatch(setFirstLive(newChannel))
    } else {
      dispatch(setSecondLive(newChannel))
    }
  }, [characterLiveSocket.watchableTime])

  const onSelectItem = useCallback(() => {
    if (whiteList.includes(_.get(user, 'id'))) {
      joinLivestream()
      return
    } else if (whiteList?.length > 0) {
      setOpenModal(true)
      setTextModal(textModalOk)
      return
    }

    if (blackList.includes(_.get(user, 'id'))) {
      setOpenModal(true)
      setTextModal(textModalNg)
      return
    } else if (blackList?.length > 0) {
      joinLivestream()
      return
    }

    const isDreamCompass = (() => {
      const requireDreamCompass = _.get(liveSetting, 'is_dream_compass')
      if (!requireDreamCompass) return true

      return isHasDreamcompass
    })()

    if (!isDreamCompass) {
      setOpen(true)
      setModalType('DREAM_COMPASS')
      setFreeMinutes(_.get(liveSetting, 'passport_free_time'))
      return
    }

    const isRequirePassport = _.get(liveSetting, 'is_platinum_passport')
    const isFreePassport = _.get(liveSetting, 'is_free_platium_passport')
    if (isRequirePassport && !isHasPassport) {
      if (!isFreePassport) {
        setOpen(true)
        setModalType('PLATINUM_PASSPORT')
      } else if (_.get(livestream, 'first_joined_at')) {
        setOpenModalPassportRejoin(true)
      } else {
        setOpen(true)
        setModalType('FREE_PLATINUM_PASSPORT')
        setFreeMinutes(_.get(liveSetting, 'passport_free_time'))
      }
      return
    }

    if (_.get(livestream, 'is_extra_time') && !_.get(livestream, 'can_join_extra_time')) {
      joinLivestream()
      return
    }
    joinLivestream()
  }, [user, livestream])

  const handleContinueExtra = () => {
    setSendModal(true)
  }

  const joinLivestream = () => {
    const path = VOICE_LIVE_PATH.DuringDelivery.replace(':title', title)
    navigate(path.replace(':channel_id', liveSetting.open_id))
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const hiddenNormalTime = () => {
    refetch()
  }

  const sendExtraContinueTime = async () => {
    if (user.red_amount >= 5) {
      const { item, flag, currentLevel, nextLevel } = await postLiveTime({
        livestream_id: livestream.id
      })
      if (item && currentLevel && nextLevel) {
        localStorage.setItem('item', JSON.stringify(item))
        localStorage.setItem('currentLevel', currentLevel)
        localStorage.setItem('nextLevel', nextLevel)
      } else {
        localStorage.setItem('item', '')
        localStorage.setItem('currentLevel', '')
        localStorage.setItem('nextLevel', '')
      }
      if (flag) {
        setSendModal(false)
        return
      }
      authDispatch({
        type: authActionTypes.SPEND_DIAMOND_RED,
        payload: 5
      })
      setSendModal(false)
      joinLivestream()
    } else {
      setSendModal(false)
      setOpenModalContinue(true)
    }
  }

  const isShowExtra = () => {
    if (isNotInWhiteList) return false

    if (isInBlackList) return false

    return (
      canJoinExtra &&
      !isDisplayBlockingExtraTime &&
      isExtraTime &&
      flag &&
      (openWaitingModal || (canJoinWithDreamcompass && isStillContinueExtra && isContinueExtra))
    )
  }

  const handleClickChannel = () => {
    if (isShowExtra()) {
      handleContinueExtra()
    } else if (
      isLive &&
      incomming &&
      (canJoinNormal || (isStartLive && canJoinExtra && isContinueExtra && !flag))
    ) {
      localStorage.setItem('item', '')
      localStorage.setItem('currentLevel', '')
      localStorage.setItem('nextLevel', '')
      onSelectItem()
    } else if (isExtraTime) {
      setOpenExtraModal(true)
    }
  }

  const bgBorder = () => {
    if (isPremiumTime) {
      return 'border-[#B5B944]'
    } else {
      return isExtraTime ? 'border-[#FF4747]' : 'border-[#4C9DFF]'
    }
  }

  const tagTime = () => {
    if (isPremiumTime) {
      return tagPremiumTime
    } else {
      return isExtraTime ? tagExtraTime : tagNormalTime
    }
  }

  const bgLovePoint = () => {
    if (isPremiumTime) {
      return 'text-[#B5B944]'
    } else {
      return isExtraTime ? 'text-[#FF4747]' : 'text-[#4C9DFF]'
    }
  }

  return (
    <>
      {!isLive && liveSetting && livestream ? (
        <div className="flex flex-col relative w-full">
          <div className="text-[#4C9DFF] absolute left-0 z-10 -top-4 text-xs">
            <img src={lightningIcon} className="inline-block mr-1" />
            {title}ブース
          </div>
          <div className={`${boothContainer} border-[#878787] text-white text-xs h-[186px]`}>
            <img src={waitingImage} className={imgClassName} />
            <div className="absolute w-full h-full opacity-80 bg-[#3A3A3A]" />
            <div
              className="absolute -top-1 -left-1 py-[5px] px-2 text-[#9AC8FF]"
              style={{
                backgroundImage: `url(${tagCharacterLive})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                backgroundPosition: 'center'
              }}
            >
              <span className="text-[12px] mr-1">next</span>
              <span className="text-[14px] mr-2">{livestream.name}</span>
            </div>
            <div className="absolute right-0 px-2 whitespace-nowrap">
              <div className="text-[9px]">次の配信まであと</div>
              <div className="bg-white text-[#8A8A8A] text-[13px] rounded-3xl py-0.5 px-3 whitespace-nowrap">
                <Countdown
                  date={_.get(livestream, 'start_time')}
                  onComplete={() => setIsLive(true)}
                  renderer={({ hours, minutes, seconds }) => {
                    return <div>{formattedCountDownTime(hours, minutes, seconds)}</div>
                  }}
                />
              </div>
            </div>
            <div
              className="absolute bottom-3.5 left-1/2 -translate-x-1/2 bg-transparent rounded-3xl border-[#9AC8FF] border-[3.5px] w-[135px] py-0.5 px-2.5 whitespace-nowrap cursor-pointer"
              onClick={handleScrollToSchedule}
            >
              LIVEスケジュールを
              <br />
              確認する
            </div>
          </div>
        </div>
      ) : incomming && livestream ? (
        <div className="flex flex-col relative w-full">
          <div
            className={`${
              isExtraTime ? 'text-[#FF4747]' : 'text-[#4C9DFF]'
            } absolute left-0 z-10 -top-4 text-xs`}
          >
            <img src={lightningIcon} className="inline-block mr-1" />
            {title}ブース
          </div>
          <div className="absolute w-full flex text-[14px] h-[25px]">
            <div className="absolute -top-5 right-1 w-full z-10">
              <StatusDiv gradient={isExtraTime ? '#ff7170' : '#97caff'} onClick={() => {}} />
              <img
                src={tagTime()}
                className="w-[110px] h-[35px] absolute top-0 -right-1 z-10"
              />
            </div>
          </div>
          {isShowExtra() && <ContinueDiv onClick={handleContinueExtra}>Continue!</ContinueDiv>}
          <div
            className={`${boothContainer} ${bgBorder()} cursor-pointer h-[186px]`}
            onClick={handleClickChannel}
          >
            {typeVideo ? (
              <video
                src={entranceImage}
                poster={CommonBg}
                className="w-full h-full"
                autoPlay
                loop
                muted
                playsInline={true}
              />
            ) : (
              <img src={entranceImage} className={imgClassName} />
            )}
            {!isAbsence && (
              <>
                {isStartLive && !isExtraTime && (
                  <div className={`absolute bottom-2 left-0 flex flex-col px-2 rounded-r-full w-[92px] h-[33px] ${
                    isPremiumTime ? 'bg-[#B5B944]' : 'bg-[#4C9DFF]'
                  }`}>
                    <div className="flex flex-row gap-1 mt-0.5">
                      <img src={clockIcon} className="w-[9.2px] h-[11.4px]" />
                      <span className="text-white text-[10px]">入場可能時間</span>
                    </div>
                    <div className="flex text-[16px] text-white relative bottom-1.5">
                      <Countdown
                        date={completion ? new Date() : _.get(livestream, 'watchable_time')}
                        onComplete={() => hiddenNormalTime()}
                        renderer={({ hours, minutes, seconds }) => {
                          return <div>{formattedCountDownTime(hours, minutes, seconds)}</div>
                        }}
                      />
                    </div>
                  </div>
                )}

                {canJoinExtra && incomming && isExtraTime && isDisplayBlockingExtraTime && (
                  <HiddenExtraTime
                    isShow={true}
                    time={_.get(livestream, 'extra_watchable_time')}
                    livestreamId={livestream.id}
                    className="absolute bottom-[55px] right-[0]"
                    onComplete={hiddenNormalTime}
                    characterLiveSocket={characterLiveSocket}
                  />
                )}

                {canJoinExtra && !flag && isContinueExtra && (
                  <HiddenExtraTime
                    isShow={true}
                    time={_.get(livestream, 'extra_continue_time')}
                    livestreamId={livestream.id}
                    className="absolute bottom-[55px] right-[0] hidden"
                    onComplete={() => {
                      postCountinueExtraTime({
                        livestream_id: livestream.id
                      }).then(() => {
                        setOpenWaitingModal(true)
                        hiddenNormalTime()
                      })
                    }}
                    characterLiveSocket={characterLiveSocket}
                  />
                )}

                <div className="absolute bottom-0 right-0 text-[10px] p-2 flex flex-col gap-1">
                  <div
                    className={`bg-white rounded-3xl pl-1 pr-2 py-0.5 w-fit ${bgLovePoint()}`}
                  >
                    <img src={lovePointIcon} className="inline-block mr-0.5" />
                    {livestream.love_point.toString().padStart(5, '0')}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <Booth title={title} handleScrollToSchedule={handleScrollToSchedule} />
      )}
      <ModalNoticeDiamond isOpen={openModalContinue} onClose={() => setOpenModalContinue(false)} />
      <ModalExtraContinue
        open={sendModal}
        onClose={() => setSendModal(false)}
        time={_.get(livestream, 'extra_continue_time')}
        sendExtraContinueTime={sendExtraContinueTime}
      />
      <ConditionModal
        open={open}
        modalType={modalType}
        freeMinutes={freeMinutes.toString()}
        setOpenModal={setOpen}
        joinLivestream={joinLivestream}
      />
      <ModalNoticeJoin
        title={textModalOk === textModal ? '限定配信中' : '重要なお知らせ'}
        isOpen={openModal}
        onClose={handleClose}
      >
        <div
          className="space-y-4 bg-[#FFFFFF] py-4 text-center mb-[16px] font-bold
        whitespace-pre-line leading-6 text-[15px] text-bold"
        >
          {textModalOk === textModal ? (
            <div>{textModal}</div>
          ) : (
            <p dangerouslySetInnerHTML={{ __html: textModal }} />
          )}

          <img
            className={`w-[73px] h-auto ml-auto !mb-0 mt-2  mr-[10%] ${
              textModalOk === textModal ? 'rotate-[20deg]' : ''
            }`}
            src={textModalOk === textModal ? mascotChara : mascotSorry}
          />
        </div>
      </ModalNoticeJoin>
      <ModalPassportRejoin
        open={openModalPassportRejoin}
        onClose={() => setOpenModalPassportRejoin(false)}
      />
    </>
  )
}

LiveBooth.propTypes = {
  setOpenExtraModal: PropTypes.func,
  handleScrollToSchedule: PropTypes.func,
  livestream: PropTypes.object,
  refetch: PropTypes.func,
  user: PropTypes.object,
  liveSetting: PropTypes.object,
  title: PropTypes.string,
  isFirst: PropTypes.bool
}

export default LiveBooth

const shake = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0.5px);
  }
`

const wave = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  100% {
    transform: scale(1.3, 1.9);
    opacity: 0;
  }
`

const StatusDiv = styled.div.attrs({
  className: 'absolute z-1 w-[110px] h-[25px] top-0 -right-1'
})`
  border-radius: 40px;
  animation: ${shake} 1s ease-in-out infinite, ${wave} 1s ease-in-out infinite;
  background: linear-gradient(to right, white 0%, ${({ gradient }) => gradient} 60%);
  opacity: 0.6;
  transform: scale(1);
  transform-origin: center;
`

const ContinueDiv = styled.div`
   {
    position: absolute;
    top: 40px;
    right: -6px;
    border-radius: 40px;
    border: 2px solid #ff4747;
    text-align: center;
    color: #ff4747;
    background: white;
    padding: 0 4px;
    z-index: 1;
  }

  ::before {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    left: 47px;
    bottom: -22px;
    border: 11px solid;
    border-color: #ff4747 transparent transparent #ff4747;
    clip-path: polygon(40% 0, 100% 0, 0 65%);
  }

  ::after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    left: 52px;
    bottom: -14px;
    border: 8px solid;
    border-color: #fff transparent transparent #fff;
    clip-path: polygon(50% 0, 100% 0, 0 70%);
  }
`
