// @ts-nocheck
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useMemo, useCallback, useContext } from 'react'
import styled from 'styled-components'
import { useAuthState } from 'src/providers/AuthProviders'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useConversation } from 'src/pages/main/dmTalk/hooks/useConversation'
import { InputDMTalk } from 'src/pages/main/storyTalk/components/InputDMTalk'
import VectorIcon from 'src/assets/img/common/white_backward_rounded_vector.png'
import LoveLevel from 'src/assets/img/message/love_level.png'
import { ItemChatReceived } from 'src/components/message/ChatBox/MessageReceived'
import { ItemChatRealTalk } from 'src/pages/main/storyTalk/components/ItemChatRealTalk'
import { BtnShowChatBox } from 'src/components/message/BtnShowChatBox'
import { isNavigator } from 'src/lib/character'
import { Modal } from 'src/components/common/Modal'
import TipsModal from 'src/components/common/TipsModal'
import GrowEffectModal from 'src/components/Effect/GrowEffectModal'
import MultiLoveEffectModal from 'src/components/Effect/MultiLoveEffectModal'
import { getConversationItem } from 'src/services/get/getChat'
import BtnReturn from 'src/assets/img/common/btn_return.png'
import NoContent from 'src/components/layouts/NoContent'

import MascotChara from 'src/assets/img/mini_game/mascot_chara.png'
import BackBtn from 'src/assets/img/mini_game/btn/btn_back.png'
import PlayBtn from 'src/assets/img/mini_game/btn/btn_play.png'
import MiniGameTitle from 'src/assets/img/mini_game/title/tittle_minigame.png'
import ModalBg from 'src/assets/img/mini_game/daishi.png'
import MiniGameIcon from 'src/assets/img/mini_game/btn/btn_game.png'
import BGModal from 'src/assets/img/common/bg-modal-confirm.png'
import BGModalTitle from 'src/assets/img/common/bg-modal-title.png'
import BtnClose from 'src/assets/img/common/btn-close.png'
import { MYPAGE_CHARACTER_IMAGES } from 'src/constants/characters'
import BackGroundDefault from 'src/assets/img/common/bg_common.jpg'
import BtnCheckBack from 'src/assets/img/realtalk/btn_check.png'
import BackGroundWattingOne from 'src/assets/img/realtalk/wating_back_1.png'
import BackGroundWattingTwo from 'src/assets/img/realtalk/wating_back_2.png'
import BgDMTalk from 'src/assets/img/realtalk/bg.svg'
import IconLove from 'src/assets/img/realtalk/heart_white.svg'

import { postBackGround, postIsCheckStory } from 'src/services/post/postBackGround'
import { useLoadingMore } from 'src/services/hooks/useLoadingMore'
import LoadingIcon from 'src/components/layouts/LoadingIcon'
import { MainLayout } from 'src/components/layouts/MainLayout'
import { ModalMiniGame } from 'src/components/common/ModalMiniGame'
import { ModalChangeBg } from 'src/components/common/ModalChangeBg'
import { HistoryRouteContext } from 'src/routes'
import { ModalGift } from 'src/components/common/ModalGift'
import { MAIN_PATHS } from 'src/routes/main'

export const DmTalkDetail = () => {
  const [hidden, setHidden] = useState(false)
  const [isExpand, setIsExpand] = useState(false)
  const [sizeInput, setSizeInput] = useState(0)
  const [isOpenGameModal, setIsOpenGameModal] = useState(false)
  const { user } = useAuthState()
  const lastScrollHeight = useRef(0)
  const roomChatRef = useRef()
  const {
    data: conversationData,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    addNewMessageToCache,
    updateMessageToCache,
    isFetched
  } = useConversation('realtime_chat')
  const { characterId } = useParams()
  const navigate = useNavigate()
  const [character, setCharacter] = useState({})
  const [loveLvSettings, setLoveLvSettings] = useState({})
  const [bgImage, setBgImage] = useState('')
  const [bgImagePreview, setBgImagePreview] = useState('')
  const [messages, setMessages] = useState([])
  const [scroll, setScroll] = useState(true)
  const [isPreview, setIsPreview] = useState(false)
  const [indexBg, setIndexBg] = useState(0)
  const [multiLevel, setMultiLevel] = useState({})
  const [isMultiLoveModalOpen, setIsMultiLoveModalOpen] = useState(false)
  const [isGrowModalOpen, setIsGrowModalOpen] = useState(false)
  const [isOpenGift, setIsOpenGift] = useState(false)
  const [modals, setModals] = useState([])
  const [currentModal, setCurrentModal] = useState({})
  const [conversationId, setConversationId] = useState()
  const [present, setPresent] = useState(null)
  const [checkText, setCheckText] = useState(0)
  const [checkClickIcon, setCheckClickIcon] = useState(true)
  const [checkClickIconSetting, setCheckClickIconSetting] = useState(false)
  const [checkClickIconBg, setCheckClickIconBg] = useState(true)
  const [isClickIconBg, setIsClickIconBg] = useState(false)
  const [files, setFiles] = useState('')
  const [fileVideo, setFileVideo] = useState([])
  const [backgroundSetting, setBackgroundSetting] = useState({})
  const { redirectTo } = useContext(HistoryRouteContext)
  const location = useLocation()
  const preUrl = location.state?.preUrl

  const isNoContent = useMemo(
    () => isFetched && !conversationData?.pages?.find((page) => page?.first_page)?.character?.allow_realtime_talk,
    [conversationData, isFetched]
  )

  const scrollToLast = () => {
    roomChatRef.current?.scrollTo({
      top: roomChatRef.current?.scrollHeight - lastScrollHeight.current
    })
    lastScrollHeight.current = 0
  }

  useEffect(() => {
    setBackgroundSetting(MYPAGE_CHARACTER_IMAGES[characterId])
  }, [characterId])

  const handleBgCharacter = () => {
    if (conversationData) {
      switch (conversationData.pages[0].background_type) {
        case 0:
          return BgDMTalk
        case 1:
          return BackGroundDefault
        case 2:
          return backgroundSetting
        default:
          return ''
      }
    }
  }

  const backgroundDef = {
    image: '',
    room: BackGroundDefault
  }

  const backgroundPrimary = {
    image: '',
    room: BgDMTalk
  }

  const listBackGround = [backgroundPrimary, backgroundDef, backgroundSetting]
  useEffect(() => {
    if (!conversationData) return

    setMessages(conversationData.pages.map((page) => page.messages).flat())
    const firstPage = conversationData.pages.find((page) => page.first_page)
    if (firstPage) {
      setCharacter(firstPage.character)
      setConversationId(firstPage.conversation_id)
      setLoveLvSettings(firstPage.character_setting_level)
      setBgImage(firstPage.last_character_background_image_url)
    }
  }, [conversationData])

  useEffect(() => {
    conversationData && refetch()
  }, [])

  useLoadingMore({
    selector: '#start',
    skip: !hasNextPage,
    onLoadMore: () => {
      lastScrollHeight.current = roomChatRef.current?.scrollHeight ?? 0
      fetchNextPage()
    }
  })

  useEffect(() => {
    if (scroll) {
      scrollToLast()
    } else {
      setScroll(true)
    }
  }, [messages])

  const handleSetModalList = (res) => {
    const arr = []
    res.forEach((data) => {
      if (data.current_level < data.next_level) {
        arr.push(data)
      }
    })
    if (arr.length > 0) {
      setModals(arr)
      setCurrentModal(arr[0])
    } else {
      setIsOpenGift(true)
    }
  }

  useEffect(() => {
    if (!currentModal) return
    currentModal.id && setIsGrowModalOpen(true)
  }, [currentModal])

  const handleClose = useCallback(() => {
    setIsOpenGameModal(false)
  }, [isOpenGameModal])

  const changeBgImage = useCallback(
    (msg) => {
      if (!msg || !msg.is_heart_consumption || !msg.character_background_image_url) return
      setBgImage(msg.character_background_image_url)
    },
    [setBgImage]
  )

  const closeMultiLoveModal = useCallback(() => {
    setIsMultiLoveModalOpen(false)
    refetch()
  }, [setIsMultiLoveModalOpen])

  const closeGrowModal = () => {
    if (modals && currentModal && currentModal.id !== modals[modals.length - 1].id) {
      const index = modals.findIndex((d) => d.id === currentModal.id)
      setIsGrowModalOpen(false)
      setTimeout(() => {
        setCurrentModal(modals[index + 1])
      }, 100)
    } else {
      setIsGrowModalOpen(false)
      setCurrentModal({})
      setIsOpenGift(true)
    }
  }

  const lastMsgIsOwner = useMemo(() => {
    const copyMsg = [...messages]
    return copyMsg?.reverse().find((message) => message)?.is_owner
  }, [messages])

  const handleUpdateMessage = (msg) => {
    const copyMessages = [...messages]
    const index = copyMessages.findIndex(
      (message) => msg.character_chat_id === message.character_chat_id
    )
    if (index >= 0) {
      copyMessages[index] = msg
      setMessages(copyMessages)
      updateMessageToCache(msg)
      changeBgImage(msg)
    }
  }

  useEffect(() => {
    const updateIsCheckStory = async () => {
      await postIsCheckStory({
        character_id: characterId,
        user_id: user.id,
        is_check_read: true
      })
    }

    updateIsCheckStory()
  }, [])

  const handleOpenGift = async (type, msg) => {
    setCheckText(type === 'character_cloth' ? 1 : 0)
    const giftId = type === 'character_cloth' ? msg.character_cloth_id : msg.setting_item_id
    const giftAmount = type === 'character_cloth' ? msg.number_of_avatar : msg.setting_item_amount
    if (
      (type === 'character_cloth' && !msg.is_received_cloth) ||
      (type === 'setting_item' && !msg.is_received_present)
    ) {
      const res = await getConversationItem(msg.character_chat_id, type, giftId, giftAmount)
      if (res) {
        if (res.characterLevel) {
          setCharacter({ ...character, level: res.characterLevel })
          setLoveLvSettings({ ...loveLvSettings, level: res.characterLevel })
        }
        if (!res.data.items) return
        setPresent(res.data.items)
        if (!res.characterLevels) return
        handleSetModalList(res.characterLevels)
        if (type === 'character_cloth') {
          msg.is_received_cloth = true
        } else if (type === 'setting_item') {
          msg.is_received_present = true
        }
      } else {
        console.log('プレゼントは受け取り済みです')
      }
    }
  }

  const handleSetBgCharacter = () => {
    setIsPreview(false)
    postBackGround({
      background_type: indexBg,
      character_id: characterId,
      user_id: user.id
    })
  }

  return (
    <MainLayout showFooter={false} classNameHeader=''>
      {
        isNoContent ? <div className="h-full flex items-center">
          <NoContent isModal message={'直前のページに戻ります。\nナビゲーターにお知らせください'} redirectUrl={MAIN_PATHS.RealtimePage}/>
        </div> : <>
          <ModalChangeBg
            isOpen={isClickIconBg}
            onClose={() => setIsClickIconBg(false)}
            listBackGround={listBackGround}
            onChangeBg={(url, index) => {
              setIndexBg(index)
              setIsClickIconBg(false)
              setIsPreview(true)
              setBgImagePreview(url)
            }}
          />
          <div className="h-[83px] md:w-[400px] w-full bg-[#6F9BFF66]/40 flex items-end justify-between px-[16px] z-[11] pb-2">
            <div className="flex items-center justify-between gap-2">
              <button>
                <img
                  className="w-[8px] h-[17px]"
                  src={VectorIcon}
                  alt=""
                  onClick={() => redirectTo(MAIN_PATHS.RealtimePage)}
                />
              </button>
              <span className="text-[18px] text-white">{character?.name}</span>
            </div>
            <div className="flex flex-col">
              <span className="text-[7px] text-[#FFFFFF] font-bold">LOVE度</span>
              <div
                className="flex h-[18px] items-center justify-center"
                style={{ backgroundImage: `url(${IconLove})`, backgroundRepeat: 'no-repeat' }}
              >
                <span className="font-Inter leading-[0px] text-[#A8C3FF] text-xs font-bold">
                  {character?.level?.love?.level}
                </span>
              </div>
            </div>
          </div>
          <div
            className={`${hidden ? 'hidden' : 'block'} bg-no-repeat bg-cover h-[calc(100dvh-167px)] relative`}
            style={{ backgroundImage: `url(${bgImagePreview || handleBgCharacter()})` }}
          >
            <div className={'rounded-[14px]'}>
              <WrapperChatBox isExpand={isExpand} sizeInput={sizeInput} ref={roomChatRef}>
                {conversationData && (
                  <img
                    src={MiniGameIcon}
                    alt="ミニゲームアイコン"
                    className="absolute top-2 right-3 w-[12%] aspect-square cursor-pointer z-50"
                    onClick={() => setIsOpenGameModal(true)}
                  />
                )}
                {isFetching ? <LoadingIcon /> : <div id="start" />}
                {messages.map((message, i) => (
                  <div key={i}>
                    {message?.is_owner ? (
                      <ItemChatReceived data={message} />
                    ) : (
                      <ItemChatRealTalk
                        msg={message}
                        loveLvSettings={loveLvSettings}
                        character={character}
                        changeBgImage={changeBgImage}
                        isScrollOpenTalk={() => setScroll(false)}
                        handleUpdateMessage={handleUpdateMessage}
                        setMultiLevel={setMultiLevel}
                        setIsMultiLoveModalOpen={setIsMultiLoveModalOpen}
                        handleOpenGift={handleOpenGift}
                      />
                    )}
                    <br />
                  </div>
                ))}
                <br />
              </WrapperChatBox>
            </div>
            <div className="fixed bottom-0 mx-auto z-10 w-screen sm:w-desktop-width">
              {isPreview ? (
                <div className="flex justify-around items-center h-[87px]">
                  <button
                    className={
                      'w-[40%] text-white text-[24px] font-bold text-center rounded-[32px] py-2 font-MPlus1 bg-metallic-gray'
                    }
                    onClick={() => {
                      setIsPreview(false)
                      setBgImagePreview(handleBgCharacter())
                    }}
                  >
                    キャンセル
                  </button>
                  <button
                    className={
                      'w-[40%] text-white text-[24px] font-bold text-center rounded-[32px] py-2 font-MPlus1 bg-cornflower-blue'
                    }
                    onClick={() => {
                      handleSetBgCharacter()
                    }}
                  >
                    変更する
                  </button>
                </div>
              ) : (
                <InputDMTalk
                  setCheckClickIcon={setCheckClickIcon}
                  setCheckClickIconBg={setCheckClickIconBg}
                  setIsClickIconBg={setIsClickIconBg}
                  setCheckClickIconSetting={setCheckClickIconSetting}
                  senderId={user?.id}
                  conversationId={conversationId}
                  handleNewMessages={(msg) => {
                    if (msg) {
                      setMessages([...messages, msg])
                      addNewMessageToCache(msg)
                    }
                  }}
                  setSizeInput={setSizeInput}
                  characterId={Number(characterId)}
                  loveLvSettings={loveLvSettings}
                  character={character}
                  setFiles={setFiles}
                  files={files}
                  setFileVideo={setFileVideo}
                  fileVideo={fileVideo}
                  setIsMultiLoveModalOpen={setIsMultiLoveModalOpen}
                  setMultiLevel={setMultiLevel}
                />
              )}
            </div>
          </div>
          <div className="absolute bottom-[11px] right-[16px]" onClick={() => setHidden(false)}>
            {hidden ? <BtnShowChatBox /> : null}
          </div>
          <ModalMiniGame
            isOpen={isOpenGameModal}
            onClose={handleClose}
            onPlay={() => navigate(MAIN_PATHS.MiniGame, { state: { characterId } })}
          />
          <MultiLoveEffectModal
            character={character}
            isMultiLoveModalOpen={isMultiLoveModalOpen}
            multiLevel={multiLevel}
            onClose={closeMultiLoveModal}
          />
          {currentModal && currentModal.id && (
            <GrowEffectModal
              character={currentModal}
              isGrowModalOpen={isGrowModalOpen}
              level={{ currentLevel: currentModal.current_level, nextLevel: currentModal.next_level }}
              onClose={closeGrowModal}
            />
          )}
          <ModalGift isOpen={isOpenGift} onClose={() => setIsOpenGift(false)} present={present} />
          <TipsModal type="message_detail" isDetail={true} />
        </>
      }
    </MainLayout>
  )
}

const ModalContainer = styled.div.attrs({
  className: 'h-[400px] w-[320px] bg-fill bg-no-repeat'
})`
  background-image: url(${ModalBg});
`

const ModalHeader = styled.div.attrs({
  className: 'w-[85%] mx-auto mt-[44px]'
})``

const ModalBody = styled.div.attrs({
  className: 'mt-6 w-[72%] mx-auto text-center text-xs leading-4 font-light'
})``

const ModalFooter = styled.div.attrs({
  className: 'flex justify-center w-[85%] mx-auto mt-6 cursor-pointer'
})``

const WrapperChatBox = styled.div.attrs({
  className: 'overflow-y-auto overflow-x-hidden hidden-scroll'
})`
  height: calc(100dvh - 167px);
  padding: 2px 4px 0 4px;
`

const GiftModalBody = styled.div.attrs({
  className: 'w-full h-fit px-8 relative'
})`
  background-image: url(${BGModal});
  background-size: 100% 100%;
`

const ModalTitle = styled.div.attrs({
  className:
    'h-fit px-8 py-[7px] relative text-center text-white text-xl font-vldLineGR leading-[25px] mx-4'
})`
  background-image: url(${BGModalTitle});
  background-size: 100% 100%;

  @media (max-width: 410px) {
    font-size: 1rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  @media (max-width: 316px) {
    font-size: 0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
`

const GiftBox = styled.div.attrs({
  className: 'mx-auto my-6'
})`
  background-size: 100% 100%;
  width: 152px;
  height: 172px;
`
