import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useChannels } from 'src/components/layouts/hooks/useChannel'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { MAIN_PATHS } from 'src/routes/main'
import { MENU_PATHS } from 'src/routes/menu'
import { HistoryRouteContext } from 'src/routes'

import useNewsListChannel from 'src/services/common/useNewsListChannel'
import useUserContactListChannel from 'src/services/common/useUserContactChannel'
import useNewsHasNotifications from 'src/hooks/useNewsHasNotifications'
import NotificationIcon from 'src/components/common/NotificationIcon'

import iconMypage from 'src/assets/img/footer/mypage.png'
import iconActiveMypage from 'src/assets/img/footer/mypage_active.png'
import iconTalk from 'src/assets/img/footer/talk.png'
import iconActiveTalk from 'src/assets/img/footer/talk_active.png'
import iconMenu from 'src/assets/img/footer/menu.png'
import iconActiveMenu from 'src/assets/img/footer/menu_active.png'
import iconGacha from 'src/assets/img/footer/gacha.png'
import iconActiveGacha from 'src/assets/img/footer/gacha_active.png'
import iconShop from 'src/assets/img/footer/shop.png'
import iconActiveShop from 'src/assets/img/footer/shop_active.png'

import { useAuthState } from 'src/providers/AuthProviders'
import useConversationListChannel from 'src/services/common/useConversationListChannel'

export const Footer = ({ active }) => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const { user } = useAuthState()
  const { data: channelList } = useChannels(user.id)
  const [hasUnreadMessage, setHasUnreadMessage] = useState(false)
  const [hasUnreadMessageStory, setHasUnreadMessageStory] = useState(false)
  const { message } = useConversationListChannel()

  const isNewMessageInquiry = useSelector((state) => state.getNotifications.isNewMessageInquiry)
  const inquiryNoti = useSelector((state) => state.getNotifications.inquiryNoti)
  const hasNews = useSelector((state) => state.getNotifications.hasNews)
  const hasNewsNotifications = useSelector((state) => state.getNotifications.hasNewsNotifications)

  const buttons = [
    {
      label: 'マイページ',
      icon: iconMypage,
      active: iconActiveMypage,
      cat: 'mypage',
      goto: MAIN_PATHS.MyPage
    },
    {
      label: 'ガチャ',
      icon: iconGacha,
      active: iconActiveGacha,
      cat: 'gacha',
      goto: MAIN_PATHS.CapsuleToyPage
    },
    {
      label: 'ショップ',
      icon: iconShop,
      active: iconActiveShop,
      cat: 'shop',
      goto: MAIN_PATHS.ShopPage
    },
    {
      label: 'トーク',
      icon: iconTalk,
      active: iconActiveTalk,
      cat: 'talk',
      goto: MAIN_PATHS.MessagePage
    },
    {
      label: 'メニュー',
      icon: iconMenu,
      active: iconActiveMenu,
      cat: 'menu',
      goto: MENU_PATHS.Menu
    }
  ]

  useEffect(() => {
    if (channelList) {
      const unread = (channelList || []).some(
        (item) => item.character.id !== 6 && item.unreadTotal > 0
      )
      setHasUnreadMessage(unread)
      const hasUnreadMessageStory = (channelList || []).every((data) => data.is_check_read === true)
      setHasUnreadMessageStory(hasUnreadMessageStory)
    }
  }, [channelList])

  useEffect(() => {
    if (message?.conversation_id && message?.roomType === 'realtime' && message?.unreadTotal > 0) {
      setHasUnreadMessage(true)
    }
    if (message?.roomType === 'single' && message?.is_check_read === false) {
      setHasUnreadMessageStory(true)
    }
  }, [message])

  useNewsListChannel()
  useUserContactListChannel()
  useNewsHasNotifications()

  return (
    <ul
      className="max-h-footer-offset fixed bottom-0 bg-cover bg-center bg-white grid grid-cols-5 z-50 shadow-[0_0_0_0.1px_#C5C5C5]"
      style={{ width: 'inherit' }}
    >
      {buttons.map((v, i) => {
        return (
          <ButtonFooter
            key={i}
            className={`text-xs text-center bg-cover bg-center relative p-4 cursor-pointer ${
              i === buttons.length - 1
                ? 'shadow-[0_0_0_0.1px_#C5C5C5,0_0_0_0.1px_#C5C5C5]'
                : 'shadow-[0_0_0_0.1px_#C5C5C5,0_0_0_0]'
            }`}
            onClick={() => redirectTo(v.goto)}
          >
            <img className="mx-auto h-[48px]" src={active === v.cat ? v.active : v.icon} />
            {v.cat === 'menu' &&
              (inquiryNoti || isNewMessageInquiry || hasNewsNotifications || hasNews) && (
              <NotificationIcon className="top-3 right-4" />
            )}
            {v.cat === 'talk' && (hasUnreadMessageStory || hasUnreadMessage) && (
              <NotificationIcon className="top-3 right-4" />
            )}
          </ButtonFooter>
        )
      })}
    </ul>
  )
}

const ButtonFooter = styled.li`
  @media (max-width: 395px) {
    padding-bottom: 1rem !important;
    p {
      font-size: 8px !important;
    }
  }
`

Footer.propTypes = {
  active: PropTypes.string
}
