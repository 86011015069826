import IdolEvent from 'src/models/Event'
import axiosInstance from '../common/axiosInstance'
import { API_ROUTES } from '../common/routes'
import bannerImageDefault from 'src/assets/img/mypage/banner/banner.png'

export const getEventDetail = async (id) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Events.getDetail.replace(':id', id))
    const event = res.data.event
    const ret = new IdolEvent(
      event.id,
      event.article_content_html,
      event.display_link,
      event.display_on_the_event_page,
      event.display_on_the_my_page,
      event.display_period_setting,
      event.start_time,
      event.end_time,
      event.image || bannerImageDefault,
      event.index,
      event.link,
      event.name
    )

    return ret
  } catch {
    return null
  }
}
