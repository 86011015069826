import React from 'react'
import './App.css'

import { BrowserRouter as Router } from 'react-router-dom'
import QueryProvider from 'src/providers/QueryProvider'
import ScrollToTop from 'src/components/common/ScrollToTop'
import { IndexRouter } from 'src/routes'
import { MaintenanceProvider } from 'src/providers/MaintenanceProviders'
import { stores } from 'src/stores/index'
import { Provider as ProviderRedux } from 'react-redux'

import styled from 'styled-components'
import ActionCableProvider from './providers/ActionCableProvider'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
}

TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <AppWrapper>
      <ProviderRedux store={stores}>
        <QueryProvider>
          <MaintenanceProvider>
            <ActionCableProvider>
              <Router>
                <ScrollToTop />
                <IndexRouter />
              </Router>
            </ActionCableProvider>
          </MaintenanceProvider>
        </QueryProvider>
      </ProviderRedux>
    </AppWrapper>
  )
}

export default App

const AppWrapper = styled.div.attrs({
  className: 'App w-screen bg-no-repeat bg-center bg-cover overflow-hidden h-[100dvh]'
})``
