import { useEffect, useState, useContext } from 'react'
import StandardPageWrapper from 'src/components/common/StandardPageWrapper'
import Carousel from 'src/pages/main/storyTalk/components/Carousel'
import useLoading from 'src/components/layouts/hooks/useLoading'

import { getChatList } from 'src/services/get/getChat'
import getCharacters from 'src/services/get/getCharacters'
import { useAuthState } from 'src/providers/AuthProviders'
import Loading from 'src/components/layouts/Loading'
import useConversationListChannel from 'src/services/common/useConversationListChannel'
import TipsModal from 'src/components/common/TipsModal'
import { useSelector, useDispatch } from 'react-redux'
import { fetchDataRecommend } from 'src/redux/character/getRecommendMans'
import { characterSlide } from 'src/constants/character_slide'
import { getMessages } from 'src/constants/message_talk'
import { DMTalkContext } from 'src/pages/main/dmTalk/DMTalk'

export const StoryTalk = () => {
  const { setLoadingRecommendMan } = useContext(DMTalkContext)
  const [currentImage, setCurrentImage] = useState()
  const [recommendMan, setRecommendMan] = useState([])
  const [miniRecommendMan, setMiniRecommendMan] = useState([])
  const [messages, setMessages] = useState([])
  const dispatch = useDispatch()
  const { recommendMans } = useSelector((state) => state.getRecommendMans)
  const [usedRecommendMans, setUsedRecommendMans] = useState([])

  useEffect(() => {
    if (!recommendMans.length) {
      dispatch(fetchDataRecommend())
    } else {
      const copy = [...recommendMans].map((man) => ({
        ...man,
        recommend: man.id === 1
      }))
      setUsedRecommendMans(copy.splice(0, 5))
    }
  }, [dispatch, recommendMans])

  const GETID = usedRecommendMans.find((item) => item.recommend)
  const { user } = useAuthState()
  const { message } = useConversationListChannel()

  useEffect(() => {
    ;(async () => {
      const [channelList, characters] = await Promise.all([
        getChatList(user.id, 'single_chat'),
        getCharacters()
      ])
      if (channelList) {
        setMessages(getMessages(characters, channelList))
      }
    })()
  }, [])

  useEffect(() => {
    if (usedRecommendMans.length === 0) return
    const dataSort = usedRecommendMans
      .filter((x) => !x.recommend)
      .sort((a, b) => {
        if (b.level.love.level - a.level.love.level !== 0) {
          return b.level.love.level - a.level.love.level
        } else if (b.level.love.current_items - a.level.love.current_items !== 0) {
          return b.level.love.current_items - a.level.love.current_items
        } else {
          return a.id - b.id
        }
      })
    const secondValue = dataSort[1]
    dataSort.splice(1, 1)
    dataSort.push(secondValue)
    dataSort.unshift(usedRecommendMans.find((x) => x.recommend))
    const updatedRecommendMan = characterSlide(dataSort)
    const index = updatedRecommendMan.findIndex((d) => d.recommend === true)

    if (index !== -1) {
      const first = updatedRecommendMan.slice(index)
      const second = updatedRecommendMan.slice(0, index)
      const dataResult = [...first, ...second]
      setRecommendMan(dataResult)
    }

    const miniResult = updatedRecommendMan.sort((a, b) => {
      if (a.recommend && !b.recommend) {
        return -1
      } else if (!a.recommend && b.recommend) {
        return 1
      } else if (b.level.love.level - a.level.love.level !== 0) {
        return b.level.love.level - a.level.love.level
      } else if (b.level.love.current_items - a.level.love.current_items !== 0) {
        return b.level.love.current_items - a.level.love.current_items
      } else {
        return a.id - b.id
      }
    })
    setMiniRecommendMan(miniResult)

    setCurrentImage(GETID?.id)
  }, [usedRecommendMans])

  const updateRecommendById = (newArr) => {
    setRecommendMan(newArr)
    const copyMini = [...miniRecommendMan]
    newArr.forEach((data) => {
      const index = copyMini.findIndex((d) => d.id === data.id)
      if (index > -1) {
        copyMini[index].recommend = data.recommend
      }
    })
    setMiniRecommendMan(copyMini)
  }

  useEffect(() => {
    if (!message?.conversation_id) return
    const copyMessages = [...messages]
    const index = copyMessages.findIndex((msg) => msg.conversation_id === message.conversation_id)
    if (index >= 0) {
      copyMessages[index] = { ...copyMessages[index], ...message }
      setMessages(copyMessages)
    }
  }, [message])
  useEffect(() => {
    setLoadingRecommendMan(recommendMan.length === 0)
  }, [recommendMan])
  const { showLoading, progress } = useLoading([recommendMan?.length === 0])

  if (showLoading || recommendMan?.length === 0) {
    return <Loading progress={progress} />
  } else {
    return (
      <>
        <StandardPageWrapper
          className={
            'carousel-standard-wrapper flex flex-col overflow-x-clip h-[calc(100dvh-230px)] fixed top-[160px]'
          }
        >
          <Carousel
            defaultValue={usedRecommendMans}
            mypageImage={recommendMan.map((itemA) => {
              const matchingItemB = messages.find((itemB) => itemB.character.id === itemA.id)
              if (matchingItemB) {
                return {
                  ...itemA,
                  unreadTotal: matchingItemB.unreadTotal
                }
              }
              return itemA
            })}
            message={message}
            updateRecommendById={updateRecommendById}
            currentImage={currentImage}
            setCurrentImage={setCurrentImage}
            messages={messages}
            miniRecommendMan={miniRecommendMan.map((itemA) => {
              const matchingItemB = messages.find((itemB) => itemB.character.id === itemA.id)
              if (matchingItemB) {
                return {
                  ...itemA,
                  unreadTotal: matchingItemB.unreadTotal
                }
              }
              return itemA
            })}
          />

          <TipsModal type="realtalk" />
        </StandardPageWrapper>
      </>
    )
  }
}
