import React, { useContext } from 'react'

const DuringContext = React.createContext({
  title: '',
  channelId: '',
  liveInfoQuery: {},
  setAnimationItems: (data) => {},
  liveItemsSocket: {}
})

const useDuringContext = () => {
  return useContext(DuringContext)
}

// eslint-disable-next-line react/prop-types
const DuringProvider = ({ children, value }) => {
  return <DuringContext.Provider value={value}>{children}</DuringContext.Provider>
}

export { DuringProvider, useDuringContext }
