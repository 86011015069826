import PropTypes from 'prop-types'
import { useState } from 'react'

const MODE_FREQUENCY = {
  ONE_TIME: '回目のみ',
  EVERY_MULTIPLE: '回以上毎回'
}
const Reward = ({ isPickup, lotteryCount, lotteryFrom, lotteryTo, modeGroupKey }) => {
  const [text] = useState(() => {
    if (modeGroupKey === MODE_FREQUENCY.EVERY_MULTIPLE) {
      return `毎回 ピックアップSSR ${lotteryTo}個以上確定`
    } else if (modeGroupKey === MODE_FREQUENCY.ONE_TIME) {
      return `ピックアップSSR ${lotteryTo}個以上確定`
    }
  })

  const [title] = useState(() => {
    if (lotteryCount === 1) {
      return isPickup ? `11回ガチャ ${lotteryFrom}回目 以降` : `11回ガチャ ${lotteryFrom}回目`
    } else if (lotteryCount === 2) {
      return isPickup ? `1回ガチャ ${lotteryFrom}回目 以降` : `11回ガチャ ${lotteryFrom}回目`
    }
  })

  return (
    <div className="flex flex-col items-center justify-center gap-2 font-semibold">
      <p className={`${lotteryCount === 1 ? 'bg-cornflower-blue' : 'bg-sky-blue'} rounded-[1.3125rem] text-white text-[0.875rem] px-1.5`}>{title}</p>
      <p className="rounded-[1.3125rem] text-cornflower-blue text-[0.9375rem]">{text}</p>
    </div>
  )
}

const RewardBy11RollModal = ({ gachaModes }) => {
  return (
    <>
      <div className="bg-white h-full flex flex-col w-full font-Inter py-4">
        <ul>
          {gachaModes.map((mode) => (
            <li key={mode.id}>
              <Reward
                isPickup={mode.is_pickup}
                lotteryCount={mode.lottery_count}
                lotteryTo={mode.lottery_to}
                lotteryFrom={mode.lottery_from}
                modeGroupKey={mode.mode_group_key}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

RewardBy11RollModal.propTypes = {
  gachaModes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      is_pickup: PropTypes.bool.isRequired,
      lottery_count: PropTypes.number.isRequired,
      lottery_from: PropTypes.number.isRequired,
      lottery_to: PropTypes.number.isRequired,
      mode_group_key: PropTypes.string.isRequired
    })
  ).isRequired
}

Reward.propTypes = {
  isPickup: PropTypes.bool.isRequired,
  lotteryCount: PropTypes.number.isRequired,
  lotteryFrom: PropTypes.number.isRequired,
  lotteryTo: PropTypes.number.isRequired,
  modeGroupKey: PropTypes.string.isRequired
}
export default RewardBy11RollModal
