import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { closeModalButton } from 'src/constants/button_close'
import { HistoryRouteContext } from 'src/routes'
import { MAIN_PATHS } from 'src/routes/main'
import ModalCommon from 'src/components/common/ModalCommon'
import mascotChara from 'src/assets/img/character/mascot/good_mood.png'

const ConditionModal = ({ open, modalType, freeMinutes, setOpenModal, joinLivestream }) => {
  const { redirectTo } = useContext(HistoryRouteContext)

  const onClose = () => {
    setOpenModal(false)
    modalType === 'FREE_PLATINUM_PASSPORT' && joinLivestream()
  }

  const modalButtons = () => {
    if (modalType === 'DREAM_COMPASS') {
      return [
        {
          label: 'キャンセル',
          onClick: () => onClose(),
          type: 'close'
        },
        {
          label: 'ショップへ',
          onClick: () => redirectTo(MAIN_PATHS.ShopPage)
        }
      ]
    }
    return [closeModalButton(modalType === 'FREE_PLATINUM_PASSPORT' ? 'OK' : '閉じる', onClose)]
  }

  return (
    <ModalCommon title="お知らせ" open={open} onClose={onClose} buttons={modalButtons()}>
      <div
        className={`relative space-y-4 bg-white px-5 pt-6 text-center text-[15px] text-[#484848] font-bold ${
          modalType === 'DREAM_COMPASS' ? 'pb-6' : 'pb-14'
        }`}
      >
        {modalType === 'FREE_PLATINUM_PASSPORT' ? (
          <>
            <p>LIVE配信に入場するコケ！</p>
            <p>
              貴女はプラチナパスポートをお持
              <br />
              ちではない為、<span className="text-[#FF0000]">視聴時間は入場か</span>
              <br />
              <span className="text-[#FF0000]">ら{freeMinutes}分間</span>のみ、配信を視聴でき
              <br />
              るョ！
            </p>
            <p>下のOKボタンをタップしてネ♪</p>
            <p>
              ※視聴時間を過ぎると強制的に退
              <br />
              場になるョ！
            </p>
          </>
        ) : modalType === 'DREAM_COMPASS' ? (
          <div className="text-[17px] text-[#6F9BFF] font-semibold">
            <p>
              本日のLIVE配信は
              <br />
              『夢のコンパス有効期限内』の
              <br />
              ユーザー様のみ参加できるコ
              <br />
              ケ！
            </p>
            <p>
              ショップから夢のコンパスを購
              <br />
              入すると参加できるようにな
              <br />
              るョ♪
            </p>
          </div>
        ) : (
          <>
            <p>
              プラチナパスポートをお持ちでな
              <br />
              い為、あなたはLIVE配信に参加で
              <br />
              きないコケ…。
              <br />
              ショップから「プラチナパスポー
              <br />
              ト付きダイヤ」をご購入の上、再
              <br />
              度お越しくださいだコケ！
            </p>
            <p>
              プラチナパスポートは「配信参
              <br />
              加」の他にも「ハート消費量減
              <br />
              少」「回復ハート増加」「LOVE
              <br />
              度上昇率1.5倍」等、恩恵がいっぱ
              <br />
              いだコケ！
            </p>
          </>
        )}
        {modalType !== 'DREAM_COMPASS' && (
          <img className="absolute bottom-3 right-9 w-[73px]" src={mascotChara} />
        )}
      </div>
    </ModalCommon>
  )
}

ConditionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  modalType: PropTypes.string.isRequired,
  freeMinutes: PropTypes.string,
  setOpenModal: PropTypes.func,
  joinLivestream: PropTypes.func
}

export default ConditionModal
