import PropTypes from 'prop-types'
import { CURRENCY_TYPE } from 'src/models/GachaInfo'
import ModalCommon from 'src/components/common/ModalCommon'

import BannerDiamond from 'src/assets/img/gacha/banner_diamond.png'
import BannerTicket from 'src/assets/img/gacha/banner_ticket.png'
import BannerSSRTicket from 'src/assets/img/gacha/banner_ssr_ticket.png'
import BannerDiamondFirst from 'src/assets/img/gacha/banner_diamond_1.png'
import BannerTicketFirst from 'src/assets/img/gacha/banner_ticket_1.png'
import BannerSSRTicketFirst from 'src/assets/img/gacha/banner_ssr_ticket_1.png'

const CapsuleCurrencySelect = ({
  headerText,
  setCurrency,
  handleClose,
  ticket,
  itemsTicketRoll
}) => {
  const checkText = '11回ガチャを引く'
  return (
    <ModalCommon
      buttons={[
        {
          label: '閉じる',
          onClick: handleClose
        }
      ]}
      onClose={handleClose}
      title={headerText}
    >
      <div className="bg-white flex flex-col items-center justify-center py-4 gap-4 font-Inter text-cornflower-blue font-semibold">
        <div className="relative">
          <BannerButton
            image={headerText === checkText ? BannerDiamond : BannerDiamondFirst}
            onClick={() => setCurrency(CURRENCY_TYPE.Diamond)}
            headerText={headerText}
          />
          <p className="text-[0.5625rem] text-center absolute -bottom-2 whitespace-nowrap left-1/2 transform -translate-x-1/2">
            ※青ダイヤから優先的に消費されます
          </p>
        </div>
        <div className="relative">
          <BannerButton
            image={headerText === checkText ? BannerTicket : BannerTicketFirst}
            onClick={() => setCurrency(CURRENCY_TYPE.Ticket)}
            headerText={headerText}
          />
          <p className="text-xs text-center absolute -bottom-2 whitespace-nowrap left-1/2 transform -translate-x-1/2">
            ガチャチケット所有数
            <span className="text-[#FF4747] mx-2">
              {itemsTicketRoll.filter((el) => el.itemKind === 'gacha_ticket')[0]?.amount || 0}
            </span>
            枚
          </p>
        </div>
        {ticket && (
          <div className="relative">
            <BannerButton
              image={headerText === checkText ? BannerSSRTicket : BannerSSRTicketFirst}
              onClick={() => setCurrency(CURRENCY_TYPE.SSRTicket)}
              headerText={headerText}
            />
            <p className="text-xs text-center absolute -bottom-2 whitespace-nowrap left-1/2 transform -translate-x-1/2">
              SSR確定ガチャチケット所有数
              <span className="text-[#FF4747] mx-2">
                {itemsTicketRoll.filter((el) => el.itemKind === 'ssr_ticket')[0]?.amount || 0}
              </span>
              枚
            </p>
          </div>
        )}
        <span className="text-xs text-center mt-4">
          ※ガチャ演出の音量にご注意ください
          <br />
          マナーモードでも音が流れます
        </span>
      </div>
    </ModalCommon>
  )
}

CapsuleCurrencySelect.propTypes = {
  headerText: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  ticket: PropTypes.bool.isRequired,
  itemsTicketRoll: PropTypes.array
}

const BannerButton = ({ image, onClick }) => {
  return (
    <>
      <button type="button" className={'w-72 px-4'} onClick={onClick}>
        <img src={image} />
      </button>
    </>
  )
}

BannerButton.propTypes = {
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  subtext: PropTypes.string
}

export default CapsuleCurrencySelect
