import { useContext, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'

import PropTypes from 'prop-types'

import getHistoryEvents from 'src/services/get/getHistoryEvents'
import { MYPAGE_PATHS } from 'src/routes/myPage'
import { HistoryRouteContext } from 'src/routes'
import useLoading from 'src/components/layouts/hooks/useLoading'

import { Wrapper } from 'src/components/common/Wrapper'
import Loading from 'src/components/layouts/Loading'
import NoContentImage from 'src/components/layouts/NoContentImage'
import Pagination from 'src/components/common/Pagination'
import NoticeLayout from 'src/pages/myPage/Notice/NoticeLayout'
import EventItem from 'src/pages/myPage/Notice/Event/EventItem'

const EventArchiveList = () => {
  window.scrollTo(0, 0)
  const contentRef = useRef(null)
  const { redirectTo } = useContext(HistoryRouteContext)
  const [currPage, setCurrPage] = useState(1)
  const { data: archiveEvents, isLoading } = useQuery({
    queryKey: ['past-events', currPage],
    queryFn: () => getHistoryEvents(currPage)
  })

  useEffect(() => {
    contentRef.current?.scrollTo(0, 0)
  }, [archiveEvents])

  useEffect(() => {
    const pastEventsCurrPage = localStorage.getItem('pastEventsCurrPage')
    if (pastEventsCurrPage) {
      setCurrPage(Number(pastEventsCurrPage))
      localStorage.removeItem('pastEventsCurrPage')
    }
  }, [])

  const { showLoading, progress } = useLoading([isLoading])

  if (showLoading) {
    return (
      <Wrapper>
        <Loading progress={progress} />
      </Wrapper>
    )
  }

  return (
    <NoticeLayout
      title="過去イベント"
      activetab="events"
      classNameHeader="!bg-gradient-sky-to-pink"
    >
      {archiveEvents.events?.length === 0 || archiveEvents.length === 0 ? (
        <NoContentImage message="現在見れる過去の<br />イベントはありません" />
      ) : (
        <div className="w-11/12 mx-auto">
          <div style={{ height: 'calc(100vh - 350px)' }} className="overflow-y-auto hidden-scroll">
            {archiveEvents.events?.map((event) => (
              <EventItem key={event.id} event={{ ...event, pastEventsCurrPage: currPage }} />
            ))}
          </div>

          <Pagination
            totalPage={archiveEvents.total_pages}
            currPage={currPage}
            setCurrPage={setCurrPage}
          />
          <div
            className="bg-sky-blue w-5/6 rounded-2xl text-white py-2 mt-4 font-semibold mx-auto"
            onClick={() => redirectTo(MYPAGE_PATHS.EventListPage)}
          >
            開催中のイベントを見る
          </div>
        </div>
      )}
    </NoticeLayout>
  )
}

EventArchiveList.propTypes = {
  setViewPastEvents: PropTypes.func
}

export default EventArchiveList
