import React from 'react'
import PropTypes from 'prop-types'
import LiveWaiting from 'src/assets/img/mypage/live_waiting.png'
import lightningIcon from 'src/pages/voiceLive/assets/lightning_icon.png'

const Booth = ({ title, handleScrollToSchedule }) => {
  const imgClassName = 'absolute w-full h-full object-cover scale-[1.4]'
  const boothContainer = 'relative overflow-hidden border-[1.5px] border-solid rounded-lg'

  return (
    <div className="flex flex-col relative w-full">
      <div className='text-[#4C9DFF] absolute left-0 z-10 -top-4 text-xs'>
        <img src={lightningIcon} className='inline-block mr-1' />
        {title}ブース
      </div>
      <div className={`${boothContainer} border-[#878787] text-white text-xs h-[186px] w-full`}>
        <img src={LiveWaiting} className={imgClassName} style={{ transformOrigin: '70px 0' }} />
        <div className="absolute w-full h-full opacity-80 bg-[#3A3A3A]" />
        <div className="absolute text-[#9AC8FF] top-[45%] left-1/2 -translate-x-1/2">配信準備中</div>
        <div
          className="absolute bottom-3.5 left-1/2 -translate-x-1/2 bg-transparent rounded-3xl border-[#9AC8FF] border-[3.5px] w-[135px] py-0.5 px-2.5 whitespace-nowrap cursor-pointer"
          onClick={handleScrollToSchedule}
        >
          LIVEスケジュールを
          <br />
          確認する
        </div>
      </div>
    </div>
  )
}

Booth.propTypes = {
  title: PropTypes.string,
  handleScrollToSchedule: PropTypes.func
}

export default Booth
