/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import styled from 'styled-components'
import { genGachaTimeString } from 'src/lib/date'
import { useAuthState } from 'src/providers/AuthProviders'
import { DRAW_TYPE } from 'src/models/GachaInfo'

import GradientButton from 'src/components/common/GradientButton'
import DialogBox from 'src/components/common/DialogBox'
import ModalCommon from 'src/components/common/ModalCommon'
import RewardBy11RollModal from 'src/pages/main/capsule/components/RewardBy11RollModal'
import CapsuleDetailsModal from 'src/pages/main/capsule/CapsuleDetailsModal'

import arrow from 'src/assets/img/menu/btn_pink/vector.png'

const CapsuleSlide = ({
  image,
  startAt,
  endAt,
  showDrawModal,
  gachasMode,
  setTicket,
  useSSGachaTicket,
  countTimeRollCurrent,
  gachaDetail
}) => {
  const { user } = useAuthState()
  const [modalContent, setModalContent] = useState(null)
  const [buttons, setButtons] = useState([])
  const [title, setTitle] = useState('')
  const dreamCompass = user.dream_compass
  const isDisabled =
    !dreamCompass || gachaDetail.use_compass_user === false || !gachaDetail.use_compass
  const [isReady, setIsReady] = useState(false)
  const checkButonRoll = () => {
    return (
      <>
        <div className="w-[30%] relative">
          <DialogBox
            text="毎日1回無料"
            position="-top-3 left-1/2"
            background="#6F9BFF"
            border="1px solid white"
          />
          <GradientButton
            textStyle="text-lg"
            buttonStyle="aspect-[114/40]"
            title="1回ガチャ"
            disabled={isDisabled}
            onClick={handleDrawOneTimeFree}
          />
          <p
            className="text-cornflower-blue absolute font-semibold text-[0.625rem] whitespace-nowrap z-10"
            style={{
              textShadow: '-2px 0 white, 2px 0 white, 0 2px white, 0 -2px white',
              filter: 'drop-shadow(0px 0px 2px #ffffff)',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '80%'
            }}
          >
            夢のコンパス所有者のみ
          </p>
        </div>
      </>
    )
  }

  const handleCloseModal = () => {
    setModalContent(null)
    setTitle('')
    setButtons([])
  }

  const handleOpenModal = (title, buttons, modalContent) => {
    setTitle(title)
    setButtons(buttons)
    setModalContent(modalContent)
  }
  const handleClickRewardBy11RollCountButton = () => {
    handleOpenModal(
      'ガチャ回数ボーナス',
      [
        {
          label: '閉じる',
          onClick: () => handleCloseModal()
        }
      ],
      <RewardBy11RollModal gachaModes={gachasMode} />
    )
  }
  const handleAboutReward = () => {
    handleOpenModal(
      '提供割合',
      [
        {
          label: '閉じる',
          onClick: () => handleCloseModal()
        }
      ],
      <CapsuleDetailsModal handleClose={handleCloseModal} gachaDetail={gachaDetail} />
    )
  }
  const handleDrawOneTimeFree = () => {
    if (!isDisabled) {
      showDrawModal(DRAW_TYPE.Free)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => setIsReady(true), 500)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div>
      {modalContent && (
        <ModalCommon
          open={!!modalContent}
          buttons={buttons}
          title={title}
          onClose={handleCloseModal}
        >
          {modalContent}
        </ModalCommon>
      )}
      <ResponsiveSlide
        className="w-full aspect-[13/10] bg-light-pink rounded-lg relative drop-shadow-md"
        style={{
          backgroundImage: `url('${image}')`,
          backgroundSize: '100% 100%',
          objectFit: 'cover'
        }}
      >
        <div className={clsx('absolute w-full bottom-[12%]')}>
          <div
            className={clsx(
              isReady
                ? 'flex justify-center gap-2 items-center py-3 bg-[rgba(0,0,0,0.20)]'
                : 'hidden',
              'w-full'
            )}
          >
            {checkButonRoll()}
            <div className="w-[30%]">
              {countTimeRollCurrent?.one_time_roll > 0 && (
                <DialogBox
                  textStyle="text-cornflower-blue text-xs font-Inter font-semibold"
                  bottomBorder="#6F9BFF"
                  text={`${countTimeRollCurrent?.one_time_roll}回目`}
                  position="-top-0 left-[50%]"
                  background="white"
                  border="1px solid"
                />
              )}
              <GradientButton
                buttonStyle="aspect-[114/40]"
                textStyle="text-lg"
                title="1回ガチャ"
                onClick={() => {
                  showDrawModal(DRAW_TYPE.One)
                  setTicket(useSSGachaTicket)
                }}
              />
            </div>
            <div className="w-[30%] relative">
              {countTimeRollCurrent?.eleven_time_roll > 0 && (
                <DialogBox
                  textStyle="text-cornflower-blue text-xs font-Inter font-semibold"
                  bottomBorder="#6F9BFF"
                  text={`${countTimeRollCurrent?.eleven_time_roll}回目`}
                  position="-top-3 left-[50%]"
                  background="white"
                  border="1px solid"
                />
              )}
              <GradientButton
                buttonStyle="aspect-[114/40]"
                textStyle="text-lg"
                title="11回ガチャ"
                onClick={() => {
                  showDrawModal(DRAW_TYPE.Eleven)
                  setTicket(useSSGachaTicket)
                }}
              />
            </div>
          </div>
          <StyledContainer
          >
            <div className="bg-[#787878] px-2 rounded-3xl py-[2px]">
              {genGachaTimeString(startAt, endAt)}
            </div>
            <div className="flex gap-1">
              <StyledInfoButton onClick={handleClickRewardBy11RollCountButton}>
                <p>回数報酬</p>
                <img src={arrow} className="!w-[5px] h-[10px]" />
              </StyledInfoButton>
              <StyledInfoButton onClick={handleAboutReward}>
                <p>提供割合について</p>
                <img src={arrow} className="!w-[5px] h-[10px]" />
              </StyledInfoButton>
            </div>
          </StyledContainer>
        </div>
      </ResponsiveSlide>
    </div>
  )
}

CapsuleSlide.propTypes = {
  image: PropTypes.string,
  startAt: PropTypes.string.isRequired,
  endAt: PropTypes.string.isRequired,
  gachasMode: PropTypes.array.isRequired,
  showDrawModal: PropTypes.func.isRequired,
  gachaDetail: PropTypes.object
}

const ResponsiveSlide = styled.div``

const StyledInfoButton = styled.button.attrs(() => ({
  type: 'button',
  className:
    'bg-cornflower-blue py-[0.0625rem] py-[2px] px-2 flex items-center gap-2 font-medium text-white'
}))`
  border-radius: 27px;
  font-family: 'MPlus1', sans-serif;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  img {
    width: 5px;
    height: 10px;
  }
`

const StyledContainer = styled.div`
  @media (min-width: 395px) {
    font-size: 0.75rem; /* sm:text-xs */
  }
  position: absolute;
  display: flex;
  color: white;
  font-family: 'MPlus1', sans-serif; /* font-MPlus1 */
  font-weight: 500; /* font-medium */
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 2%; /* px-2 */
  bottom: -1.5rem; /* -bottom-6 */
  font-size: 11px;
  @media (max-width: 639px) {
    padding-left: 3%;
    padding-right: 3%; /* xs:px-[3%] */
  }
`

export default CapsuleSlide
