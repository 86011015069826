import { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import clsx from 'clsx'
import { HistoryRouteContext } from 'src/routes'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './event_slide_banner.css'

const EventSlideBanner = ({ events }) => {
  const { redirectTo } = useContext(HistoryRouteContext)

  const getLinkTo = useMemo(
    () => (event) => event.displayLink ? `/${event.link}` : `/notice/events/detail/${event.id}`
  )

  const filteredEvents = useMemo(() => {
    return events
      .filter((event) => !event.displayPeriodSetting && event.displayOnTheMyPage)
      .slice(0, 7)
  }, [events])

  return (
    <CarouselContainer>
      <Carousel
        autoPlay
        infiniteLoop
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        renderIndicator={(onClickHandler, isSelected) => (
          <div
            onClick={onClickHandler}
            className={clsx(
              'w-2.5 h-2.5 rounded-full mr-1.5',
              isSelected ? 'bg-blue-800' : 'border border-slate-400'
            )}
          />
        )}
      >
        {filteredEvents.map((event) => (
          <div
            onClick={() => redirectTo(getLinkTo(event))}
            key={event.id}
            className="h-[88px] overflow-hidden rounded-2xl"
          >
            <img className="h-full object-cover" src={event.image} />
          </div>
        ))}
      </Carousel>
    </CarouselContainer>
  )
}

EventSlideBanner.propTypes = {
  events: PropTypes.array.isRequired
}

export default EventSlideBanner

const CarouselContainer = styled.div.attrs({
  className: 'carousel-wrapper cursor-pointer px-2 custom-event-slide'
})``
