import PropTypes from 'prop-types'
import TitleHeader from 'src/components/common/TitleHeader'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import Btn from 'src/assets/img/common/btn.png'
import MessageError from 'src/assets/img/character/mascot/sorry.png'
// import { useCallback } from 'react'

export const ForbiddenMessageModal = ({ handleClose }) => {
  return (
    <div className="w-full m-4 px-4 pt-4 pb-8 bg-popup-l bg-fill bg-no-repeat rounded-md flex flex-col items-center">
      <ModalCloseButton handleClose={handleClose} />
      <TitleHeader
        title={'送信出来ません'}
        className="my-6"
      />
      <div className="mt-6 text-lg font-vldLineGR font-normal truncate">
        <p>
          不適切な文字列が含まれている<br />
          為、送信できなかったコケッ
        </p>
      </div>
      <div className="flex justify-end w-full">
        <img className="w-32" src={MessageError} alt="" />
      </div>
      <button
        className="text-xl font-vldLineGR text-white text-center font-normal leading-[126%] truncate"
        style={{ backgroundImage: `url('${Btn}')`, backgroundSize: '100% 100%', padding: '16px 40px' }}
        onClick={handleClose}
      >
        閉じる
      </button>

    </div>
  )
}

ForbiddenMessageModal.propTypes = {
  handleClose: PropTypes.func.isRequired
}
