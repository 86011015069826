import PropTypes from 'prop-types'
import Phone from 'src/assets/img/common/phone.png'

const StartLoading = ({ progress = 0 }) => {
  const containerStyle = {
    width: '100%',
    overflow: 'hidden',
    marginTop: '30%',
    position: 'relative',
    borderRadius: '8px',
    clipPath: `inset(${100 - progress}% 0% 0% 0%)`,
    transition: 'clip-path 0.5s linear'
  }

  const imageStyle = {
    width: '168px',
    height: '101px',
    display: 'block',
    margin: '0 auto',
    objectFit: 'cover'
  }

  return (
    <div style={containerStyle}>
      <img src={Phone} alt="Phone" style={imageStyle} />
    </div>
  )
}

StartLoading.propTypes = {
  progress: PropTypes.number,
  onComplete: PropTypes.func
}

export default StartLoading
